import listToCollectionById from '@/utils/listToCollectionById'

export const ADVENTURE_TRAUMA_HEAR_LEFT = 'adventure_trauma_hear_left'
export const ADVENTURE_TRAUMA_HEAR_RIGHT = 'adventure_trauma_hear_right'
export const ADVENTURE_TRAUMA_ARM_FINGERS_LEFT = 'adventure_trauma_arm_fingers_left'
export const ADVENTURE_TRAUMA_ARM_FINGERS_RIGHT = 'adventure_trauma_arm_fingers_right'
export const ADVENTURE_TRAUMA_LEG_FINGERS_LEFT = 'adventure_trauma_leg_fingers_left'
export const ADVENTURE_TRAUMA_LEG_FINGERS_RIGHT = 'adventure_trauma_leg_fingers_right'
export const ADVENTURE_TRAUMA_SOMETIMES = 'adventure_trauma_sometimes'
export const ADVENTURE_TRAUMA_HEALED = 'adventure_trauma_healed'
export const ADVENTURE_DISEASE_COUGH = 'adventure_disease_cough'
export const ADVENTURE_DISEASE_SMALLPOX = 'adventure_disease_smallpox'
export const ADVENTURE_DISEASE_WHITE_HAIR = 'adventure_disease_white_hair'
export const ADVENTURE_POISONED_TRAP = 'adventure_poisoned_trap'
export const ADVENTURE_POISONED_MONSTER = 'adventure_poisoned_monster'
export const ADVENTURE_LOST_TRINKET = 'adventure_lost_trinket'
export const ADVENTURE_FRIGHTEN_ABANDON = 'adventure_frighten_abandon'
export const ADVENTURE_LEARNED = 'adventure_learned'
export const ADVENTURE_TREASURE_SMALL = 'adventure_treasure_small'
export const ADVENTURE_TREASURE_BIG = 'adventure_treasure_big'
export const ADVENTURE_MAGIC_ITEM = 'adventure_magic_item'

const scarsText = `★ПЕРСОНАЖ★ ★был★ на волоске от смерти. ★Его★ тело уродуют скверные шрамы, и недостаёт`
const illText = `★ПЕРСОНАЖ★ ★заразился★ какой-то болезнью, шастая по нечистотам. ★Он★ ★излечился★ от неё, но теперь у ★него★`
const poisonedDisadvantageText = `★Он★ ★восстановился★, но в следующий раз, когда ★ему★ придётся проходить испытание против яда, ★он★ ★получит★ помеху на это испытание.`
const treasureText = `сокровище во время ★его★ приключения. У ★него★ осталось ★DICE★ зм от ★его★ доли.`

const adventureList = [
  {
    name: `${scarsText} левого уха.`,
    id: ADVENTURE_TRAUMA_HEAR_LEFT,
    probabilityWeight: 2,
  },
  {
    name: `${scarsText} правого уха.`,
    id: ADVENTURE_TRAUMA_HEAR_RIGHT,
    probabilityWeight: 2,
  },
  {
    name: `${scarsText} ★DICE★ пальцев на левой руке.`,
    id: ADVENTURE_TRAUMA_ARM_FINGERS_LEFT,
    dice: {
      diceCount: 1,
      diceType: 3,
    },
    probabilityWeight: 2,
  },
  {
    name: `${scarsText} ★DICE★ пальцев на правой руке.`,
    id: ADVENTURE_TRAUMA_ARM_FINGERS_RIGHT,
    dice: {
      diceCount: 1,
      diceType: 3,
    },
    probabilityWeight: 2,
  },
  {
    name: `${scarsText} ★DICE★ пальцев на левой ноге.`,
    id: ADVENTURE_TRAUMA_LEG_FINGERS_LEFT,
    dice: {
      diceCount: 1,
      diceType: 4,
    },
    probabilityWeight: 2,
  },
  {
    name: `${scarsText} ★DICE★ пальцев на правой ноге.`,
    id: ADVENTURE_TRAUMA_LEG_FINGERS_RIGHT,
    dice: {
      diceCount: 1,
      diceType: 4,
    },
    probabilityWeight: 4,
  },
  {
    name: `★ПЕРСОНАЖ★ ★получил★ тяжёлую травму. Хотя рана зажила, она всё равно время от времени даёт о себе знать.`,
    id: ADVENTURE_TRAUMA_SOMETIMES,
    probabilityWeight: 12,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★ранен★, но со временем полностью ★выздоровел★.`,
    id: ADVENTURE_TRAUMA_HEALED,
    probabilityWeight: 12,
  },
  {
    name: `${illText} постоянный кашель.`,
    id: ADVENTURE_DISEASE_COUGH,
    probabilityWeight: 4,
  },
  {
    name: `${illText} оспины на коже.`,
    id: ADVENTURE_DISEASE_SMALLPOX,
    probabilityWeight: 4,
  },
  {
    name: `${illText} не по возрасту седые волосы.`,
    id: ADVENTURE_DISEASE_WHITE_HAIR,
    probabilityWeight: 4,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★отравлен★ ловушкой. ${poisonedDisadvantageText}`,
    id: ADVENTURE_POISONED_TRAP,
    probabilityWeight: 6,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★отравлен★ монстром. ${poisonedDisadvantageText}`,
    id: ADVENTURE_POISONED_MONSTER,
    probabilityWeight: 6,
  },
  {
    name: `★ПЕРСОНАЖ★ ★потерял★ что-то сентиментально важное для ★него★ во время приключения. Уберите одну безделушку из ★его★ вещей.`,
    id: ADVENTURE_LOST_TRINKET,
    probabilityWeight: 12,
  },
  {
    name: `★ПЕРСОНАЖ★ ужасно ★испугался★ чего-то, с чем ★он★ ★столкнулся★, после чего ★он★ ★сбежал★, бросив товарищей на произвол судьбы.`,
    id: ADVENTURE_FRIGHTEN_ABANDON,
    probabilityWeight: 12,
  },
  {
    name: `★ПЕРСОНАЖ★ многому ★научился★ во время ★его★ приключений. В следующий раз, когда ★он★ ★будет★ совершать проверку характеристики или проходить испытание, ★он★ ★получит★ преимущество на этот бросок.`,
    id: ADVENTURE_LEARNED,
    probabilityWeight: 12,
  },
  {
    name: `★ПЕРСОНАЖ★ ★обнаружил★ небольшое ${treasureText}`,
    id: ADVENTURE_TREASURE_SMALL,
    dice: {
      diceCount: 2,
      diceType: 6,
    },
    probabilityWeight: 12,
  },
  {
    name: `★ПЕРСОНАЖ★ ★нашёл★ большое ${treasureText}`,
    id: ADVENTURE_TREASURE_BIG,
    dice: {
      diceCount: 1,
      diceType: 20,
      diceBOnus: 50,
    },
    probabilityWeight: 12,
  },
  {
    name: `★ПЕРСОНАЖ★ ★обнаружил★ обычный магический предмет (по выбору Мастера).`,
    id: ADVENTURE_MAGIC_ITEM,
    probabilityWeight: 12,
  },
]

export default adventureList
export const adventureCollection = listToCollectionById(adventureList)
