import listToCollectionById from '@/utils/listToCollectionById'

export const PALADIN_CHOICE_HOLY_QUEST = 'paladin_choice_holy_quest'
export const PALADIN_CHOICE_ANCESTOR_QUEST = 'paladin_choice_ancestor_quest'
export const PALADIN_CHOICE_BEACON_OF_LIGHT = 'paladin_choice_beacon_of_light'
export const PALADIN_CHOICE_SQUIRE = 'paladin_choice_squire'
export const PALADIN_CHOICE_CLEAN_EVIL = 'paladin_choice_clean_evil'
export const PALADIN_CHOICE_HOLY_SWORD = 'paladin_choice_holy_sword'

const paladinChoiceList = [
  {
    name: `Передо мной появилось фантастическое существо и призвало меня выполнить священную миссию.`,
    id: PALADIN_CHOICE_HOLY_QUEST,
    probabilityWeight: 1,
  },
  {
    name: `Один из моих предков оставил священную миссию невыполненной, и я ★намерен★ завершить его дело.`,
    id: PALADIN_CHOICE_ANCESTOR_QUEST,
    probabilityWeight: 1,
  },
  {
    name: `Мир — это тёмное и ужасное место. Я ★решил★ служить маяком света в сгущающихся тенях.`,
    id: PALADIN_CHOICE_BEACON_OF_LIGHT,
    probabilityWeight: 1,
  },
  {
    name: `Я ★служил★ оруженосцем у паладина, учась всему, что нужно для принесения собственной священной клятвы.`,
    id: PALADIN_CHOICE_SQUIRE,
    probabilityWeight: 1,
  },
  {
    name: `Злу нужно противостоять на всех фронтах. Я ощущаю необходимость искать зло и вычищать его из этого мира.`,
    id: PALADIN_CHOICE_CLEAN_EVIL,
    probabilityWeight: 1,
  },
  {
    name: `Стать паладином — естественное следствие моей непоколебимой веры. Приняв клятву, я ★стал★ святым мечом моей религии.`,
    id: PALADIN_CHOICE_HOLY_SWORD,
    probabilityWeight: 1,
  },
]

export default paladinChoiceList
export const paladinChoiceCollection = listToCollectionById(paladinChoiceList)
