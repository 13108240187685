import INCH_PER_FEET from '@/constants/INCH_PER_FEET'
import INCH_PER_SM from '@/constants/INCH_PER_SM'

import generateWeightText from '@/utils/generateWeightText'

export default ({ tall, weight }) => {
  const feet = Math.floor(tall / INCH_PER_FEET)
  const inches = tall % INCH_PER_FEET
  const tallText = inches
    ? `${feet}' ${inches}"`
    : `${feet}'`
  const tallSm = Math.round(tall / INCH_PER_SM)
  const weightText = generateWeightText(weight)

  return tall && weight
    ? `Рост ${tallText} (${tallSm} см), вес ${weightText}`
    : ''
}
