const {
  BACKGROUND_COURTIER,
  BACKGROUND_GUILD_ARTISAN,
} = require('./../../backgroundIdList')
const {LANG_ANY} = require('./../../languageIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  GEAR_POUCH,
  GEAR_CLOTHES_FINE,
} = require('./../../gearIdList')
const {
  SKILL_INSIGHT,
  SKILL_PERSUASION,
} = require('./../../skillList')
const guild_artisan = require('./guild_artisan')

module.exports = {
  id: BACKGROUND_COURTIER,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Придворный',
    [GENDER_FEMALE]: 'Придворная',
  },
  nameEn: 'Courtier',
  description: [
    {
      header: 'Придворный',
      text: `Когда-то, Вы обладали определенным положением среди знатного двора или какой-либо бюрократической организации. Вне зависимости от того, принадлежали Вы к членам знатной семьи или нет — именно Ваши таланты, а не обстоятельства Вашего рождения, обеспечили Ваше положение.

Были ли Вы одним из множества чиновников, обслуживающего персонала, и прочих прихлебателей при дворе Сильверимуна, или возможно Вы были вхожи в один из вычурных и, иногда, кровожадных гильдейских конгломератов, знати, искателей приключений или секретных обществ Глубоководья. А может быть Вы могли бы быть одним из закулисных хранителей закона или функционеров Врат Балдура или Невервинтера, или, быть может, Вы выросли в округе или самом замке Даггерфорд.

Даже если Вы больше не являетесь полноправным членом группы, которая обеспечила Вам путевку в жизнь, Ваши отношения с бывшим коллегами могут быть преимуществом для Вас и Ваших товарищей по поиску приключений. Вы могли бы выполнять задания с Вашим новым товарищам для продвижения интересов этой организации, давшей Вам в жизни старт. В любом случае, способности, которые Вы отточили в бытность придворным, сослужат Вам хорошую службу в Ваших приключениях.`,
      source: {
        id: SOURCE_SCAG,
        page: 147,
      },
    },
    {
      header: 'Умение: Придворный функционер',
      text: `Ваше знание принципов работы бюрократии даёт Вам доступ к записям и внутренним работам любого королевского двора или правительства, которое Вы можете повстречать. Вы знаете, кто является воротилой, к кому обратиться, чтобы удовлетворить Ваше прошение и какие на данный момент интриги плетутся.`,
      source: {
        id: SOURCE_SCAG,
        page: 148,
      },
    },
    {
      header: 'Предлагаемые характеристики',
      text: `Используйте таблицы для предыстории [гильдейского ремесленника](BACKGROUND:${BACKGROUND_GUILD_ARTISAN}), как основу для Ваших черт характера и мотивов, изменяя их содержание при необходимости, чтобы более точно описать Вашу личность в качестве придворного.

Королевский двор или бюрократическая организация, с которой Вы начинали, явно или не напрямую связана с Вашей привязанностью (которая может относиться к определённым личностям в группе, таким как Ваш спонсор или ментор). Ваши идеалы могу совпадать с основной линией философии Вашего двора или организации.`,
      source: {
        id: SOURCE_SCAG,
        page: 148,
      },
    },
    ...guild_artisan.description.slice(4),
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_INSIGHT,
        SKILL_PERSUASION,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: `
* [Комплект отличной одежды](GEAR:${GEAR_CLOTHES_FINE}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 5 зм.
`,
  source: {
    id: SOURCE_SCAG,
    page: 147,
  },
}
