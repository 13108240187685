import listToCollectionById from '@/utils/listToCollectionById'
import { GENDER_MALE } from '@/constants/genderList'
import { PARAM_CHA } from '@/constants/paramList'

export const APPEARANCE_BALD = 'bald'
export const APPEARANCE_BEARD = 'beard'
export const APPEARANCE_BEAUTY = 'beauty'
export const APPEARANCE_BRACELET = 'bracelet'
export const APPEARANCE_BRAID = 'braid'
export const APPEARANCE_CLOTHES_ALIEN = 'clothes_alien'
export const APPEARANCE_CLOTHES_DIRTY = 'clothes_dirty'
export const APPEARANCE_CLOTHES_OFFICIAL = 'clothes_official'
export const APPEARANCE_CLOTHES_WIDE = 'clothes_wide'
export const APPEARANCE_DIADEM = 'diadem'
export const APPEARANCE_DIFFERENT_EYE_COLOR = 'different_eye_color'
export const APPEARANCE_EARRING = 'earring'
export const APPEARANCE_FINGER_MISSED = 'finger_missed'
export const APPEARANCE_MOLE = 'mole'
export const APPEARANCE_NECKLACE = 'necklace'
export const APPEARANCE_NOSE = 'nose'
export const APPEARANCE_PIERCING = 'piercing'
export const APPEARANCE_POSTURE_STRAIGHT = 'posture_straight'
export const APPEARANCE_POSTURE_TWISTED = 'posture_twisted'
export const APPEARANCE_SCAR = 'scar'
export const APPEARANCE_SKIN_COLOR = 'skin_color'
export const APPEARANCE_TATOO = 'tatoo'
export const APPEARANCE_TICK = 'tick'
export const APPEARANCE_TOOTH_MISSED = 'tooth_missed'
export const APPEARANCE_UGLINESS = 'ugliness'
export const APPEARANCE_UNCOMMON_EYE_COLOR = 'uncommon_eye_color'

const appearanceList = [
  {
    name: `Приметные серьги`,
    id: APPEARANCE_EARRING,
    probabilityWeight: 1,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Приметное ожерелье`,
    id: APPEARANCE_NECKLACE,
    probabilityWeight: 1,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Приметная диадема`,
    id: APPEARANCE_DIADEM,
    probabilityWeight: 1,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Приметные браслеты`,
    id: APPEARANCE_BRACELET,
    probabilityWeight: 1,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Пышная одежда`,
    id: APPEARANCE_CLOTHES_WIDE,
    probabilityWeight: 2,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Чужеземная одежда`,
    id: APPEARANCE_CLOTHES_ALIEN,
    probabilityWeight: 2,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Необычный цвет глаз`,
    id: APPEARANCE_UNCOMMON_EYE_COLOR,
    probabilityWeight: 2,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Глаза разного цвета`,
    id: APPEARANCE_DIFFERENT_EYE_COLOR,
    probabilityWeight: 2,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Необычный цвет кожи`,
    id: APPEARANCE_SKIN_COLOR,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Приметный нос`,
    id: APPEARANCE_NOSE,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: -1,
    },
  },
  {
    name: `Борода`,
    id: APPEARANCE_BEARD,
    genderId: GENDER_MALE,
    probabilityWeight: 2,
    paramBonuses: {
      [PARAM_CHA]: +1,
    },
  },
  {
    name: `Волосы, заплетённые в косы`,
    id: APPEARANCE_BRAID,
    probabilityWeight: 2,
    paramBonuses: {
      [PARAM_CHA]: +1,
    },
  },
  {
    name: `Официальная, чистая одежда`,
    id: APPEARANCE_CLOTHES_OFFICIAL,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: +2,
    },
  },
  {
    name: `Грязная и драная одежда`,
    id: APPEARANCE_CLOTHES_DIRTY,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: -2,
    },
  },
  {
    name: `Татуировки`,
    id: APPEARANCE_TATOO,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: -1,
    },
  },
  {
    name: `Нервный тик`,
    id: APPEARANCE_TICK,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: -2,
    },
  },
  {
    name: `Родинка`,
    id: APPEARANCE_MOLE,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: -1,
    },
  },
  {
    name: `Пирсинг`,
    id: APPEARANCE_PIERCING,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Отсутствующий зуб`,
    id: APPEARANCE_TOOTH_MISSED,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: -3,
    },
  },
  {
    name: `Отсутствующий палец`,
    id: APPEARANCE_FINGER_MISSED,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: -2,
    },
  },
  {
    name: `Заметный шрам`,
    id: APPEARANCE_SCAR,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: -1,
    },
  },
  {
    name: `Лысина`,
    id: APPEARANCE_BALD,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: -1,
    },
  },
  {
    name: `Скрюченная осанка`,
    id: APPEARANCE_POSTURE_TWISTED,
    probabilityWeight: 2,
    paramBonuses: {
      [PARAM_CHA]: -3,
    },
  },
  {
    name: `Прямая осанка`,
    id: APPEARANCE_POSTURE_STRAIGHT,
    probabilityWeight: 2,
    paramBonuses: {
      [PARAM_CHA]: +2,
    },
  },
  {
    name: `Невероятная красота`,
    id: APPEARANCE_BEAUTY,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: +4,
    },
  },
  {
    name: `Невероятное уродство`,
    id: APPEARANCE_UGLINESS,
    probabilityWeight: 4,
    paramBonuses: {
      [PARAM_CHA]: -4,
    },
  },
]

export default appearanceList
export const appearanceCollection = listToCollectionById(appearanceList)
