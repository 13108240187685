import listToCollectionById from '@/utils/listToCollectionById'

export const CRIME_ABDUCTION = 'crime_abduction'
export const CRIME_ATTACK = 'crime_attack'
export const CRIME_FAKE_DOCS = 'crime_fake_docs'
export const CRIME_FAKE_GOLD = 'crime_fake_gold'
export const CRIME_MURDER = 'crime_murder'
export const CRIME_RACKET = 'crime_racket'
export const CRIME_ROBBERY = 'crime_robbery'
export const CRIME_SMUGGLING = 'crime_smuggling'
export const CRIME_STEAL = 'crime_steal'

const crimeList = [
  {
    name: `убийство`,
    id: CRIME_MURDER,
    probabilityWeight: 2,
  },
  {
    name: `кражу`,
    id: CRIME_STEAL,
    probabilityWeight: 2,
  },
  {
    name: `грабёж`,
    id: CRIME_ROBBERY,
    probabilityWeight: 2,
  },
  {
    name: `нападение`,
    id: CRIME_ATTACK,
    probabilityWeight: 2,
  },
  {
    name: `контрабанду`,
    id: CRIME_SMUGGLING,
    probabilityWeight: 2,
  },
  {
    name: `похищение`,
    id: CRIME_ABDUCTION,
    probabilityWeight: 2,
  },
  {
    name: `вымогательство`,
    id: CRIME_RACKET,
    probabilityWeight: 2,
  },
  {
    name: `подделку золота`,
    id: CRIME_FAKE_GOLD,
    probabilityWeight: 1,
  },
  {
    name: `подделку документов`,
    id: CRIME_FAKE_DOCS,
    probabilityWeight: 1,
  },
]

export default crimeList
export const crimeCollection = listToCollectionById(crimeList)
