import listToCollectionById from '@/utils/listToCollectionById'

import {
  CREATURE_COMMONER,
  CREATURE_RIDING_HORSE,
} from '@/constants/creatureIdList'
import {
  GEAR_POTION_OF_HEALING,
  GEAR_ACID,
} from '@/constants/gearIdList'
import {
  OCCUPATION_ALCHEMIST,
  OCCUPATION_NOBLE,
  OCCUPATION_WIZARD,
} from '@/constants/occupationVariantList'

import {
  VARIANT_CANTRIP,
  VARIANT_GOD,
  VARIANT_SIMPLE_WEAPON,
  VARIANT_TRINKET,
} from './variantIdList'

export const BOON_ACID_VIAL = 'boon_acid_vial'
export const BOON_GOLD = 'boon_gold'
export const BOON_HEAL_POTION = 'boon_heal_potion'
export const BOON_HORSE = 'boon_horse'
export const BOON_INHERITANCE = 'boon_inheritance'
export const BOON_SAVIOR = 'boon_savior'
export const BOON_SCROLL = 'boon_scroll'
export const BOON_TEMPLE = 'boon_temple'
export const BOON_TREASURE_MAP = 'boon_treasure_map'
export const BOON_TRINKET = 'boon_trinket'
export const BOON_WEAPON = 'boon_weapon'

const giftPreText = `★ПЕРСОНАЖ★ ★получил★`
const giftPostText = `в подарок от`

const boonList = [
  {
    name: `${giftPreText} свиток с заговором «${VARIANT_CANTRIP}» ${giftPostText} дружественного волшебника.`,
    id: BOON_SCROLL,
    characterGenerationParams: {
      relationText: 'дружественный волшебник',
      occupationId: OCCUPATION_WIZARD,
    },
    detailDictIdList: VARIANT_CANTRIP,
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★спас★ [обывателя](CREATURE:${CREATURE_COMMONER}), который теперь обязан ★ему★ жизнью. Эта личность сопровождает ★его★ в путешествиях и выполняет для ★него★ повседневные задачи. Если ★ПЕРСОНАЖ★ ★будет★ с ним обращаться грубо, наплевательски или ★станет★ подвергать его опасности, этот персонаж покинет ★его★. Определите особенности этого персонажа, используя дополнительные таблицы и обсудив со своим Мастером.`,
    id: BOON_SAVIOR,
    characterGenerationParams: {
      relationText: 'спасённый обыватель',
    },
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★нашёл★ [ездовую лошадь](CREATURE:${CREATURE_RIDING_HORSE}).`,
    id: BOON_HORSE,
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★нашёл★ немного золота. Добавьте ★DICE★ зм к ★его★ стандартному начальному богатству.`,
    id: BOON_GOLD,
    dice: {
      diceCount: 1,
      diceType: 20,
    },
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★получил★ в наследство ${VARIANT_SIMPLE_WEAPON}.`,
    id: BOON_WEAPON,
    detailDictIdList: VARIANT_SIMPLE_WEAPON,
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★нашёл★ нечто интересное! Добавьте к ★его★ снаряжению безделушку: ${VARIANT_TRINKET}.`,
    id: BOON_TRINKET,
    detailDictIdList: VARIANT_TRINKET,
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ когда-то ★прислуживал★ в местном храме ${VARIANT_GOD}. При следующем вашем визите туда ★ему★ могут полностью восстановить хиты.`,
    id: BOON_TEMPLE,
    detailDictIdList: VARIANT_GOD,
    probabilityWeight: 2,
  },
  {
    name: `${giftPreText} [зелье лечения](GEAR:${GEAR_POTION_OF_HEALING}) ${giftPostText} знакомого алхимика.`,
    id: BOON_HEAL_POTION,
    characterGenerationParams: {
      relationText: 'знакомый алхимик',
      occupationId: OCCUPATION_ALCHEMIST,
    },
    probabilityWeight: 1,
  },
  {
    name: `${giftPreText} флакон [кислоты](GEAR:${GEAR_ACID}) ${giftPostText} знакомого алхимика.`,
    id: BOON_ACID_VIAL,
    characterGenerationParams: {
      relationText: 'знакомый алхимик',
      occupationId: OCCUPATION_ALCHEMIST,
    },
    probabilityWeight: 1,
  },
  {
    name: `★ПЕРСОНАЖ★ ★нашёл★ карту сокровищ.`,
    id: BOON_TREASURE_MAP,
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★получил★ стипендию от дальнего родственника, позволяющую ★ему★ вести _комфортное существование_ в течение ★DICE★ лет. Если ★ПЕРСОНАЖ★ ★решил★ вести существование более высокого уровня, уменьшите расходы на него на 2 зм в течение этого периода времени.`,
    id: BOON_INHERITANCE,
    characterGenerationParams: {
      relationText: 'дальний родственник',
      occupationId: OCCUPATION_NOBLE,
    },
    dice: {
      diceCount: 1,
      diceType: 20,
    },
    probabilityWeight: 2,
  },
]

export default boonList
export const boonCollection = listToCollectionById(boonList)
