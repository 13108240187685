import listToCollectionById from '@/utils/listToCollectionById'
import {
  BACKGROUND_ACOLYTE,
  BACKGROUND_CHARLATAN,
  BACKGROUND_CITY_WATCH,
  BACKGROUND_CLAN_CRAFTER,
  BACKGROUND_CLOISTERED_SCHOLAR,
  BACKGROUND_COURTIER,
  BACKGROUND_CRIMINAL,
  BACKGROUND_ENTERTAINER,
  BACKGROUND_FAR_TRAVELLER,
  BACKGROUND_FOLK_HERO,
  BACKGROUND_GLADIATOR,
  BACKGROUND_GUILD_ARTISAN,
  BACKGROUND_GUILD_MERCHANT,
  BACKGROUND_HERMIT,
  BACKGROUND_INVESTIGATOR,
  BACKGROUND_KNIGHT,
  BACKGROUND_KNIGHT_OF_THE_ORDER,
  BACKGROUND_MERCENARY_VETERAN,
  BACKGROUND_NOBLE,
  BACKGROUND_OUTLANDER,
  BACKGROUND_PIRATE,
  BACKGROUND_SAGE,
  BACKGROUND_SAILOR,
  BACKGROUND_SOLDIER,
  BACKGROUND_SPY,
  BACKGROUND_URBAN_BOUNTY_HUNTER,
  BACKGROUND_URCHIN,
  BACKGROUND_UTHGARDT_TRIBE_MEMBER,
  BACKGROUND_WATERDHAVIAN_NOBLE,
} from '@/constants/backgroundIdList'

const TRAIT_AFORISMY = 'trait_aforismy'
const TRAIT_ARGUMENTY = 'trait_argumenty'
const TRAIT_CHISTYE_RUKI = 'trait_chistye_ruki'
const TRAIT_CHUZYE_BOGI = 'trait_chuzye_bogi'
const TRAIT_CODEX = 'trait_codex'
const TRAIT_CRUSHIT = 'trait_crushit'
const TRAIT_DEISTVOVAT = 'trait_deistvovat'
const TRAIT_DLINNYE_SLOVA = 'trait_dlinnye_slova'
const TRAIT_DOBROTA = 'trait_dobrota'
const TRAIT_DRAKA_ETO_OK = 'trait_draka_eto_ok'
const TRAIT_EDA_NE_OK = 'trait_eda_ne_ok'
const TRAIT_EDA_OK = 'trait_eda_ok'
const TRAIT_EDA_STRANNAYA = 'trait_eda_strannaya'
const TRAIT_ENEMIES = 'trait_enemies'
const TRAIT_FILOSOF = 'trait_filosof'
const TRAIT_GAMBLER = 'trait_gambler'
const TRAIT_GOLOD_VOLKA = 'trait_golod_volka'
const TRAIT_GRUB = 'trait_grub'
const TRAIT_HARSH_HUMOR = 'trait_harsh_humor'
const TRAIT_HLADNOKROVEN = 'trait_hladnokroven'
const TRAIT_HOLY_BUNCH = 'trait_holy_bunch'
const TRAIT_IDEALIZE = 'trait_idealize'
const TRAIT_ISOLATION = 'trait_isolation'
const TRAIT_JAJDA_PRIKLYUCHENIY = 'trait_jajda_priklyucheniy'
const TRAIT_JOKES_BLACK = 'trait_jokes_black'
const TRAIT_KAK_UKRAST = 'trait_kak_ukrast'
const TRAIT_KARMAN = 'trait_karman'
const TRAIT_KOTYATA = 'trait_kotyata'
const TRAIT_KRADUT_TAYNY = 'trait_kradut_tayny'
const TRAIT_LENTYAY = 'trait_lentyay'
const TRAIT_LEST = 'trait_lest'
const TRAIT_LIAR = 'trait_liar'
const TRAIT_LICHNOE_PROSTRANSTVO = 'trait_lichnoe_prostranstvo'
const TRAIT_LIDER_OBSHINY = 'trait_lider_obshiny'
const TRAIT_LOMAYU = 'trait_lomayu'
const TRAIT_MALO_DRUZEY = 'trait_malo_druzey'
const TRAIT_MANY_FRIENDS = 'trait_many_friends'
const TRAIT_MARAFON = 'trait_marafon'
const TRAIT_MEDLENNO = 'trait_medlenno'
const TRAIT_MNOGO_LESTI = 'trait_mnogo_lesti'
const TRAIT_MNOGO_RABOTY = 'trait_mnogo_raboty'
const TRAIT_MODA = 'trait_moda'
const TRAIT_MUSOR = 'trait_musor'
const TRAIT_NAOBOROT = 'trait_naoborot'
const TRAIT_NASTROENIE_KAK_NOTY = 'trait_nastroenie_kak_noty'
const TRAIT_NEDOVERIE = 'trait_nedoverie'
const TRAIT_NET_MILOSTI = 'trait_net_milosti'
const TRAIT_NEVOSPITAN = 'trait_nevospitan'
const TRAIT_NEW_ROMANTIC = 'trait_new_romantic'
const TRAIT_NEZNAKOMYE_RITUALY = 'trait_neznakomye_ritualy'
const TRAIT_NE_CENTR = 'trait_ne_centr'
const TRAIT_NE_MOYUS = 'trait_ne_moyus'
const TRAIT_NE_NAMEKI = 'trait_ne_nameki'
const TRAIT_NORISK = 'trait_norisk'
const TRAIT_NOT_TOLERATE_OTHER_DEITY = 'trait_not_tolerate_other_deity'
const TRAIT_NOVYE_PORTY = 'trait_novye_porty'
const TRAIT_NO_ETICKET = 'trait_no_eticket'
const TRAIT_NO_SOCIAL = 'trait_no_social'
const TRAIT_ODNA_KROV = 'trait_odna_krov'
const TRAIT_ONELINER = 'trait_oneliner'
const TRAIT_ONLY_TEMPLE = 'trait_only_temple'
const TRAIT_OPTIMISM = 'trait_optimism'
const TRAIT_OSKORBLENIE = 'trait_oskorblenie'
const TRAIT_OSKORBLENIYA = 'trait_oskorbleniya'
const TRAIT_PARI = 'trait_pari'
const TRAIT_PERFECTIONIST = 'trait_perfectionist'
const TRAIT_PLAN_B = 'trait_plan_b'
const TRAIT_POLOJITSYA = 'trait_polojitsya'
const TRAIT_PO_POSTUPKAM = 'trait_po_postupkam'
const TRAIT_PREZRENIE = 'trait_prezrenie'
const TRAIT_PROFESSION = 'trait_profession'
const TRAIT_PRYACHUS = 'trait_pryachus'
const TRAIT_PRYAMOLINEYNO = 'trait_pryamolineyno'
const TRAIT_RASSKAZ = 'trait_rasskaz'
const TRAIT_RAZRYADIT_OBSTANOVKU = 'trait_razryadit_obstanovku'
const TRAIT_REGALII = 'trait_regalii'
const TRAIT_ROMANTIK = 'trait_romantik'
const TRAIT_RUGAN = 'trait_rugan'
const TRAIT_SACRED_FALSE = 'trait_sacred_false'
const TRAIT_SACRED_QUOTE = 'trait_sacred_quote'
const TRAIT_SARCASM = 'trait_sarcasm'
const TRAIT_SLAVA = 'trait_slava'
const TRAIT_SLUHI = 'trait_sluhi'
const TRAIT_SMOTRYU_PRYAMO_EPTA = 'trait_smotryu_pryamo_epta'
const TRAIT_SNOB = 'trait_snob'
const TRAIT_SOBSTVENNOST = 'trait_sobstvennost'
const TRAIT_SOPEREJIVAYU = 'trait_soperejivayu'
const TRAIT_SOTRU_V_POROSHOK = 'trait_sotru_v_poroshok'
const TRAIT_SOVERSHENSTVO = 'trait_sovershenstvo'
const TRAIT_SPLYU_SPINOY = 'trait_splyu_spinoy'
const TRAIT_SPOKOEN = 'trait_spokoen'
const TRAIT_SPRAVEDLIVOST = 'trait_spravedlivost'
const TRAIT_SYNDROM_VOYNY = 'trait_syndrom_voyny'
const TRAIT_TAYNY = 'trait_tayny'
const TRAIT_TEMPLE_FOOD = 'trait_temple_food'
const TRAIT_TERPELIV_K_TUPYM = 'trait_terpeliv_k_tupym'
const TRAIT_TIPA_UMNIY = 'trait_tipa_umniy'
const TRAIT_TOLERATE_OTHER_DEITY = 'trait_tolerate_other_deity'
const TRAIT_TORGOVATSYA = 'trait_torgovatsya'
const TRAIT_UROKI_PRIRODY = 'trait_uroki_prirody'
const TRAIT_USTAYU = 'trait_ustayu'
const TRAIT_USTROENY_VNUTRI = 'trait_ustroeny_vnutri'
const TRAIT_UVEREN = 'trait_uveren'
const TRAIT_VEJLIV = 'trait_vejliv'
const TRAIT_VOENNYE_BAYKI = 'trait_voennye_bayki'
const TRAIT_VOLKI = 'trait_volki'
const TRAIT_VOPROSY = 'trait_voprosy'
const TRAIT_VOSTORG = 'trait_vostorg'
const TRAIT_VRANYE_RASSKAZ = 'trait_vranye_rasskaz'
const TRAIT_VSE_KNIGI = 'trait_vse_knigi'
const TRAIT_V_BEDE = 'trait_v_bede'
const TRAIT_V_MYSLI = 'trait_v_mysli'
const TRAIT_ZAMYSEL = 'trait_zamysel'
const TRAIT_ZLYE_DOBRYAKI = 'trait_zlye_dobryaki'
const TRAIT_ZNAMENIE = 'trait_znamenie'
const TRAIT_ZVERI = 'trait_zveri'

const traitVariantList = [
  {
    id: TRAIT_IDEALIZE,
    name: `Я идеализирую конкретного героя своей веры и постоянно ссылаюсь на его поступки и свершения.`,
    backgroundLimit: BACKGROUND_ACOLYTE,
  },
  {
    id: TRAIT_ENEMIES,
    name: `Я пытаюсь найти общую позицию даже у самых яростных врагов, сопереживая им, и всегда стремясь к примирению.`,
  },
  {
    id: TRAIT_ZNAMENIE,
    name: `Я вижу знамения в каждом событии и поступке. Боги пытаются говорить с нами, нужно лишь прислушаться.`,
    backgroundLimit: BACKGROUND_ACOLYTE,
  },
  {
    id: TRAIT_OPTIMISM,
    name: `Ничто не может поколебать мой оптимизм.`,
  },
  {
    id: TRAIT_SACRED_QUOTE,
    name: `При любой оказии я цитирую священные тексты и притчи.`,
    backgroundLimit: BACKGROUND_ACOLYTE,
  },
  {
    id: TRAIT_SACRED_FALSE,
    name: `При любой оказии я цитирую (и часто перевираю) священные тексты и притчи.`,
    backgroundLimit: BACKGROUND_ACOLYTE,
  },
  {
    id: TRAIT_TOLERATE_OTHER_DEITY,
    name: `Я ★терпим★ к другим верованиям, и уважаю поклонение другим богам.`,
    backgroundLimit: BACKGROUND_ACOLYTE,
  },
  {
    id: TRAIT_NOT_TOLERATE_OTHER_DEITY,
    name: `Я ★нетерпим★ к другим верованиям, и порицаю поклонение другим богам.`,
    backgroundLimit: BACKGROUND_ACOLYTE,
  },
  {
    id: TRAIT_TEMPLE_FOOD,
    name: `Я люблю хорошую еду, выпивку и высокое общество представителей своего храма. Жизнь вдали от этого меня раздражает.`,
    backgroundLimit: BACKGROUND_ACOLYTE,
  },
  {
    id: TRAIT_ONLY_TEMPLE,
    name: `Я ★пробыл★ в храме слишком долго, и мне недостаёт опыта взаимодействия с людьми за его пределами.`,
    backgroundLimit: BACKGROUND_ACOLYTE,
  },
  {
    id: TRAIT_NEW_ROMANTIC,
    name: `Я легко начинаю и разрываю романтические отношения, и постоянно ищу новых.`,
  },
  {
    id: TRAIT_JOKES_BLACK,
    name: `У меня припасены шуточки на все случаи жизни, особенно на те, где юмор вообще неуместен.`,
  },
  {
    id: TRAIT_LEST,
    name: `Лесть — мой излюбленный способ добиться желаемого.`,
    backgroundLimit: [
      BACKGROUND_CHARLATAN,
      BACKGROUND_CRIMINAL,
      BACKGROUND_COURTIER,
      BACKGROUND_SPY,
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
    ],
  },
  {
    id: TRAIT_GAMBLER,
    name: `Я прирождённый игрок, и не могу удержаться от риска, если на кону стоит что-то стоящее.`,
  },
  {
    id: TRAIT_LIAR,
    name: `Я постоянно вру, зачастую даже без малейшей на то причины.`,
  },
  {
    id: TRAIT_SARCASM,
    name: `Сарказм и оскорбления — мои излюбленные приёмы.`,
    backgroundLimit: [
      BACKGROUND_CHARLATAN,
      BACKGROUND_CRIMINAL,
      BACKGROUND_SPY,
      BACKGROUND_URCHIN,
    ],
  },
  {
    id: TRAIT_HOLY_BUNCH,
    name: `Я ношу с собой различные святые символы, и взываю к тем божествам, что лучше подходят для каждого конкретного случая.`,
    backgroundLimit: [
      BACKGROUND_CHARLATAN,
    ],
  },
  {
    id: TRAIT_KARMAN,
    name: `Я тащу в карман всё, что плохо лежит и имеет хоть какую-то ценность.`,
    backgroundLimit: [
      BACKGROUND_CHARLATAN,
      BACKGROUND_CRIMINAL,
      BACKGROUND_URCHIN,
    ],
  },
  {
    id: TRAIT_PLAN_B,
    name: `У меня всегда есть план на случай, если всё пойдёт не так как задумано.`,
    backgroundLimit: [
      BACKGROUND_CRIMINAL,
      BACKGROUND_URBAN_BOUNTY_HUNTER,
      BACKGROUND_INVESTIGATOR,
    ],
  },
  {
    id: TRAIT_HLADNOKROVEN,
    name: `Я всегда ★хладнокровен★, несмотря ни на что. Я никогда не повышаю голоса и не позволяю эмоциям управлять мной.`,
  },
  {
    id: TRAIT_KAK_UKRAST,
    name: `На новом месте я первым делом подмечаю, где находятся различные ценности — или места, где они могут быть спрятаны.`,
    backgroundLimit: [
      BACKGROUND_CRIMINAL,
      BACKGROUND_URCHIN,
    ],
  },
  {
    id: TRAIT_MANY_FRIENDS,
    name: `Я предпочту обзавестись ещё один другом, нежели ещё одним врагом.`,
  },
  {
    id: TRAIT_NEDOVERIE,
    name: `У меня проблемы с доверием. Те, кто выглядят самыми порядочными, зачастую скрывают множество грязных секретов.`,
  },
  {
    id: TRAIT_NORISK,
    name: `Мне наплевать на риск. Никогда не говорите мне о возможных последствиях.`,
  },
  {
    id: TRAIT_NAOBOROT,
    name: `Наилучший способ заставить меня сделать что-то — сказать мне этого не делать.`,
  },
  {
    id: TRAIT_OSKORBLENIE,
    name: `Я срываюсь даже от малейшего оскорбления.`,
  },
  {
    id: TRAIT_RASSKAZ,
    name: `Для любой ситуации я найду подходящий рассказ.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
      BACKGROUND_SAGE,
      BACKGROUND_GLADIATOR,
      BACKGROUND_OUTLANDER,
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_KNIGHT,
    ],
  },
  {
    id: TRAIT_SLUHI,
    name: `Куда бы я ни ★пришёл★, я начинаю собирать местные слухи и распространять сплетни.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
    ],
  },
  {
    id: TRAIT_ROMANTIK,
    name: `Я безнадёжно ★романтичен★, и всегда ищу «кого-то особого».`,
  },
  {
    id: TRAIT_RAZRYADIT_OBSTANOVKU,
    name: `Никто не сердится на меня или возле меня подолгу, так как я стараюсь разрядить любую напряжённую обстановку.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
    ],
  },
  {
    id: TRAIT_OSKORBLENIYA,
    name: `Мне нравятся оскорбления, даже если они направлены на меня.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
      BACKGROUND_GLADIATOR,
    ],
  },
  {
    id: TRAIT_NE_CENTR,
    name: `Мне обидно, если я не нахожусь в центре внимания.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
      BACKGROUND_GLADIATOR,
    ],
  },
  {
    id: TRAIT_SOVERSHENSTVO,
    name: `Превыше всего я ценю совершенство.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
      BACKGROUND_GLADIATOR,
      BACKGROUND_CLAN_CRAFTER,
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_GUILD_MERCHANT,
    ],
  },
  {
    id: TRAIT_NASTROENIE_KAK_NOTY,
    name: `Моё настроение и намерения меняются так же быстро, как ноты в музыке.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
    ],
  },
  {
    id: TRAIT_PO_POSTUPKAM,
    name: `Я сужу других по поступкам, а не словам.`,
  },
  {
    id: TRAIT_V_BEDE,
    name: `Если кто-то в беде, я всегда ★готов★ прийти на помощь.`,
  },
  {
    id: TRAIT_PRYAMOLINEYNO,
    name: `Задумав что-то, я прямолинейно иду к цели, невзирая на помехи.`,
  },
  {
    id: TRAIT_SPRAVEDLIVOST,
    name: `У меня обострённое чувство справедливости, и в споре я всегда ищу самого честного решения.`,
  },
  {
    id: TRAIT_UVEREN,
    name: `Я ★уверен★ в своих способностях, и стараюсь внушить такую же уверенность в других.`,
  },
  {
    id: TRAIT_DEISTVOVAT,
    name: `Пусть думают другие. Я предпочитаю действовать.`,
    backgroundLimit: [
      BACKGROUND_FOLK_HERO,
      BACKGROUND_OUTLANDER,
      BACKGROUND_GLADIATOR,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_SOLDIER,
      BACKGROUND_CITY_WATCH,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_PIRATE,
      BACKGROUND_SAILOR,
    ],
  },
  {
    id: TRAIT_DLINNYE_SLOVA,
    name: `Я неправильно произношу длинные слова, когда пытаюсь выглядеть умнее.`,
    backgroundLimit: [
      BACKGROUND_FOLK_HERO,
      BACKGROUND_OUTLANDER,
      BACKGROUND_GLADIATOR,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: TRAIT_USTAYU,
    name: `Я быстро устаю. Когда уже я найду свою судьбу?.`,
    backgroundLimit: [
      BACKGROUND_FOLK_HERO,
    ],
  },
  {
    id: TRAIT_PERFECTIONIST,
    name: `Я считаю, что всё, что делается, должно делаться правильно. Ничего не могу с этим поделать — я ★перфекционист★.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_CLAN_CRAFTER,
    ],
  },
  {
    id: TRAIT_SNOB,
    name: `Я ★сноб★, свысока ★смотрящий★ на тех, кто не может оценить изящное искусство.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_ENTERTAINER,
      BACKGROUND_GUILD_MERCHANT,
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_CLAN_CRAFTER,
    ],
  },
  {
    id: TRAIT_USTROENY_VNUTRI,
    name: `Мне всегда нужно знать, как вещи устроены внутри.`,
    backgroundLimit: [
      BACKGROUND_CLAN_CRAFTER,
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_GUILD_MERCHANT,
    ],
  },
  {
    id: TRAIT_AFORISMY,
    name: `У меня много остроумных афоризмов и есть пословицы на любой случай.`,
    backgroundLimit: [
      BACKGROUND_CLAN_CRAFTER,
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_GUILD_MERCHANT,
      BACKGROUND_SAGE,
      BACKGROUND_HERMIT,
      BACKGROUND_OUTLANDER,
      BACKGROUND_ENTERTAINER,
    ],
  },
  {
    id: TRAIT_GRUB,
    name: `Я ★груб★ с теми, у кого нет моей тяги к честному и справедливому труду.`,
    backgroundLimit: [
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_CLAN_CRAFTER,
    ],
  },
  {
    id: TRAIT_PROFESSION,
    name: `О своей профессии я могу говорить часами.`,
    backgroundLimit: [
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_CLAN_CRAFTER,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: TRAIT_TORGOVATSYA,
    name: `Я не могу легко расставаться с деньгами, и могу сколь угодно долго торговаться.`,
  },
  {
    id: TRAIT_SLAVA,
    name: `Благодаря работе я ★прославился★, и хочу, чтобы все это ценили. Меня всегда потрясает, когда другие говорят, что не слышали обо мне.`,
    backgroundLimit: [
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_CLAN_CRAFTER,
      BACKGROUND_ENTERTAINER,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_GLADIATOR,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_PIRATE,
      BACKGROUND_FOLK_HERO,
    ],
  },
  {
    id: TRAIT_ISOLATION,
    name: `Я так много ★прожил★ в изоляции, что редко говорю, предпочитая жесты и редкое мычание.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: TRAIT_SPOKOEN,
    name: `Я абсолютно ★безмятежен★, даже в самых ужасных ситуациях.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
      BACKGROUND_SAGE,
    ],
  },
  {
    id: TRAIT_LIDER_OBSHINY,
    name: `У лидера моей общины были высказывания для любой ситуации, и я стремлюсь поделиться этой мудростью.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: TRAIT_SOPEREJIVAYU,
    name: `Я ужасно сопереживаю всем, кто страдает.`,
  },
  {
    id: TRAIT_NO_ETICKET,
    name: `Мне чужд этикет и общественные ожидания.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_SAGE,
    ],
  },
  {
    id: TRAIT_ZAMYSEL,
    name: `Я связываю всё, что происходит со мной, с грандиозным замыслом космического масштаба.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_SAGE,
    ],
  },
  {
    id: TRAIT_V_MYSLI,
    name: `Я часто погружаюсь в свои мысли, отвлекаясь от окружения.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_SAGE,
    ],
  },
  {
    id: TRAIT_FILOSOF,
    name: `Я работаю над великой философской теорией и люблю тех, кто разделяет мои идеи.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_SAGE,
    ],
  },
  {
    id: TRAIT_MNOGO_LESTI,
    name: `Я применяю так много лести, что все, с кем я разговариваю, чувствуют себя самыми чудесными и важными персонами в мире.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_COURTIER,
    ],
  },
  {
    id: TRAIT_DOBROTA,
    name: `Обыватели любят меня за доброту и великодушие.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_KNIGHT,
    ],
  },
  {
    id: TRAIT_REGALII,
    name: `Один лишь взгляд на мои регалии заставляет перестать сомневаться в том, что я выше немытого отребья.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_COURTIER,
    ],
  },
  {
    id: TRAIT_MODA,
    name: `Я много трачу на то, чтобы выглядеть потрясающе, и по последнему слову моды.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_ENTERTAINER,
      BACKGROUND_COURTIER,
    ],
  },
  {
    id: TRAIT_CHISTYE_RUKI,
    name: `Мне не нравится марать руки, и я не хочу умереть в каком-нибудь неподобающем месте.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_ENTERTAINER,
      BACKGROUND_COURTIER,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: TRAIT_ODNA_KROV,
    name: `Несмотря на благородное рождение, я не ставлю себя выше народа. У всех нас течёт одинаковая кровь.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_KNIGHT,
    ],
  },
  {
    id: TRAIT_NET_MILOSTI,
    name: `Потеряв мою милость, обратно её не вернёшь.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_COURTIER,
    ],
  },
  {
    id: TRAIT_SOTRU_V_POROSHOK,
    name: `Оскорбишь меня, и я сотру тебя в порошок, уничтожу твоё имя, и сожгу твои поля.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_COURTIER,
    ],
  },
  {
    id: TRAIT_JAJDA_PRIKLYUCHENIY,
    name: `Мной руководит жажда приключений, которая и увела меня из дома.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_FAR_TRAVELLER,
    ],
  },
  {
    id: TRAIT_KOTYATA,
    name: `Я слежу за друзьями, как если бы они были слепыми котятами.`,
  },
  {
    id: TRAIT_MARAFON,
    name: `Однажды я ★пробежал★ 40 миль без остановки, чтобы предупредить свой клан о приближающейся орде орков. Если понадобится, я повторю это.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: TRAIT_UROKI_PRIRODY,
    name: `Для любой ситуации у меня есть уроки, извлечённые из наблюдений за природой.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: TRAIT_GOLOD_VOLKA,
    name: `Я не понимаю богатых и воспитанных. Деньги и хорошие манеры не спасут тебя от голодного волка.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: TRAIT_LOMAYU,
    name: `Я всё время что-то беру в руки, рассеянно кручу и иногда ломаю.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: TRAIT_ZVERI,
    name: `Я гораздо комфортнее чувствую себя среди зверей, чем среди людей.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: TRAIT_VOLKI,
    name: `Меня действительно вырастили волки.`,
    backgroundLimit: BACKGROUND_OUTLANDER,
  },
  {
    id: TRAIT_TIPA_UMNIY,
    name: `Я использую многосложные слова, создающие впечатление образованности.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_CHARLATAN,
      BACKGROUND_CITY_WATCH,
    ],
  },
  {
    id: TRAIT_VSE_KNIGI,
    name: `Я ★прочитал★ все книги в величайших библиотеках мира — или, по крайней мере, говорю так.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_CHARLATAN,
    ],
  },
  {
    id: TRAIT_TERPELIV_K_TUPYM,
    name: `Я ★привык★ помогать тем, кто не так умён как я, и терпеливо всем всё объясняю.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: TRAIT_TAYNY,
    name: `Больше всего на свете я люблю тайны.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_INVESTIGATOR,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: TRAIT_ARGUMENTY,
    name: `Прежде чем принять решение, я выслушаю аргументы обеих спорящих сторон.`,
  },
  {
    id: TRAIT_MEDLENNO,
    name: `Я… говорю… медленно… когда разговариваю… с идиотами…, то есть… практически… всегда.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: TRAIT_NO_SOCIAL,
    name: `В социальном взаимодействии я ужасно неуклюж.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_OUTLANDER,
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: TRAIT_KRADUT_TAYNY,
    name: `Я ★убеждён★, что остальные постоянно хотят украсть мои тайны.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: TRAIT_POLOJITSYA,
    name: `Друзья знают, что всегда могут на меня положиться.`,
  },
  {
    id: TRAIT_MNOGO_RABOTY,
    name: `Я усердно тружусь, чтобы потом можно было хорошо отдохнуть.`,
  },
  {
    id: TRAIT_NOVYE_PORTY,
    name: `Мне нравится заходить в новые порты и находить друзей за кружечкой эля.`,
    backgroundLimit: [
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
    ],
  },
  {
    id: TRAIT_VRANYE_RASSKAZ,
    name: `Я ★готов★ приврать, чтобы получился хороший рассказ.`,
  },
  {
    id: TRAIT_DRAKA_ETO_OK,
    name: `Для меня драка в таверне — отличный способ познакомиться с новым городом.`,
    backgroundLimit: [
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
    ],
  },
  {
    id: TRAIT_PARI,
    name: `Я никогда не откажусь от пари.`,
  },
  {
    id: TRAIT_RUGAN,
    name: `Я ругаюсь как банда орков.`,
    backgroundLimit: [
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
      BACKGROUND_SOLDIER,
      BACKGROUND_CITY_WATCH,
    ],
  },
  {
    id: TRAIT_LENTYAY,
    name: `Мне нравится, когда работа выполнена, особенно если её можно поручить другому.`,
  },
  {
    id: TRAIT_VEJLIV,
    name: `Я всегда ★вежлив★ и ★почтителен★.`,
  },
  {
    id: TRAIT_SYNDROM_VOYNY,
    name: `Меня терзают воспоминания о войне. Не могу забыть то насилие, что мне довелось увидеть.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_KNIGHT,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
    ],
  },
  {
    id: TRAIT_MALO_DRUZEY,
    name: `Я ★потерял★ много друзей, и теперь с трудом завожу новых.`,
    backgroundLimit: [
      BACKGROUND_CITY_WATCH,
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_KNIGHT,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
    ],
  },
  {
    id: TRAIT_VOENNYE_BAYKI,
    name: `У меня много воодушевляющих и предостерегающих рассказов из военного прошлого, подходящих практически к любой боевой ситуации.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_KNIGHT,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
    ],
  },
  {
    id: TRAIT_SMOTRYU_PRYAMO_EPTA,
    name: `Я могу смотреть прямо на адскую гончую, не отводя глаз.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_KNIGHT,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
    ],
  },
  {
    id: TRAIT_CRUSHIT,
    name: `Мне нравится быть ★сильным★ и крушить вещи.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_PIRATE,
    ],
  },
  {
    id: TRAIT_HARSH_HUMOR,
    name: `У меня грубое чувство юмора.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_PIRATE,
      BACKGROUND_SAILOR,
    ],
  },
  {
    id: TRAIT_ONELINER,
    name: `Я решаю проблемы прямолинейно. Простое решение — лучший путь к успеху.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_PIRATE,
      BACKGROUND_SAILOR,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_KNIGHT,
    ],
  },
  {
    id: TRAIT_MUSOR,
    name: `В моих карманах полно побрякушек и объедков.`,
    backgroundLimit: [
      BACKGROUND_URCHIN,
      BACKGROUND_HERMIT,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: TRAIT_VOPROSY,
    name: `Я задаю очень много вопросов.`,
    backgroundLimit: [
      BACKGROUND_URCHIN,
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_INVESTIGATOR,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: TRAIT_PRYACHUS,
    name: `Я часто забиваюсь в узкие закутки, где никто не сможет добраться до меня.`,
    backgroundLimit: [
      BACKGROUND_URCHIN,
    ],
  },
  {
    id: TRAIT_SPLYU_SPINOY,
    name: `Я всегда сплю, прижавшись спиной к стене или дереву, сжимая узелок со всеми своими пожитками в руках.`,
    backgroundLimit: [
      BACKGROUND_URCHIN,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: TRAIT_NEVOSPITAN,
    name: `Я ★невоспитан★, и ем как свинья.`,
    backgroundLimit: [
      BACKGROUND_URCHIN,
      BACKGROUND_OUTLANDER,
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_FAR_TRAVELLER,
    ],
  },
  {
    id: TRAIT_ZLYE_DOBRYAKI,
    name: `Я ★убеждён★, что все, кто проявляют доброту ко мне, на самом деле скрывают злые намерения.`,
    backgroundLimit: [
      BACKGROUND_URCHIN,
      BACKGROUND_INVESTIGATOR,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: TRAIT_NE_MOYUS,
    name: `Я не люблю мыться.`,
    backgroundLimit: [
      BACKGROUND_URCHIN,
      BACKGROUND_OUTLANDER,
      BACKGROUND_HERMIT,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_SOLDIER,
      BACKGROUND_FAR_TRAVELLER,
    ],
  },
  {
    id: TRAIT_NE_NAMEKI,
    name: `Я прямо говорю о том, на что прочие предпочитают лишь намекнуть, или промолчать.`,
    backgroundLimit: [
      BACKGROUND_URCHIN,
      BACKGROUND_OUTLANDER,
      BACKGROUND_HERMIT,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
      BACKGROUND_SOLDIER,
      BACKGROUND_KNIGHT,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: TRAIT_LICHNOE_PROSTRANSTVO,
    name: `Мои представления о личном пространстве расходятся с таковыми окружающих. Нечаянно я вторгаюсь в личное пространство других, или, наоборот, реагирую на вторжение в моё собственное, что происходит из-за разницы обычаев.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: TRAIT_EDA_OK,
    name: `Мои представления о том, что является едой, а что — нет, расходятся с таковыми окружающих. Я часто нахожу трапезные традиции окружающих восхитительными.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: TRAIT_EDA_STRANNAYA,
    name: `Мои представления о том, что является едой, а что — нет, расходятся с таковыми окружающих. Я часто нахожу трапезные традиции окружающих сбивающими с толку.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: TRAIT_EDA_NE_OK,
    name: `Мои представления о том, что является едой, а что — нет, расходятся с таковыми окружающих. Я часто нахожу трапезные традиции окружающих отвратительными.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: TRAIT_CODEX,
    name: `У меня строгий кодекс чести, который нелегко воспринять окружающим.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: TRAIT_SOBSTVENNOST,
    name: `У меня строгое чувство собственности, которые нелегко воспринять окружающим.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: TRAIT_VOSTORG,
    name: `Я выражаю восторг не свойственным для окружающих образом.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_OUTLANDER,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: TRAIT_PREZRENIE,
    name: `Я выражаю презрение не свойственным для окружающих образом.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_OUTLANDER,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: TRAIT_CHUZYE_BOGI,
    name: `Я выражаю почтение моим божествам, способами, которые кажутся чужими в этих землях.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: TRAIT_NEZNAKOMYE_RITUALY,
    name: `Я начинаю или оканчиваю свой день традиционными ритуалами, которые незнакомы окружающим.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
].map(
  item => ({
    ...item,
    probabilityWeight: item.backgroundLimit ? 10 : 1,
  })
)

export default traitVariantList
export const traitVariantCollection = listToCollectionById(traitVariantList)
