import listToCollectionById from '@/utils/listToCollectionById'

export const KNOW_PARENT_KNOW = 'know_parent_know'
export const KNOW_PARENT_DO_NOT_KNOW = 'know_parent_do_not_know'

const knowParentsVariantList = [
  {
    name: '★ПЕРСОНАЖ★ ★знает★ своих родителей.',
    id: KNOW_PARENT_KNOW,
    probabilityWeight: 95,
  },
  {
    name: '★ПЕРСОНАЖ★ не ★знает★ своих родителей.',
    id: KNOW_PARENT_DO_NOT_KNOW,
    probabilityWeight: 5,
  },
]

export default knowParentsVariantList
export const knowParentsVariantCollection = listToCollectionById(knowParentsVariantList)
