import { PC_RACE_HALF_ELF, PC_RACE_HALF_ORC, PC_RACE_TIEFLING } from '@/constants/pcRaceIdList'

import { parentHalfElfCollection } from '@/components/CharacterGenerator/constants/parentHalfElfList'
import { parentHalfOrcCollection } from '@/components/CharacterGenerator/constants/parentHalfOrcList'
import { parentTieflingCollection } from '@/components/CharacterGenerator/constants/parentTieflingList'

export default ({parentHalfRaceId, raceId}) => {
  if (parentHalfRaceId) {
    if (raceId === PC_RACE_HALF_ELF) {
      return parentHalfElfCollection[parentHalfRaceId].name
    }
    if (raceId === PC_RACE_HALF_ORC) {
      return parentHalfOrcCollection[parentHalfRaceId].name
    }
    if (raceId === PC_RACE_TIEFLING) {
      return parentTieflingCollection[parentHalfRaceId].name
    }
  }

  return ''
}
