import listToCollectionById from '@/utils/listToCollectionById'

export const RANGER_CHOICE_DANGER_BEASTS = 'ranger_choice_danger_beasts'
export const RANGER_CHOICE_ANIMAL_SPEAK = 'ranger_choice_animal_speak'
export const RANGER_CHOICE_NEED_FOR_ADVENTURE = 'ranger_choice_need_for_adventure'
export const RANGER_CHOICE_FRONTIER = 'ranger_choice_frontier'
export const RANGER_CHOICE_OLD_RANGER = 'ranger_choice_old_ranger'
export const RANGER_CHOICE_ARMY_SCOUT = 'ranger_choice_army_scout'

const rangerChoiceList = [
  {
    name: `Я ★обрёл★ цель, пока ★оттачивал★ свои охотничьи навыки, уничтожая опасных животных на краю цивилизации.`,
    id: RANGER_CHOICE_DANGER_BEASTS,
    probabilityWeight: 1,
  },
  {
    name: `У меня всегда был особы подход к животным — я могу успокоить их словом или прикосновением.`,
    id: RANGER_CHOICE_ANIMAL_SPEAK,
    probabilityWeight: 1,
  },
  {
    name: `Я ★страдал★ от страшной тяги к путешествиям, а образ жизни следопыта давал мне причину не задерживаться на одном месте слишком долго.`,
    id: RANGER_CHOICE_NEED_FOR_ADVENTURE,
    probabilityWeight: 1,
  },
  {
    name: `Я уже ★видел★, что происходит, когда монстры выходят из темноты. Я ★взял★ на себя обязательно стать первым рубежом защиты от зла, находящегося за пределами цивилизации.`,
    id: RANGER_CHOICE_FRONTIER,
    probabilityWeight: 1,
  },
  {
    name: `Я ★встретил★ седого следопыта, который обучил меня выживать в лесу и рассказал много секретов диких земель.`,
    id: RANGER_CHOICE_OLD_RANGER,
    probabilityWeight: 1,
  },
  {
    name: `Я ★служил★ в армии, изучая принципы моей профессии во время прокладывания троп и поиска вражеских лагерей.`,
    id: RANGER_CHOICE_ARMY_SCOUT,
    probabilityWeight: 1,
  },
]

export default rangerChoiceList
export const rangerChoiceCollection = listToCollectionById(rangerChoiceList)

