const {
  BACKGROUND_KNIGHT,
  BACKGROUND_NOBLE,
} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const noble = require('./noble')

module.exports = {
  ...noble,
  id: BACKGROUND_KNIGHT,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Рыцарь',
    [GENDER_FEMALE]: 'Рыцарь',
  },
  nameEn: 'Knight',
  description: [
    {
      header: 'Разновидность благородного: Рыцарь',
      text: `Во многих обществах рыцари — низший слой [благородного сословия](BACKGROUND:${BACKGROUND_NOBLE}), но это всё равно путь в высший свет.

Будучи символом благородства и идеалом галантной любви, Вы можете добавить в снаряжение знамя или подарок от леди, которой Вы вручили своё сердце (она может быть вашей привязанностью).`,
      source: {
        id: SOURCE_PHB,
        page: 137,
      },
    },
    {
      header: 'Умение: Слуги',
      text: `На вас работают трое слуг, преданных вашей семье. Это могут быть помощники и слуги, а один может быть сквайром, помогающим вам, и при этом учащимся рыцарству. Двое оставшихся могут ухаживать за вашим конём и доспехом (а также они будут помогать его надевать).

Это обыватели, выполняющие за вас простую работу, но они не будут за вас сражаться, не пойдут за вами в опасные места (такие как подземелья), и они покинут вас, если их будут подвергать опасности или с ними будут плохо обращаться. `,
      source: {
        id: SOURCE_PHB,
        page: 137,
      },
    },
    ...noble.description.slice(0, 1),
    ...noble.description.slice(3),
  ],
  equipment: `${noble.equipment}* Знамя или подарок от леди, которой Вы вручили своё сердце`,
  source: {
    id: SOURCE_PHB,
    page: 137,
  },
}
