const {SOURCE_PHB} = require('./../../sourceList')
const {BACKGROUND_SPY} = require('./../../backgroundIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const criminal = require('./criminal')

module.exports = {
  ...criminal,
  id: BACKGROUND_SPY,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Шпион',
    [GENDER_FEMALE]: 'Шпионка',
  },
  nameEn: 'Spy',
  description: [
    {
      header: 'Шпион',
      text: `Несмотря на то, что ваши способности не слишком отличаются от способностей взломщика или контрабандиста, Вы научились применять их в другом ключе: в качестве специалиста по шпионажу.

Вполне возможно, что вы — официально уполномоченный агент короны, или же Вы просто продаёте добытые сведения тому, кто больше заплатит.`,
      source: {
        id: SOURCE_PHB,
        page: 131,
      },
    },
    ...criminal.description.slice(1, 2),
    ...criminal.description.slice(0, 1),
    ...criminal.description.slice(2),
  ],
  source: {
    id: SOURCE_PHB,
    page: 131,
  },
}
