module.exports = [
  require('./list/acolyte'),
  require('./list/agent_emerald_enclave'),
  require('./list/agent_harpers'),
  require('./list/agent_lords_alliance'),
  require('./list/agent_order_gauntlet'),
  require('./list/agent_zhentarim'),
  require('./list/anthropologist'),
  require('./list/archaeologist'),
  require('./list/athlete'),
  require('./list/charlatan'),
  require('./list/city_watch'),
  require('./list/clan_crafter'),
  require('./list/cloistered_scholar'),
  require('./list/courtier'),
  require('./list/criminal'),
  require('./list/entertainer'),
  require('./list/far_traveller'),
  require('./list/folk_hero'),
  require('./list/gate_warden_2022_07_18'),
  require('./list/giant_foundling_2022_07_18'),
  require('./list/gladiator'),
  require('./list/guild_artisan'),
  require('./list/guild_merchant'),
  require('./list/hermit'),
  require('./list/inheritor'),
  require('./list/investigator'),
  require('./list/knight'),
  require('./list/knight_of_the_order'),
  require('./list/mercenary_veteran'),
  require('./list/noble'),
  require('./list/outlander'),
  require('./list/pirate'),
  require('./list/planar_philosopher_2022_07_18'),
  require('./list/rune_carver_2022_07_18'),
  require('./list/sage'),
  require('./list/sailor'),
  require('./list/soldier'),
  require('./list/spy'),
  require('./list/urban_bounty_hunter'),
  require('./list/urchin'),
  require('./list/uthgardt_tribe_member'),
  require('./list/waterdhavian_noble'),
]
