const {SOURCE_SCAG} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  BACKGROUND_NOBLE,
  BACKGROUND_WATERDHAVIAN_NOBLE,
} = require('./../../backgroundIdList')
const {
  GEAR_CLOTHES_FINE,
  GEAR_SIGNET_RING,
  GEAR_WATERSKIN,
} = require('./../../gearIdList')
const {
  CAT_GAME_SET,
  CAT_MUSIC_TOOLS,
} = require('./../../gearCategoryList')
const {
  SKILL_HISTORY,
  SKILL_PERSUASION,
} = require('./../../skillList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const noble = require('./noble')

module.exports = {
  id: BACKGROUND_WATERDHAVIAN_NOBLE,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Дворянин Глубоководья',
    [GENDER_FEMALE]: 'Дворянка Глубоководья',
  },
  nameEn: 'Waterdhavian Noble',
  description: [
    {
      header: 'Дворянин Глубоководья',
      text: `Вы — потомок одной из высокородных семей Глубоководья. Человеческие семьи, ревностно охраняющие свои привилегии и положение в Городе Роскоши, дворяне Глубоководья по всему Фаэруну обладают репутацией эксцентричных, испорченных, продажных и, прежде всего, богатых особ.

Будь Вы яркой иллюстрацией такой репутации или тем, кто подтверждает правило, являясь исключением, люди ожидают от вас определенного поведения, как только они узнают вашу фамилию, и что она означает. Ваши причины отправиться в приключения, скорее всего, связаны с вашей семьей: бунтарь ли Вы для своей семьи, предпочитающий спускаться в грязные подземелья потягиванию ззара на балах? Или, быть может, Вы взялись за оружие или заклинания в интересах семьи, с намерением привнести больше славы в её наследие?

Согласуйте со своим Мастером семью, частью которой Вы являетесь — в Глубоководье существует около семидесяти пяти родословных, каждая со своими финансовыми интересами, специализациями и структурой. Вы можете быть частью основной ветви семейства, возможно, ожидая однажды встать во главе его. Или Вы можете быть одним множества родственников, обладая меньшим авторитетом, но и с меньшей ответственностью.`,
      source: {
        id: SOURCE_SCAG,
        page: 155,
      },
    },
    {
      header: 'Умение: Поддержание стиля',
      text: `Пока Вы находитесь в Глубоководье или где-либо на Севере, ваш дом берет на себя все ваши повседневные расходы. Вашего имени и печатки достаточно, чтобы покрыть большинство ваших трат; постоялые дворы, таверны и пиршественные залы часто бывают рады записать ваш долг и отправить счёт в ваше фамильное поместье в Глубоководье чтобы уладить ваш долг.

Это преимущество позволяет вести комфортный образ жизни без ежедневных затрат в 2 зм, либо уменьшать на эту сумму затраты на богатый и аристократический образ жизни. Вы не можете вести менее затратный образ жизни и получать эту сумму в виде дохода — Вы получаете все эти услуги в кредит, а не в виде денег.`,
      source: {
        id: SOURCE_SCAG,
        page: 155,
      },
    },
    {
      header: 'Предлагаемые характеристики',
      text: `Используйте таблицы предыстории [благородного](BACKGROUND:${BACKGROUND_NOBLE}) в качестве основы для ваших черт характера и мотивов, изменяя их, где это необходимо, чтобы ваша личность соответствовала представителю благородной семьи Глубоководья.

Как и другие дворяне, Вы родились и выросли в другом мире в отличии от того, в котором живет большинство людей — в том, который предоставляет вам привилегии, но и призывает вас выполнять долг, подобающий вашему положению. Ваша привязанность может относится только к семье, или же может связана с другими союзными или оппозиционными благородными домами. Ваш идеал зависит в некоторой степени от того, какова ваша роль в семье, как Вы собираетесь представлять ваш дом для мира в целом.`,
      source: {
        id: SOURCE_SCAG,
        page: 155,
      },
    },
    ...noble.description.slice(4),
  ],
  proficiency: {
    tool: {
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_GAME_SET,
          CAT_MUSIC_TOOLS,
        ],
      },
    },
    skill: {
      list: [
        SKILL_HISTORY,
        SKILL_PERSUASION,
      ],
    },
    language: LANG_ANY,
  },
  equipment: `
* [Бурдюк](GEAR:${GEAR_WATERSKIN}) с отличным ззаром или вином,
* [Комплект отличной одежды](GEAR:${GEAR_CLOTHES_FINE}),
* [Кольцо с печатью](GEAR:${GEAR_SIGNET_RING}) или брошь,
* Свиток с генеалогическим древом,
* Кошелёк с 20 зм
`,
  source: {
    id: SOURCE_SCAG,
    page: 155,
  },
}
