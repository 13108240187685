import {CAT_SIMPLE_MELEE_WEAPON, CAT_SIMPLE_RANGE_WEAPON} from '@/constants/gearCategoryList'
import gearList from '@/constants/gearList'

export {gearCollection as simpleWeaponCollection} from '@/constants/gearList'

export default gearList
  .filter(
    ({category}) => (
      category && (
        category.includes(CAT_SIMPLE_MELEE_WEAPON) ||
        category.includes(CAT_SIMPLE_RANGE_WEAPON)
      )
    )
  )
  .map(({id}) => ({
    id,
    probabilityWeight: 1,
  }))
