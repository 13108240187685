import {
  ADD_SUBCHARACTER,
  HERO_GENERATION_PARAM_COLLECTION_UPDATE,
  REGENERATE_HERO,
  REGENERATE_SUBCHARACTER,
} from '@/components/CharacterGenerator/store/action-types'
import DEFAULT_STATE from '@/components/CharacterGenerator/store/default_state'
import idGenerator from '@/components/CharacterGenerator/store/idGenerator'

import Character from '@/components/CharacterGenerator/Character'

import HERO_ID from '@/components/CharacterGenerator/constants/HERO_ID'

export default function plotReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case ADD_SUBCHARACTER: {
      const { params } = action
      const id = idGenerator()
      const paramsWithId = {
        ...params,
        id,
      }

      return {
        ...state,
        sideCharacterIdList: [
          ...state.sideCharacterIdList,
          id,
        ],
        characterCollection: {
          ...state.characterCollection,
          [id]: new Character(paramsWithId),
        },
      }
    }

    case HERO_GENERATION_PARAM_COLLECTION_UPDATE: {
      const { paramCollection } = action

      return {
        ...state,
        heroGenerationParamCollection: {
          ...state.heroGenerationParamCollection,
          ...paramCollection,
        },
      }
    }

    case REGENERATE_SUBCHARACTER: {
      const { id } = action
      const {characterGenerationParams} = state.characterCollection[id]

      return {
        ...state,
        characterCollection: {
          ...state.characterCollection,
          [id]: new Character(characterGenerationParams),
        },
      }
    }

    case REGENERATE_HERO: {
      const hero = new Character({
        id: HERO_ID,
        ...state.heroGenerationParamCollection,
      })

      return {
        ...state,
        characterCollection: {
          [HERO_ID]: hero,
        },
        sideCharacterIdList: [],
      }
    }

    default:
      return state
  }
}
