import declint from 'declint-ru'

import POUND_PER_KG from '@/constants/POUND_PER_KG'

const poundWordList = ['фунт', 'фунта', 'фунтов']

const generateWeightText = (weight = 0, roundToDecimals = false) => {
  const weightPoundText = weight % 1
    ? poundWordList[1]
    : declint(weight, poundWordList)

  const weightKg = String(
    roundToDecimals
      ? Math.round(weight * 10 / POUND_PER_KG) / 10
      : Math.round(weight / POUND_PER_KG),
  )

  return `${weight} ${weightPoundText} (${weightKg} кг)`.replace(
    /\./g,
    ','
  )
}

export default generateWeightText
