const {BACKGROUND_ARCHAEOLOGIST} = require('./../../backgroundIdList')
const {SOURCE_TOA} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GEAR_CARTOGRAPHER_S_TOOLS,
  GEAR_CASE_MAP_OR_SCROLL,
  GEAR_CLOTHES_TRAVELERS,
  GEAR_CROWBAR,
  GEAR_HAMMER_SLEDGE,
  GEAR_LANTERN_BULLSEYE,
  GEAR_LANTERN_HOODED,
  GEAR_NAVIGATORS_TOOLS,
  GEAR_POLE,
  GEAR_POUCH,
  GEAR_SHOVEL,
  GEAR_TENT,
  GEAR_WHIP,
} = require('./../../gearIdList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_HUMAN,
  PC_RACE_YUAN_TI_PUREBLOOD,
} = require('./../../pcRaceIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_HISTORY,
  SKILL_SURVIVAL,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_ARCHAEOLOGIST,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Археолог',
    [GENDER_FEMALE]: 'Археолог',
  },
  nameEn: 'Archaeologist',
  description: [
    {
      header: 'Археолог',
      text: `Археологи много узнают о давно затерянных империях и культурах, изучая их останки — кости, руины, оставшиеся после них предметы быта и гробницы. Те, кто посвятили свою жизнь археологии, отправляются в разные уголки мира, чтобы исследовать разрушенные города и затерянные подземелья, копаться в земле ради ценных артефактов, которые смогут рассказать историю о монархах и верховных жрецах, войнах и катаклизмах.`,
      source: {
        id: SOURCE_TOA,
        page: 193,
      },
    },
    {
      header: 'Умение: Исторические знания',
      text: `Входя в руины или подземелье, Вы можете с уверенностью определить их первоначальное предназначение и создателей, если они являются представителями [дварфов](PC_RACE:${PC_RACE_DWARF}), [эльфов](PC_RACE:${PC_RACE_ELF}), [людей](PC_RACE:${PC_RACE_HUMAN}), [юань-ти](PC_RACE:${PC_RACE_YUAN_TI_PUREBLOOD}) и других известных рас. Также Вы можете прикинуть стоимость предмета, которому более века.`,
      source: {
        id: SOURCE_TOA,
        page: 193,
      },
    },
    {
      header: 'Искатель',
      text: `Прежде чем встать на путь искателя приключений, Вы провели большую часть взрослой жизни, копаясь в пыли в поисках реликвий, обладающих весьма сомнительной ценностью. Вам удалось продать несколько интересных экземпляров, что позволило купить хорошее снаряжение для начала пути приключенца.

Один из предметов представляет для Вас особую эмоциональную ценностью. Выберите его из таблицы _Ценные предметы_ или определите случайно.

# Ценные предметы

| к8 | Предмет | к8 | Предмет |
|-|-|-|-|
| 1 | [10-футовый шест](GEAR:${GEAR_POLE}) | 5 | Медальон
| 2 | [Лом](GEAR:${GEAR_CROWBAR}) | 6 | [Лопата](GEAR:${GEAR_SHOVEL})
| 3 | Шляпа | 7 | [Кувалда](GEAR:${GEAR_HAMMER_SLEDGE})
| 4 | [Фонарь, закрытый](GEAR:${GEAR_LANTERN_HOODED}) | 8 | [Кнут](GEAR:${GEAR_WHIP})
`,
      source: {
        id: SOURCE_TOA,
        page: 193,
      },
    },
    {
      header: 'Персонализация',
      text: `Лишь немногие археологи смогут удержаться от желания исследовать затерянные руины или подземелья, особенно если дело касается легенд и слухов о несметных сокровищах чародеев, военачальников или королевских семей.

Некоторые археологи гонятся за славой или богатством, другие считают себя некими светочами, у которых есть шанс представить миру забытое прошлое или скрыть от сообщества сокровище, которое не должно оказаться в плохих руках. Вне зависимости от мотивации, археологи сочетают в себе качества умудрённого опытом историка, смешанного с отчаянным героизмом и неудержимой тягой к охоте за сокровищами.`,
      source: {
        id: SOURCE_TOA,
        page: 193,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Мне нравятся хорошие загадки и тайны. |
| 2 | Я храню всё и никогда ничего не выбрасываю. |
| 3 | Слава для меня важнее, чем деньги. |
| 4 | Я не чураюсь забрать что-то у мёртвого. |
| 5 | В пыльной гробнице я чувствую себя счастливее, чем в самом сердце цивилизации. |
| 6 | Ловушки меня не беспокоят. Меня беспокоят идиоты, которые их активируют. |
| 7 | Неудачи случаются, но я никогда не сдамся! |
| 8 | Пусть я и похож на учёного, но люблю хорошую драку. Эти кулаки созданы чтобы бить. |
`,
      source: {
        id: SOURCE_TOA,
        page: 193,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Сохранение.** Этот артефакт принадлежит музею. | Доброе |
| 2 | **Жадность.** Я не буду рисковать своей жизнью просто так. Я ожидаю какой-то вид оплаты за это. | Любое |
| 3 | **Жажда смерти.** Нет ничего более захватывающего, чем побег из лап смерти. | Хаотичное |
| 4 | **Достоинство.** К мёртвым и их имуществу нужно относиться с должным уважением. | Принципиальное |
| 5 | **Бессмертие.** Все мои похождения связаны с поиском секретов вечной жизни. | Любое |
| 6 | **Опасность.** С любым крупным открытием приходит и большая опасность. Два зайца одним махом | Любое |
`,
      source: {
        id: SOURCE_TOA,
        page: 193,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | С раннего детства я слушал рассказы о затерянном городе. Я найду его, разгадаю его секреты и займу своё место в анналах истории. |
| 2 | Я хочу найти своего учителя, который пропал во время экспедиции некоторое время назад. |
| 3 | У меня есть друг-соперник. Только один из нас сможет стать самым лучшим. Я докажу, что им стану я. |
| 4 | Я не буду продавать предмет искусства или другое сокровище, если оно имеет культурную ценность. |
| 5 | Я тайно влюблён в того, кто выделяет средства для моих археологических экспедиций. |
| 6 | Я хочу принести славу библиотеке, музею или университету |
`,
      source: {
        id: SOURCE_TOA,
        page: 193,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Я втайне боюсь одного из обычных диких животных. На своей работе я вижу их постоянно. |
| 2 | Я не могу уйти из комнаты, не попытавшись найти потайную дверь. |
| 3 | Если я не исследую древние руины или подземелья, то становлюсь нервным и нетерпеливым. |
| 4 | У меня нет времени на друзей или семью. Каждую свободную минуту я трачу на подготовку или обдумывание своей следующей экспедиции. |
| 5 | Когда есть выбор, идти направо или налево, я всегда выбираю налево. |
| 6 | Я могу уснуть только в полной темноте. |
`,
      source: {
        id: SOURCE_TOA,
        page: 193,
      },
    },
  ],
  proficiency: {
    tool: {
      toChoose: {
        count: 1,
        list: [
          GEAR_CARTOGRAPHER_S_TOOLS,
          GEAR_NAVIGATORS_TOOLS,
        ],
      },
    },
    skill: {
      list: [
        SKILL_HISTORY,
        SKILL_SURVIVAL,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 1,
    },
  },
  equipment: `
* [Деревянный тубус](GEAR:${GEAR_CASE_MAP_OR_SCROLL}), содержащий карту затерянного города или подземелья
* [Фонарь](GEAR:${GEAR_LANTERN_BULLSEYE})
* Кирка
* [Дорожная одежда](GEAR:${GEAR_CLOTHES_TRAVELERS})
* [Лопата](GEAR:${GEAR_SHOVEL})
* [Двухместная палатка](GEAR:${GEAR_TENT})
* Одна безделушка с последних раскопок
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 25 зм
`,
  source: {
    id: SOURCE_TOA,
    page: 193,
  },
}
