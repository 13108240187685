import transformText from '@/utils/transformText'

import {bondVariantCollection} from '@/components/CharacterGenerator/constants/personalization/bondVariantList'

export default ({ genderId, bondId }) => {
  if (bondId) {
    const {name} = bondVariantCollection[bondId]

    return transformText(genderId)(name)
  }

  return ''
}
