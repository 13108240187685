import arrify from 'arrify'
import _ from 'lodash'
import { PARAM_ANY, paramIdList } from '@/constants/paramList'

export default (statsObj, paramBonuses) => {
  if (paramBonuses) {
    Object
      .entries(paramBonuses)
      .forEach(
        ([key, value]) => {
          arrify(value).forEach(
            valueItem => {
              const statId = key === PARAM_ANY
                ? _.sample(paramIdList)
                : key

              statsObj[statId] = statsObj[statId] + valueItem
            },
          )
        },
      )
  }
}
