import upLetter from '@/utils/upLetter'

import {
  VARIANT_PHYSICAL_POSITIVE,
  VARIANT_PHYSICAL_NEGATIVE,
} from '@/components/CharacterGenerator/constants/variantIdList'
import variantDict from '@/components/CharacterGenerator/constants/variantDict'

export default character => {
  const {genderId} = character
  const negativeFeatureId = character[VARIANT_PHYSICAL_NEGATIVE]
  const positiveFeatureId = character[VARIANT_PHYSICAL_POSITIVE]
  const negativeFeature = variantDict[VARIANT_PHYSICAL_NEGATIVE].collection[negativeFeatureId].nameByGender[genderId]
  const positiveFeature = variantDict[VARIANT_PHYSICAL_POSITIVE].collection[positiveFeatureId].nameByGender[genderId]

  return upLetter(`${positiveFeature}, но ${negativeFeature}`)
}
