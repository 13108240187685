const {BACKGROUND_FOLK_HERO} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  CAT_ARTISAN_TOOLS,
  CAT_TRANSPORT_GROUND,
} = require('./../../gearCategoryList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_POT_IRON,
  GEAR_POUCH,
  GEAR_SHOVEL,
} = require('./../../gearIdList')
const {
  SKILL_ANIMAL_HANDLING,
  SKILL_SURVIVAL,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_FOLK_HERO,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Народный герой',
    [GENDER_FEMALE]: 'Народная героиня',
  },
  nameEn: 'Folk Hero',
  description: [
    {
      header: 'Народный герой',
      text: `У вас было низкое социальное положение, но судьбой было уготовано большее. Жители вашей родной деревни уже считают вас своим героем, но вам предначертано сражаться с тиранами и чудовищами, угрожающими другим.`,
      source: {
        id: SOURCE_PHB,
        page: 132,
      },
    },
    {
      header: 'Умение: Деревенское гостеприимство',
      text: `Вы сами из простого народа, и потому легко находите с ними общий язык. Вы можете найти место, чтобы спрятаться, отдохнуть или подлечиться среди обывателей, если только Вы не угрожаете им. Они укроют вас от представителей закона и тех, кто ищет вас, но своими жизнями за вас они рисковать не будут.`,
      source: {
        id: SOURCE_PHB,
        page: 132,
      },
    },
    {
      header: 'Определяющее событие',
      text: `Вы занимались обычным делом для деревенщины. Возможно, Вы возделывали землю, трудились в шахте, прислуживали дворянину, пасли скот, рубили деревья или копали могилы. Но потом произошло нечто, втянувшее вас в великие события. Выберите или определите случайно определяющее событие, сделавшее вас героем среди своего народа.

| к10 | Определяющее событие |
|-|-|
| 1 | Я дал отпор прислужникам тирана. |
| 2 | Я спас народ от природного катаклизма. |
| 3 | Я сразился один на один с ужасным чудищем. |
| 4 | Я обокрал продажного торговца, чтобы помочь бедным. |
| 5 | Я возглавил ополчение, отбросившее врага. |
| 6 | Я вломился в замок тирана и украл оружие для своего народа. |
| 7 | Я учил крестьян, как использовать обычные инструменты в качестве оружия против солдат тирана. |
| 8 | Лорд отменил непопулярный закон после того как я выступил с акцией протеста. |
| 9 | Небожитель, фея или другое подобное существо благословило меня или открыло тайну моего рождения. |
| 10 | Вступив в армию лорда, я получил хорошее звание и заслужил благодарности. |
`,
      source: {
        id: SOURCE_PHB,
        page: 132,
      },
    },
    {
      header: 'Персонализация',
      text: `Народный герой — один из обывателей, как в хорошем смысле, так и в плохом. Многие из них считают своё происхождение положительной стороной, а не изъяном, и их родное поселение остаётся навсегда очень важным для них.`,
      source: {
        id: SOURCE_PHB,
        page: 132,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Я сужу других по поступкам, а не словам. |
| 2 | Если кто-то в беде, я всегда готов прийти на помощь. |
| 3 | Задумав что-то, я прямолинейно иду к цели, невзирая на помехи. |
| 4 | У меня обострённое чувство справедливости, и в споре я всегда ищу самого честного решения. |
| 5 | Я уверен в своих способностях, и стараюсь внушить такую же уверенность в других. |
| 6 | Пусть думают другие. Я предпочитаю действовать. |
| 7 | Я неправильно произношу длинные слова, когда пытаюсь выглядеть умнее. |
| 8 | Я быстро устаю. Когда уже я найду свою судьбу? |
`,
      source: {
        id: SOURCE_PHB,
        page: 132,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Уважение.** Нужно относиться друг к другу с достоинством и уважением. | Доброе |
| 2 | **Справедливость.** Закон не должен делать ни для кого исключений, и никто не может стать выше закона. | Принципиальное |
| 3 | **Свобода.** Тираны не должны угнетать народ. | Хаотичное |
| 4 | **Мощь.** Если я стану сильным, я смогу брать то, что захочу — и я это заслуживаю. | Злое |
| 5 | **Искренность.** Нет ничего хорошего в том, что ты притворяешься тем, кем не являешься. | Нейтральное |
| 6 | **Судьба.** Никто и ничто не собьёт меня с пути к высшему призванию. | Любое |
`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | У меня есть семья, но я не знаю, где она. Надеюсь, когда-нибудь я увижу их вновь. |
| 2 | Я работал на земле, я люблю землю, и я буду защищать землю. |
| 3 | Один благородный меня дико поколотил, и я вымещаю свой гнев на всех встреченных задирах. |
| 4 | Мои инструменты — символ моей былой жизни, и я ношу их, чтобы не забывать корни. |
| 5 | Я защищаю тех, кто не может защитить себя сам. |
| 6 | Хотел бы я, чтобы та, кого я любил в детстве, разделила со мной судьбу. |
`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Тиран, правящий моими землями, ни перед чем не остановится, лишь бы убить меня. |
| 2 | Я слепо верю в своё предназначение, и не замечаю ни своих недостатков, ни смертельных опасностей. |
| 3 | Те, кто знают меня молодым, знают и мою самую постыдную тайну, так что я никогда не вернусь домой. |
| 4 | Я беззащитен перед пороками большого города, особенно перед пьянством. |
| 5 | Я тайно считаю, что было бы хорошо стать тираном, правящим землями. |
| 6 | Я не доверяю союзникам. |
`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
  ],
  proficiency: {
    tool: {
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_ARTISAN_TOOLS,
        ],
      },
      list: [
        CAT_TRANSPORT_GROUND,
      ],
    },
    skill: {
      list: [
        SKILL_ANIMAL_HANDLING,
        SKILL_SURVIVAL,
      ],
    },
  },
  equipment: `
* Ремесленные инструменты (один вид на Ваш выбор),
* [Лопата](GEAR:${GEAR_SHOVEL}),
* [Железный горшок](GEAR:${GEAR_POT_IRON}),
* [Обычная одежда](GEAR:${GEAR_CLOTHES_COMMON}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 132,
  },
}
