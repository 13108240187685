const arrify = require('arrify')

const listToCollectionById = require('./../../utils/listToCollectionById')
const prepareForSearch = require('./../../utils/prepareForSearch')
const sortByName = require('./../../utils/sortByName')

const SEARCH_PROP_NAME = require('./../SEARCH_PROP_NAME')
const { GENDER_MALE } = require('./../genderList')

const backgroundRawList = require('./backgroundRawList')

const backgroundList = backgroundRawList
  .map(
    item => {
      const name = item.nameByGender[GENDER_MALE]
      const description = arrify(item.description)[0]
      const descriptionText = typeof description === 'object'
        ? description.text
        : description

      return {
        ...item,
        name,
        isReady: item.isReady || false,
        [SEARCH_PROP_NAME]: prepareForSearch(
          [
            name,
            descriptionText,
            item.nameEn,
            arrify(item.nameAlt || '').join(' ') || '',
            arrify(item.nameEnAlt || '').join(' ') || '',
          ]
            .filter(e => e)
            .join('\n')
        ),
      }
    }
  )
  .sort(sortByName)

module.exports = backgroundList
module.exports.backgroundCollection = listToCollectionById(backgroundList)
