import React from 'react'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'

import generateTextLinks from '@/utils/generateTextLinks'
import Icon from '@/components/Icon'
import Markdown from '@/components/Markdown'

import './LifeEventStyles.less'

export default ({ text, onGenerateSideCharacter }) => (
  <section className='LifeEvent'>
    <Markdown>{generateTextLinks(text)}</Markdown>
    {
      onGenerateSideCharacter
        ? (
          <button
            title='Сгенерировать персонажа для этого события'
            onClick={onGenerateSideCharacter}
            className='LifeEvent_generateBtn'
          >
            <Icon icon={faUserPlus}/>
          </button>
        )
        : null
    }
  </section>
)
