import React from 'react'

import LifeEvent from './../LifeEvent'

import  './LifeEventListStyles.less'

export default ({list, character}) => (
  <section className='LifeEventList'>
    <header className='LifeEventList_header'>События в жизни</header>
    <ol className='LifeEventList_list'>
      {
        list.map(
          lifeEvent => (
            <li
              key={lifeEvent.id}
              className='LifeEventList_item'
            >
              <LifeEvent
                lifeEvent={lifeEvent}
                character={character}
              />
            </li>
          ),
        )
      }
    </ol>
  </section>
)
