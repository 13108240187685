import transformText from '@/utils/transformText'

import variantDict from '@/components/CharacterGenerator/constants/variantDict'
import { VARIANT_PARENT_TYPE } from '@/components/CharacterGenerator/constants/variantIdList'

import generateTextByCharacter from '@/components/CharacterGenerator/utils/generateTextByCharacter'

export default character => {
  const {missedParentList} = character
  return missedParentList && missedParentList.length
    ? missedParentList.map(
      ({ details, parentTypeId }) => {
        const {name: parentName, genderId} = variantDict[VARIANT_PARENT_TYPE].collection[parentTypeId]

        const {text: missedParentText} = generateTextByCharacter(character)({genderId, ...details})

        return transformText(genderId)(missedParentText)
          .replace(new RegExp('★Родитель★','g'), parentName)
      },
    )
    : ''
}
