import { GENDER_MALE } from '@/constants/genderList'

import { FAMILY_FOSTER_OTHER_RACE } from '@/components/CharacterGenerator/constants/familyVariantList'
import {
  SUPERNATURAL_EVENT_DREAM_DEITY,
  SUPERNATURAL_EVENT_GHOUL,
  SUPERNATURAL_EVENT_PORTAL,
  SUPERNATURAL_EVENT_SACRED_PLACE,
} from "@/components/CharacterGenerator/constants/supernaturalEventList"
import { WEIRD_STUFF_SLAVERY } from '@/components/CharacterGenerator/constants/weirdStuffList'
import { MISSED_PARENT_REASON_SLAVE } from '@/components/CharacterGenerator/constants/missedParentReasonVariantList'
import { EVENT_BATTLE_CREATURE_TYPE, EVENT_BATTLE_PC_RACE } from '@/components/CharacterGenerator/constants/lifeEventVariantList'
import { DEATH_GOD_HIMSELF, DEATH_BATTLE, DEATH_MURDERED } from '@/components/CharacterGenerator/constants/deathList'

import variantDict from '@/components/CharacterGenerator/constants/variantDict'
import {
  VARIANT_BEAST,
  VARIANT_CANTRIP,
  VARIANT_CREATURE_TYPE,
  VARIANT_FAMILY,
  VARIANT_GOD,
  VARIANT_MONSTER,
  VARIANT_OCCUPATION,
  VARIANT_OUTER_PLANE,
  VARIANT_RACE,
  VARIANT_SIMPLE_WEAPON,
  VARIANT_SPELL,
  VARIANT_SPELL_WITH_DAMAGE,
  VARIANT_TRINKET,
} from '@/components/CharacterGenerator/constants/variantIdList'

export default ({ name, genderId = GENDER_MALE }) =>
  (resultText, { detailDictId, detailId, text, parentDetailId }) => {
    switch (detailDictId) {
      case VARIANT_CANTRIP:
      case VARIANT_SPELL:
      case VARIANT_SPELL_WITH_DAMAGE: {
        const text = variantDict[detailDictId].collection[detailId].name
        return resultText.replace(detailDictId, `[${text}](SPELL:${detailId})`)
      }

      case VARIANT_RACE: {
        switch (parentDetailId) {
          case EVENT_BATTLE_CREATURE_TYPE:
          case EVENT_BATTLE_PC_RACE:
          case FAMILY_FOSTER_OTHER_RACE: {
            const raceText = variantDict[detailDictId].collection[detailId].name.plural.genitive

            return resultText.replace(detailDictId, raceText)
          }

          case DEATH_BATTLE:
          case MISSED_PARENT_REASON_SLAVE: {
            const raceText = variantDict[detailDictId].collection[detailId].name.plural.instrumental

            return resultText.replace(detailDictId, raceText)
          }

          case SUPERNATURAL_EVENT_GHOUL: {
            const raceText = variantDict[detailDictId].collection[detailId].name.singular[genderId].genitive

            return resultText.replace(detailDictId, raceText)
          }

          case DEATH_MURDERED: {
            const raceText = variantDict[detailDictId].collection[detailId].name.singular[genderId].instrumental

            return resultText.replace(detailDictId, raceText)
          }

          default: {
            return resultText.replace(detailDictId, name)
          }
        }
      }

      case VARIANT_BEAST:
      case VARIANT_MONSTER: {
        const text = variantDict[detailDictId].collection[detailId].name

        return resultText.replace(detailDictId, `[${text}](CREATURE:${detailId})`)
      }

      case VARIANT_SIMPLE_WEAPON: {
        const text = variantDict[detailDictId].collection[detailId].name

        return resultText.replace(detailDictId, `[${text}](GEAR:${detailId})`)
      }

      case VARIANT_CREATURE_TYPE: {
        switch (parentDetailId) {
          case WEIRD_STUFF_SLAVERY: {
            const raceText = variantDict[detailDictId].collection[detailId].name.singular[genderId].dative

            return resultText.replace(detailDictId, raceText)
          }

          default: {
            const raceText = variantDict[detailDictId].collection[detailId].name.plural.genitive

            return resultText.replace(detailDictId, raceText)
          }
        }
      }

      case VARIANT_GOD: {
        let text = variantDict[detailDictId].collection[detailId].nameFull.genitive

        switch (parentDetailId) {
          case SUPERNATURAL_EVENT_DREAM_DEITY: {
            const { nameFull: { instrumental }, genderId } = variantDict[detailDictId].collection[detailId]
            const word = genderId === GENDER_MALE
              ? 'который предупредил'
              : 'которая предупредила'

            text = `${instrumental}, ${word}`
            break
          }

          case SUPERNATURAL_EVENT_SACRED_PLACE: {
            const { nameFull: { genitive }, genderId } = variantDict[detailDictId].collection[detailId]

            text = genitive
            break
          }

          case DEATH_GOD_HIMSELF: {
            const { nameFull: { instrumental }, genderId } = variantDict[detailDictId].collection[detailId]
            const word = genderId === GENDER_MALE
              ? 'разгневанным'
              : 'разгневанной'

            text = `${word} ${instrumental}`
            break
          }
        }

        return resultText.replace(detailDictId, text)
      }

      case VARIANT_OUTER_PLANE: {
        const plane = variantDict[detailDictId].collection[detailId]
        const { fullName: { local, localPreposition } } = plane
        let text = `${localPreposition} ${local}`

        switch (parentDetailId) {
          case SUPERNATURAL_EVENT_PORTAL: {
            const { fullName: { accusative: planeName } } = plane
            text = `${localPreposition} ${planeName}`
            break
          }
        }

        return resultText.replace(detailDictId, text)
      }

      case VARIANT_TRINKET: {
        const { name, description } = variantDict[detailDictId].collection[detailId]
        const descriptionText = description
          ? ` (${description})`
          : ''

        return resultText.replace(detailDictId, `**${name}**${descriptionText}`)
      }

      case VARIANT_FAMILY: {
        switch (detailId) {
          case FAMILY_FOSTER_OTHER_RACE: {
            if (name.plural) {
              const raceText = name.plural.genitive

              return resultText.replace(detailDictId, raceText)
            }
          }
        }
        break
      }

      case VARIANT_OCCUPATION: {
        if (typeof resultText === 'string') {
          const occupationName = variantDict[detailDictId].collection[detailId].name[genderId]

          return resultText.replace(detailDictId, occupationName)
        }

        return resultText[genderId]
      }
    }

    return resultText
      ? resultText.replace(detailDictId, text)
      : name
  }
