const {BACKGROUND_PLANAR_PHILOSOPHER_2022_07_18} = require('./../../backgroundIdList')
const {SOURCE_UA_2022_07_18_WotM} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  SKILL_ARCANA,
  SKILL_PERSUASION,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_PLANAR_PHILOSOPHER_2022_07_18,
  isReady: false,
  nameByGender: {
    [GENDER_MALE]: 'Планарный философ',
    [GENDER_FEMALE]: 'Планарная философ',
  },
  nameEn: 'Planar Philosopher',
  proficiency: {
    skill: {
      list: [
        SKILL_ARCANA,
        SKILL_PERSUASION,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: `
* [Комплект обычной одежды](GEAR:${GEAR_CLOTHES_COMMON}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм с других миров или планов
`,
  source: {
    id: SOURCE_UA_2022_07_18_WotM,
    page: 4,
  },
}
