const {BACKGROUND_ANTHROPOLOGIST} = require('./../../backgroundIdList')
const {SOURCE_TOA} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_INK,
  GEAR_INK_PEN,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  PC_RACE_AARAKOCRA,
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GOBLIN,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
  PC_RACE_LIZARDFOLK,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_INSIGHT,
  SKILL_RELIGION,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_ANTHROPOLOGIST,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Антрополог',
    [GENDER_FEMALE]: 'Антрополог',
  },
  nameEn: 'Anthropologist',
  description: [
    {
      header: 'Антрополог',
      text: `Вас всегда привлекали другие культуры, начиная с древних и примитивных времён и заканчивая самыми современными достижениями цивилизаций. Изучая обычаи других культур, философию, законы, ритуалы, религиозные верования, языки и искусство, Вы узнали, как племена, империи и другие формы общества создают свою судьбу и следуют ей до самого конца. Эти знания дались Вам не только через книги и древние свитки, но и за счёт получения информации из первых рук — Вы посетили затерянные поселения и изучили местные истории и культуру.`,
      source: {
        id: SOURCE_TOA,
        page: 192,
      },
    },
    {
      header: 'Умение: Младший лингвист',
      text: `Вы можете общаться с гуманоидами, которые не говорят на известных Вам языках. Вы должны понаблюдать за взаимодействием гуманоида с другим гуманоидом в течение 1 дня. После этого Вы научитесь основным жестам и словам, которых хватит для общения на базовом уровне.`,
      source: {
        id: SOURCE_TOA,
        page: 192,
      },
    },
    {
      header: 'Погружение в культуру народов',
      text: `Прежде чем встать на путь искателя приключений, Вы провели большую часть своей взрослой жизни вдали от родных земель, живя среди тех, кто отличается от Вас. Вы изучили их культуру и научились понимать местных жителей, которые в конечном итоге стали относиться к Вам как к одному из своих.

Одна культура оказала большое влияние на Вас, изменив Ваши верования и обычаи. Вы можете выбрать расу из таблицы _Перенятая культура_, которая Вас приютила, или определить её случайно.

# Перенятая культура

| к8 | Культура | к8 | Культура |
|-|-|-|-|
| 1 | [Ааракокры](PC_RACE:${PC_RACE_AARAKOCRA}) | 5 | [Полурослики](PC_RACE:${PC_RACE_HALFLING}) |
| 2 | [Дварфы](PC_RACE:${PC_RACE_DWARF}) | 6 | [Люди](PC_RACE:${PC_RACE_HUMAN})  |
| 3 | [Эльфы](PC_RACE:${PC_RACE_ELF}) | 7 | [Людоящеры](PC_RACE:${PC_RACE_LIZARDFOLK}) |
| 4 | [Гоблины](PC_RACE:${PC_RACE_GOBLIN}) | 8 | [Орки](PC_RACE:${PC_RACE_ORC}) |
`,
      source: {
        id: SOURCE_TOA,
        page: 192,
      },
    },
    {
      header: 'Персонализация',
      text: `Антропологи оставляют позади привычное им общество для того, чтобы заняться изучением другого. Они стремятся узнать то, как другие расы или цивилизации сумели выжить или то, что помешало им сделать это. Некоторыми антропологами движет интерес к чему-то новому, а другие гонятся за славой и почётом, попросту желая стать первыми, кто найдёт новую расу, затерянное племя или какую-то информацию о судьбе империи, давно канувшей в лету.`,
      source: {
        id: SOURCE_TOA,
        page: 192,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к6 | Черта характера |
|-|-|
| 1 | Я предпочитают компанию тех, кто не похож на меня, включая представителей других рас. |
| 2 | Я сторонник соблюдения этикета приличия и местных обычаев. |
| 3 | Я буду лучше наблюдать, чем вмешиваться. |
| 4 | Живя среди тех, кто почитает насилие, я стал более терпим к нему. |
| 5 | Я рискну своей жизнью, чтобы открыть новую культуру или разгадать тайну чего-то давно забытого. |
| 6 | Когда я впервые прибываю в новое поселение, я должен узнать все обычаи местных. |
`,
      source: {
        id: SOURCE_TOA,
        page: 192,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Открытия.** Я хочу быть первым, кто сумеет найти затерянную цивилизацию. | Любое |
| 2 | **Дистанция.** Ни одна культура не должна вмешиваться в дела другой, даже если последним нужна помощь. | Принципиальное |
| 3 | **Знания.** Познавая другие культуры и расы, мы познаем себя. | Любое |
| 4 | **Сила.** Обычному народу требуется сильный лидер. Я сделаю всё возможное, чтобы дать им это. | Принципиальное |
| 5 | **Защита.** Я должен сделать все, что в моих силах, чтобы спасти общество от вымирания | Доброе |
| 6 | **Безразличие.** Жизнь жестока. Зачем кого-то спасать, если он все равно рано или поздно помрёт? | Хаотичное |
`,
      source: {
        id: SOURCE_TOA,
        page: 192,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Мой учитель дал мне дневник, полный знаний и древней мудрости. Его потеря уничтожит меня. |
| 2 | Однажды пожив в первобытного племени, я хочу вернуться и посмотреть, что с ними стало. |
| 3 | Много лет назад трагедия настигла членов изолированного общества, с которыми я был в хороших отношениях. Я не забыл их. |
| 4 | Я хочу узнать больше о культуре определённых гуманоидов, которая очень привлекает меня. |
| 5 | Я хочу отомстить за клан, племя, королевство или империю, которое было уничтожено. |
| 6 | У меня с собой есть безделушка, которая является ключом к разгадке тайны затерянной цивилизации. |
`,
      source: {
        id: SOURCE_TOA,
        page: 192,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | На море меня сильно укачивает. |
| 2 | Я разговариваю сам с собой и не завожу друзей. |
| 3 | Я верю, что мой интеллект намного выше, чем у тех народов, которые я изучаю. Я уверен, что смогу многому их научить. |
| 4 | Я подцепил очень вредные привычки, живя среди орков, гоблинов или людоящеров. |
| 5 | Я постоянно жалуюсь. |
| 6 | Я ношу племенную маску и никогда её не снимаю. |
`,
      source: {
        id: SOURCE_TOA,
        page: 192,
      },
    },
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_INSIGHT,
        SKILL_RELIGION,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: `
* Дневник в кожаной обложке,
* [Бутылочка чернил](GEAR:${GEAR_INK}),
* [Писчее перо](GEAR:${GEAR_INK_PEN}),
* [Дорожная одежда](GEAR:${GEAR_CLOTHES_TRAVELERS})
* Одна безделушка с уникальными метками,
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм.
`,
  source: {
    id: SOURCE_TOA,
    page: 192,
  },
}
