const {BACKGROUND_AGENT_LORDS_ALLIANCE} = require('./../../backgroundIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_HISTORY,
  SKILL_INSIGHT,
} = require('./../../skillList')
const faction_agent_1st = require('./../common/faction_agent_1st')
const faction_agent_2nd = require('./../common/faction_agent_2nd')
const faction_agent_equipment = require('./../common/faction_agent_equipment')

module.exports = {
  id: BACKGROUND_AGENT_LORDS_ALLIANCE,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Агент Альянса лордов',
    [GENDER_FEMALE]: 'Агент Альянса лордов',
  },
  nameEn: 'Lords’ Alliance Agent',
  description: [
    ...faction_agent_1st,
    {
      header: 'Альянс Лордов',
      text: `На одном уровне, агенты Альянса Лордов являются представителями городов и государств, которые составляют альянс. Но, как фракция с интересами, простирающимися значительно дальше локальной политики и географии, Альянс обладает ресурсами и людьми, которые работают на саму организацию и её внутренние интересы. От агентов Альянса требуют владения навыком Истории, и они всегда могут положиться на помощь правительства, группы и лидеров, которое является частью Альянса или поддерживают его идеалы.`,
      source: {
        id: SOURCE_SCAG,
        page: 148,
      },
    },
    ...faction_agent_2nd,
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_INSIGHT,
        SKILL_HISTORY,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: faction_agent_equipment('Альянса лордов'),
  source: {
    id: SOURCE_SCAG,
    page: 148,
  },
}
