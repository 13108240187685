import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import declint from 'declint-ru'

import action_addSubCharacter from '@/components/CharacterGenerator/store/actions/addSubCharacter'

import LifeEventComponent from './LifeEventComponent'
import arrify from 'arrify'

class LifeEventContainer extends Component {
  state = {
    isSubCharacterGenerated: false,
  }

  onGenerateSideCharacter = () => {
    const { lifeEvent: {characterGenerationParams}, character, addSubCharacter } = this.props

    arrify(characterGenerationParams).forEach(
      params => {
        addSubCharacter({
          ...params,
          parentCharacterId: character.id,
        })
      }
    )

    this.setState({isSubCharacterGenerated: true})
  }

  render () {
    const { isSubCharacterGenerated } = this.state
    const { lifeEvent: { text, happensAtAge, characterGenerationParams } } = this.props
    const ageText = `**${happensAtAge} ${declint(happensAtAge, ['год', 'года', 'лет'])}**`

    const onGenerateSideCharacter = characterGenerationParams && !isSubCharacterGenerated
      ? this.onGenerateSideCharacter
      : null

    return (
      <LifeEventComponent
        text={`${ageText}. ${text}`}
        onGenerateSideCharacter={onGenerateSideCharacter}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addSubCharacter: action_addSubCharacter,
      },
      dispatch,
    ),
  }
}

export default connect(null, mapDispatchToProps)(LifeEventContainer)
