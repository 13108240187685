import arrify from 'arrify'
import listToCollectionById from '@/utils/listToCollectionById'
import { SOURCE_DMG, SOURCE_PHB } from '@/constants/sourceList'
import {
  ALIGNMENT_CE,
  ALIGNMENT_CG,
  ALIGNMENT_CN,
  ALIGNMENT_LE,
  ALIGNMENT_LG,
  ALIGNMENT_LN,
  ALIGNMENT_N,
  ALIGNMENT_NE,
  ALIGNMENT_NG,
  ALIGNMENT_NO,
} from '@/constants/aligmentList'
import {
  CREATURE_AARAKOCRA,
  CREATURE_DRAGON_RED_ADULT,
  CREATURE_AZER,
  CREATURE_BAPHOMET,
  CREATURE_BEHOLDER,
  CREATURE_BEL,
  CREATURE_BLINK_DOG,
  CREATURE_CENTAUR,
  CREATURE_CLOACKER,
  CREATURE_COUATL,
  CREATURE_DAO,
  CREATURE_DARKMANTLE,
  CREATURE_DEMOGORGON,
  CREATURE_DRAGON_TURTLE,
  CREATURE_DRYAD,
  CREATURE_EFREETI,
  CREATURE_FIRE_GIANT,
  CREATURE_FOMORIAN,
  CREATURE_GALEB_DUHR,
  CREATURE_GARGOYLE,
  CREATURE_GERYON,
  CREATURE_GHOST,
  CREATURE_GHOUL,
  CREATURE_GOBLIN,
  CREATURE_GRAZZT,
  CREATURE_IMP,
  CREATURE_INCUBUS,
  CREATURE_INVISIBLE_STALKER,
  CREATURE_KRAKEN,
  CREATURE_LARVA,
  CREATURE_LEVIATHAN,
  CREATURE_LICH,
  CREATURE_MAGMIN,
  CREATURE_MARID,
  CREATURE_MIND_FLAYER,
  CREATURE_MOLOCH,
  CREATURE_NECROMANCER,
  CREATURE_NIGHT_HAG,
  CREATURE_OGRE,
  CREATURE_ORCUS,
  CREATURE_PEGASUS,
  CREATURE_PHASE_SPIDER,
  CREATURE_PIT_FIEND,
  CREATURE_PIXIE,
  CREATURE_RAKSHASA,
  CREATURE_SALAMANDER,
  CREATURE_SATYR,
  CREATURE_SPRITE,
  CREATURE_SUCCUBUS,
  CREATURE_TIAMAT,
  CREATURE_TREANT,
  CREATURE_UNICORN,
  CREATURE_VAMPIRE,
  CREATURE_WATER_WEIRD,
  CREATURE_WIGHT,
  CREATURE_XORN,
  CREATURE_YEENOGHU,
  CREATURE_YOCHLOL,
  CREATURE_ZARIEL,
  CREATURE_ZOMBIE,
} from '@/constants/creatureIdList'
import {
  SPELL_ASTRAL_PROJECTION,
  SPELL_CALM_EMOTIONS,
  SPELL_CONJURE_WOODLAND_BEINGS,
  SPELL_DISPEL_EVIL_AND_GOOD,
  SPELL_ETHEREALNESS,
  SPELL_FEEBLEMIND,
  SPELL_FORCECAGE,
  SPELL_GATE,
  SPELL_GEAS,
  SPELL_LESSER_RESTORATION,
  SPELL_MORDENKAINEN_S_MAGNIFICENT_MANSION,
  SPELL_PLANE_SHIFT,
  SPELL_REMOVE_CURSE,
  SPELL_SEE_INVISIBILITY,
  SPELL_TRUE_SEEING,
  SPELL_WALL_OF_FORCE,
  SPELL_WISH,
} from '@/constants/spellIdList'
import {
  PLANE_TYPE_INNER,
  PLANE_TYPE_MATERIAL,
  PLANE_TYPE_OTHER_PLANES,
  PLANE_TYPE_OUTER,
  PLANE_TYPE_POSITIVE_NEGATIVE,
  PLANE_TYPE_TRANSITIVE,
} from '@/constants/planeTypeList'
import {
  GOD_LOLTH,
} from '@/constants/godIdList'

export const PLANE_ABYSS = 'abyss'
export const PLANE_ACHERON = 'acheron'
export const PLANE_AEBRYNIS = 'aebrynis'
export const PLANE_ARBOREA = 'arborea'
export const PLANE_ARCADIA = 'arcadia'
export const PLANE_ASTRAL = 'astral'
export const PLANE_ATHAS = 'athas'
export const PLANE_BAATOR = 'nine_hells'
export const PLANE_BEASTLANDS = 'beastlands'
export const PLANE_BYTOPIA = 'bytopia'
export const PLANE_CARCERI = 'carceri'
export const PLANE_DEMIPLANES = 'demiplanes'
export const PLANE_EBERRON = 'eberron'
export const PLANE_ELEMENTAL_CHAOS = 'elemental_chaos'
export const PLANE_ELYSIUM = 'elysium'
export const PLANE_ETHEREAL = 'ethereal'
export const PLANE_FAR_REALM = 'far_realm'
export const PLANE_FEYWILD = 'feywild'
export const PLANE_GEHENNA = 'gehenna'
export const PLANE_HADES = 'hades'
export const PLANE_KRYNN = 'krynn'
export const PLANE_LIMBO = 'limbo'
export const PLANE_MECHANUS = 'mechanus'
export const PLANE_MOUNT_CELESTIA = 'mount_celestia'
export const PLANE_MYSTARA = 'mystara'
export const PLANE_NEGATIVE = 'negative'
export const PLANE_OERTH = 'oerth'
export const PLANE_OF_AIR = 'air'
export const PLANE_OF_EARTH = 'earth'
export const PLANE_OF_FIRE = 'fire'
export const PLANE_OF_WATER = 'water'
export const PLANE_OUTLANDS = 'outlands'
export const PLANE_PANDEMONIUM = 'pandemonium'
export const PLANE_POSITIVE = 'positive'
export const PLANE_SHADOWFELL = 'shadowfell'
export const PLANE_TORIL = 'toril'
export const PLANE_YSGARD = 'ysgard'

const innerPlanesDescription = {
  header: 'Внутренние планы',
  text: `_Он лежал на спине на опалённых и тлеющих камнях, уставившись в дымное серое небо, освещаемое далёкими, скрытыми от глаз огнями. Вокруг камбиона море лавы изрыгало струи газа и пламени. Первичный План Огня. Спасибо падшим, подумал Вок. Никогда не думал, что буду настолько счастлив вновь здесь очутиться._

— Томас М. Рейд, Призрачная равнина

Внутренние Планы окружают и охватывают Материальный план и его отражения, предоставляя сырую стихийную материю, из которой состоят все миры. Четыре Стихийных Плана — Воды, Воздуха, Земли и Огня — формируют кольцо вокруг Материального плана, находясь при этом в бурлящем котле Стихийного Хаоса. Все эти планы связаны между собой, и пограничные регионы часто описывают как самостоятельные планы.

На внутреннем краю, где они ближе всего к Материальному плану (в концептуальном, а не географическом смысле), четыре Стихийных Плана напоминают местность на Материальном плане. Здесь присутствуют все четыре стихии, формируя землю, моря и небо. Однако доминирующая стихия проявляется в окружающей среде сильнее, демонстрируя свои качества.

Среди обитателей этого внутреннего кольца числятся [ааракокры](CREATURE:${CREATURE_AARAKOCRA}), [эйзеры](CREATURE:${CREATURE_AZER}), гении, [горгульи](CREATURE:${CREATURE_GARGOYLE}), [дракочерепахи](CREATURE:${CREATURE_DRAGON_TURTLE}), [зорны](CREATURE:${CREATURE_XORN}), мефиты и саламандры. Некоторые из них родом с Материального плана, но при наличии доступа к требуемой магии все они могут переходить на Материальный план и жить там.

Чем дальше от Материального плана, тем чужероднее и враждебнее становятся Стихийные планы. Здесь, в отдалённых регионах, стихии существуют в своей чистейшей форме: огромные пространства плотной земли, пылающий огонь, кристально-чистая вода и чистейший воздух. Чужеродные субстанции здесь встречаются очень редко; в глубине Плана Земли очень мало воздуха, а в глубине Плана Огня почти невозможно найти почву под ногами. Эти области значительно менее гостеприимны к путникам с Материального плана, чем пограничные регионы. Эти места очень слабо изучены, так что когда обсуждают План Огня, например, обычно имеют в виду именно пограничные участки.

Дальние области являются по большей части владениями стихийных духов, которых обычно называют не «существами», а «элементалями», включая Стихийных Князей Зла (предтеч, состоящих из чистой стихийной ярости) и стихийных духов, которых заклинатели помещают в [водных аномалий](CREATURE:${CREATURE_WATER_WEIRD}), [галеб дуров](CREATURE:${CREATURE_GALEB_DUHR}), големов, [магминов](CREATURE:${CREATURE_MAGMIN}) и [невидимых охотников](CREATURE:${CREATURE_INVISIBLE_STALKER}). Этим стихийным существам на домашних планах не нужна ни пища, ни другие средства для существования, так как их насыщает стихийная энергия этих мест.`,
  source: {
    id: SOURCE_DMG,
    page: 53,
  },
}

const outerPlanesDescriptionList = [
  {
    header: 'Внешние планы',
    text: `_Алый купол был исчерчен потоками ядовитого газа, похожими на грязные облака. Закручиваясь, они формировали нечто, похожее на глядящие с неба глаза. Глаза, которые рассеивались, не успев сфокусироваться, но тут же формирующиеся снова, и так по кругу. Окутанная рубиновым свечением, лежала тёмная кошмарная земля, представлявшая собой обнажённые камни и потоки искр и пламени, в тенях от которого извивались и дёргались какие-то твари. Горы царапали рубиновое небо. Земля Зубов, как однажды удачно назвал это место Азут, глядя на бесконечные остроконечные скалы. Это были Встречающие Земли, царство ужаса, принявшее жизни бесчисленных смертных. Они вращались над Аверно, верхней из Девяти Преисподних._

— Эд Гринвуд, Эльминстер в Аду

Если Внутренние Планы это сырая материя и энергия, составляющая мультивселенную, то Внешние Планы предоставляют направление, мысль и цель для сотворения этой самой мультивселенной. Соответственно, многие мудрецы считают Внешние Планы божественными или духовными, так как больше всего они известны тем, что там живут боги.

При обсуждении всего, что касается божеств, язык становится очень метафоричным. Их настоящие дома это вовсе не какие-то конкретные строения, а воплощение той самой идеи, что Внешние Планы — это миры мысли и духа. Так же, как и в случае со Стихийными Планами, ощутимую часть Внешних Планов можно представить в виде пограничного региона, а обширные духовные области будут лежать за пределами обычных органов чувств.

Даже в воспринимаемых частях внешность может быть обманчивой. Изначально большинство Внешних Планов выглядит гостеприимно и знакомо уроженцам Материального плана. Однако даже ландшафт здесь может меняться по прихоти мощных сил, обитающих здесь, полностью перестраиваясь, стирая и заново формируя реальность, удовлетворяющую потребности этих божеств.

Расстояние — практически бессмысленное понятие на Внешних Планах. Воспринимаемые части планов могут выглядеть небольшими, но могут также растягиваться до размеров, кажущихся бесконечными. Искатели приключений могут пройти за день все Девять преисподних, с первого по девятый слой — если повелители Преисподних захотят этого. В противном же случае путники по одному только слою могут пробираться неделю.

По умолчанию Внешние Планы это группа из шестнадцати планов, соответствующих восьми мировоззрениям (исключая нейтральность, которой соответствуют Внешние Земли, описанные в разделе «прочие планы») и переходам между ними. 

# Внешние планы

| Внешний план                    | Мировоззрение |
|---------------------------------|---------------|
| Гора Селестия, Семь Небес       | ПД            |
| Битопия, Двойной рай            | НД, ПД        |
| Элизиум, Благословенные Поля    | НД            |
| Звериные Земли, Дебри           | НД, ХД        |
| Арборея, Олимпийские просторы   | ХД            |
| Асгард, Героические владения    | ХН, ХД        |
| Лимбо, Вечно бурлящий хаос      | ХН            |
| Пандемоний, Завывающие Глубины  | ХН, ХЗ        |
| Бездны, Бесконечные слои        | ХЗ            |
| Карцери, Забытые Преисподние    | НЗ, ХЗ        |
| Гадес, Серые Пустоши            | НЗ            |
| Геенна, Мрачная вечность        | НЗ, ПЗ        |
| Девять преисподних (Баатор)     | ПЗ            |
| Ахерон, Бесконечные поля битв   | ПН, ПЗ        |
| Механус, Механическая нирвана   | ПН            |
| Аркадия, Мирные Королевства     | ПН, ПД        |

Планы с элементом добра в мировоззрении называются Верхними Планами, а содержащие элемент зла называются Нижними Планами. Мировоззрение плана это его сущность, и персонажи, чьё мировоззрение не совпадает с мировоззрением плана, чувствует себя на нём очень неуютно. Например, когда доброе существо посещает Элизиум, оно чувствует себя в гармонии, но злое существо не сможет там найти себе место.

Верхние Планы — дом небожителей, включая ангелов, [коатлей](CREATURE:${CREATURE_COUATL}) и [пегасов](CREATURE:${CREATURE_PEGASUS}). Нижние Планы — дом исчадий: демонов, дьяволов, юголотов и им подобных. В планах между ними есть свои уникальные обитатели: раса конструктов модронов в Механусе и аберрации слаады в Лимбо.`,
    source: {
      id: SOURCE_DMG,
      page: 58,
    },
  },
  {
    header: 'Слои внешних планов',
    text: `Большая часть Внешних Планов содержит в себе несколько разных сред или царств. Эти царства часто представляют и изображают как связанные между собой слои одного и того же плана, поэтому путники и называют их «слоями». Например, Гора Селестия напоминает пирог из семи слоёв, в Девяти Преисподних девять слоёв, а в Бездне, кажется, слоёв несчётное множество.

Большинство порталов извне ведёт на первый слой составного плана. В зависимости от плана, этот слой изображают либо верхним, либо нижним. Будучи местом прибытия чужаков, первый слой выполняет задачу городских ворот.`,
    source: {
      id: SOURCE_DMG,
      page: 59,
    },
  },
  {
    header: 'Путешествие между внешними планами',
    text: `Путешествие между Внешними Планами не отличается от простого перемещения на Внешний План. Персонажи, путешествующие посредством заклинания [Проекция в астрал](SPELL:${SPELL_ASTRAL_PROJECTION}), могут перейти с одного плана в Астральный План, и искать там цветной вихрь, ведущий в нужное место. Персонажи могут также использовать [Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT}), чтобы перейти непосредственно на другой план. Однако гораздо чаще персонажи используют порталы — либо порталы, соединяющие два плана, либо порталы, ведущие в Сигил, Город Дверей, в котором есть порталы на все существующие планы.

Две планарные достопримечательности соединяют между собой часть Внешних Планов: река Стикс и Бесконечная Лестница. В вашей кампании могут существовать и другие планарные перекрёстки, такие как Мировое Древо, чьи корни касаются Нижних Планов, а ветви достигают Верхних Планов, или может быть, в вашей космологии можно перейти с одного плана на другой просто пешком, пройдя долгий путь.`,
    source: {
      id: SOURCE_DMG,
      page: 59,
    },
  },
  {
    header: 'Река Стикс',
    text: `Эта река бурлит от грязи, обломков и трупов от сражений, идущих на её берегах. Все существа, кроме исчадий, касающиеся её воды или выпивающие её, попадают под действие заклинания [Слабоумие](SPELL:${SPELL_FEEBLEMIND}). СЛ испытания Интеллекта для противодействия этому эффекту равна 15.

Стикс течёт по верхним слоям Ахерона, Девяти Преисподних, Геенны, Гадеса, Карцери, Бездны и Пандемония. Притоки Стикса идут с нижних слоёв этих планов. Например, притоки Стикса есть на всех слоях Девяти Преисподних, что и позволяет переходить с одного слоя этого плана на другой.

По водам Стикса ходят мрачные паромы, управляемые кормчими, знающими как справляться с непредсказуемыми потоками и завихрениями воды. За соответствующую плату, эти кормчие могут перевезти пассажиров с одного плана на другой. Некоторые из них — исчадия, но встречаются также души мёртвых существ с Материального плана.`,
    source: {
      id: SOURCE_DMG,
      page: 59,
    },
  },
  {
    header: 'Бесконечная Лестница',
    text: `Бесконечная Лестница это межпространственная винтовая лестница, соединяющая планы. Вход на Бесконечную Лестницу обычно выглядит как ничем не примечательная дверь. За проходом находится небольшая площадка с такими же непримечательными лестницами вверх и вниз. Бесконечная Лестница изменяет свой облик, пока по ней поднимаешься или спускаешься, становясь из обычной деревянной или каменной лестницы хаотичным скопищем ступенек, висящих в лучащемся пространстве, где нет двух ступенек с одинаковым направлением гравитации. Говорят, что если тщательно поискать на площадке Бесконечной Лестницы, можно найти своё самое сокровенное желание.`,
    source: {
      id: SOURCE_DMG,
      page: 59,
    },
  },
  {
    header: 'Опциональные правила',
    text: `У каждого Внешнего Плана есть своя характеристика, делающая путешествие по нему уникальным опытом. План может влиять на посетителей множеством способов, взывая к чертам характера и слабостям, демонстрируя так свой характер, или даже меняя мировоззрение в сторону мировоззрения уроженцев этого плана. В описание каждого плана входит одно или несколько правил, которые Вы можете использовать для того, чтобы сделать это место запоминающимся.

# Опциональное правило: психический диссонанс

Все Внешние Планы излучают психический диссонанс, влияющий на посетителей с отличающимся мировоззрением — добрых существ на Нижних Планах, злых на Верхних Планах — если те проведут здесь слишком много времени. Вы можете воспроизвести этот диссонанс этим опциональным правилом. В конце длинного отдыха, проведённого на неподходящем плане, посетитель должен пройти испытание Телосложения СЛ 10. При провале существо получает одну степень истощения. Несовпадение по шкале принципиальность-хаотичность не вызывает такого эффекта, поэтому на Механус и Лимбо это правило не распространяется. `,
    source: {
      id: SOURCE_DMG,
      page: 60,
    },
  },
]

const extraPlanarTravelDescription = {
  header: 'Планарные путешествия',
  text: `Когда искатели приключений отправляются на другие планы, они совершают невероятные путешествия по границе существования в мистические места, где и выполняют свои задания. О таких путешествиях слагают легенды. Хождение в мир мёртвых, поиски слуг богов, или сделки с ифритами в их родном городе ещё долгие годы будут поводом для складывания песен и рассказов.

Путешествия на планы вне Материального плана можно совершать двумя способами: либо активацией заклинания, либо посредством портала.

**Заклинания.** Множество заклинаний позволяют напрямую или опосредованно обращаться к другим планам существования. [Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT}) и [Врата](SPELL:${SPELL_GATE}) могут перемещать искателей приключений напрямую на другие планы существования, хоть и с разной точностью. [Эфирность](SPELL:${SPELL_ETHEREALNESS}) позволяет искателям приключений входить на Эфирный План и перемещаться оттуда на планы, с которыми тот граничит — Царство Теней, Страна Фей и Стихийные Планы. Заклинание [Проекция в астрал](SPELL:${SPELL_ASTRAL_PROJECTION}) позволяет искателям приключений проецироваться в Астральный План, а оттуда на Внешние Планы.

**Порталы.** _«Портал»_ это общий термин для стационарных соединений между планами, связывающими конкретное места одного плана с конкретным местом другого. Некоторые порталы похожи на дверной проём, открытое окно или затянутый туманом проход, и простой вход туда вызовет путешествие между планами. Другие представляют собой настоящие строения (круги стоящих камней, парящие башни, корабли под парусами, или даже целые города) существующие одновременно на нескольких планах, либо прыгающие с одного плана на другой. Некоторые могут быть воронками, соединяющими Стихийный План с похожими местами Материального плана, например, жерло вулкана может вести на План Огня, а глубины океана — на План Воды.`,
  source: {
    id: SOURCE_PHB,
    page: 302,
  },
}

const planeList = [
  {
    id: PLANE_ASTRAL,
    type: PLANE_TYPE_TRANSITIVE,
    name: {
      nominative: `Астральный план`,
      accusative: `Астральный план`,
      local: `Астральном плане`,
      localPreposition: `на`,
    },
    nameEn: `Astral Plane`,
    description: [
      `_Халисстра открыла глаза и увидела, что плывёт по бескрайнему серебристому морю. Вдали неспешно ползли мягкие серые облака, в небе яростно извивались странные тёмные полосы, концы их были словно закреплены где-то непостижимо далеко, а середины отчаянно вращались, будто скакалки в детских руках. Она глянула вниз, любопытствуя, что удерживает её, и не увидела ничего, кроме того же странного перламутрового неба под ногами и вокруг._

_Она глубоко вздохнула, поражённая этим зрелищем, и почувствовала, как лёгкие её заполнились чем-то более сладким и, пожалуй, чуть более плотным, чем воздух, но, вместо того чтобы поперхнуться или захлебнуться, она, похоже, вполне приспособилась к этому веществу. По всему её телу пробежала дрожь, она поняла, что сам процесс дыхания зачаровывает её._

— Ричард Бейкер, Приговор

Астральный План — мир мыслей и грёз, в котором гости путешествуют в виде бестелесных душ, пытаясь перейти во Внешние Планы. Это огромное серебристое море, одинаковое и над головой и под ногами, с белыми и серыми лоскутами, носящимися промеж сгустков света, подобных далёким звёздам. Большая часть Астрального Моря представляет собой пустые просторы. Гости могут наткнуться на окаменевший труп мёртвого бога или просто обломки скал, плывущие в серебристой пустоте. Часто встречаются цветные вихри — магические разноцветные диски из света, блестящие как крутящиеся монеты.

Существа на Астральном Плане не стареют и не страдают от голода и жажды. Именно поэтому гуманоиды, живущие на Астральном Плане (такие как гитиянки), создают заставы на других планах, часто, на Материальном плане, чтобы их дети могли нормально взрослеть.

Путник на Астральном Плане может перемещаться, просто думая о перемещении, но расстояния здесь значат мало. В сражении скорость ходьбы существа (в футах) равна 3 × значение Интеллекта. Чем умнее существо, тем проще ему контролировать здесь своё перемещение.`,
      {
        header: 'Проекция в астрал',
        text: `Путешествие в Астральный План посредством заклинания [Проекция в астрал](SPELL:${SPELL_ASTRAL_PROJECTION}) включает в себя проецирование сюда сознания, обычно в поисках врат на один из Внешних Планов. Поскольку Внешние Планы это не только физические места, но и особые состояния духа, это позволяет персонажу появиться на Внешнем Плане как если бы он дошёл туда физически, но словно во сне. Смерть такого персонажа — хоть на Астральном Плане, хоть на Внешнем Плане — не причиняет ему настоящий урон. Только разрубание серебряной нити во время нахождения на Астральном Плане (а также смерть беспомощного физического тела на Материальном плане) могут убить персонажа по-настоящему. Таким образом, высокоуровневые персонажи иногда путешествуют на Внешние Планы при помощи [Проекции в астрал](SPELL:${SPELL_ASTRAL_PROJECTION}), не ища порталы и более прямолинейные заклинания.

Мало что может разрубить серебряную нить путника. Чаще всего это происходит из-за психического ветра. Легендарные серебряные мечи гитиянок тоже обладают таким свойством. Персонаж, путешествующий по Астральному Плану в своём собственном теле (посредством заклинания [Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT}) или одного из редких порталов, ведущих прямиком сюда), лишён серебряной нити. `,
        source: {
          id: SOURCE_DMG,
          page: 48,
        },
      },
      {
        header: 'Цветные вихри',
        text: `Врата, ведущие с Астрального Плана на другие планы, выглядят как двумерные цветные вихри, 1к6 × 10 футов диаметром. Для перехода на другой план требуется найти цветной вихрь, ведущий на желаемый план. Эти переходы на другие планы можно опознать по цвету, как показано в приведённой ниже таблице. Поиск вихря нужного цвета зависит от удачи: обычно это занимает 1к4 × 10 часов. 

# Астральные цветные вихри

| к20   | План               | Цвет вихря       | 
|-------|--------------------|------------------| 
| 1     | Асгард             | Индиго           | 
| 2     | Лимбо              | Угольно-чёрный   | 
| 3     | Пандемоний         | Пурпурный        | 
| 4     | Бездна             | Аметистовый      | 
| 5     | Карцери            | Оливковый        | 
| 6     | Гадес              | Рыжий            | 
| 7     | Геенна             | Красно-коричневый| 
| 8     | Девять преисподних | Рубиновый        | 
| 9     | Ахерон             | Огненно-красный  | 
| 10    | Механус            | Светло-синий     | 
| 11    | Аркадия            | Тёмно-оранжевый  | 
| 12    | Гора Селестия      | Золотой          | 
| 13    | Битопия            | Янтарный         | 
| 14    | Элизиум            | Оранжевый        | 
| 15    | Звериные Земли     | Изумрудно-зелёный| 
| 16    | Арборея            | Сапфирово-синий  | 
| 17    | Внешние Земли      | Коричневый       | 
| 18    | Эфирный План       | Бурлящий белый   | 
| 19—20 | Материальный план  | Серебряный       |  
`,
        source: {
          id: SOURCE_DMG,
          page: 48,
        },
      },
      {
        header: 'Психический ветер',
        text: `Психический ветер отличается от того, что дует на Материальном плане. Это ураган мыслей, сминающий сознание путников, а не их тела. Психический ветер состоит из утраченных воспоминаний, забытых идей, пустых дум и подсознательных страхов, сбившихся в единую массу и летающих по Астральному Плану.

Первым предвестником психического ветра является быстрое потемнение серебристо-серого неба. Через несколько раундов окрестности становятся тёмными как в безлунную ночь. Пока небеса темнеют, путник чувствует тряску, как если бы сам план сопротивлялся буре. Однако уже через несколько раундов, так же быстро, ветер исчезает, а небо становится обычным.

У психического ветра два эффекта: перемещающий и ментальный. Группа, путешествующая вместе, претерпевает один и тот же перемещающий эффект. Однако, каждый путник, попавший под действие ветра, должен также пройти испытание Интеллекта СЛ 15. При провале он подвергается также ментальному эффекту. Совершите бросок к20 два раза и сверьтесь с приведённой ниже таблицей, чтобы определить перемещающий и ментальный эффекты.

# Эффекты психического ветра

| к20   | Перемещающий эффект |
|-------|---------------------|
| 1–8   | Отклонение; добавьте 1к6 часов ко времени путешествия |
| 9–12  | Сбиты с пути; добавьте 3к10 часов ко времени путешествия |
| 13–16 | Блуждания; в конце путешествия персонажи прибывают не в то место, куда шли |
| 17–20 | Персонажи отправлены через цветной вихрь на случайным образом выбранный план (совершите бросок по таблице «астральные цветные вихри») |

| к20   | Ментальный эффект   |
|-------|---------------------|
| 1–8   | Ошеломлён на 1 минуту; Вы можете повторять испытание в конце каждого своего хода, чтобы окончить действие этого эффекта на вас |
| 9–10  | [Кратковременное безумие](/generator-madness) |
| 11–12 | Урон психической энергией 11 (2к10) |
| 13–16 | Урон психической энергией 22 (4к10) |
| 17–18 | [Долговременное безумие](/generator-madness) |
| 19–20 | Лишён сознания на 5 (1к10) минут; действие этого эффекта оканчивается на вас, если Вы получаете урон или если другое существо действием приведёт вас в чувство  |
`,
        source: {
          id: SOURCE_DMG,
          page: 48,
        },
      },
      {
        header: 'Сцены на астральном плане',
        text: ` По просторам Астрального Плана бродят планарные путешественники и беглецы с других планов. Самыми знаменитыми обитателями Астрального Плана являются гитиянки, беглая раса разбойников, «плавающих» на астральных кораблях, убивающая астральных путешественников и совершающая набеги на планы, граничащие с Астралом. Их город, Ту’нарат, парит в Астральном Плане, построенный на огромной скале, которая в действительности является телом мёртвого бога.

Небожители, исчадия и смертные исследователи часто посещают Астральный План в поисках цветных вихрей, ведущих в желаемое ими место. Персонажи, надолго остающиеся в Астрале, могут встретиться с ангелами, демонами, дьяволами, [ночными каргами](CREATURE:${CREATURE_NIGHT_HAG}), юголотами и другими планарными путешественниками.`,
        source: {
          id: SOURCE_DMG,
          page: 49,
        },
      },
      {
        header: 'Астральный План',
        text: `Астральный План это царство мыслей и грёз, в котором посетители в виде бестелесных душ переходят в планы богов и демонов. Это огромное серебристое море, одинаковое и сверху и снизу, с белыми и серыми лоскутами, кружащимися среди сгустков света, напоминающих далёкие звёзды. Непостоянные цветные вихри крутятся в воздухе подобно вращающимся монетам. Здесь можно найти участки твёрдой материи, но большая часть Астрального Плана представляет собой открытое пространство.`,
        source: {
          id: SOURCE_PHB,
          page: 302,
        },
      }
    ],
    source: {
      id: SOURCE_DMG,
      page: 47,
    },
  },
  {
    id: PLANE_ETHEREAL,
    type: PLANE_TYPE_TRANSITIVE,
    name: {
      nominative: `Эфирный план`,
      accusative: `Эфирный план`,
      local: `Эфирном плане`,
      localPreposition: `на`,
    },
    nameEn: `Ethereal Plane`,
    description: [
      `_Тамлин чувствовал на себе руку, чувствовал, как тело его начинает мерцать и становится туманным. Крики и вопли остались где-то позади. Окружающие его стены выглядели как всего лишь серые тени. Ривален и Бреннус стояли возле него._

_«Эфирный план, — сказал Ривален. — Здесь дыхание дракона нас не настигнет»._

— Пол С. Кемп, Буря теней

Эфирный План это затянутое туманом измерение. Его «берега», называемые Пограничным Эфиром, нахлёстываются на Материальный план и Внутренние Планы, так что у каждого места на этих планах есть соответствующее место на Эфирном Плане. Видимость в Пограничном Эфире ограничена 60 футами. В Глубинном Эфире туман ещё гуще, и там видимость ограничена 30 футами.

Персонажи могут с помощью заклинания [Эфирность](SPELL:${SPELL_ETHEREALNESS}) попасть в Пограничный Эфир. Заклинание [Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT}) позволяет переместиться как в Пограничный, так и в Глубинный Эфир, но если предполагаемое место назначения не является конкретным местом или кругом телепортации, точка прибытия может оказаться в любом месте этого плана. `,
      {
        header: 'Пограничный эфир',
        text: `У находящегося в Пограничном Эфире путника зрение простирается на тот план, с которым пересекается Эфир, но всё выглядит размытым и неразборчивым, цвета смешиваются друг с другом, а грани выглядят ворсистыми. Такой наблюдатель смотрит словно бы сквозь кривое и заиндевевшее стекло, и не видит ничего, что находится далее чем в 30 футах на другом плане. Сам же он, в свою очередь, обычно невидим для наблюдателя с соседнего плана, если не прибегать к помощи магии.

Обычно существа, находящиеся в Пограничном Эфире, не могут атаковать существ на смежном плане, и наоборот. Путник на Эфирном Плане невидим и абсолютно неслышим для тех, кто находится на смежном плане, и сплошные препятствия на смежном плане не мешают перемещению существа по Пограничному Эфиру. Существуют исключения для некоторых магических эффектов (включая всё, что состоит из магического силового поля) и живых существ. Это делает Эфирный План идеальным местом для разведки, шпионажа и тайного перемещения. Эфирный План также не подчиняется законам гравитации; существа могут перемещаться вверх и вниз так же легко, как в стороны. `,
        source: {
          id: SOURCE_DMG,
          page: 49,
        },
      },
      {
        header: 'Глубинный эфир',
        text: `Для того чтобы достичь Глубинного Эфира, нужно воспользоваться заклинаниями [Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT}) или [Врата](SPELL:${SPELL_GATE}), либо же воспользоваться магическим порталом. Посетителей Глубинного Эфира окутывает клубящийся туман. По всему плану разбросаны завесы парящего цвета, и проход сквозь такую завесу переносит путника в область Пограничного Эфира, смежного с одним из Внутренних Планов, с Материальным планом, Страной Фей или Царством Теней. Цвет завесы указывает на план, находящийся у Пограничного Эфира; смотрите прилагающуюся таблицу:

# Эфирные завесы

| к8 | План              | Цвет завесы                |
|----|-------------------|----------------------------|
| 1  | Материальный план | Ярко-бирюзовый             |
| 2  | Царство Теней     | Тускло-серый               |
| 3  | Страна Фей        | Опалесцирующий белый       |
| 4  | План Воздуха      | Бледно-синий               |
| 5  | План Земли        | Красновато-коричневый      |
| 6  | План Огня         | Оранжевый                  |
| 7  | План Воды         | Зелёный                    |
| 8  | Стихийный Хаос    | Бурлящая смесь всех цветов |

Путешествие по Глубинному Эфиру ради путешествия из одного плана в другой не похоже на физическое перемещение. Расстояние здесь не имеет значения, поэтому, несмотря на то, что путники чувствуют, что могут перемещаться простым усилием воли, скорость их нельзя измерить, да и прошедшее время трудно подсчитать. Переход между планами в Глубинном Эфире занимает 1к10 × 10 часов, вне зависимости от исходного и конечного планов. Однако в сражении существа считаются имеющими обычную скорость. `,
        source: {
          id: SOURCE_DMG,
          page: 49,
        },
      },
      {
        header: 'Эфирные циклоны',
        text: `Эфирный циклон это закрученная колонна, блуждающая по плану. Такие циклоны появляются внезапно, рушат и вырывают эфирные тела на своём пути, и тащат с собой захваченные объекты на огромные расстояния. Путники с пассивным значением Мудрости (Внимательность) 15 и выше получают 1к4 раундов предупреждения: глубокий гул в эфирной материи. Путники, не способные воспользоваться завесой или порталом для перемещения прочь из текущего места, подвергаются действию циклона. Бросьте к20 и определите по прилагающейся таблице эффект для всех окрестных существ.

# Эфирный циклон

| к20   | Эффект               | 
|-------|----------------------| 
| 1–12  | Продление путешествия| 
| 13–19 | Перенос в Пограничный Эфир, граничащий со случайным планом (совершите бросок по таблице «Эфирные завесы») | 
| 20    | Перенос на Астральный План | 

Чаще всего последствия эфирного циклона заключаются в том, что запланированное путешествие будет проходить дольше, чем предполагалось. Все персонажи в группе, путешествующие вместе, должны пройти испытание Харизмы СЛ 15. Если преуспеет как минимум половина группы, путешествие затягивается на 1к10 часов. В противном случае путешествие затягивается вдвое. Иногда группу сдувает в Пограничный Эфир, смежный с каким-нибудь планом. И очень редко циклон пробивает дыру в ткани плана и зашвыривает отряд на Астральный План.`,
        source: {
          id: SOURCE_DMG,
          page: 50,
        },
      },
      {
        header: 'Сцены на эфирном плане',
        text: `Большинство сцен в Пограничном Эфире происходит с существами с Материального плана, чьи чувства или умения простираются на Эфирный План ([исчезающие пауки](CREATURE:${CREATURE_PHASE_SPIDER}), например). [Привидения](CREATURE:${CREATURE_GHOST}) тоже свободно перемещаются между Эфирным и Материальным Планами. В Глубинном Эфире большинство сцен происходит с другими путниками, особенно часто с теми, что пришли с Внутренних Планов (элементали, гении и саламандры), а также с более редкими небожителями, исчадиями и феями.`,
        source: {
          id: SOURCE_DMG,
          page: 50,
        },
      },
      {
        header: 'Эфирный План',
        text: `Эфирный План это затянутое туманами измерение, которое иногда описывают как великий океан. Его берега, называемые Пограничным Эфиром, пересекаются с Материальным планом и Внутренними Планами, так что у всех мест этих планов есть общая граница с Эфирным Планом. Зрение некоторых существ может простираться на Пограничный Эфир, а заклинания [Видение невидимого](SPELL:${SPELL_SEE_INVISIBILITY}) и [Истинное зрение](SPELL:${SPELL_TRUE_SEEING}) предоставляют на время такую способность. Некоторые магические эффекты тоже простираются с Материального плана на Пограничный Эфир, в частности, эффекты, использующие силовое поле, такие как [Узилище](SPELL:${SPELL_FORCECAGE}) и [Силовая стена](SPELL:${SPELL_WALL_OF_FORCE}). Глубины этого плана, Глубинный Эфир — регион крутящихся разноцветных туманов.`,
        source: {
          id: SOURCE_PHB,
          page: 302,
        },
      }
    ],
    source: {
      id: SOURCE_DMG,
      page: 49,
    },
  },
  {
    id: PLANE_FEYWILD,
    type: PLANE_TYPE_MATERIAL,
    name: {
      nominative: `Страна Фей`,
      accusative: `Страну Фей`,
      local: `Стране Фей`,
      localPreposition: `в`,
    },
    nameEn: `Feywild`,
    description: [
      `_Прохождение через портал походило на принятие тёплой ванны, хотя прохлада в воздухе никуда не делась. Вначале всё затихло — рёв воды, перекатывающейся по камням, крики лягушек и стрёкот сверчков на берегу, вечерний гомон города за его спиной… Но уже через миг мир оказался переполненным жизнью. Лягушки и ночные птицы пели хором; в воздухе были разлиты осенние запахи; лунный свет окрасил цветы в синий, серебристый и фиолетовый; а рокот воды стал сложной симфонией._
      
— Джеймс Уайетт, Клятва мести

Страна Фей, также называемая Планом Фей, это земля приглушённого света и чудес, место музыки и смерти. Это царство вечных сумерек, с волшебными огоньками, носящимися на лёгком ветру и огромными бабочками, порхающими по рощам и полям. Небо постоянно окрашено в цвета заходящего солнца, которое никогда не заходит (да и не восходит, раз уж на то пошло); солнце здесь застыло на одном месте, над самым горизонтом. Вдалеке от населённых мест, которыми правят благие феи, составляющие Летний Двор, находятся земли с переплетёнными колючими кустами и топкими болотами — идеальные охотничьи угодья для неблагих фей.

Страна Фей существует параллельно Материальному плану, это альтернативное измерение, занимающее то же самое место в космологии. Ландшафт Страны Фей подобен миру природы, но здесь его элементы принимают потрясающие обличья. Там, где на Материальном плане стоит вулкан, в Стране Фей стоит гора, увенчанная кристаллами размером с небоскрёб, пылающими внутренним огнём. Широкая и грязная река Материального плана может иметь отражение в виде чистого извилистого родника невероятной красоты. Болото может отражаться в виде огромной зловещей топи. А перемещение в Страну Фей из развалин на Материальном плане может перенести путников к дверям замка архифеи.

Страна Фей населена лесными существами, такими как эльфы, [дриады](CREATURE:${CREATURE_DRYAD}), [сатиры](CREATURE:${CREATURE_SATYR}), [пикси](CREATURE:${CREATURE_PIXIE}) и [спрайты](CREATURE:${CREATURE_SPRITE}), а также [кентаврами](CREATURE:${CREATURE_CENTAUR}) и такими магическими созданиями как [мерцающие псы](CREATURE:${CREATURE_BLINK_DOG}), волшебные драконы, [тренты](CREATURE:${CREATURE_TREANT}) и [единороги](CREATURE:${CREATURE_UNICORN}). Тёмные области этого плана — дом для таких злобных существ как карги, заразы, [гоблины](CREATURE:${CREATURE_GOBLIN}), [огры](CREATURE:${CREATURE_OGRE}) и великаны. `,
      {
        header: 'Благие и неблагие феи',
        text: `В Стране Фей есть две королевы со своими придворными, и большая часть фей предана или той, или другой. Королева Титания и её Летний Двор возглавляет благих фей, а Королева Воздуха и Тьмы, правящая Сумеречным Двором, возглавляет неблагих фей. Благие и неблагие не являются аналогом добра и зла, хотя многие смертные ставят между этими понятиями знак равенства. Многие благие феи добрые, и многие неблагие злы, но их противостояние друг другу исходит от вражды их королев, а не абстрактных понятий смертных. Уродливые обитатели Страны Фей, такие как [фоморы](CREATURE:${CREATURE_FOMORIAN}) и карги, практически никогда не состоят ни при том, ни при другом дворе, а феи с независимым духом полностью отрицают дворы. Иногда дворы устраивают кровопролитные схватки, но они также соперничают в других, более-менее мирных состязаниях, а иногда даже заключают небольшие и тайные союзы. `,
        source: {
          id: SOURCE_DMG,
          page: 50,
        },
      },
      {
        header: 'Перекрёстки фей',
        text: `Перекрёстки фей это загадочные и красивые места на Материальном плане, у которых есть практически идеальные отражения в Стране Фей, что и создаёт портал в месте соприкосновения двух планов. Путник может пройти через перекрёсток фей, зайдя на поляну, войдя в пруд, наступив в круг грибов, или забравшись под ствол дерева. Ему покажется, что он оказался в Стране Фей, просто совершив очередной шаг. Для стороннего же наблюдателя такой путник просто исчезает в воздухе.

Как и другие порталы между планами, большинство перекрёстков фей то открывается, то закрывается. Перекрёсток может открываться только в полнолуние, на рассвете определённого дня, или для тех, кто несёт определённый предмет. Перекрёсток фей можно закрыть навсегда, если местность с одной из сторон изменится в значительной степени — например, если на поляне в Материальном плане построят замок.`,
        source: {
          id: SOURCE_DMG,
          page: 51,
        },
      },
      {
        header: 'Опциональные правила: магия Страны фей',
        text: `Поговаривают о детях, похищенных феями и утащенных в Страну Фей, которые через несколько лет возвращаются к родителям, не постарев ни на день, и ничего не помня ни о похитителях, ни о месте, из которого они вернулись. Искатели приключений, вернувшиеся с вылазки в Страну Фей, тоже могут обнаружить, что время на Плане Фей течёт иначе, и что их собственные воспоминания затуманены. Можете использовать эти опциональные правила, чтобы продемонстрировать необычную магию, пропитывающую этот план. 

# Потеря памяти

Существа, покидающие Страну Фей, должны пройти испытание Мудрости СЛ 10. Фейские существа автоматически преуспевают в этом испытании, равно как существа с особенностью Наследие фей, такие как эльфы. Провалившее испытание существо ничего не помнит о времени, проведённом в Стране Фей. При успехе воспоминания существа не теряются, но слегка затуманиваются. Все заклинания, способные снять проклятье, могут восстановить утраченные воспоминания такого существа.

# Искажение времени

В самой Стране Фей время течёт нормально, но персонажи могут провести там один день, а покинув её, узнать, что в остальной мультивселенной прошло значительно больше или меньше времени.

Каждый раз, когда существо или группа существ покидает Страну Фей, проведя там как минимум 1 день, Вы можете изменить прошедшее время так, как нужно для вашей кампании, или воспользоваться приведённой ниже таблицей. Заклинание [Исполнение желаний](SPELL:${SPELL_WISH}) может снять этот эффект с существ, количество которых не превышает десяти. Некоторые мощные феи могут исполнять такие желания, и готовы помочь, если их гости согласятся стать целью заклинания [Обет](SPELL:${SPELL_GEAS}) и выполнят задание после накладывания заклинания [Исполнение желаний](SPELL:${SPELL_WISH}). 

# Искажение времени в Стране Фей

| к20  | Результат               |  
|------|-------------------------|  
|1–2   | Дни становятся минутами |
|3–6   | Дни становятся часами   |
|7–13  | Без изменений           |
|14–17 | Дни становятся неделями |
|18–19 | Дни становятся месяцами |
|20    | Дни становятся годами   | 
`,
        source: {
          id: SOURCE_DMG,
          page: 51,
        },
      },
      {
        header: 'Страна Фей',
        text: `Страна Фей это земля рассеянного света и чудес, страна маленького народца с большими желаниями, место музыки и смерти. Это место вечных сумерек, где фонари медленно качаются на нежном ветру, а огромные светлячки летают по полям и рощам. Небо освещено пастельными цветами то ли заходящего, то ли восходящего солнца. В действительности же солнце не восходит и не заходит; оно остаётся на месте, чуть выше горизонта. Вдали от населённых мест, которыми правит Благой Двор, земли представляют собой губительные болота и переплетение ветвей с колючками — идеальная территория для охоты Неблагих. В Стране Фей обитают фейские существа, подобные тем, что призываются заклинанием [Призыв лесных обитателей](SPELL:${SPELL_CONJURE_WOODLAND_BEINGS}).`,
        source: {
          id: SOURCE_PHB,
          page: 301,
        },
      },
    ],
    source: {
      id: SOURCE_DMG,
      page: 50,
    },
  },
  {
    id: PLANE_SHADOWFELL,
    type: PLANE_TYPE_MATERIAL,
    name: {
      nominative: `Царство Теней`,
      accusative: `Царство Теней`,
      local: `Царстве Теней`,
      localPreposition: `в`,
    },
    nameEn: `Shadowfell`,
    description: [
      `_Ривен стоял в самой верхней комнате центральной башни своей цитадели — крепости из теней и тёмного камня, вытесанной из горной вершины… Беззвёздная бездна неба раскинулась над серо-чёрным ландшафтом, где обитали мрачные подобия живых существ. Тени, призраки, спектры, привидения и прочая нежить летала в воздухе над цитаделью и рыскала по окрестным холмам и равнинам. Их многочисленные светящиеся глаза походили на рои светлячков. Он чувствовал тьму во всём, что видел, чувствовал её как продолжение самого себя, и ему казалось, что он стал в полтора раза больше._

— Пол С. Кемп, Богорождённый

Царство Теней, также называемое Планом Тени, это измерение чёрного, серого и белого цвета, где все остальные цвета словно отсутствуют. Это место тьмы, ненавидящей свет, где в чёрной бездне неба нет ни солнца, ни звёзд.

Царство Теней нахлёстывается на Материальный план точно так же как Страна Фей. Если не отвлекаться на лишённый цветов ландшафт, это место похоже на Материальный план. Ориентиры Материального плана узнаваемы и в Царстве Теней, но они искажены и изуродованы. Там, где на Материальном плане стоит гора, в Царстве Теней торчит зазубренная скала, похожая на череп, с грудами валунов и, возможно, руинами когда-то величественного замка. Лес в Царстве Теней будет тёмным и кривым, ветки будут хватать путников за плащи, а корни будут стараться сбить с ног чужаков.

Этот бледный план населён теневыми драконами и нежитью, а также прочими существами, процветающими во мраке, включая [плащевиков](CREATURE:${CREATURE_CLOACKER}) и [чёрных мантий](CREATURE:${CREATURE_DARKMANTLE}).`,
      {
        header: 'Перекрёстки теней',
        text: `Подобно перекрёсткам фей, перекрёстки теней это места, в которых вуаль между Материальным планом и Царством Теней настолько тонка, что существа могут переходить с одного плана на другой. Сгусток тьмы в углу пыльного склепа может быть перекрёстком теней, так же как и могильная яма. Перекрёстки теней формируются в мрачных местах, в которых обитают духи или запах смерти, например, на полях сражений, кладбищах и в склепах. Они проявляются только во тьме, закрываясь от малейших проблесков света.`,
        source: {
          id: SOURCE_DMG,
          page: 52,
        },
      },
      {
        header: 'Домены ужаса',
        text: `В удалённых уголках Царства Теней можно найти жуткие демипланы, в которых правят проклятые ужасно злые создания. Самым известным таким местом является долина Баровия, над которой возвышаются шпили Замка Равенлофт, и которой правит граф Страд фон Зарович, первый вампир. Сущности Царства Теней, именуемые Тёмными Силами, создают эти домены в качестве тюрем для таких «Тёмных Владык», и из-за жестокости или беспечности иногда забрасывают туда ни в чём неповинных смертных.`,
        source: {
          id: SOURCE_DMG,
          page: 52,
        },
      },
      {
        header: 'Опциональное правило: отчаяние Царства Теней',
        text: `Царство Теней пронизано меланхолией. Долгие экскурсы в этот план могут окутать персонажей отчаянием, что и отражено в этом опциональном правиле.

Каждый раз, когда Вы посчитаете нужным (обычно, не чаще раза в день), Вы можете заставить персонажей, не происходящих из Царства Теней, пройти испытание Мудрости СЛ 10. При провале персонажа охватывает отчаяние. Совершите бросок к6, чтобы определить эффект с помощью приведённой таблицы. Вы можете придумать свои собственные эффекты отчаяния.

# Отчаяние Царства Теней

| к6  | Эффект       | 
|-----|--------------| 
| 1–3 | **Апатия.** Персонаж с помехой проходит испытания от смерти и проверки Ловкости для определения инициативы, а также получает следующую слабость: _«Мне кажется, что всё бессмысленно»_.    |
| 4–5 | **Ужас.** Персонаж проходит все испытания с помехой и получает следующую слабость: _«Я уверен, что это место убьёт меня»_. |
| 6   | **Безумие.** | Персонаж совершает с помехой проверки характеристик и испытания, использующие Интеллект, Мудрость или Харизму, и получает следующую слабость: _«Теперь я не знаю, что реально, а что — нет»_. | 

Если персонаж уже страдает от эффекта отчаяния, и проваливает это испытание, новый эффект отчаяния заменяет старый. Окончив длинный отдых, персонаж может попытаться побороть отчаяние, пройдя испытание Мудрости СЛ 15 (Сл выше, потому что избавиться от уже охватившего отчаяния гораздо сложнее). При успехе эффект отчаяния для этого персонажа оканчивается.

Заклинание [Умиротворение](SPELL:${SPELL_CALM_EMOTIONS}), а также все заклинания и эффекты, снимающие проклятье, отменяют отчаяние.`,
        source: {
          id: SOURCE_DMG,
          page: 53,
        },
      },
      {
        header: 'Вечная ночь',
        text: `У города Невервинтер в мире Забытых Королевств есть тёмное отражение в Царстве Теней: город Вечная Ночь. Вечная Ночь это город растрескавшихся каменных домов и строений из гниющих брёвен. Дороги там по большей части представляют собой утоптанную могильную землю, а в малочисленных мостовых отсутствует столько булыжников, что они выглядят безобразно. Небо здесь серое как кожа трупа, а ветер холодный и влажный, он сильно холодит кожу.

В число живых обитателей города входят безумные [некроманты](CREATURE:${CREATURE_NECROMANCER}), поставщики человеческой плоти, адепты злых божеств и прочие индивидуумы, достаточно безумные для этого. Однако живые в Вечной Ночи находятся в значительном меньшинстве, так как основную часть населения составляют ходячие мертвецы. [Зомби](CREATURE:${CREATURE_ZOMBIE}), [умертвия](CREATURE:${CREATURE_WIGHT}), [вампиры](CREATURE:${CREATURE_VAMPIRE}) и прочая нежить сделала этот город своим домом, и всё это под присмотром правящей касты разумных поедающих плоть [упырей](CREATURE:${CREATURE_GHOUL}).

Ходят слухи, что этот город является отражением одного города в каждом из существующих миров.`,
        source: {
          id: SOURCE_DMG,
          page: 53,
        },
      },
      {
        header: 'Царство Теней',
        text: `Царство Теней это тёмное измерение, мир чёрного и белого, где цвета просто вытягиваются из всего. Это место токсичной тьмы, ненавидящей свет, где небо — чёрная яма, на которой нет ни солнца, ни звёзд.`,
        source: {
          id: SOURCE_PHB,
          page: 301,
        },
      },
    ],
    source: {
      id: SOURCE_DMG,
      page: 52,
    },
  },
  {
    id: PLANE_ELEMENTAL_CHAOS,
    type: PLANE_TYPE_INNER,
    name: {
      nominative: `Стихийный Хаос`,
      accusative: `Стихийный Хаос`,
      local: `Стихийном Хаосе`,
      localPreposition: `в`,
    },
    nameEn: `Elemental Chaos`,
    description: [
      `На самых дальних границах Стихийных Планов чистые стихии сливаются в бесконечном клокочущем месиве сталкивающихся энергий и материи, называемом Стихийным Хаосом. Здесь тоже встречаются элементали, но обычно они не задерживаются здесь надолго, предпочитая комфорт своих родных планов. Судя по имеющейся информации, есть жуткие гибриды элементалей, для которых родным миром является Стихийный Хаос, но таких существ редко видят на других планах.`,
      innerPlanesDescription,
    ],
    source: {
      id: SOURCE_DMG,
      page: 53,
    },
  },
  {
    id: PLANE_OF_WATER,
    type: PLANE_TYPE_INNER,
    name: {
      nominative: `План Воды`,
      accusative: `План Воды`,
      local: `Плане Воды`,
      localPreposition: `на`,
    },
    nameEn: `Plane of Water`,
    description: [
      `Воде дано течь, не порывами, как ветер, и не перепрыгивая, как огонь, а спокойно и ровно. Это ритм потока, нектар жизни, слёзы скорби и бальзам симпатии и лечения. При наличии времени, вода пробьёт всё на своём пути.

По небу над Планом Воды по дуге ходит тёплое солнце, восходящее из-за горизонта и туда же заходящее. Однако несколько раз в день небо затягивается облаками и идёт ливень, часто сопровождающийся грозами, после чего снова наступает ясная погода. Ночью небо украшено россыпями звёзд и северными сияниями.

План Воды — это бесконечное море, называемое Морем Миров, усыпанное атоллами и островами, растущими из огромного кораллового рифа, раскинувшегося практически по всему дну. Бури, движущиеся над морем, иногда создают временные порталы на Материальный план и затягивают корабли на План Воды. Выжившие суда из бесчисленных миров плывут дальше, уже не надеясь вернуться домой.

Погода здесь бросается из крайности в крайность. Если не стоит штиль, значит, бушует ураган. Иногда сотрясения планов создают огромную волну, пробегающую по всему плану, захлёстывая острова и бросая корабли на рифы.

Жизнь процветает в верхних слоях Моря Миров, называемых Морем Света, так как ниже солнечный свет уже не пробивается. Водные гуманоиды строят замки и крепости на коралловых рифах. Наместниками этих земель являются [мариды](CREATURE:${CREATURE_MARID}), с радостью позволяющие младшим народам соперничать за территорию. Номинальный император маридов обитает в Цитадели десяти тысяч жемчужин, шикарном дворце из кораллов, усыпанном жемчужинами.

Нижние глубины, куда не пробивается свет, называются Затемнёнными Глубинами. Здесь обитают кошмарные существа, а жуткий холод и высокое давление означают быстрый конец для существ, привыкших к условиям Моря Света. Это царство принадлежит [кракенам](CREATURE:${CREATURE_KRAKEN}) и могучим [левиафанам](CREATURE:${CREATURE_LEVIATHAN}).

Любые клочки земли, возвышающиеся над поверхностью моря, становятся предметом раздора среди малочисленных дышащих воздухом обитателей этого плана. Связанные между собой плоты и корабли служат заменой настоящей твёрдой поверхности, если её не удаётся найти. Большая часть обитателей этого плана никогда не поднимается выше поверхности моря, а потому игнорирует этих жителей.

Одним из малочисленных настоящих островов этого плана является остров Ужаса. Он соединяется с Материальным планом частыми бурями, проносящимися по нему. Путники, знающие необычные потоки и течения этого плана могут свободно путешествовать между мирами, но эти бури разбивают об остров корабли, притащенные с Материального плана.

Область Плана Воды, находящаяся у Болота Забвения (находящегося на Плане Земли), называется Иловыми Равнинами. Здесь вода густа из-за почвы, потом переходит в грязную землю, а после становится огромным болотом между планами.

На противоположной стороне плана находится Море Льда, граничащее с Вечной Мерзлотой. По ледяной воде плавают айсберги и льдины, населённые холодостойкими существами из Вечной Мерзлоты. Дрейфующие айсберги уносят этих существ глубоко в План Воды, угрожая кораблям и островам в тёплых водах.

Вечная Мерзлота, также называемая Планом Льда, формирует границу между Водой и Воздухом, и является бесконечным ледником, окутанным вечными бурями. План Льда пронизан ледяными пещерами, служащими домами для белых драконов, йети, реморазов, и прочих существ, любящих холод. Эти обитатели ведут непрестанные сражения в попытках доказать свою силу и просто выжить.

Опасные чудовища и жуткий холод делают Вечную Мерзлоту опасным местом для путешествий. Большая часть путников остаётся в воздухе, предпочитая сражаться с ветром и снегом, лишь бы не спускаться на ледник.`,
      innerPlanesDescription,
    ],
    source: {
      id: SOURCE_DMG,
      page: 54,
    },
  },
  {
    id: PLANE_OF_AIR,
    type: PLANE_TYPE_INNER,
    name: {
      nominative: `План Воздуха`,
      accusative: `План Воздуха`,
      local: `План Воздуха`,
      localPreposition: `на`,
    },
    nameEn: `Plane of Air`,
    description: [
      `Ключевой сущностью воздуха является движение, оживление и воодушевление. Воздух — это дыхание жизни, ветер перемен, свежий ветер, уносящий туман невежества и приверженности устаревшим идеям.

План Воздуха — это открытое пространство, где постоянно дуют ветры с разной силой. То тут, то там парят глыбы земли — это остатки провалившихся попыток вторжения обитателей Плана Земли. Эти глыбы служат домом для существ стихийного воздуха, и многие глыбы покрыты густой растительностью. Прочие существа живут на облаках, пропитанных достаточным количеством магии, чтобы быть твёрдыми, и выдерживать вес городов и замков.

На этом плане дрейфующие облака могут заслонять видимость в любом направлении. Здесь часто происходят бури, почти всегда в сопровождении грозы, но иногда буря переходит в мощный торнадо или ураган. Воздух здесь умеренной температуры, за исключением областей, граничащих с Планом Воды (там весьма холодно) и Планом Огня (там царит зной). Дождь и снег выпадают только в частях, близких к Плану Воды.

Большая часть Плана Воздуха представляет собой сложное переплетение воздушных ручьёв, потоков и ветров, называемых Лабиринтом Ветров. Их скорость варьируется от лёгкого дуновения до воющих ураганов, способных разорвать существ на части. Даже самые опытные летающие существа должны осторожно перемещаться по этим потокам, летая по ветру, а не против него.

В некоторых местах Лабиринта Ветров находятся скрытые царства, попасть в которые можно только через определённую последовательность потоков ветра, и уже тем хорошо защищённые от вторжения чужаков. Одно из таких мест — прославленная Аакуа, сияющий домен серебряных шпилей и цветущих садов, расположенный на плодородной глыбе земли. Герцоги Ветра Аакуы ориентированы на закон и добро, и они бдительно следят за бесчинствами стихийного зла и вмешательством Стихийного Хаоса. Им прислуживают [ааракокры](CREATURE:${CREATURE_AARAKOCRA}) и малоизвестная раса, носящая название ваати.

Область Плана Воздуха, близкая с Великим Пожаром, называется Перешейками Сирокко. Горячие сухие ветра овевают земляные глыбы, превращая их в безжизненные скалы. [Горгульи](CREATURE:${CREATURE_GARGOYLE}) и их союзники с Плана Земли собираются здесь и совершают набеги на Аакуу.

Между Морем Огня (находящимся на Плане Огня) и Перешейками Сирокко царит огненная буря, которую называют Великим Пожаром или Планом Пепла. Завывающие ветра Плана Воздуха смешиваются с пепельными бурями и лавой с Плана Огня, создавая постоянный фронт бури — стену из пламени, дыма и золы. Толстый слой пепла блокирует обзор за пределами пары десятков футов, а мощные ветра ещё сильнее осложняют перемещение. Периодически встречаются сбившиеся в кучи груды пепла, и на этих парящих шарах укрываются разбойники и беглецы.

На другом краю этого плана, рядом с Вечной Мерзлотой (план льда, граничащий с Планом Воды), находится область ледяных ветров, называемая Пределом Мистралей. Эти ветра гонят снежные бури в Вечную Мерзлоту и обратно, в самое сердце Плана Воздуха. Земляные глыбы в этих местах покрыты снегом и льдом.`,
      innerPlanesDescription,
    ],
    source: {
      id: SOURCE_DMG,
      page: 55,
    },
  },
  {
    id: PLANE_OF_EARTH,
    type: PLANE_TYPE_INNER,
    name: {
      nominative: `План Земли`,
      accusative: `План Земли`,
      local: `Плане Земли`,
      localPreposition: `на`,
    },
    nameEn: `Plane of Earth`,
    description: [
      `Земля символизирует стабильность, стойкость, решимость и традиции. Этот план расположен в кольце Стихийных Планов напротив Плана Воздуха, что отражает его противоположность практически всему, что олицетворяет воздух.

План Земли это горная цепь, возвышающаяся выше всех гор Материального плана. Здесь нет своего солнца, и пики его высочайших гор не окружает воздух. Большинство гостей посещает этот план через пещеры, которыми пронизаны горы изнутри.

Самая большая пещера под горами, называемая Великой Мрачной Рытвиной, или Семикратным Лабиринтом, это столичный город [дао](CREATURE:${CREATURE_DAO}), Город Самоцветов. Дао очень гордятся своим богатством и отправляют во все стороны команды рабов на поиски новых месторождений руд и драгоценных камней. Благодаря их усилиям, все здания и важные предметы в городе изготовлены из драгоценных камней и металлов, включая тонкие инкрустированные самоцветами шпили, венчающие крыши большинства зданий. Город защищён мощным заклинанием, предупреждающим всех дао, если гость украдёт пусть даже самый маленький камушек. Воровство здесь карается смертью, причём наказание распространяется и на родственников негодяя.

Горы у Фонтанов Творения (находящихся на Плане Огня) называются Горнами. В их пещерах течёт лава, а воздух пропитан серой. Дао держат здесь кузницы и плавильные печи, в которых обрабатывают руду и куют изделия из драгоценных металлов.

Пограничный регион между планами Воды и Земли — жуткое болото, где на густой грязи и слизи растут кривые деревья и жалящие лианы. Часто встречающиеся в Болоте Забвения (также называемом Планом Грязи) застойные озёра и пруды зарастают водорослями и скрывают в себе чудовищные рои комаров. Местные малочисленные поселения состоят из деревянных построек. Некоторые построены на платформах между деревьями, а некоторые стоят на сваях. Среди болотной грязи нет твёрдых участков, поэтому дома, построенные на сваях, рано или поздно всё равно тонут.

Говорят, что предмет, выброшенный в Болото Забвения, никто не сможет найти как минимум сто лет. Время от времени отчаявшиеся гости выбрасывают здесь артефакты, на какое-то время устраняя их из мультивселенной. В поисках этих предметов искателям приключений приходится сражаться с чудовищными насекомыми и каргами.

Область этого плана, граничащая с Болотом Забвения, называется Грязевыми Холмами. Оползни постоянно уносят землю и камни в бездонное болото. Судя по всему, План Земли постоянно восстанавливает эту землю, выталкивая новый холм, когда старый превращается в ровное место.`,
      innerPlanesDescription,
    ],
    source: {
      id: SOURCE_DMG,
      page: 56,
    },
  },
  {
    id: PLANE_OF_FIRE,
    type: PLANE_TYPE_INNER,
    name: {
      nominative: `План Огня`,
      accusative: `План Огня`,
      local: `Плане Огня`,
      localPreposition: `на`,
    },
    nameEn: `Plane of Fire`,
    description: [
      `Огонь отражает энергию, страсть и перемены. Он может быть жестоким и разрушительным, как ифриты, но может также быть светом вдохновения, теплом сострадания и пламенем желания.

В зените золотистого неба Плана огня висит пылающее солнце, то растущее, то убывающее подобно луне, но с 24-часовым циклом. Цвет его разнится от раскалённобелого в полдень до тёмно-красного ночью, поэтому в самый тёмный час этот план погружён в красные сумерки. В полдень же его свет практически слепит. Большая часть дел в Латунном городе происходит в более тёмные часы.

Погода на этом плане характеризуется яростными ветрами, несущими много пепла. Несмотря на то, что воздухом здесь можно дышать, существа, пришедшие с других планов, должны прикрывать рты и глаза от колющейся золы. [Ифриты](CREATURE:${CREATURE_EFREETI}) с помощью магии отгоняют пепельные бури от Латунного города, но во всех других местах этого плана ветер как минимум порывистый, иногда достигающий силы урагана.

Жара Плана Огня сопоставима с жаром пустыни Материального плана, и потому представляет ту же самую угрозу путникам. Чем дальше углубляешься в этот план, тем меньше встречается воды. За определённой гранью вода вообще не встречается, поэтому путники должны нести с собой запасы или создавать воду магией.

Большая часть Плана Огня это обширные Пепельные Пустоши, чёрная зола и угли, пересечённые реками лавы. Скитающиеся банды [саламандр](CREATURE:${CREATURE_SALAMANDER}) сражаются здесь между собой, совершают набеги на заставы [эйзеров](CREATURE:${CREATURE_AZER}) и избегают [ифритов](CREATURE:${CREATURE_EFREETI}). Пустыня усеяна древними руинами — остатками былых цивилизаций.

Длинная цепь вулканических гор, называющихся Фонтанами Творения, служит домом [эйзерам](CREATURE:${CREATURE_AZER}). Эти горные пики растут из Плана Земли, и, изгибаясь над Пепельными Пустошами, устремляются в сторону Плана Огня. Горы на границе этого плана также называются Планом Магмы. Здесь живут [огненные великаны](CREATURE:${CREATURE_FIRE_GIANT}) и [красные драконы](CREATURE:${CREATURE_DRAGON_RED_ADULT}), а также существа с окрестных планов.

Потоки лавы из вулканов текут в сторону Плана Воздуха и формируют огромное лавовое озеро, называющееся Морем Огня, по которому [ифриты](CREATURE:${CREATURE_EFREETI}) и [эйзеры](CREATURE:${CREATURE_AZER}) плавают на огромных латунных кораблях. Из этого моря торчат острова из обсидиана и базальта, усыпанные древними руинами и логовами мощных [красных драконов](CREATURE:${CREATURE_DRAGON_RED_ADULT}). На берегу Моря Огня стоит Латунный город.`,
      {
        header: 'Латунный город',
        text: `Возможно, самым известным местом на Внутренних Планах является Латунный город, стоящий на берегу Моря Огня. Это прославленный город [ифритов](CREATURE:${CREATURE_EFREETI}), и витиеватые шпили и металлические стены отражают их величие и жестокий нрав. Изза природы Плана Огня всё в этом городе выглядит покрытым танцующим пламенем, отражая кипучую энергию этого места.

Искатели приключений часто приходят сюда в поисках легендарной магии. Если некий магический предмет вообще можно где-то купить, то его наверняка можно найти и в Латунном городе, хотя ценой может стать нечто большее, чем просто золото. Ифритам нравится торговаться, особенно если получается одержать верх в переговорах. Некоторые магические болезни или яды можно вылечить только лекарствами с базара этого города.

Сердцем города является возвышающийся Угольный Дворец, где правит деспотичный султан ифритов, окружённый знатными ифритами и толпами рабов, охранников и льстецов. `,
        source: {
          id: SOURCE_DMG,
          page: 58,
        },
      },
    ],
    source: {
      id: SOURCE_DMG,
      page: 57,
    },
  },
  {
    id: PLANE_MOUNT_CELESTIA,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Гора Селестия`,
      accusative: `Гору Селестию`,
      local: `Горе Селестии`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `семь небес Горы Селестии`,
      accusative: `семь небес Горы Селестии`,
      local: `семи небесах Горы Селестии`,
      localPreposition: `на`,
    },
    nameEn: `Mount Celestia`,
    alignmentId: ALIGNMENT_LG,
    description: [
      `Единственная священная гора Селестия выходит из сияющего Серебряного Моря и уходит в высоты, которые едва видно и трудно описать, а семь плато отмечают её семь небесных слоёв. Этот план — модель правосудия и порядка, небесной милости и бесконечного сострадания, где ангелы и защитники добра противостоят нападкам зла. Это одно из малочисленных мест на планах, где путники могут по-настоящему расслабиться. Местные обитатели стремятся быть праведными. Бесчисленные существа стремятся достичь вершины горы, но способны на это только самые чистые души. Один лишь взор на этот горный пик наполняет душу трепетом.`,
      {
        header: 'Опциональное правило: Благословенное попечение',
        text: `В противоположность диссонансу, испытываемому здесь злыми существами, добрые существа благословляются самим планом. Существа с добрым мировоззрением получают преимущества от заклинания благословение, пока они находятся на этом плане. Кроме того, завершение длинного отдыха на этом плане дарует добрым существам преимущества от заклинания [Малое восстановление](SPELL:${SPELL_LESSER_RESTORATION}).`,
        source: {
          id: SOURCE_DMG,
          page: 60,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 60,
    },
  },
  {
    id: PLANE_BYTOPIA,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Битопия`,
      accusative: `Битопию`,
      local: `Битопии`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `двойной рай Битопии`,
      accusative: `двойной рай Битопии`,
      local: `двойном раю Битопии`,
      localPreposition: `в`,
    },
    nameEn: `Bytopia`,
    alignmentId: [
      ALIGNMENT_NG,
      ALIGNMENT_LG,
    ],
    description: [
      `Два слоя Двойного рая Битопии похожи между собой, но всё же противопоставлены друг другу: один это возделываемый пасторальный ландшафт, а другой — необузданная глушь, хотя оба отражают доброту, а при необходимости и принятие порядка. Битопия — рай продуктивного труда, удовольствие от проделанной работы. Доброта, текущая сквозь этот план, создаёт в живущих здесь существах благорасположение и счастье. `,
      {
        header: 'Опциональное правило: Вездесущее добро',
        text: `В конце каждого длинного отдыха, совершённого на этом плане, посетитель, чьё мировоззрение не законно-доброе и не нейтрально-доброе, должен пройти испытание Мудрости СЛ 10. При провале мировоззрение существа меняется на принципиально-доброе или нейтрально-доброе (в зависимости от того, к чему ближе его предыдущее мировоззрение). Изменение станет постоянным, если существо не покидает план в течение 1к4 дней. В противном случае мировоззрение существа возвращается через один день, проведённый вне Битопии. 

Накладывание заклинания [Рассеивание добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}) на такое существо тоже восстанавливает исходное мировоззрение.`,
        source: {
          id: SOURCE_DMG,
          page: 60,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 60,
    },
  },
  {
    id: PLANE_ELYSIUM,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Элизиум`,
      accusative: `Элизиум`,
      local: `Элизиуме`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `благословенные поля Элизиума`,
      accusative: `благословенные поля Элизиума`,
      local: `благословенных полях Элизиума`,
      localPreposition: `в`,
    },
    nameEn: `Elysium`,
    alignmentId: ALIGNMENT_NG,
    description: [
      `Элизиум это дом существ несравненной доброты и сострадания, и гостеприимное убежище для путников, ищущих безопасную гавань. Идиллические ландшафты этого плана пронизаны жизнью и красотой. В кости и душу тех, кто приходит на этот план, проникает спокойствие. Это приют заслуженного отдыха, место, где на многих щеках блестят слёзы счастья. `,
      {
        header: 'Опциональное правило: Подавляющая радость',
        text: `Посетители этого плана рискуют попасть под сокрушающее ощущение удовольствия и счастья. В конце каждого длинного отдыха, совершённого на этом плане, посетитель должен пройти испытание Мудрости СЛ 10. При провале существо не захочет покидать этот план, не совершив здесь ещё один длинный отдых. После трёх проваленных испытаний существо уже никогда добровольно не покинет этот план, а если его перенесут насильно, сделает всё, что в его силах, чтобы вернуться на этот план. Заклинание [Рассеивание добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}) устраняет этот эффект на этом существе.`,
        source: {
          id: SOURCE_DMG,
          page: 61,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 61,
    },
  },
  {
    id: PLANE_BEASTLANDS,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Звериные Земли`,
      accusative: `Звериные Земли`,
      local: `Звериных Землях`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `дебри Звериных Земель`,
      accusative: `дебри Звериных Земель`,
      local: `дебрях Звериных Земель`,
      localPreposition: `в`,
    },
    nameEn: `The Beastlands`,
    alignmentId: [
      ALIGNMENT_NG,
      ALIGNMENT_CG,
    ],
    description: [
      `Звериные Земли это план природной свободы, лесов, разнящихся от поросших мхом мангровых рощ до заснеженной тайги, густых джунглей, где ветви переплетены так густо, что не пропускают свет, обширных равнин, где травы и дикие цветы колышутся на ветру, сияющем жизненной силой. Этот план воплощает дикость и красоту природы, но он также будит животные инстинкты, спящие в живых существах.`,
      {
        header: 'Опциональное правило: Рай охотника',
        text: `Посетители Звериных Земель обнаруживают, что их способности охотника и разведчика улучшаются, и, находясь здесь, персонажи совершают с преимуществом проверки Мудрости (Внимательность), Мудрости (Выживание) и Мудрости (Уход за животными).`,
        source: {
          id: SOURCE_DMG,
          page: 61,
        },
      },
      {
        header: 'Опциональное правило: Превращение в зверя',
        text: `Каждый раз, когда посетитель убивает зверя, родного для этого плана, убийца должен пройти испытание Харизмы СЛ 10, иначе он превратится (как будто под действием заклинания превращение) в такой же тип зверя, что он убил. В этом облике существо сохраняет своё сознание и способность говорить. В конце каждого длинного отдыха превращённое существо может повторять испытание. При успехе существо возвращает себе свой облик. После трёх проваленных испытаний превращение может быть отменено только заклинанием [Снятие проклятья](SPELL:${SPELL_REMOVE_CURSE}) или аналогичной магией. `,
        source: {
          id: SOURCE_DMG,
          page: 61,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 61,
    },
  },
  {
    id: PLANE_ARBOREA,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Арборея`,
      accusative: `Арборею`,
      local: `Арборее`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `олимпийские просторы Арбореи`,
      accusative: `олимпийские просторы Арбореи`,
      local: `олимпийских просторах Арбореи`,
      localPreposition: `на`,
    },
    nameEn: `Arborea`,
    alignmentId: ALIGNMENT_CG,
    description: [
      `Арборея символизирует не просто жизнь, а место жестокого отношения и сильной привязанности, капризов и яркой, но быстро сгорающей страсти. Местные добрые по натуре обитатели преданны сражению со злом, но их безрассудные эмоции иногда вырываются наружу с разрушительными последствиями. Ярость здесь широко распространена и считается заменой радости. Горы и леса здесь чрезвычайно крупные и красивые, и все поляны и ручьи заселены духами природы, которые не терпят нарушений обязательств. Путники должны вести себя здесь осторожно.

Арборея — дом множества эльфов и эльфийских божеств. Эльфы, родившиеся на этом плане, обладают типом «небожитель», и в сердце они очень дикие, готовые в любой миг ринуться в сражение. Во всём остальном они выглядят и ведут себя как обычные эльфы.`,
      {
        header: 'Опциональное правило: Острая тоска',
        text: `Отслеживайте, сколько дней гости проведут на Арборее. Когда посетитель уходит, он должен пройти испытание Харизмы СЛ 5, плюс 1 за каждый проведённый на этом плане день. При провале существо охватывает тоска по Арборее. Пока эффект действует, существо совершает с помехой проверки характеристик. В конце каждого длинного отдыха существо может повторять это испытание, оканчивая эффект в случае успеха. Заклинание [Рассеивание добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}) устраняет этот эффект на этом существе. `,
        source: {
          id: SOURCE_DMG,
          page: 62,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 61,
    },
  },
  {
    id: PLANE_YSGARD,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Асгард`,
      accusative: `Асгард`,
      local: `Асгарде`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `героические владения Асгарда`,
      accusative: `героические владения Асгарда`,
      local: `героических владениях Асгарда`,
      localPreposition: `в`,
    },
    nameEn: `Ysgard`,
    alignmentId: [
      ALIGNMENT_CG,
      ALIGNMENT_CN,
    ],
    description: [
      `Асгард это грубое царство парящих гор, глубоких фьордов и овеваемых ветрами полей сражений, с долгим и жарким летом, и беспощадными лютыми зимами. Его континенты парят над океаном из вулканического камня, под которым находятся такие огромные ледяные пещеры, что в них помещаются целые королевства великанов, людей, дварфов, гномов и прочих созданий. Герои приходят на Асгард, чтобы испытать своё мужество в схватке не только с самим планом, но и с великанами, драконами и прочими жуткими существами, бродящими по обширным равнинам Асгарда. `,
      {
        header: 'Опциональное правило: Бессмертный гнев',
        text: `Асгард — дом убитых героев, ведущих вечное сражение на полях славы. Все существа кроме конструктов и нежити, убитые на Асгарде атакой или заклинанием, становятся снова живыми на рассвете следующего дня. Все хиты существа восстанавливаются, а все состояния и негативные эффекты, бывшие у него перед смертью, исчезают.`,
        source: {
          id: SOURCE_DMG,
          page: 62,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 62,
    },
  },
  {
    id: PLANE_LIMBO,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Лимбо`,
      accusative: `Лимбо`,
      local: `Лимбо`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `вечно бурлящий хаос Лимбо`,
      accusative: `вечно бурлящий хаос Лимбо`,
      local: `вечно бурлящем хаосе Лимбо`,
      localPreposition: `в`,
    },
    nameEn: `Limbo`,
    alignmentId: ALIGNMENT_CN,
    description: [
      `Лимбо это план чистого хаоса, кипящий бульон из непостоянной материи и энергии. Камень здесь плавится в воду, которая затвердевает металлом, потом превращается в алмаз, сгорающий дымом, который становится снегом, и так далее, в бесконечном непредсказуемом процессе изменений. Среди этого беспорядка дрейфуют участки с более привычным ландшафтом — куски лесов, лугов, разрушенные замки, и даже бурлящие ручьи. Весь этот план — кошмарный беспорядок.

На Лимбо нет гравитации, поэтому существа, пришедшие сюда, парят на месте. Существо может перемещаться со скоростью, не превышающей своей скорости ходьбы, в любом направлении, просто подумав о желаемом направлении путешествия.

Лимбо подстраивается под волю существ, населяющих его. Очень дисциплинированное и мощное сознание может создавать здесь целые острова, и поддерживать такие места в течение нескольких лет. Однако, такие бесхитростные существа как рыбы, например, проживут не больше минуты, после чего вода, окружающая их, замёрзнет, испарится или превратится в стекло. Слаады живут здесь и парят среди хаоса, не создавая ничего, в то время как монахи гитзераев силой мысли создают целые монастыри. `,
      {
        header: 'Опциональное правило: Сила мысли',
        text: `Существо на Лимбо может действием совершить проверку Интеллекта, чтобы силой мысли переместить находящийся на этом плане предмет, видимый в пределах 30 футов. СЛ зависит от размера предмета: СЛ 5 для Крохотного, СЛ 10 для Маленького, СЛ 15 для Среднего, СЛ 20 для Большого и СЛ 25 для Огромного или ещё большего. При успешной проверке существо перемещает предмет на 5 футов плюс 1 фут за каждую единицу результата сверх положенной Сл.

Существо может также действием совершить проверку Интеллекта для изменения немагического предмета, который никто не несёт и не носит. Применяются те же правила по дистанции, а СЛ зависит от размера предмета: СЛ 10 для Крохотного, СЛ 15 для Маленького, СЛ 20 для Среднего и СЛ 25 для Большого или ещё большего. При успешной проверке существо превращает предмет в другой неживой предмет такого же размера, например, превращает валун в огненный шар.

Существо может действием совершить проверку Интеллекта, чтобы стабилизировать сферическую область с центром на себе. СЛ зависит от радиуса сферы. Базовая СЛ 5 для сферы с радиусом 10 футов; каждые дополнительные 10 футов радиуса увеличивают СЛ на 5. При успешной проверке существо не даёт этой области быть изменённой планом в течение 24 часов, или пока оно не использует это умение ещё раз.`,
        source: {
          id: SOURCE_DMG,
          page: 62,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 62,
    },
  },
  {
    id: PLANE_PANDEMONIUM,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Пандемоний`,
      accusative: `Пандемоний`,
      local: `Пандемонии`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `завывающие глубины Пандемония`,
      accusative: `завывающие глубины Пандемония`,
      local: `завывающих глубинах Пандемония`,
      localPreposition: `в`,
    },
    nameEn: `Pandemonium`,
    alignmentId: [
      ALIGNMENT_CN,
      ALIGNMENT_CE,
    ],
    description: [
      `Пандемоний это план безумия, огромная скала, пронизанная туннелями, в которых воет ветер. Здесь холодно, шумно и темно, так как нет естественного света. Ветер быстро тушит немагическое открытое пламя, такое как у факелов и костров. Из-за него же говорить приходится криком, и то слышно лишь в пределах 10 футов. Существа совершают с помехой все проверки характеристик, основанные на слухе.

Большая часть обитателей этого плана — существа, изгнанные сюда, и уже не надеющиеся сбежать, и большая их часть сведена с ума постоянным шумом, либо же живёт в таких местах, где шум ветра похож на далёкие мучительные крики.`,
      {
        header: 'Опциональное правило: Ветер безумия',
        text: `Посетитель должен проходить испытание Мудрости СЛ 10 после каждого часа, проведённого на воющем ветру. При провале существо получает один уровень истощения. Существо, получившее шестой уровень истощения на этом плане не умирает, а получает случайное бессрочное безумие, из главы 8. Окончание длинного отдыха не уменьшает уровень истощения существа, если оно не защитилось каким-либо образом от сводящего с ума ветра.`,
        source: {
          id: SOURCE_DMG,
          page: 63,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 63,
    },
  },
  {
    id: PLANE_ABYSS,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Бездна`,
      accusative: `Бездну`,
      local: `Бездне`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `бесконечные слои Бездны`,
      accusative: `бесконечные слои Бездны`,
      local: `бесконечных слоях Бездны`,
      localPreposition: `на`,
    },
    nameEn: `Abyss`,
    alignmentId: ALIGNMENT_CE,
    description: [
      `Бездна является воплощением всего порочного, жуткого и хаотичного. Её практически бесконечные слои уводят по спирали, принимая всё более и более мерзкие формы. На каждом слою Бездны царит своя уникальная среда. Нет двух похожих слоёв, и все они суровые и негостеприимные. Каждый слой также отражает энтропическую природу Бездны. Фактически, всё, что можно увидеть или коснуться на этом плане, находится в разлагающемся, ветхом или изъеденном состоянии.`,
      {
        header: 'Опциональное правило: Развращение Бездной',
        text: `Посетитель с незлым мировоззрением, оканчивающий в Бездне длинный отдых, должен пройти испытание Харизмы СЛ 10. При провале существо становится развращённым. Определите эффект развращения по прилагающейся таблице. Вы можете заменить некоторые эффекты придуманными собой. Окончив длинный отдых, развращённое существо может пройти испытание Харизмы СЛ 15. При успешном спасении эффект развращения оканчивается. Заклинание [Рассеивание добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}) и любая магия, снимающая проклятья, также оканчивают этот эффект. 

# Развращение Бездной

| к10 | Результат |
|-|-|
| 1–4 | **Предательство.** Персонаж получает следующую слабость: _«Я достигну своей цели только после того как удостоверюсь, что мои спутники не достигнут своего желаемого результата»_. |
| 5–7 | **Кровожадность.** Персонаж получает следующую слабость: _«Мне нравится убивать ради забавы, и если я начну, уже не могу остановиться»_. |
| 8–9 | **Безумные амбиции.** Персонаж получает следующую слабость: _«Мне суждено править Бездной, и мои спутники — лишь инструменты в достижении этой цели»_. |
| 10 | **Одержимость демоном.** Персонаж одержим демонической сущностью, пока не будет освобождён заклинанием [Рассеивание добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}) или аналогичной магией. Каждый раз, когда одержимый персонаж выбрасывает «1» при броске атаки, проверке характеристики или испытании, демон захватывает над ним контроль и определяет его поведение. В конце каждого своего хода одержимое существо может проходить испытание Харизмы СЛ 15. При успехе персонаж восстанавливает над собой контроль, пока вновь не выбросит «1». |

Если развращённое существо не покинет этот план в течение 1к4 + 2 дней, его мировоззрение становится хаотично-злым. Накладывание на него заклинания [Рассеивание добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}) восстанавливает исходное мировоззрение.`,
        source: {
          id: SOURCE_DMG,
          page: 63,
        },
      },
      {
        header: 'Важные слои',
        text: `Слои Бездны определяются демоническими повелителями, правящими ими, что и демонстрируют приведённые ниже примеры. Подробности о демонических повелителях можно найти в Бестиарии.
        
# Ненасытная Утроба

Слой [Демогоргона](CREATURE:${CREATURE_DEMOGORGON}) в Бездне представляет собой обширные пустоши разгульной дикости и безумия, где даже мощные демоны сходят с ума от страха. Отражая двойственную природу [Демогоргона](CREATURE:${CREATURE_DEMOGORGON}), Ненасытная Утроба состоит из массивного первозданного континента, покрытого густыми джунглями, окружённого, по-видимому, бесконечным океаном. Князь Демонов правит этим слоем, сидя в двойной змеевидной башне, выступающей над штормовым морем. Каждая из башен увенчана огромным черепом с клыками. В этой крепости Бездны мало кто может долго находиться, не сходя с ума.
        
# Танатос
       
Будь на то воля [Оркуса](CREATURE:${CREATURE_ORCUS}), все планы выглядели бы как его жуткое царство Танатос, а все существа были бы нежитью под его контролем. Укрытый сверху чёрным небом, Танатос демонстрирует мрачные горы, болота, разрушенные города и леса с чёрными искривлёнными деревьями. Ландшафт усеян склепами, мавзолеями, надгробными плитами и саркофагами. Повсюду ходит нежить, вырываясь из склепов и могил, стремясь разорвать на части глупых забредших сюда существ. [Оркус](CREATURE:${CREATURE_ORCUS}) правит Танатосом из большого дворца, известного как Утрата, построенного из обсидиана и костей. Этот дворец, находящийся на завывающей пустоши, называемой Концом Забвения, окружён склепами и могилами, образующими узкие аллеи многоярусного некрополя.
        
# Демоническая Паутина

Слой [Ллос](GOD:${GOD_LOLTH}) представляет собой обширную сеть из толстой магической паутины, создающей проходы и похожие на коконы комнаты. В этой паутине висят здания, строения, корабли и прочие предметы, будто бы пойманные огромным пауком. Эта паутина создаёт в разных местах этого плана случайные порталы, затягивающие предметы с демипланов и миров Материального плана, нужные для планов Королевы Пауков. Слуги [Ллос](GOD:${GOD_LOLTH}) также строят в паутине настоящие подземелья, охотясь на врагов [Ллос](GOD:${GOD_LOLTH}) в пересекающихся коридорах из укреплённого паутиной камня.

Глубоко под этими подземельями находятся бездонные Ямы Демонической Паутины. Там и живёт Ллос, окружённая помощницами — демонами [йоклол](CREATURE:${CREATURE_YOCHLOL}), созданными ей для служения, и которые в царстве Королевы Пауков превосходят по статусу всех других демонов.

# Бесконечный Лабиринт

Слой [Бафомета](CREATURE:${CREATURE_BAPHOMET}) на Бездне представляет собой не имеющее выхода подземелье, в центре которого находится огромный дворец Рогатого Короля в форме зиккурата. Представляющий собой месиво из кривых коридоров и многочисленных комнат, дворец окружён рвом шириной в милю, в котором скрывается безумная последовательность из лестниц и туннелей, ведущих в глубины крепости.

# Тройное Царство

Тёмный Князь [Граз’зт](CREATURE:${CREATURE_GRAZZT}) правит Аззагратом, который состоит из трёх слоёв Бездны. Его средоточием власти является потрясающий Серебряный Дворец в городе Зелатар, чьи шумные рынки и различные места для получения удовольствия привлекают со всей мультивселенной гостей, ищущих тайные магические знания и извращённые удовольствия. По приказу [Граз’зта](CREATURE:${CREATURE_GRAZZT}) демоны Аззаграта поддерживают видимость цивилизованности и вежливости. Однако так называемое Тройное Царство опасно настолько же, насколько и другие места в Бездне, и планарные путешественники могут бесследно исчезнуть в похожих на лабиринты городах и лесах, где вместо ветвей растут змеи.

# Лощины Смерти

[Йеногу](CREATURE:${CREATURE_YEENOGHU}) правит слоем из низких долин, известных как Лощины Смерти. Здесь существа должны охотиться ради выживания. Даже растения, которым нужно омывать корни кровью, могут схватить зазевавшегося прохожего. Слуги [Йеногу](CREATURE:${CREATURE_YEENOGHU}), помогающие усмирить голод своего повелителя, рыщущего по своему миру в поисках добычи, хватают существ на Материальном плане и выпускают на свободу здесь.`,
        source: {
          id: SOURCE_DMG,
          page: 63,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 63,
    },
  },
  {
    id: PLANE_CARCERI,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Карцери`,
      accusative: `Карцери`,
      local: `Карцери`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `забытые преисподние Карцери`,
      accusative: `забытые преисподние Карцери`,
      local: `забытых преисподних Карцери`,
      localPreposition: `в`,
    },
    nameEn: `Carceri`,
    alignmentId: [
      ALIGNMENT_NE,
      ALIGNMENT_CE,
    ],
    description: [
      `Являясь прототипом всех существующих тюрем, Карцери является планом одиночества и отчаяния. В его шести слоях находятся обширные болота, густые джунгли, продуваемые ветрами пустыни, зазубренные горы, безжизненные океаны и чёрный лёд. Все виды убогого жилья для предателей и подлецов, заточённых на этом плане. `,
      {
        header: 'Опциональное правило: Тюремный план',
        text: `Покинуть Карцери не так-то легко. Магические попытки любыми заклинаниями кроме [Исполнения желаний](SPELL:${SPELL_WISH}) автоматически проваливаются. Порталы и врата, открываемые в другие планы, становятся односторонними. Есть и тайные выходы отсюда, но они скрыты и хорошо охраняются ловушками и опасными чудовищами. `,
        source: {
          id: SOURCE_DMG,
          page: 64,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 64,
    },
  },
  {
    id: PLANE_HADES,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Гадес`,
      accusative: `Гадес`,
      local: `Гадесе`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `серые пустоши Гадеса`,
      accusative: `серые пустоши Гадеса`,
      local: `серых пустошах Гадеса`,
      localPreposition: `в`,
    },
    nameEn: `Hades`,
    alignmentId: ALIGNMENT_NE,
    description: [
      `Слои Гадеса называются Тремя Мраками — это места без радости, надежды и энтузиазма. Являясь серой землёй с пепельным небом, Гадес является пристанищем многочисленных душ, не доставшихся ни богам Верхних Планов, ни исчадьям, правящим Нижними Планами. Эти души становятся личинками, живущими в этом месте, лишённом солнца, луны, звёзд и времён года. Этот мрак, вытягивающий цвета и эмоции, наваливается на посетителей тяжким грузом. Правило «отчаяние Царства Теней», приведённое ранее в этой главе, может использоваться также для демонстрации влияния на посетителей и этого места. `,
      {
        header: 'Опциональное правило: Мерзкое превращение',
        text: `В конце каждого длинного отдыха, совершённого на этом плане, посетитель должен пройти испытание Мудрости СЛ 10. При провале существо получает одну степень истощения, от которой нельзя избавиться, пока существо находится на Гадесе. Если существо получает шестую степень истощения, оно не умирает, а превращается навсегда в [ларву](CREATURE:${CREATURE_LARVA}), после чего все степени истощения проходят.

[Ларва](CREATURE:${CREATURE_LARVA}) это жалкое исчадие, сохраняющее подобие своего прежнего лица, но с телом жирного червя. У [ларвы](CREATURE:${CREATURE_LARVA}) остаются лишь призрачные воспоминания о предыдущей жизни, а все игровые параметры меняются на параметры [ларвы](CREATURE:${CREATURE_LARVA}).

На Гадесе ползает множество [ларв](CREATURE:${CREATURE_LARVA}). [Ночные карги](CREATURE:${CREATURE_NIGHT_HAG}), [личи](CREATURE:${CREATURE_LICH}) и [ракшасы](CREATURE:${CREATURE_RAKSHASA}) собирают их, чтобы в дальнейшем использовать в мерзких ритуалах. Другим исчадиям нравится их просто есть.`,
        source: {
          id: SOURCE_DMG,
          page: 64,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 64,
    },
  },
  {
    id: PLANE_GEHENNA,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Геенна`,
      accusative: `Геенну`,
      local: `Геенне`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `мрачная вечность Геенны`,
      accusative: `мрачную вечность Геенны`,
      local: `мрачной вечности Геенны`,
      localPreposition: `в`,
    },
    nameEn: `Gehenna`,
    alignmentId: [
      ALIGNMENT_NE,
      ALIGNMENT_LE,
    ],
    description: [
      `Геенна это план подозрений и жадности. Это родина юголотов, обитающих здесь в огромных количествах. На всех четырёх её слоях доминируют вулканические горы, а в воздухе парят меньшие вулканические подобия айсбергов, периодически врезающиеся в настоящие горы.

Каменистая поверхность этого плана делает перемещения трудными и опасными. Практически везде поверхность находится под углом 45 градусов, но есть и более крутые обрывы и глубокие каньоны. Опасности добавляют и вулканические расселины, источающие ядовитые испарения и опаляющий огонь.

На Геенне нет места жалости и состраданию. Исчадия, живущие здесь, самые жадные и эгоистичные во всей мультивселенной. `,
      {
        header: 'Опциональное правило: Жестокая помеха',
        text: `Жестокая природа этого плана мешает посетителям помогать друг другу. Каждый раз, когда посетитель сотворяет заклинание с благоприятным эффектом, включая заклинания, восстанавливающие хиты и отменяющие состояния, заклинатель вначале должен пройти испытание Харизмы СЛ 10. При провале заклинание проваливается, ячейка заклинания тратится, и действие тратится впустую.`,
        source: {
          id: SOURCE_DMG,
          page: 65,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 64,
    },
  },
  {
    id: PLANE_BAATOR,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Баатор`,
      accusative: `Баатор`,
      local: `Бааторе`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `девять преисподних Баатора`,
      accusative: `девять преисподних Баатора`,
      local: `девяти преисподних Баатора`,
      localPreposition: `в`,
    },
    nameEn: `The Nine Hells`,
    alignmentId: ALIGNMENT_LE,
    description: [
      `Девять преисподних Баатора будоражат воображение путешественников, жадность искателей сокровищ и ярость благопристойных существ. Это план законопослушания и зла, и образчик осознанной жестокости. Дьяволы Девяти Преисподних обязаны повиноваться вышестоящим, но внутри своих индивидуальных каст они бунтуют как хотят. Многие идут на что угодно, на любую подлость, чтобы продвинуться рангом повыше. На самом верху иерархии находится Асмодей, которого никому не перехитрить. Если кто-то его свергнет, он будет править всем планом. Таков закон Девяти Преисподних.`,
      {
        header: 'Опциональное правило: Вездесущее зло',
        text: `Девять преисподних пропитаны злом, и посетители этого плана чувствуют его влияние. В конце каждого длинного отдыха, совершённого на этом плане, посетитель, чьё мировоззрение не злое, должен пройти испытание Мудрости СЛ 10. При провале мировоззрение существа меняется на принципиально-злое. Изменение станет постоянным, если существо не покидает план в течение 1к4 дней. В противном случае мировоззрение существа возвращается через один день, проведённый вне Девяти Преисподних. Накладывание заклинания [Рассеивания добра и зла](SPELL:${SPELL_DISPEL_EVIL_AND_GOOD}) на такое существо тоже восстанавливает исходное мировоззрение. `,
        source: {
          id: SOURCE_DMG,
          page: 65,
        },
      },
      {
        header: 'Девять слоёв',
        text: `У Девяти Преисподних девять слоёв. Восемь первых находятся под контролем архидьяволов, служащих Асмодею, эрцгерцогу Нессуса, девятого слоя. Чтобы достичь самого глубокого слоя Девяти Преисподних, нужно пройти по всем восьми предыдущим слоям. Быстрее всего это сделать по реке Стикс, проходящей по всем слоям, но только самые храбрые искатели приключений могут выдержать муки и пытки такого путешествия.

# Аверно

Никакие планарные порталы не ведут на нижние слои Девяти Преисподних, таков приказ Асмодея. Именно поэтому первый слой, Аверно, является местом прибытия всех гостей этого плана. Аверно представляет собой каменистую пустошь с реками крови и облаками кусачих мух. Иногда с чёрного неба падают огненные кометы, оставляющие дымящиеся кратеры. Пустые поля сражений завалены оружием и костями, показывая, где легионы Девяти Преисподних встретили на своей земле врагов и победили.

Аверно правит эрцгерцогиня [Зариэль](CREATURE:${CREATURE_ZARIEL}), сместившая своего конкурента, [Бэла](CREATURE:${CREATURE_BEL}), который лишился милости Асмодея и по принуждению стал советником Зариэль. [Тиамат, Королева Злых Драконов](CREATURE:${CREATURE_TIAMAT}), пленена на этом слое, и правит своим небольшим доменом, но не покидает Девять преисподних согласно древнему контракту с Асмодеем (условия которого известны только Тиамат и Владыке Девяти). 

Средоточием власти [Зариэль](CREATURE:${CREATURE_ZARIEL}) является парящая в воздухе базальтовая цитадель, украшенная частично сожжёнными трупами посетителей, не сумевших угодить эрцгерцогине. [Зариэль](CREATURE:${CREATURE_ZARIEL}) выглядит как ангел, чья когда-то прекрасная кожа и крылья обожжены огнём. Её глаза горят яростным белым светом, от которого глядящие на неё существа могут воспламениться.

# Дис

Дис, второй слой Девяти Преисподних, это лабиринт из каньонов, зажатых горами, богатыми железной рудой. По каньонам проложены дороги из железа, за которыми присматривают гарнизоны, размещающиеся в железных крепостях на скалах.

Этот слой назван в честь своего повелителя, Диспатера. Этот эрцгерцог, ловкий манипулятор и мошенник, дьявольски привлекателен. Только небольшие рожки, хвост и копыто на левой ноге отличают его от человека. Его алый трон находится в сердце Железного Города Дис, огромном метрополисе, самом большом в Девяти Преисподних. Путешественники по планам приходят сюда для заключения сделок с дьяволами, [ночными каргами](CREATURE:${CREATURE_NIGHT_HAG}), [ракшасами](CREATURE:${CREATURE_RAKSHASA}), [инкубами](CREATURE:${CREATURE_INCUBUS}), [суккубами](CREATURE:${CREATURE_SUCCUBUS}) и прочими исчадиями. Диспатер получает отчисления со всех сделок, заключённых здесь, благодаря особому пункту во всех контрактах.

Диспатер — один из самых преданных и находчивых вассалов Асмодея, и мало кто во всей мультивселенной может его перехитрить. Он больше других дьяволов одержим идеей заключать сделки со смертными в обмен на их души, и его эмиссары безустанно плетут зловещие козни на Материальном плане.

# Минаурос

Третий слой Девяти Преисподних это сплошное зловонное болото. С коричневого неба этого слоя идёт кислотный дождь, толстый слой грязи покрывает его гниющую поверхность, а под трясинами скрываются ямы, поджидающие неосторожных путников. На болотах стоят огромные города из резного камня, включая великий город Минаурос, в честь которого и назван этот план.

Скользкие стены этого города возвышаются на сотни футов, защищая затопленные залы Маммона. Эрцгерцог Минауроса напоминает массивного змея с торсом и головой лысого рогатого гуманоида. Жадность Маммона вошла в легенды, и это один из малочисленных архидьяволов, оказывающий услуги за золото, а не души. Его логово завалено сокровищами тех, кто пытался — и не смог — превзойти его в сделке.

# Флегетос

Флегетос, четвёртый слой, это огненный пейзаж, в котором моря горячей магмы создают горячие ураганные ветра, удушающий дым и вулканический пепел. В заполненном огнём кратере самого большого вулкана Флегетоса находится Абримох, город-крепость, построенный из обсидиана и тёмного стекла. Из-за рек расплавленной лавы, стекающих по его внешним стенам, город напоминает чашу гигантского адского фонтана.

В Абримохе власть принадлежит двум архидьяволам, правящим в тандеме: эрцгерцогу Белиалу и эрцгерцогине Фьёрне, дочери Белиала. Белиал — привлекательный дьявол с мощным телосложением, ведущий себя учтиво, даже когда его слова несут угрозу. Его дочь — статная дьяволица, чья красота способна найти отзвук в самом чёрном сердце Девяти Преисподних. Союз Белиала и Фьёрны нерушим, так как каждый понимает, что его выживание зависит от партнёра.

# Стигия

Пятый слой Девяти Преисподних это морозное царство льда, в котором горит холодное пламя. Слой окружён замёрзшим морем, а угрюмое небо разрывается от молний.

Эрцгерцог Левистус, когда-то предавший Асмодея, заморожен во льдах Стигии в качестве наказания, но по-прежнему правит своим планом, телепатически общаясь со своими последователями и слугами, как в Девяти Преисподних, так и на Материальном плане. Стигия это ещё и дом её бывшего правителя, змеевидного архидьявола [Гериона](CREATURE:${CREATURE_GERYON}), освобождённого от должности Асмодеем, чтобы пленённый Левистус мог править дальше. Впадение [Гериона](CREATURE:${CREATURE_GERYON}) в немилость вызвало множество споров. Никто не знает, была ли у Асмодея тайная причина смещать архидьявола, или же он для чего-то испытывает преданность [Гериона](CREATURE:${CREATURE_GERYON}).

# Малболг

Малболг, шестой слой, пережил многих правителей, в том числе Малагард, графиню-ведьму, и архидьявола [Молоха](CREATURE:${CREATURE_MOLOCH}). Малагард лишилась милости и была уничтожена Асмодеем в приступе обиды, а её предшественник, [Молох](CREATURE:${CREATURE_MOLOCH}), всё ещё обитает где-то на шестом слое в облике [беса](CREATURE:${CREATURE_IMP}), намереваясь вернуть себе расположение Асмодея. Малболг выглядит как бесконечный склон невероятно огромной горы. Части этого слоя время от времени ломаются, создавая опасные и оглушительные каменные лавины. Обитатели Малболга живут в рассыпающихся крепостях и огромных пещерах, вырезанных в склоне.

Сейчас эрцгерцогиней Малболга является дочь Асмодея, Гласия. Она выглядит как [суккуб](CREATURE:${CREATURE_SUCCUBUS}) с небольшими рожками, кожаными крыльями и расщеплённым хвостом. От отца она унаследовала жестокость и любовь к козням. Цитадель, в которой она проживает на склоне Малболга, стоит на треснувших колоннах и опорах, крепких, несмотря на внешний вид. Под дворцом находится лабиринт, связанный с клетками и пыточными камерами, где Гласия держит в заточении и пытает тех, кто не угодил ей.

# Маладомини

Седьмой слой, Маладомини, это покрытая руинами пустошь. Мёртвые города формируют заброшенный урбанистический ландшафт, а между ними стоят пустые рудники, ветхие дороги, груды шлака, пустые остовы крепостей и рои голодных мух.

Эрцгерцогом Маладомини является Вельзевул, Повелитель Мух. У этого жирного исчадия нижняя половина тела огромного слизняка. Это наказание Асмодея за пошатнувшуюся преданность. Вельзевул — презренное и жалкое чудище, которое давно планирует свергнуть Асмодея, но у которого никогда это не получалось. На нём лежит проклятье, из-за которого все сделки, заключённые с ним, обречены на провал. Периодически Асмодей оказывает благосклонность Вельзевулу, но причины этого непонятны другим эрцгерцогам, хотя они и подозревают, что эрцгерцог Нессуса всё ещё ценит поверженного противника.

# Кания

Кания, восьмой слой Девяти Преисподних, это ледяной ландшафт, бури которого могут содрать плоть с костей. Города, вмороженные в лёд, могут предоставить укрытие гостям и пленникам правителя Кании, великолепного и коварного архидьявола Мефистофеля.

Мефистофель обитает в ледяной цитадели Мефистар, в которой он и планирует захват Трона Баатора и завоевание всех планов. Это величайший союзник и противник Асмодея, и эрцгерцог Нессуса вроде бы доверяет советам Мефистофеля. Мефистофель знает, что не сможет сместить Асмодея, пока тот не совершит роковой ошибки, и потому оба ждут, пока обстоятельства не столкнут их. Мефистофель также является чем-то вроде крёстного отца Гласии, что лишь делает ситуацию более запутанной.

Мефистофель это импозантный, высокий дьявол с впечатляющими рогами и сдержанными манерами. Он торгует душами, как прочие архидьяволы, но редко уделяет время недостойным существам. Его инстинкты остры как ледяные ветра Кании, и говорят, что перехитрить его может только Асмодей.

# Нессус

Самый нижний слой Девяти Преисподних, Нессус, это царство тёмных ям, стены которых усыпаны крепостями. Там генералы, [исчадия преисподней](CREATURE:${CREATURE_PIT_FIEND}), преданные Асмодею, муштруют легионы дьяволов и строят планы по завоеванию мультивселенной. В центре этого слоя находится огромный разлом неизвестной глубины, в котором возвышается Мальшим, огромная цитадель в форме шпиля, дом Асмодея и его инфернальных придворных.

Мальшим напоминает огромный пустой внутри сталагмит. Это также тюрьма для душ, запертых Асмодеем для сохранности. Просьба убедить его выпустить даже одну душу обойдётся очень дорого, ходят слухи, что раньше эрцгерцог Нессуса брал за это целые королевства.

Асмодей чаще всего предстаёт в виде привлекательного бородатого гуманоида с небольшими рожками на лбу, пронзительными красными глазами и в струящейся мантии. Он принимает и другие обличья, но его редко видят без скипетра с рубином в руке. Асмодей — самый хитрый и благовоспитанный из архидьяволов. Абсолютное зло, представителем которого он является, можно увидеть либо если он сам того пожелает, либо же если он забудется и выйдет из себя.`,
        source: {
          id: SOURCE_DMG,
          page: 65,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 65,
    },
  },
  {
    id: PLANE_ACHERON,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Ахерон`,
      accusative: `Ахерон`,
      local: `Ахероне`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `бесконечные поля битв Ахерона`,
      accusative: `бесконечные поля битв Ахерона`,
      local: `бесконечных полях битв Ахерона`,
      localPreposition: `на`,
    },
    nameEn: `Acheron`,
    alignmentId: [
      ALIGNMENT_LN,
      ALIGNMENT_LE,
    ],
    description: [
      `У Ахерона четыре слоя, и каждый состоит из огромных железных кубов, парящих в воздухе. Иногда кубы сталкиваются. Эхо от таких столкновений проносится по плану, сливаясь со звуками воюющих армий. Такова природа Ахерона: борьба и война, и духи павших солдат ведут вечный бой с орками Груумша, гоблиноидами Маглубиет и легионами прочих воинствующих богов. `,
      {
        header: 'Опциональное правило: Кровожадность',
        text: `Ахерон вознаграждает существ за причинение вреда другим, даруя стойкость в бою. Находясь на Ахероне, существо получает временные хиты в количестве, равном половине максимума хитов, каждый раз, когда опускает хиты враждебного существа до 0. `,
        source: {
          id: SOURCE_DMG,
          page: 67,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 67,
    },
  },
  {
    id: PLANE_MECHANUS,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Механус`,
      accusative: `Механус`,
      local: `Механусе`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `механическая нирвана Механуса`,
      accusative: `механическую нирвану Механуса`,
      local: `механической нирване Механуса`,
      localPreposition: `в`,
    },
    nameEn: `Mechanus`,
    alignmentId: ALIGNMENT_LN,
    description: [
      `На Механусе порядок представлен царством часовых шестерёнок, сцепленных между собой и вращающихся с разными скоростями. Создаётся впечатление, что шестерёнки заняты вычислениями, такими масштабными, что ни одно божество не может понять его предназначение. Механус воплощает абсолютный порядок, и его влияние чувствуют те, кто проводят здесь время. Основными обитателями Механуса являются модроны. Этот план является ещё и домом создателя модронов: божественной сущности, называющейся Праймом. `,
      {
        header: 'Опциональное правило: Закон средних чисел',
        text: `Находясь на Механусе, существа всегда используют средний урон для атак и заклинаний. Например, атака, обычно причиняющая урон 1к10 + 5, на Механусе всегда причиняет урон 10.`,
        source: {
          id: SOURCE_DMG,
          page: 67,
        },
      },
      {
        header: 'Опциональное правило: Преобладающий порядок',
        text: `В конце каждого длинного отдыха, совершённого на этом плане, посетитель, чьё мировоззрение не принципиально-нейтральное, должен пройти испытание Мудрости СЛ 10. При провале мировоззрение существа меняется на принципиально-нейтральное. Мировоззрение существа возвращается через один день, проведённый вне Механуса. Накладывание заклинания рассеивание добра и зла на такое существо тоже восстанавливает исходное мировоззрение.`,
        source: {
          id: SOURCE_DMG,
          page: 67,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 67,
    },
  },
  {
    id: PLANE_ARCADIA,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Аркадия`,
      accusative: `Аркадию`,
      local: `Аркадии`,
      localPreposition: `на`,
    },
    fullName: {
      nominative: `мирные королевства Аркадии`,
      accusative: `мирные королевства Аркадии`,
      local: `мирных королевствах Аркадии`,
      localPreposition: `в`,
    },
    nameEn: `Arcadia`,
    alignmentId: [
      ALIGNMENT_LN,
      ALIGNMENT_LG,
    ],
    description: [
      `Аркадия славится садами растущих идеальными рядами деревьев, вычерченными по линейке ручьями, ровными полями, идеальными дорогами и городами, спроектированными в геометрически приятных формах. Горы здесь не повреждены эрозией. Всё на Аркадии направлено на всеобщее благо и безупречное существование. Здесь чистота вечна, и ничто не нарушает гармонию.

Смена дня и ночи зависит от шара, парящего над самым высоким горным пиком Аркадии. Половина этого шара излучает солнечный свет и приносит день; другая половина испускает лунный свет и приносит звёздную ночь. Шар вращается с постоянной скоростью, сменяя день на ночь на всём плане.

За погоду на Аркадии отвечают четыре союзных полубога, называемых Королями Бури: Король Облаков, Королева Ветров, Король Молний и Королева Дождей. Каждый из них живёт в замке, окружённом погодой, за которую он отвечает.

Под прекрасными горами Аркадии находятся многочисленные королевства дварфов, которым по много тысяч лет. У дварфов, родившихся на этом плане, тип «небожитель», и они всегда храбрые и добросердечные, но во всё остальном они выглядят и ведут себя как обычные дварфы. `,
      {
        header: 'Опциональное правило: Планарная живучесть',
        text: `Находясь на этом плане, существа не могут быть испуганными и отравленными, и обладают иммунитетом к болезням и ядам.`,
        source: {
          id: SOURCE_DMG,
          page: 68,
        },
      },
      ...outerPlanesDescriptionList,
    ],
    source: {
      id: SOURCE_DMG,
      page: 67,
    },
  },
  {
    id: PLANE_OUTLANDS,
    type: PLANE_TYPE_OUTER,
    name: {
      nominative: `Внешние земли`,
      accusative: `Внешние земли`,
      local: `Внешних землях`,
      localPreposition: `на`,
    },
    nameEn: `The Outlands`,
    alignmentId: ALIGNMENT_N,
    description: [
      `Внешние Земли это план между Внешними Планами. Это план нейтральности, включающий понемногу от всего, и хранящий всё это в парадоксальном равновесии — одновременно гармоничном и противодействующем. На нём разный ландшафт, с прериями, горами и мелкими реками.

Внешние Земли круглые, словно огромный диск. Те, кто представляют Внешние Планы в виде колеса, считают Внешние Земли доказательством такого устройства, называя их миниатюрной копией планов. Однако с этим аргументом можно поспорить, так как именно устройство Внешних Земель подсказало идею Великого Колеса.

По внешней границе круга, через равные расстояния, стоят портальные города: шестнадцать поселений, где каждое построено вокруг портала, ведущего на один из Внешних Планов. Каждый город имеет много общего с тем планом, куда ведёт его портал. В этих городах часто есть портальные послы, потому там можно увидеть весьма странные вещи вроде небожителя и исчадия, сидящих в таверне за бутылкой вина.

Учитывая тот факт, что на Внешних Землях даже на лошади можно попасть из ада в рай, кампанию с планарными перемещениями можно проводить без самих перемещений. Внешние Земли на Внешних Планах — ближайший аналог обычного мира на Материальном плане.`,
      {
        header: 'Портальные города Внешних Земель',
        text: `
| Город          | Место назначения портала        |
|----------------|---------------------------------|
| Превосходный   | Семь небес горы Селестии        |
| Торговые врата | Двойной рай Битопии             |
| Экстазия       | Благословенные поля Элизиума    |
| Фаунель        | Дебри Звериных Земель           |
| Сильвания      | Олимпийские просторы Арбореи    |
| Глориум        | Героические владения Асгарда    |
| Каос           | Вечно бурлящий хаос Лимбо       |
| Бедлам         | Завывающие глубины Пандемония |
| Зачумлённый    | Бесконечные слои Бездны         |
| Клятый         | Забытые преисподние Карцери     |
| Безнадёжность  | Серые пустоши Гадеса            |
| Факел          | Мрачная вечность Геенны         |
| Костяк         | Девять преисподних Баатора      |
| Ригус          | Бесконечные поля битв Ахерона   |
| Автомата       | Механическая нирвана Механуса   |
| Стойкость      | Мирные королевства Аркадии      |
`,
        source: {
          id: SOURCE_DMG,
          page: 68,
        },
      },
      {
        header: 'Сигил, Город Дверей',
        text: `В центре Внешних Земель, словно ось громадного колеса, находится Пик — острая как игла гора, возвышающаяся в небеса. Над узким пиком этой горы парит город Сигил, чьи многочисленные здания построены на внутренней поверхности огромного кольца. Существа, стоящие на улице Сигила, могут видеть, как город загибается кверху, а дальний конец города и вовсе находится у них над головой. Называемый Городом Дверей, этот многолюдный планарный мегаполис содержит бесчисленные порталы на другие планы и в другие миры.

Сигил — рай для торговцев. Товары, припасы и информация стекаются сюда со всех планов. В городе ведут бойкую торговлю информацией о планах, в частности, о командных словах и предметах, необходимых для активации того или иного портала. Здесь покупают и продают портальные ключи всех видов.

Город является доменом загадочной Госпожи Боли, сущности, старой как боги, но с целями, неизвестными ни одному мудрецу. Может, Сигил это её тюрьма? Может, она — сверженный создатель мультивселенной? Никто не знает. А если знает, то не говорит. `,
        source: {
          id: SOURCE_DMG,
          page: 69,
        },
      },
    ],
    source: {
      id: SOURCE_DMG,
      page: 68,
    },
  },
  {
    id: PLANE_DEMIPLANES,
    type: PLANE_TYPE_OTHER_PLANES,
    name: {
      nominative: `демипланы`,
      accusative: `демипланы`,
      local: `демипланах`,
      localPreposition: `на`,
    },
    nameEn: `Demiplanes`,
    description: `Демипланы это межпространственные участки, создаваемые самыми разными способами и подчиняющиеся своим собственным законам физики. Некоторые из них создаются заклинаниями. Другие существуют сами по себе, как складки реальности, отщипнутые от остальной мультивселенной. Теоретически, заклинание [Уход в иной мир](SPELL:${SPELL_PLANE_SHIFT}) может привести путников в демиплан, но подобрать для настройки на него нужную частоту невероятно трудно. Заклинание [врата](SPELL:${SPELL_GATE}) в этом плане надёжнее, при условии, что заклинатель знает нужный ему демиплан.

Демиплан может быть маленьким, как одна комната, или большим, как целый мир. Например, заклинание [Великолепный особняк Морденкайнена](SPELL:${SPELL_MORDENKAINEN_S_MAGNIFICENT_MANSION}) создаёт демиплан, состоящий из прихожей и смежных с ней комнат, а долина Баровия (в игровом мире Равенлофт) существует тоже в демиплане, находясь под властью вампира, Страда фон Заровича. Если демиплан соединён с Материальным планом или любым другим планом, войти в него можно так же легко, как в обычный портал или туманную завесу.`,
    source: {
      id: SOURCE_DMG,
      page: 69,
    },
  },
  {
    id: PLANE_FAR_REALM,
    type: PLANE_TYPE_OTHER_PLANES,
    name: {
      nominative: `Дальний Предел`,
      accusative: `Дальний Предел`,
      local: `Дальнем Пределе`,
      localPreposition: `на`,
    },
    nameEn: `The Far Realm `,
    description: `Дальний Предел находится за пределами известной мультивселенной. В сущности, это может быть абсолютно иная мультивселенная со своими собственными физическими и магическими законами. Там, где энергия Дальнего Предела просачивается на другой план, жизнь и материя искажаются, становясь чужеродными формами, не подчиняющимися обычной геометрии и биологии. Аберрации, такие как [свежеватели разума](CREATURE:${CREATURE_MIND_FLAYER}) и [злобоглазы](CREATURE:${CREATURE_BEHOLDER}), либо родом с этого плана, либо сформированы под его жутким влиянием.

Сущности, обитающие в Дальнем Пределе, слишком чужеродны, чтобы обычное сознание могло принять их без вреда для себя. Громадные существа парят там в нигде, охваченные безумием. Невыразимые твари шепчут жуткую истину тем, кто осмелится слушать. Для смертных знания Дальнего Предела это триумф сознания над границами материи, пространства и даже разума. Некоторые колдуны включаются в эту борьбу, заключая договоры с такими сущностями. Те, кто видели Дальний Предел, безумно бормочут о глазах, щупальцах и ужасе.

Известных порталов в Дальний Предел нет, по крайней мере, работающих. Древние эльфы когда-то пронзили границу тысячелетий большим порталом в Дальний Предел на горе Пик Огненной Бури, но их цивилизация сгинула в кровавом ужасе, и местоположение этого портала (и даже его мира) теперь забыто. Теоретически, могут существовать и другие порталы, через которые чужеродные силы отравляют окрестные земли Материального плана.`,
    source: {
      id: SOURCE_DMG,
      page: 69,
    },
  },
  {
    id: PLANE_TORIL,
    type: PLANE_TYPE_MATERIAL,
    name: {
      nominative: `Торил`,
      accusative: `Торил`,
      local: `Ториле`,
      localPreposition: `на`,
    },
    nameEn: `Toril`,
    description: `На Ториле (мир героического фэнтези Забытых Королевств) фантастические города и королевства покоятся среди останков древних империй и давно исчезнувших стран. Мир огромен, а его подземелья хранят тайны истории. Кроме центрального континента, Фаэруна, на Ториле есть регионы Аль-Кадим, Кара-Тур и Мацтика.`,
    source: {
      id: SOURCE_DMG,
      page: 69,
    },
  },
  {
    id: PLANE_OERTH,
    type: PLANE_TYPE_MATERIAL,
    name: {
      nominative: `Орт`,
      accusative: `Орт`,
      local: `Орте`,
      localPreposition: `на`,
    },
    nameEn: `Oerth`,
    description: `На Орте (мир меча и магии Грейхока) таких героев как Бигби и Морденкайнен ведёт жадность и амбиции. Центром региона Фланаэсс является Свободный Город Грейхок, город негодяев и архимагов, кишащий приключениями. Злой полубог Иуз правит на севере кошмарным царством, угрожающим всей цивилизации. `,
    source: {
      id: SOURCE_DMG,
      page: 69,
    },
  },
  {
    id: PLANE_KRYNN,
    type: PLANE_TYPE_MATERIAL,
    name: {
      nominative: `Кринн`,
      accusative: `Кринн`,
      local: `Кринне`,
      localPreposition: `на`,
    },
    nameEn: `Krynn`,
    description: `На Кринне (мир эпичного фэнтези Саги о Копье) возвращение богов омрачилось восстанием королевы злых драконов Такхизис и её войск, погрузивших континент Ансалон в войну.`,
    source: {
      id: SOURCE_DMG,
      page: 69,
    },
  },
  {
    id: PLANE_ATHAS,
    type: PLANE_TYPE_MATERIAL,
    name: {
      nominative: `Атхас`,
      accusative: `Атхас`,
      local: `Атхасе`,
      localPreposition: `на`,
    },
    nameEn: `Athas`,
    description: `На Атхасе (мир меча и магии Тёмного Солнца) капля воды может стоить больше человеческой жизни. Боги покинули этот пустынный мир, в котором правят деспотичные короли-чародеи, а металл редок и стоит очень дорого.`,
    source: {
      id: SOURCE_DMG,
      page: 69,
    },
  },
  {
    id: PLANE_EBERRON,
    type: PLANE_TYPE_MATERIAL,
    name: {
      nominative: `Эберрон`,
      accusative: `Эберрон`,
      local: `Эберроне`,
      localPreposition: `на`,
    },
    nameEn: `Eberron`,
    description: `На Эберроне (мир героического фэнтези Эберрон) окончилась ужасная война, сменившаяся холодной войной, подпитываемой политическими интригами. На континенте Кхорвайр магия обыденна, отмеченные драконами дома соперничают друг с другом за власть, а стихийные транспортные средства позволяют отправиться в любой уголок исследованного мира.`,
    source: {
      id: SOURCE_DMG,
      page: 69,
    },
  },
  {
    id: PLANE_AEBRYNIS,
    type: PLANE_TYPE_MATERIAL,
    name: {
      nominative: `Эбринис`,
      accusative: `Эбринис`,
      local: `Эбринисе`,
      localPreposition: `на`,
    },
    nameEn: `Aebrynis`,
    description: `На Эбринисе (мир героического фэнтези Birthright) наследники, род которых восходит к богам, соперничают за власть на континенте Церилия. Монархи, прелаты, главы гильдий и великие волшебники разбираются с возникающими перед ними, как перед правителями, задачами, и при этом борются с жуткими чудищами, рождёнными от крови злого божества.`,
    source: {
      id: SOURCE_DMG,
      page: 69,
    },
  },
  {
    id: PLANE_MYSTARA,
    type: PLANE_TYPE_MATERIAL,
    name: {
      nominative: `Мистар`,
      accusative: `Мистар`,
      local: `Мистаре`,
      localPreposition: `на`,
    },
    nameEn: `Mystara`,
    description: `На Мистаре (мир героического фэнтези, зародившийся в первых изданиях D&D) сталкиваются разнообразные культуры, дикие чудища и воюющие империи. Мир также формируется вмешательством Бессмертных — бывших искателей приключений, достигнувших практически божественного статуса.`,
    source: {
      id: SOURCE_DMG,
      page: 69,
    },
  },
  {
    id: PLANE_POSITIVE,
    type: PLANE_TYPE_POSITIVE_NEGATIVE,
    name: {
      nominative: `Положительный План`,
      accusative: `Положительный План`,
      local: `Положительном Плане`,
      localPreposition: `на`,
    },
    nameEn: `Positive Plane`,
    description: `Как купол над всеми остальными планами висит Положительный План, источник энергии излучения и сырой жизненной силы, наполняющей всех живых существ, от самых крохотных до великанов.`,
    source: {
      id: SOURCE_PHB,
      page: 301,
    },
  },
  {
    id: PLANE_NEGATIVE,
    type: PLANE_TYPE_POSITIVE_NEGATIVE,
    name: {
      nominative: `Отрицательный План`,
      accusative: `Отрицательный План`,
      local: `Отрицательном Плане`,
      localPreposition: `на`,
    },
    nameEn: `Negative Plane`,
    description: `Тёмным отражением Положительного Плана является Отрицательный План, источник некротической энергии, уничтожающей жизнь и оживляющей нежить.`,
    source: {
      id: SOURCE_PHB,
      page: 301,
    },
  },
]
  .map(
    plane => ({
      ...plane,
      fullName: plane.fullName || plane.name,
      alignmentId: plane.alignmentId || ALIGNMENT_NO,
      description: plane.description
        ? [
          ...arrify(plane.description),
          extraPlanarTravelDescription,
        ]
        : extraPlanarTravelDescription,
    }),
  )

export default planeList

export const planeCollection = listToCollectionById(planeList)
