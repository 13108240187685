const {BACKGROUND_GUILD_MERCHANT} = require('./../../backgroundIdList')
const {LANG_ANY} = require('./../../languageIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_MULE,
  GEAR_NAVIGATORS_TOOLS,
  GEAR_POUCH,
  GEAR_WAGON,
} = require('./../../gearIdList')
const guild_artisan = require('./guild_artisan')

module.exports = {
  ...guild_artisan,
  id: BACKGROUND_GUILD_MERCHANT,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Гильдейский купец',
    [GENDER_FEMALE]: 'Гильдейская купчиха',
  },
  nameEn: 'Guild Merchant',
  description: [
    {
      header: 'Гильдейский купец',
      text: `Вы можете состоять не в гильдии ремесленников, а в гильдии купцов, караванщиков и лавочников. Сами Вы ничего не мастерите, но зарабатываете на жизнь, покупая и продавая творения чужих рук (или сырьё, необходимое ремесленникам). Ваша гильдия может быть большим торговым синдикатом (или семейством), с капиталовложениями по всему региону. Возможно, Вы перевозите товары из одного места в другое, на корабле, в фургоне или караване, или покупаете их у бродячих торговцев и продаёте в своём маленьком магазинчике. В какой-то мере, жизнь странствующего торговца ближе к приключениям, чем жизнь ремесленника.

Вместо владения ремесленным инструментом Вы можете овладеть [инструментами навигатора](GEAR:${GEAR_NAVIGATORS_TOOLS}) или дополнительным языком. А вместо наличия ремесленных инструментов Вы можете начать игру с [мулом](GEAR:${GEAR_MULE}) и [телегой](GEAR:${GEAR_WAGON}).`,
      source: {
        id: SOURCE_PHB,
        page: 134,
      },
    },
    ...guild_artisan.description,
  ],
  proficiency: {
    ...guild_artisan.proficiency,
    tool: {
      list: [
        GEAR_NAVIGATORS_TOOLS,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: `
* [Мул](GEAR:${GEAR_MULE}),
* [Телега](GEAR:${GEAR_WAGON}),
* Рекомендательное письмо из гильдии,
* [Дорожная одежда](GEAR:${GEAR_CLOTHES_TRAVELERS}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 15 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 134,
  },
}
