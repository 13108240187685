import rollDice from '@/utils/rollDice'

const CUBE_TYPE = 6
const CUBE_ROLL_COUNT = 4
const CUBE_ROLL_COUNT_OLD_SCHOOL = 3
const CUBE_REMOVE_COUNT = 1
const CUBE_REMOVE_COUNT_OLD_SCHOOL = 0

const roll = rollDice(CUBE_TYPE)

export default (oldSchool = false) => {
  const rollArr = []
  const rollCount = oldSchool
    ? CUBE_ROLL_COUNT_OLD_SCHOOL
    : CUBE_ROLL_COUNT
  const cubeRemoveCount = oldSchool
    ? CUBE_REMOVE_COUNT_OLD_SCHOOL
    : CUBE_REMOVE_COUNT

  for (let i = 0; i < rollCount; i++) {
    rollArr.push(roll() + 1)
  }

  return rollArr
    .sort((a, b) => a - b)
    .slice(cubeRemoveCount)
    .reduce((summ, digit) => summ + digit)
}
