import listToCollectionById from '@/utils/listToCollectionById'

import { VARIANT_DEATH, VARIANT_RACE, VARIANT_CRIME } from '@/components/CharacterGenerator/constants/variantIdList'

export const MISSED_PARENT_REASON_DEAD = 'missed_parent_reason_dead'
export const MISSED_PARENT_REASON_JAIL = 'missed_parent_reason_jail'
export const MISSED_PARENT_REASON_LOST = 'missed_parent_reason_lost'
export const MISSED_PARENT_REASON_QUIT = 'missed_parent_reason_quit'
export const MISSED_PARENT_REASON_SLAVE = 'missed_parent_reason_slave'

const missedParentReasonVariantList = [
  {
    name: `★Родитель★ ${VARIANT_DEATH}`,
    id: MISSED_PARENT_REASON_DEAD,
    detailDictIdList: VARIANT_DEATH,
    probabilityWeight: 2,
  },
  {
    name: `★Родитель★ ★был★ ★заключён★ в тюрьму за ${VARIANT_CRIME}.`,
    id: MISSED_PARENT_REASON_JAIL,
    detailDictIdList: VARIANT_CRIME,
    probabilityWeight: 1,
  },
  {
    name: `★Родитель★ ★был★ ★порабощён★ ${VARIANT_RACE}.`,
    id: MISSED_PARENT_REASON_SLAVE,
    detailDictIdList: VARIANT_RACE,
    probabilityWeight: 1,
  },
  {
    name: `★Родитель★ ★бросил★ семью.`,
    id: MISSED_PARENT_REASON_QUIT,
    probabilityWeight: 2,
  },
  {
    name: `★Родитель★ ★пропал★ без вести.`,
    id: MISSED_PARENT_REASON_LOST,
    probabilityWeight: 2,
  },
]

export default missedParentReasonVariantList
export const missedParentReasonVariantCollection = listToCollectionById(missedParentReasonVariantList)
