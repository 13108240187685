import * as pcClassIdCollection from '@/constants/pcClassIdList'

const pcClassIdList = Object.values(pcClassIdCollection)

export default pcClassIdList.map(
  id => ({
    id,
    probabilityWeight: 1,
  })
)
