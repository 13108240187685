import listToCollectionById from '@/utils/listToCollectionById'

import { GENDER_FEMALE, GENDER_MALE } from '@/constants/genderList'

export const OCCUPATION_ACADEMIC = 'academic'
export const OCCUPATION_ACCOUNTANT = 'accountant'
export const OCCUPATION_ACTOR = 'actor'
export const OCCUPATION_ADVENTURER = 'adventurer'
export const OCCUPATION_ALCHEMIST = 'alchemist'
export const OCCUPATION_ANIMAL_TAMER = 'animal_tamer'
export const OCCUPATION_ANIMAL_TRAINER = 'animal_trainer'
export const OCCUPATION_APPRENTICE = 'apprentice'
export const OCCUPATION_ARISTOCRAT = 'aristocrat'
export const OCCUPATION_ARMORER = 'armorer'
export const OCCUPATION_ARTISAN = 'artisan'
export const OCCUPATION_ARTIST = 'artist'
export const OCCUPATION_ASSASSIN = 'assassin'
export const OCCUPATION_ASTROLOGER = 'astrologer'
export const OCCUPATION_AUTHOR = 'author'
export const OCCUPATION_BAKER = 'baker'
export const OCCUPATION_BANDIT = 'bandit'
export const OCCUPATION_BANKER = 'banker'
export const OCCUPATION_BARBER = 'barber'
export const OCCUPATION_BARDE = 'barde'
export const OCCUPATION_BARTENDER = 'bartender'
export const OCCUPATION_BASKET_MAKER = 'basket_maker'
export const OCCUPATION_BEADLE = 'beadle'
export const OCCUPATION_BEEKEEPER = 'beekeeper'
export const OCCUPATION_BEGGAR = 'beggar'
export const OCCUPATION_BLACKSMITH = 'blacksmith'
export const OCCUPATION_BOOKBINDER = 'bookbinder'
export const OCCUPATION_BOOKKEEPER = 'bookkeeper'
export const OCCUPATION_BOTTLE_MAKER = 'bottle_maker'
export const OCCUPATION_BOUNTY_HUNTER = 'bounty_hunter'
export const OCCUPATION_BREWER = 'brewer'
export const OCCUPATION_BUREAUCRAT = 'bureaucrat'
export const OCCUPATION_BUTCHER = 'butcher'
export const OCCUPATION_CARAVAN_GUARD = 'caravan_guard'
export const OCCUPATION_CARPENTER = 'carpenter'
export const OCCUPATION_CARTOGRAPHER = 'cartographer'
export const OCCUPATION_CARVER = 'carver'
export const OCCUPATION_CHANDLER = 'chandler'
export const OCCUPATION_CHEESEMAKER = 'cheesemaker'
export const OCCUPATION_CHEST_MAKER = 'chest_maker'
export const OCCUPATION_CHIMNEY_SWEEPER = 'chimney_sweeper'
export const OCCUPATION_CLOCKSMITH = 'clocksmith'
export const OCCUPATION_COBBLER = 'cobbler'
export const OCCUPATION_CON_ARTIST = 'con_artist'
export const OCCUPATION_CONFECTIONER = 'confectioner'
export const OCCUPATION_COOK = 'cook'
export const OCCUPATION_COOPER = 'cooper'
export const OCCUPATION_COSTERMONGER = 'costermonger'
export const OCCUPATION_CRIMINAL = 'criminal'
export const OCCUPATION_CUTPURSE = 'cutpurse'
export const OCCUPATION_DAIRY_MAID = 'dairy_maid'
export const OCCUPATION_DISTILLER = 'distiller'
export const OCCUPATION_DITCH_DIGGER = 'ditch_digger'
export const OCCUPATION_DOCTOR = 'doctor'
export const OCCUPATION_DRUID = 'druid'
export const OCCUPATION_DYER = 'dyer'
export const OCCUPATION_ENGRAVER = 'engraver'
export const OCCUPATION_EXORCIST = 'exorcist'
export const OCCUPATION_FALCONER = 'falconer'
export const OCCUPATION_FARMER = 'farmer'
export const OCCUPATION_FISHER = 'fisher'
export const OCCUPATION_FLETCHER = 'fletcher'
export const OCCUPATION_FORESTER = 'forester'
export const OCCUPATION_FORTUNE_TELLER = 'fortune_teller'
export const OCCUPATION_FURNITURE_MAKER = 'furniture_maker'
export const OCCUPATION_FURRIER = 'furrier'
export const OCCUPATION_GLADIATOR = 'gladiator'
export const OCCUPATION_GLASSBLOWER = 'glassblower'
export const OCCUPATION_GLAZIER = 'glazier'
export const OCCUPATION_GLOVEMAKER = 'glovemaker'
export const OCCUPATION_GONGFARMER = 'gongfarmer'
export const OCCUPATION_GRAVEDIGGER = 'gravedigger'
export const OCCUPATION_GUIDE = 'guide'
export const OCCUPATION_HABERDASHER = 'haberdasher'
export const OCCUPATION_HATTER = 'hatter'
export const OCCUPATION_HEALER = 'healer'
export const OCCUPATION_HERBALIST = 'herbalist'
export const OCCUPATION_HERDER = 'herder'
export const OCCUPATION_HERMIT = 'hermit'
export const OCCUPATION_HISTORIAN = 'historian'
export const OCCUPATION_HUNTER = 'hunter'
export const OCCUPATION_INDENTURED_SERVANT = 'indentured_servant'
export const OCCUPATION_INNKEEPER = 'innkeeper'
export const OCCUPATION_JESTER = 'jester'
export const OCCUPATION_JEWELER = 'jeweler'
export const OCCUPATION_KNIGHT = 'knight'
export const OCCUPATION_KOJEMYAKA = 'kojemyaka'
export const OCCUPATION_LABORER = 'laborer'
export const OCCUPATION_LAMPLIGHTER = 'lamplighter'
export const OCCUPATION_LANDLORD = 'landlord'
export const OCCUPATION_LAWMAN = 'lawman'
export const OCCUPATION_LAWYER = 'lawyer'
export const OCCUPATION_LIBRARIAN = 'librarian'
export const OCCUPATION_LIGHTHOUSE_KEEPER = 'lighthouse_keeper'
export const OCCUPATION_LOCKSMITH = 'locksmith'
export const OCCUPATION_LOOKOUT = 'lookout'
export const OCCUPATION_LUMBERJACK = 'lumberjack'
export const OCCUPATION_MARINER = 'mariner'
export const OCCUPATION_MERCENARY = 'mercenary'
export const OCCUPATION_MERCHANT = 'merchant'
export const OCCUPATION_MESSENGER = 'messenger'
export const OCCUPATION_MILLER = 'miller'
export const OCCUPATION_MINER = 'miner'
export const OCCUPATION_MINSTREL = 'minstrel'
export const OCCUPATION_MIRROR_MAKER = 'mirror_maker'
export const OCCUPATION_MONEYLENDER = 'moneylender'
export const OCCUPATION_MORTICIAN = 'mortician'
export const OCCUPATION_NAVIGATOR = 'navigator'
export const OCCUPATION_NOBLE = 'noble'
export const OCCUPATION_NUN = 'nun'
export const OCCUPATION_NURSE = 'nurse'
export const OCCUPATION_OPTICIAN = 'optician'
export const OCCUPATION_OSTLER = 'ostler'
export const OCCUPATION_OUTCAST = 'outcast'
export const OCCUPATION_OUTLAW = 'outlaw'
export const OCCUPATION_OVER_BIRD = 'over_bird'
export const OCCUPATION_OWLBEAR_BREEDER = 'owlbear_breeder'
export const OCCUPATION_PAINTER = 'painter'
export const OCCUPATION_PEDDLER = 'peddler'
export const OCCUPATION_POLITICIAN = 'politician'
export const OCCUPATION_PORTRAIT_PAINTER = 'portrait_painter'
export const OCCUPATION_POTIONEER = 'potioneer'
export const OCCUPATION_POTTER = 'potter'
export const OCCUPATION_PRIEST = 'priest'
export const OCCUPATION_RANGER = 'ranger'
export const OCCUPATION_RAT_CATCHER = 'rat_catcher'
export const OCCUPATION_REFUGEE = 'refugee'
export const OCCUPATION_RESEARCHER = 'researcher'
export const OCCUPATION_ROOFER = 'roofer'
export const OCCUPATION_ROPE_MAKER = 'maker'
export const OCCUPATION_SADDLER = 'saddler'
export const OCCUPATION_SAILOR = 'sailor'
export const OCCUPATION_SALTAR = 'saltar'
export const OCCUPATION_SCHOLAR = 'scholar'
export const OCCUPATION_SCRIBE = 'scribe'
export const OCCUPATION_SCULPTOR = 'sculptor'
export const OCCUPATION_SERVANT = 'servant'
export const OCCUPATION_SHAMAN = 'shaman'
export const OCCUPATION_SHEPPERD = 'shepperd'
export const OCCUPATION_SHERIFF = 'sheriff'
export const OCCUPATION_SKINNER = 'skinner'
export const OCCUPATION_SLAVE = 'slave'
export const OCCUPATION_SMUGGLER = 'smuggler'
export const OCCUPATION_SOAPMAKER = 'soapmaker'
export const OCCUPATION_SOLDIER = 'soldier'
export const OCCUPATION_SQUIRE = 'squire'
export const OCCUPATION_STABLE_HAND = 'stable_hand'
export const OCCUPATION_STONEMASON = 'stonemason'
export const OCCUPATION_TAILOR = 'tailor'
export const OCCUPATION_TANNER = 'tanner'
export const OCCUPATION_TAX_COLLECTOR = 'tax_collector'
export const OCCUPATION_THIEF = 'thief'
export const OCCUPATION_THUG = 'thug'
export const OCCUPATION_TINKERER = 'tinkerer'
export const OCCUPATION_TOWN_CRYER = 'town_cryer'
export const OCCUPATION_TOWN_GUARD = 'town_guard'
export const OCCUPATION_TOYMAKER = 'toymaker'
export const OCCUPATION_TRADER = 'trader'
export const OCCUPATION_TRAPPER = 'trapper'
export const OCCUPATION_TREASURE_HUNTER = 'treasure_hunter'
export const OCCUPATION_VAGRANT = 'vagrant'
export const OCCUPATION_VARNISHER = 'varnisher'
export const OCCUPATION_WAINWRIGHT = 'wainwright'
export const OCCUPATION_WANDERER = 'wanderer'
export const OCCUPATION_WASHERWOMAN = 'washerwoman'
export const OCCUPATION_WEAVER = 'weaver'
export const OCCUPATION_WIZARD = 'wizard'
export const OCCUPATION_WIZARDS_APPRENTICE = 'wizards_apprentice'
export const OCCUPATION_WOODCUTTER = 'woodcutter'
export const OCCUPATION_WRESTLER = 'wrestler'

const occupationList = [
  {
    id: OCCUPATION_ACADEMIC,
    name: {
      [GENDER_MALE]: 'академик',
      [GENDER_FEMALE]: 'академик',
    },
    probabilityWeight: 30,
  },
  {
    id: OCCUPATION_ACCOUNTANT,
    name: {
      [GENDER_MALE]: 'бухгалтер',
      [GENDER_FEMALE]: 'бухгалтерша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_ACTOR,
    name: {
      [GENDER_MALE]: 'актёр',
      [GENDER_FEMALE]: 'актриса',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_ADVENTURER,
    name: {
      [GENDER_MALE]: 'искатель приключений',
      [GENDER_FEMALE]: 'искательница приключений',
    },
    probabilityWeight: 30,
  },
  {
    id: OCCUPATION_ALCHEMIST,
    name: {
      [GENDER_MALE]: 'алхимик',
      [GENDER_FEMALE]: 'алхимик',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_ANIMAL_TAMER,
    name: {
      [GENDER_MALE]: 'укротитель',
      [GENDER_FEMALE]: 'укротительница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_ANIMAL_TRAINER,
    name: {
      [GENDER_MALE]: `дрессировщик`,
      [GENDER_FEMALE]: `дрессировщик`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_APPRENTICE,
    name: {
      [GENDER_MALE]: 'подмастерье',
      [GENDER_FEMALE]: 'подмастерье',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_ARISTOCRAT,
    name: {
      [GENDER_MALE]: 'аристократ',
      [GENDER_FEMALE]: 'аристократка',
    },
    probabilityWeight: 6,
  },
  {
    id: OCCUPATION_ARMORER,
    name: {
      [GENDER_MALE]: `бронник`,
      [GENDER_FEMALE]: `бронник`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_ARTISAN,
    name: {
      [GENDER_MALE]: 'ремесленник',
      [GENDER_FEMALE]: 'ремесленница',
    },
    probabilityWeight: 45,
  },
  {
    id: OCCUPATION_ARTIST,
    name: {
      [GENDER_MALE]: 'артист',
      [GENDER_FEMALE]: 'артистка',
    },
    probabilityWeight: 30,
  },
  {
    id: OCCUPATION_ASSASSIN,
    name: {
      [GENDER_MALE]: 'убийца',
      [GENDER_FEMALE]: 'убийца',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_ASTROLOGER,
    name: {
      [GENDER_MALE]: `астролог`,
      [GENDER_FEMALE]: `астролог`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_AUTHOR,
    name: {
      [GENDER_MALE]: 'писатель',
      [GENDER_FEMALE]: 'писательница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BAKER,
    name: {
      [GENDER_MALE]: 'пекарь',
      [GENDER_FEMALE]: 'пекарь',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BANDIT,
    name: {
      [GENDER_MALE]: 'разбойник',
      [GENDER_FEMALE]: 'разбойница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BANKER,
    name: {
      [GENDER_MALE]: 'банкир',
      [GENDER_FEMALE]: 'банкирша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BARBER,
    name: {
      [GENDER_MALE]: 'цирюльник',
      [GENDER_FEMALE]: 'цирюльница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BARDE,
    name: {
      [GENDER_MALE]: 'бард',
      [GENDER_FEMALE]: 'бард',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BARTENDER,
    name: {
      [GENDER_MALE]: 'бармен',
      [GENDER_FEMALE]: 'барменша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BASKET_MAKER,
    name: {
      [GENDER_MALE]: `корзинщик`,
      [GENDER_FEMALE]: `корзинщица`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_BEADLE,
    name: {
      [GENDER_MALE]: `церковный сторож`,
      [GENDER_FEMALE]: `церковный сторож`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_BEEKEEPER,
    name: {
      [GENDER_MALE]: 'пчеловод',
      [GENDER_FEMALE]: 'пчеловодка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BEGGAR,
    name: {
      [GENDER_MALE]: 'нищий',
      [GENDER_FEMALE]: 'нищая',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BLACKSMITH,
    name: {
      [GENDER_MALE]: 'кузнец',
      [GENDER_FEMALE]: 'кузнец',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BOOKBINDER,
    name: {
      [GENDER_MALE]: `переплётчик`,
      [GENDER_FEMALE]: `переплётчица`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_BOOKKEEPER,
    name: {
      [GENDER_MALE]: 'счетовод',
      [GENDER_FEMALE]: 'счетоводка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BOTTLE_MAKER,
    name: {
      [GENDER_MALE]: 'бутылкодел',
      [GENDER_FEMALE]: 'бутылкодел',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BOUNTY_HUNTER,
    name: {
      [GENDER_MALE]: 'охотник за головами',
      [GENDER_FEMALE]: 'охотница за головами',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BREWER,
    name: {
      [GENDER_MALE]: 'пивовар',
      [GENDER_FEMALE]: 'пивоварка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BUREAUCRAT,
    name: {
      [GENDER_MALE]: 'бюрократ',
      [GENDER_FEMALE]: 'бюрократ',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_BUTCHER,
    name: {
      [GENDER_MALE]: `мясник`,
      [GENDER_FEMALE]: `мясник`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_CARAVAN_GUARD,
    name: {
      [GENDER_MALE]: `охранник каравана`,
      [GENDER_FEMALE]: `охранница каравана`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_CARPENTER,
    name: {
      [GENDER_MALE]: 'столяр',
      [GENDER_FEMALE]: 'столярка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_CARTOGRAPHER,
    name: {
      [GENDER_MALE]: 'картограф',
      [GENDER_FEMALE]: 'картограф',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_CARVER,
    name: {
      [GENDER_MALE]: 'резчик',
      [GENDER_FEMALE]: 'резчица',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_CHANDLER,
    name: {
      [GENDER_MALE]: `лавочник`,
      [GENDER_FEMALE]: `лавочница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_CHEESEMAKER,
    name: {
      [GENDER_MALE]: `сыровар`,
      [GENDER_FEMALE]: `сыроварка`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_CHEST_MAKER,
    name: {
      [GENDER_MALE]: `плотник`,
      [GENDER_FEMALE]: `плотница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_CHIMNEY_SWEEPER,
    name: {
      [GENDER_MALE]: `трубочист`,
      [GENDER_FEMALE]: `трубочистка`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_CLOCKSMITH,
    name: {
      [GENDER_MALE]: 'часовщик',
      [GENDER_FEMALE]: 'часовщица',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_COBBLER,
    name: {
      [GENDER_MALE]: 'сапожник',
      [GENDER_FEMALE]: 'сапожница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_CON_ARTIST,
    name: {
      [GENDER_MALE]: 'мошенник',
      [GENDER_FEMALE]: 'мошенница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_CONFECTIONER,
    name: {
      [GENDER_MALE]: `кондитер`,
      [GENDER_FEMALE]: `кондитерша`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_COOK,
    name: {
      [GENDER_MALE]: 'повар',
      [GENDER_FEMALE]: 'повариха',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_COOPER,
    name: {
      [GENDER_MALE]: `бондарь`,
      [GENDER_FEMALE]: `бондарь`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_COSTERMONGER,
    name: {
      [GENDER_MALE]: `лоточник`,
      [GENDER_FEMALE]: `лоточница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_CRIMINAL,
    name: {
      [GENDER_MALE]: 'лиходей',
      [GENDER_FEMALE]: 'лиходейка',
    },
    probabilityWeight: 30,
  },
  {
    id: OCCUPATION_CUTPURSE,
    name: {
      [GENDER_MALE]: `карманник`,
      [GENDER_FEMALE]: `карманница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_DAIRY_MAID,
    name: {
      [GENDER_MALE]: 'молочник',
      [GENDER_FEMALE]: 'молочница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_DISTILLER,
    name: {
      [GENDER_MALE]: `винокур`,
      [GENDER_FEMALE]: `винокур`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_DITCH_DIGGER,
    name: {
      [GENDER_MALE]: `золотарь`,
      [GENDER_FEMALE]: `золотарша`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_DOCTOR,
    name: {
      [GENDER_MALE]: 'доктор',
      [GENDER_FEMALE]: 'докторша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_DRUID,
    name: {
      [GENDER_MALE]: 'друид',
      [GENDER_FEMALE]: 'друидша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_DYER,
    name: {
      [GENDER_MALE]: `красильщик`,
      [GENDER_FEMALE]: `красильщица`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_ENGRAVER,
    name: {
      [GENDER_MALE]: `гравёр`,
      [GENDER_FEMALE]: `гравёрша`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_EXORCIST,
    name: {
      [GENDER_MALE]: 'заклинатель',
      [GENDER_FEMALE]: 'заклинательница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_FALCONER,
    name: {
      [GENDER_MALE]: `сокольничий`,
      [GENDER_FEMALE]: `сокольничья`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_FARMER,
    name: {
      [GENDER_MALE]: 'фермер',
      [GENDER_FEMALE]: 'фермерша',
    },
    probabilityWeight: 36,
  },
  {
    id: OCCUPATION_FISHER,
    name: {
      [GENDER_MALE]: 'рыболов',
      [GENDER_FEMALE]: 'рыболовша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_FLETCHER,
    name: {
      [GENDER_MALE]: 'стрельник',
      [GENDER_FEMALE]: 'стрельница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_FORESTER,
    name: {
      [GENDER_MALE]: `лесник`,
      [GENDER_FEMALE]: `лесничиха`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_FORTUNE_TELLER,
    name: {
      [GENDER_MALE]: 'гадатель',
      [GENDER_FEMALE]: 'гадалка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_FURNITURE_MAKER,
    name: {
      [GENDER_MALE]: `мебельщик`,
      [GENDER_FEMALE]: `мебельщица`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_FURRIER,
    name: {
      [GENDER_MALE]: `скорняк`,
      [GENDER_FEMALE]: `скорнячка`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_GLADIATOR,
    name: {
      [GENDER_MALE]: 'гладиатор',
      [GENDER_FEMALE]: 'гладиаторша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_GLASSBLOWER,
    name: {
      [GENDER_MALE]: `стеклодув`,
      [GENDER_FEMALE]: `стеклодув`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_GLAZIER,
    name: {
      [GENDER_MALE]: `стекольщик`,
      [GENDER_FEMALE]: `стекольщица`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_GLOVEMAKER,
    name: {
      [GENDER_MALE]: `перчаточник`,
      [GENDER_FEMALE]: `перчаточница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_GONGFARMER,
    name: {
      [GENDER_MALE]: `говночист`,
      [GENDER_FEMALE]: `говночистка`,
    },
    probabilityWeight: 1,
  },
  {
    id: OCCUPATION_GRAVEDIGGER,
    name: {
      [GENDER_MALE]: 'могильщик',
      [GENDER_FEMALE]: 'могильщица',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_GUIDE,
    name: {
      [GENDER_MALE]: 'проводник',
      [GENDER_FEMALE]: 'проводница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_HABERDASHER,
    name: {
      [GENDER_MALE]: `галантерейщик`,
      [GENDER_FEMALE]: `галантерейщица`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_HATTER,
    name: {
      [GENDER_MALE]: `шляпник`,
      [GENDER_FEMALE]: `шляпница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_HEALER,
    name: {
      [GENDER_MALE]: `целитель`,
      [GENDER_FEMALE]: `целительница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_HERBALIST,
    name: {
      [GENDER_MALE]: 'травник',
      [GENDER_FEMALE]: 'травница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_HERDER,
    name: {
      [GENDER_MALE]: 'скотовод',
      [GENDER_FEMALE]: 'скотоводка',
    },
    probabilityWeight: 36,
  },
  {
    id: OCCUPATION_HERMIT,
    name: {
      [GENDER_MALE]: 'отшельник',
      [GENDER_FEMALE]: 'отшельница',
    },
    probabilityWeight: 10,
  },
  {
    id: OCCUPATION_HISTORIAN,
    name: {
      [GENDER_MALE]: 'историк',
      [GENDER_FEMALE]: 'историк',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_HUNTER,
    name: {
      [GENDER_MALE]: 'охотник',
      [GENDER_FEMALE]: 'охотница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_INDENTURED_SERVANT,
    name: {
      [GENDER_MALE]: `прислуга`,
      [GENDER_FEMALE]: `прислуга`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_INNKEEPER,
    name: {
      [GENDER_MALE]: 'трактирщик',
      [GENDER_FEMALE]: 'трактирщица',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_JESTER,
    name: {
      [GENDER_MALE]: 'шут',
      [GENDER_FEMALE]: 'шут',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_JEWELER,
    name: {
      [GENDER_MALE]: `ювелир`,
      [GENDER_FEMALE]: `ювелирщица`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_KNIGHT,
    name: {
      [GENDER_MALE]: 'рыцарь',
      [GENDER_FEMALE]: 'рыцарша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_KOJEMYAKA,
    name: {
      [GENDER_MALE]: `кожемяка`,
      [GENDER_FEMALE]: `кожемяка`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_LABORER,
    name: {
      [GENDER_MALE]: 'чернорабочий',
      [GENDER_FEMALE]: 'чернорабочая',
    },
    probabilityWeight: 90,
  },
  {
    id: OCCUPATION_LAMPLIGHTER,
    name: {
      [GENDER_MALE]: 'фонарщик',
      [GENDER_FEMALE]: 'фонарщица',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_LANDLORD,
    name: {
      [GENDER_MALE]: 'арендодатель',
      [GENDER_FEMALE]: 'арендодательница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_LAWMAN,
    name: {
      [GENDER_MALE]: 'судебный пристав',
      [GENDER_FEMALE]: 'судебный пристав',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_LAWYER,
    name: {
      [GENDER_MALE]: 'юрист',
      [GENDER_FEMALE]: 'юрист',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_LIBRARIAN,
    name: {
      [GENDER_MALE]: 'библиотекарь',
      [GENDER_FEMALE]: 'библиотекарша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_LIGHTHOUSE_KEEPER,
    name: {
      [GENDER_MALE]: 'смотритель маяка',
      [GENDER_FEMALE]: 'смотрительница маяка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_LOCKSMITH,
    name: {
      [GENDER_MALE]: `слесарь`,
      [GENDER_FEMALE]: `слесарь`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_LOOKOUT,
    name: {
      [GENDER_MALE]: 'дозорный',
      [GENDER_FEMALE]: 'дозорная',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_LUMBERJACK,
    name: {
      [GENDER_MALE]: 'лесоруб',
      [GENDER_FEMALE]: 'лесорубка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_MARINER,
    name: {
      [GENDER_MALE]: `мореплаватель`,
      [GENDER_FEMALE]: `мореплавательница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_MERCENARY,
    name: {
      [GENDER_MALE]: 'наёмник',
      [GENDER_FEMALE]: 'наёмница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_MERCHANT,
    name: {
      [GENDER_MALE]: `купец`,
      [GENDER_FEMALE]: `купчиха`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_MESSENGER,
    name: {
      [GENDER_MALE]: 'посыльный',
      [GENDER_FEMALE]: 'посыльная',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_MILLER,
    name: {
      [GENDER_MALE]: `мельник`,
      [GENDER_FEMALE]: `мельник`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_MINER,
    name: {
      [GENDER_MALE]: 'шахтёр',
      [GENDER_FEMALE]: 'шахтёрка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_MINSTREL,
    name: {
      [GENDER_MALE]: `менестрель`,
      [GENDER_FEMALE]: `менестрель`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_MIRROR_MAKER,
    name: {
      [GENDER_MALE]: `зеркальщик`,
      [GENDER_FEMALE]: `зеркальщица`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_MONEYLENDER,
    name: {
      [GENDER_MALE]: `ростовщик`,
      [GENDER_FEMALE]: `ростовщица`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_MORTICIAN,
    name: {
      [GENDER_MALE]: 'гробовщик',
      [GENDER_FEMALE]: 'гробовщица',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_NAVIGATOR,
    name: {
      [GENDER_MALE]: `штурман`,
      [GENDER_FEMALE]: `штурманша`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_NOBLE,
    name: {
      [GENDER_MALE]: 'дворянин',
      [GENDER_FEMALE]: 'дворянка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_NUN,
    name: {
      [GENDER_MALE]: 'монах',
      [GENDER_FEMALE]: 'монахиня',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_NURSE,
    name: {
      [GENDER_MALE]: 'нянь',
      [GENDER_FEMALE]: 'няня',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_OPTICIAN,
    name: {
      [GENDER_MALE]: `оптик`,
      [GENDER_FEMALE]: `оптик`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_OSTLER,
    name: {
      [GENDER_MALE]: `конюх`,
      [GENDER_FEMALE]: `конюх`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_OUTCAST,
    name: {
      [GENDER_MALE]: 'изгой',
      [GENDER_FEMALE]: 'изгой',
    },
    probabilityWeight: 10,
  },
  {
    id: OCCUPATION_OUTLAW,
    name: {
      [GENDER_MALE]: `преступник`,
      [GENDER_FEMALE]: `преступница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_OVER_BIRD,
    name: {
      [GENDER_MALE]: `печник`,
      [GENDER_FEMALE]: `печница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_OWLBEAR_BREEDER,
    name: {
      [GENDER_MALE]: 'разводчик совомедов',
      [GENDER_FEMALE]: 'разводчица совомедов',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_PAINTER,
    name: {
      [GENDER_MALE]: `маляр`,
      [GENDER_FEMALE]: `маляр`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_PEDDLER,
    name: {
      [GENDER_MALE]: `коробейник`,
      [GENDER_FEMALE]: `коробейница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_POLITICIAN,
    name: {
      [GENDER_MALE]: 'политик',
      [GENDER_FEMALE]: 'политик',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_PORTRAIT_PAINTER,
    name: {
      [GENDER_MALE]: 'художник-портретист',
      [GENDER_FEMALE]: 'художница-портретистка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_POTIONEER,
    name: {
      [GENDER_MALE]: 'зельевар',
      [GENDER_FEMALE]: 'зельеварка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_POTTER,
    name: {
      [GENDER_MALE]: `гончар`,
      [GENDER_FEMALE]: `гончар`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_PRIEST,
    name: {
      [GENDER_MALE]: 'священник',
      [GENDER_FEMALE]: 'священница',
    },
    probabilityWeight: 30,
  },
  {
    id: OCCUPATION_RANGER,
    name: {
      [GENDER_MALE]: 'следопыт',
      [GENDER_FEMALE]: 'следопытка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_RAT_CATCHER,
    name: {
      [GENDER_MALE]: 'крысолов',
      [GENDER_FEMALE]: 'крысоловка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_REFUGEE,
    name: {
      [GENDER_MALE]: 'беженец',
      [GENDER_FEMALE]: 'беженка',
    },
    probabilityWeight: 10,
  },
  {
    id: OCCUPATION_RESEARCHER,
    name: {
      [GENDER_MALE]: 'исследователь',
      [GENDER_FEMALE]: 'исследовательница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_ROOFER,
    name: {
      [GENDER_MALE]: 'кровельщик',
      [GENDER_FEMALE]: 'кровельщица',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_ROPE_MAKER,
    name: {
      [GENDER_MALE]: `канатный дел мастер`,
      [GENDER_FEMALE]: `канатный дел мастер`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_SADDLER,
    name: {
      [GENDER_MALE]: `шорник`,
      [GENDER_FEMALE]: `шорница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_SAILOR,
    name: {
      [GENDER_MALE]: 'моряк',
      [GENDER_FEMALE]: 'морячка',
    },
    probabilityWeight: 30,
  },
  {
    id: OCCUPATION_SALTAR,
    name: {
      [GENDER_MALE]: `солевар`,
      [GENDER_FEMALE]: `солеварка`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_SCHOLAR,
    name: {
      [GENDER_MALE]: 'учёный',
      [GENDER_FEMALE]: 'учёная',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_SCRIBE,
    name: {
      [GENDER_MALE]: 'писец',
      [GENDER_FEMALE]: 'писец',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_SCULPTOR,
    name: {
      [GENDER_MALE]: 'скульптор',
      [GENDER_FEMALE]: 'скульпторша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_SERVANT,
    name: {
      [GENDER_MALE]: 'слуга',
      [GENDER_FEMALE]: 'слуга',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_SHAMAN,
    name: {
      [GENDER_MALE]: 'шаман',
      [GENDER_FEMALE]: 'шаманка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_SHEPPERD,
    name: {
      [GENDER_MALE]: 'пастух',
      [GENDER_FEMALE]: 'пастушка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_SHERIFF,
    name: {
      [GENDER_MALE]: 'капитан стражи',
      [GENDER_FEMALE]: 'капитан стражи',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_SKINNER,
    name: {
      [GENDER_MALE]: `кожевник`,
      [GENDER_FEMALE]: `кожевница`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_SLAVE,
    name: {
      [GENDER_MALE]: 'раб',
      [GENDER_FEMALE]: 'рабыня',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_SMUGGLER,
    name: {
      [GENDER_MALE]: `контрабандист`,
      [GENDER_FEMALE]: `контрабандистка`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_SOAPMAKER,
    name: {
      [GENDER_MALE]: 'мыловар',
      [GENDER_FEMALE]: 'мыловарка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_SOLDIER,
    name: {
      [GENDER_MALE]: 'солдат',
      [GENDER_FEMALE]: 'солдатка',
    },
    probabilityWeight: 30,
  },
  {
    id: OCCUPATION_SQUIRE,
    name: {
      [GENDER_MALE]: 'оруженосец',
      [GENDER_FEMALE]: 'оруженоска',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_STABLE_HAND,
    name: {
      [GENDER_MALE]: 'конюх',
      [GENDER_FEMALE]: 'конюх',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_STONEMASON,
    name: {
      [GENDER_MALE]: 'каменщик',
      [GENDER_FEMALE]: 'каменщица',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_TAILOR,
    name: {
      [GENDER_MALE]: 'портной',
      [GENDER_FEMALE]: 'портниха',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_TANNER,
    name: {
      [GENDER_MALE]: 'дубильщик',
      [GENDER_FEMALE]: 'дубильщица',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_TAX_COLLECTOR,
    name: {
      [GENDER_MALE]: `мытарь`,
      [GENDER_FEMALE]: `мытарь`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_THIEF,
    name: {
      [GENDER_MALE]: 'вор',
      [GENDER_FEMALE]: 'воровка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_THUG,
    name: {
      [GENDER_MALE]: 'головорез',
      [GENDER_FEMALE]: 'головорезка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_TINKERER,
    name: {
      [GENDER_MALE]: 'лудильщик',
      [GENDER_FEMALE]: 'лудильщик',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_TOWN_CRYER,
    name: {
      [GENDER_MALE]: 'глашатай',
      [GENDER_FEMALE]: 'глашатай',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_TOWN_GUARD,
    name: {
      [GENDER_MALE]: 'стражник',
      [GENDER_FEMALE]: 'стражница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_TOYMAKER,
    name: {
      [GENDER_MALE]: 'игрушечник',
      [GENDER_FEMALE]: 'игрушечница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_TRADER,
    name: {
      [GENDER_MALE]: 'торговец',
      [GENDER_FEMALE]: 'торговка',
    },
    probabilityWeight: 30,
  },
  {
    id: OCCUPATION_TRAPPER,
    name: {
      [GENDER_MALE]: 'траппер',
      [GENDER_FEMALE]: 'трапперша',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_TREASURE_HUNTER,
    name: {
      [GENDER_MALE]: 'охотник за сокровищами',
      [GENDER_FEMALE]: 'охотница за сокровищами',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_VAGRANT,
    name: {
      [GENDER_MALE]: `бродяга`,
      [GENDER_FEMALE]: `бродяга`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_VARNISHER,
    name: {
      [GENDER_MALE]: `лакировщик`,
      [GENDER_FEMALE]: `лакировщица`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_WAINWRIGHT,
    name: {
      [GENDER_MALE]: `колесник`,
      [GENDER_FEMALE]: `колесник`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_WANDERER,
    name: {
      [GENDER_MALE]: 'странник',
      [GENDER_FEMALE]: 'странница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_WASHERWOMAN,
    name: {
      [GENDER_MALE]: 'прачка',
      [GENDER_FEMALE]: 'прачка',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_WEAVER,
    name: {
      [GENDER_MALE]: `ткач`,
      [GENDER_FEMALE]: `ткачиха`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_WIZARD,
    name: {
      [GENDER_MALE]: 'волшебник',
      [GENDER_FEMALE]: 'волшебница',
    },
    probabilityWeight: 15,
  },
  {
    id: OCCUPATION_WIZARDS_APPRENTICE,
    name: {
      [GENDER_MALE]: `ученик волшебника`,
      [GENDER_FEMALE]: `ученица волшебника`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_WOODCUTTER,
    name: {
      [GENDER_MALE]: `дровосек`,
      [GENDER_FEMALE]: `дровосек`,
    },
    probabilityWeight: 5,
  },
  {
    id: OCCUPATION_WRESTLER,
    name: {
      [GENDER_MALE]: 'борец',
      [GENDER_FEMALE]: 'борец',
    },
    probabilityWeight: 15,
  },
]

export default occupationList
export const occupationCollection = listToCollectionById(occupationList)
