const {
  BACKGROUND_ENTERTAINER,
  BACKGROUND_GLADIATOR,
} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  GEAR_CLOTHES_COSTUME,
  GEAR_NET,
  GEAR_POUCH,
  GEAR_TRIDENT,
} = require('./../../gearIdList')
const entertainer = require('./entertainer')

module.exports = {
  ...entertainer,
  id: BACKGROUND_GLADIATOR,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Гладиатор',
    [GENDER_FEMALE]: 'Гладиаторша',
  },
  nameEn: 'Gladiator',
  description: [
    {
      header: 'Гладиатор',
      text: `Гладиатор — такой же [артист](BACKGROUND:${BACKGROUND_ENTERTAINER}) как менестрель или циркач, обученный превращать бой в искусство, от которого ликуют толпы. Вашим амплуа являются яркие сражения, хотя Вы можете быть при этом акробатом или актёром.

При использовании умения **«По многочисленным просьбам»** Вы можете найти место для выступления в месте, где развлекают сражениями — арену гладиаторов или тайный бойцовский клуб.

Вы можете заменить музыкальный инструмент в снаряжении на недорогое, но необычное оружие, такое как [трезубец](GEAR:${GEAR_TRIDENT}) или [сеть](GEAR:${GEAR_NET}).`,
      source: {
        id: SOURCE_PHB,
        page: 132,
      },
    },
    ...entertainer.description.slice(1),
  ],
  equipment: `
* Музыкальный инструмент (на Ваш выбор) или необычное оружие, такое как [трезубец](GEAR:${GEAR_TRIDENT}) или [сеть](GEAR:${GEAR_NET}),
* Подарок от поклонницы (любовное письмо, локон волос или безделушка),
* [Костюм](GEAR:${GEAR_CLOTHES_COSTUME}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 15 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 132,
  },
}
