import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Character from '@/components/CharacterGenerator/components/Character'
import HERO_ID from '@/components/CharacterGenerator/constants/HERO_ID'

import action_regenerateHero from '@/components/CharacterGenerator/store/actions/regenerateHero'

const HeroContainer = ({ characterCollection, regenerateHero, ...rest }) => {
  const character = characterCollection[HERO_ID]

  return character
    ? (
      <Character
        className='Hero'
        character={character}
        {...rest}
      />
    )
    : null
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        regenerateHero: action_regenerateHero,
      },
      dispatch,
    ),
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  characterCollection: state.characterCollection,
})

export default connect(mapStateToProps, mapDispatchToProps)(HeroContainer)
