import listToCollectionById from '@/utils/listToCollectionById'

export const FACE_GOOD_ATTRACTIVE = 'attractive'
export const FACE_GOOD_BEAUTIFUL = 'beautiful'
export const FACE_GOOD_CUTE = 'cute'
export const FACE_GOOD_FANCIABLE = 'fanciable'
export const FACE_GOOD_GLAMOROUS = 'glamorous'
export const FACE_GOOD_INNOCENT = 'innocent'
export const FACE_GOOD_MAGNIFICENT = 'magnificent'
export const FACE_GOOD_PRETTY = 'pretty'
export const FACE_GOOD_SIGHTLY = 'sightly'
export const FACE_GOOD_STUNNING = 'stunning'

const faceGoodList = [
  {
    name: `милое`,
    id: FACE_GOOD_CUTE,
    probabilityWeight: 4,
  },
  {
    name: `привлекательное`,
    id: FACE_GOOD_ATTRACTIVE,
    probabilityWeight: 5,
  },
  {
    name: `прекрасное`,
    id: FACE_GOOD_BEAUTIFUL,
    probabilityWeight: 5,
  },
  {
    name: `обаятельное`,
    id: FACE_GOOD_GLAMOROUS,
    probabilityWeight: 3,
  },
  {
    name: `невинное`,
    id: FACE_GOOD_INNOCENT,
    probabilityWeight: 3,
  },
  {
    name: `божественное`,
    id: FACE_GOOD_STUNNING,
    probabilityWeight: 2,
  },
  {
    name: `необычное`,
    id: FACE_GOOD_FANCIABLE,
    probabilityWeight: 3,
  },
  {
    name: `приятное`,
    id: FACE_GOOD_PRETTY,
    probabilityWeight: 3,
  },
  {
    name: `великолепное`,
    id: FACE_GOOD_MAGNIFICENT,
    probabilityWeight: 2,
  },
  {
    name: `красивое`,
    id: FACE_GOOD_SIGHTLY,
    probabilityWeight: 3,
  },
]

export default faceGoodList
export const faceGoodCollection = listToCollectionById(faceGoodList)
