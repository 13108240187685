import listToCollectionById from '@/utils/listToCollectionById'

export const FIGHTER_CHOICE_ACADEMY = 'fighter_choice_academy'
export const FIGHTER_CHOICE_SQUIRE = 'fighter_choice_squire'
export const FIGHTER_CHOICE_REVENGE_MONSTERS = 'fighter_choice_revenge_monsters'
export const FIGHTER_CHOICE_ARMY = 'fighter_choice_army'
export const FIGHTER_CHOICE_CROSSING = 'fighter_choice_crossing'
export const FIGHTER_CHOICE_INNER_TALENT = 'fighter_choice_inner_talent'

const fighterChoiceList = [
  {
    name: `Я ★хотел★ отточить мои боевые навыки, поэтому ★поступил★ в военное училище.`,
    id: FIGHTER_CHOICE_ACADEMY,
    probabilityWeight: 1,
  },
  {
    name: `Я ★был★ оруженосцем у рыцаря, который научил меня сражаться, ухаживать за конём и вести себя с честью. Я ★решил★ выбрать для себя этот путь.`,
    id: FIGHTER_CHOICE_SQUIRE,
    probabilityWeight: 1,
  },
  {
    name: `Ужасные монстры, обрушившиеся на моё поселение, убили кого-то, кого я ★любил★. Я ★взял★ в руки оружие, чтобы убить этих тварей и подобных им.`,
    id: FIGHTER_CHOICE_REVENGE_MONSTERS,
    probabilityWeight: 1,
  },
  {
    name: `Я ★вступил★ в армию и ★научился★ сражаться как член отряда.`,
    id: FIGHTER_CHOICE_ARMY,
    probabilityWeight: 1,
  },
  {
    name: `Я ★вырос★ сражаясь, и ★оттачивал★ свои навыки, защищаясь от людей, которые переходили мне дорогу.`,
    id: FIGHTER_CHOICE_CROSSING,
    probabilityWeight: 1,
  },
  {
    name: `Я всегда ★мог★ взять практически любое оружие и ★знал★, как с ним обращаться.`,
    id: FIGHTER_CHOICE_INNER_TALENT,
    probabilityWeight: 1,
  },
]

export default fighterChoiceList
export const fighterChoiceCollection = listToCollectionById(fighterChoiceList)
