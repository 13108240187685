import listToCollectionById from '@/utils/listToCollectionById'
import { VARIANT_CRIME, VARIANT_DEATH } from './variantIdList'

export const TRAGEDY_BLIGHT_BROTHER = 'tragedy_blight_brother'
export const TRAGEDY_BLIGHT_FAMILY_MEMBER = 'tragedy_blight_family_member'
export const TRAGEDY_BLIGHT_SISTER = 'tragedy_blight_sister'
export const TRAGEDY_CITY_MOVE_OUT = 'tragedy_city_move_out'
export const TRAGEDY_CITY_REBUILD = 'tragedy_city_rebuild'
export const TRAGEDY_DARK_MAGIC = 'tragedy_dark_magic'
export const TRAGEDY_DEATH_FAMILY_MEMBER = 'tragedy_death_family_member'
export const TRAGEDY_DEATH_FRIEND = 'tragedy_death_friend'
export const TRAGEDY_END_FRIENDSHIP_BY_FRIEND = 'tragedy_end_friendship_by_friend'
export const TRAGEDY_END_FRIENDSHIP_BY_MISUNDERSTANDING = 'tragedy_end_friendship_by_misunderstanding'
export const TRAGEDY_END_FRIENDSHIP_BY_YOU = 'tragedy_end_friendship_by_you'
export const TRAGEDY_EXILE_NEW_HOME = 'tragedy_exile_new_home'
export const TRAGEDY_EXILE_WASTELAND = 'tragedy_exile_wasteland'
export const TRAGEDY_JAIL = 'tragedy_jail'
export const TRAGEDY_LABOR = 'tragedy_labor'
export const TRAGEDY_LOST_ALL = 'tragedy_lost_all'
export const TRAGEDY_LOVER_DEAD_CURRENT_GUILTY = 'tragedy_lover_dead_current_guilty'
export const TRAGEDY_LOVER_DEAD_CURRENT_MAYBE_GUILTY = 'tragedy_lover_dead_current_maybe_guilty'
export const TRAGEDY_LOVER_DEAD_CURRENT_PURE = 'tragedy_lover_dead_current_pure'
export const TRAGEDY_LOVER_DEAD_POTENTIAL_GUILTY = 'tragedy_lover_dead_potential_guilty'
export const TRAGEDY_LOVER_DEAD_POTENTIAL_MAYBE_GUILTY = 'tragedy_lover_dead_potential_maybe_guilty'
export const TRAGEDY_LOVER_DEAD_POTENTIAL_PURE = 'tragedy_lover_dead_potential_pure'
export const TRAGEDY_LOVER_DISSAPEAR = 'tragedy_lover_dissapear'
export const TRAGEDY_OFFEND = 'tragedy_offend'
export const TRAGEDY_ROMANTIC_STOP_LOVER = 'tragedy_romantic_stop_lover'
export const TRAGEDY_ROMANTIC_STOP_PEACE = 'tragedy_romantic_stop_peace'
export const TRAGEDY_ROMANTIC_STOP_YOU = 'tragedy_romantic_stop_you'
export const TRAGEDY_SCANDAL = 'tragedy_scandal'
export const TRAGEDY_SLAVE_GALLEY = 'tragedy_slave_galley'

const friendshipEndText = `Дружба закончилась плохо, и кто-то другой теперь настроен враждебно к ★нему★.`
const crimeInnocentText = `★ПЕРСОНАЖ★ ★был★ ★осуждён★ на тюремное заключение за ${VARIANT_CRIME}, которого ★он★ не ★совершал★, и ★провёл★ ★DICE★ лет`
const warRuinText = `Война разорила ★его★ родное поселение, превратив всё в руины.`
const hungerText = `Ужасная порча поразила урожай ★его★ поселения, и многим пришлось голодать.`
const familyShameText = `что ужасно опозорило ★его★ в глазах семьи. ★ПЕРСОНАЖ★ для семьи теперь в лучшем случае никто. Впрочем, со временем они могут простить ★его★.`
const exiledNoReasonText = `По неизвестным для ★него★ причинам ★он★ ★был★ ★изгнан★ из родного поселения.`
const loverDeathGuiltyText = `★ПЕРСОНАЖ★ ★виновен★ в этом.`
const loverDeathGuiltyPartlyText = `★ПЕРСОНАЖ★ косвенно ★виновен★ в этом.`
const loverCurrentDeathText = `Нынешний романтический партнёр ${VARIANT_DEATH}.`
const loverPotentialDeathText = `Потенциальный романтический партнёр ${VARIANT_DEATH}.`

const tragedyList =  [
  {
    name: `Член семьи ${VARIANT_DEATH}.`,
    id: TRAGEDY_DEATH_FAMILY_MEMBER,
    detailDictIdList: VARIANT_DEATH,
    characterGenerationParams: {
      sameRace: true,
      relationText: 'погибший член семьи',
    },
    probabilityWeight: 48,
  },
  {
    name: `Близкий друг ${VARIANT_DEATH}.`,
    id: TRAGEDY_DEATH_FRIEND,
    detailDictIdList: VARIANT_DEATH,
    characterGenerationParams: {
      relationText: '★погибший★ ★близкий★ ★друг★',
    },
    probabilityWeight: 48,
  },
  {
    name: `${friendshipEndText} Причина — недопонимание.`,
    id: TRAGEDY_END_FRIENDSHIP_BY_MISUNDERSTANDING,
    characterGenerationParams: {
      relationText: '★бывший★ ★друг★',
    },
    probabilityWeight: 16,
  },
  {
    name: `${friendshipEndText} ★ПЕРСОНАЖ★ понимает, что причина — ★его★ поступок.`,
    id: TRAGEDY_END_FRIENDSHIP_BY_YOU,
    characterGenerationParams: {
      relationText: '★бывший★ ★друг★',
    },
    probabilityWeight: 16,
  },
  {
    name: `${friendshipEndText} ★ПЕРСОНАЖ★ понимает, что причина — поступок ★друга★.`,
    id: TRAGEDY_END_FRIENDSHIP_BY_FRIEND,
    characterGenerationParams: {
      relationText: '★бывший★ ★друг★',
    },
    probabilityWeight: 16,
  },
  {
    name: `Из-за бедствия ★ПЕРСОНАЖ★ ★потерял★ всё, чем ★владел★, и ★ему★ пришлось начать жизнь с нуля.`,
    id: TRAGEDY_LOST_ALL,
    probabilityWeight: 48,
  },
  {
    name: `${crimeInnocentText} на каторге.`,
    id: TRAGEDY_LABOR,
    detailDictIdList: VARIANT_CRIME,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 16,
  },
  {
    name: `${crimeInnocentText} в тюрьме.`,
    id: TRAGEDY_JAIL,
    detailDictIdList: VARIANT_CRIME,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 16,
  },
  {
    name: `${crimeInnocentText} гребцом на судне.`,
    id: TRAGEDY_SLAVE_GALLEY,
    detailDictIdList: VARIANT_CRIME,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 16,
  },
  {
    name: `${warRuinText} Впоследствии ★ПЕРСОНАЖ★ ★помог★ отстроить город.`,
    id: TRAGEDY_CITY_REBUILD,
    probabilityWeight: 24,
  },
  {
    name: `${warRuinText} Впоследствии ★ПЕРСОНАЖ★ ★отправился★ в другое место.`,
    id: TRAGEDY_CITY_MOVE_OUT,
    probabilityWeight: 24,
  },
  {
    name: `Возлюбленный исчез без следа. С тех пор ★ПЕРСОНАЖ★ ★ищет★ этого человека.`,
    id: TRAGEDY_LOVER_DISSAPEAR,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: '★возлюбленный★',
    },
    probabilityWeight: 48,
  },
  {
    name: `${hungerText} ★ПЕРСОНАЖ★ ★потерял★ брата.`,
    id: TRAGEDY_BLIGHT_BROTHER,
    characterGenerationParams: {
      sameRace: true,
      relationText: 'брат',
    },
    probabilityWeight: 16,
  },
  {
    name: `${hungerText} ★ПЕРСОНАЖ★ ★потерял★ сестру.`,
    id: TRAGEDY_BLIGHT_SISTER,
    characterGenerationParams: {
      sameRace: true,
      relationText: 'сестра',
    },
    probabilityWeight: 16,
  },
  {
    name: `${hungerText} ★ПЕРСОНАЖ★ ★потерял★ члена семьи.`,
    id: TRAGEDY_BLIGHT_FAMILY_MEMBER,
    characterGenerationParams: {
      sameRace: true,
      relationText: 'член семьи',
    },
    probabilityWeight: 16,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★вовлечён★ в скандал, ${familyShameText}`,
    id: TRAGEDY_SCANDAL,
    probabilityWeight: 16,
  },
  {
    name: `★ПЕРСОНАЖ★ ★связался★ с тёмной магией, ${familyShameText}`,
    id: TRAGEDY_DARK_MAGIC,
    probabilityWeight: 16,
  },
  {
    name: `★ПЕРСОНАЖ★ ★оскорбил★ кого-то важного, ${familyShameText}`,
    id: TRAGEDY_OFFEND,
    characterGenerationParams: {
      relationText: 'оскоблённая важная персона',
    },
    probabilityWeight: 16,
  },
  {
    name: `${exiledNoReasonText} ★ПЕРСОНАЖ★ некоторое время ★скитался★ по диким землям.`,
    id: TRAGEDY_EXILE_WASTELAND,
    probabilityWeight: 24,
  },
  {
    name: `${exiledNoReasonText} ★ПЕРСОНАЖ★ ★нашёл★ новое место для жизни.`,
    id: TRAGEDY_EXILE_NEW_HOME,
    probabilityWeight: 24,
  },
  {
    name: `★ПЕРСОНАЖ★ завершил роман, потому что романтический партнёр обидел ★его★. `,
    id: TRAGEDY_ROMANTIC_STOP_LOVER,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: 'бывший романтический партнёр',
    },
    probabilityWeight: 16,
  },
  {
    name: `★ПЕРСОНАЖ★ ★обидел★ своего романтического партнёра и на этом роман завершился.`,
    id: TRAGEDY_ROMANTIC_STOP_YOU,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: 'бывший романтический партнёр',
    },
    probabilityWeight: 16,
  },
  {
    name: `Роман завершился по обоюдному согласию.`,
    id: TRAGEDY_ROMANTIC_STOP_PEACE,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: 'бывший романтический партнёр',
    },
    probabilityWeight: 16,
  },
  {
    name: `${loverCurrentDeathText} ${loverDeathGuiltyText}`,
    id: TRAGEDY_LOVER_DEAD_CURRENT_GUILTY,
    detailDictIdList: VARIANT_DEATH,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: 'погибший романтический партнёр',
    },
    probabilityWeight: 1,
  },
  {
    name: `${loverCurrentDeathText} ${loverDeathGuiltyPartlyText}`,
    id: TRAGEDY_LOVER_DEAD_CURRENT_MAYBE_GUILTY,
    detailDictIdList: VARIANT_DEATH,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: 'погибший романтический партнёр',
    },
    probabilityWeight: 1,
  },
  {
    name: loverCurrentDeathText,
    id: TRAGEDY_LOVER_DEAD_CURRENT_PURE,
    detailDictIdList: VARIANT_DEATH,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: 'погибший романтический партнёр',
    },
    probabilityWeight: 22,
  },
  {
    name: `${loverPotentialDeathText} ${loverDeathGuiltyText}`,
    id: TRAGEDY_LOVER_DEAD_POTENTIAL_MAYBE_GUILTY,
    detailDictIdList: VARIANT_DEATH,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: 'погибший романтический партнёр',
    },
    probabilityWeight: 1,
  },
  {
    name: `${loverPotentialDeathText} ${loverDeathGuiltyPartlyText}`,
    id: TRAGEDY_LOVER_DEAD_POTENTIAL_PURE,
    detailDictIdList: VARIANT_DEATH,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: 'погибший романтический партнёр',
    },
    probabilityWeight: 1,
  },
  {
    name: loverPotentialDeathText,
    id: TRAGEDY_LOVER_DEAD_POTENTIAL_GUILTY,
    detailDictIdList: VARIANT_DEATH,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: 'погибший романтический партнёр',
    },
    probabilityWeight: 22,
  },
]

export default tragedyList
export const tragedyCollection = listToCollectionById(tragedyList)
