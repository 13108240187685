import React from 'react'
import { connect } from 'react-redux'

import CharacterListComponent from './CharacterListComponent'

const CharacterListContainer = props => (
  <CharacterListComponent {...props}/>
)

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  sideCharacterIdList: state.sideCharacterIdList,
})

export default connect(mapStateToProps)(CharacterListContainer)
