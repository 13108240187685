import listToCollectionById from '@/utils/listToCollectionById'
import {CREATURE_GHOUL, CREATURE_GHOST} from '@/constants/creatureIdList'

import {
  VARIANT_DEATH,
  VARIANT_RACE,
  VARIANT_GOD,
  VARIANT_OUTER_PLANE,
} from './variantIdList'

export const SUPERNATURAL_EVENT_CHARDMED_FEY = 'supernatural_event_chardmed_fey'
export const SUPERNATURAL_EVENT_SAW_DEMON = 'supernatural_event_saw_demon'
export const SUPERNATURAL_EVENT_TEMPTED_DEVIL = 'supernatural_event_tempted_devil'
export const SUPERNATURAL_EVENT_WAKE_UP_STREET = 'supernatural_event_wake_up_street'
export const SUPERNATURAL_EVENT_SACRED_PLACE = 'supernatural_event_sacred_place'
export const SUPERNATURAL_EVENT_OMEN_RED_COMET = 'supernatural_event_omen_red_comet'
export const SUPERNATURAL_EVENT_OMEN_FACE_BLIZZARD = 'supernatural_event_omen_face_blizzard'
export const SUPERNATURAL_EVENT_OMEN_STRANGE = 'supernatural_event_omen_strange'
export const SUPERNATURAL_EVENT_GOD_ESCAPE = 'supernatural_event_god_escape'
export const SUPERNATURAL_EVENT_MIRACLE = 'supernatural_event_miracle'
export const SUPERNATURAL_EVENT_HAUNTED_HOUSE = 'supernatural_event_haunted_house'
export const SUPERNATURAL_EVENT_POSSESSED_CELESTIAL = 'supernatural_event_possessed_celestial'
export const SUPERNATURAL_EVENT_POSSESSED_DEVIL = 'supernatural_event_possessed_devil'
export const SUPERNATURAL_EVENT_POSSESSED_DEMON = 'supernatural_event_possessed_demon'
export const SUPERNATURAL_EVENT_POSSESSED_FEY = 'supernatural_event_possessed_fey'
export const SUPERNATURAL_EVENT_POSSESSED_ELEMENTAL = 'supernatural_event_possessed_elemental'
export const SUPERNATURAL_EVENT_POSSESSED_UNDEAD = 'supernatural_event_possessed_undead'
export const SUPERNATURAL_EVENT_GHOST = 'supernatural_event_ghost'
export const SUPERNATURAL_EVENT_GHOUL = 'supernatural_event_ghoul'
export const SUPERNATURAL_EVENT_DREAM_CELESTIAL = 'supernatural_event_dream_celestial'
export const SUPERNATURAL_EVENT_DREAM_DEITY = 'supernatural_event_dream_deity'
export const SUPERNATURAL_EVENT_FEYWILD = 'supernatural_event_feywild'
export const SUPERNATURAL_EVENT_SHADOW = 'supernatural_event_shadow'
export const SUPERNATURAL_EVENT_PORTAL = 'supernatural_event_portal'

const omenText = `Для ★него★ очевидно, что это — некое предзнаменование.`
const possessionText = `★ПЕРСОНАЖ★ ★был★ недолго ★одержим★`
const dreamText = `о надвигающейся угрозе.`

const supernaturalEventList = [
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★околдован★ феей и ★пленён★ на ★DICE★ лет, прежде чем ★сумел★ освободиться.`,
    id: SUPERNATURAL_EVENT_CHARDMED_FEY,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 6,
  },
  {
    name: `★ПЕРСОНАЖ★ ★видел★ демона и ★сумел★ сбежать прежде, чем он смог что-либо совершить с ★ним★.`,
    id: SUPERNATURAL_EVENT_SAW_DEMON,
    probabilityWeight: 6,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★соблазнён★ дьяволом. Пройдите испытание Мудрости СЛ 10. При провале ★его★ мировоззрение склоняется на один пункт в сторону зла (если оно ещё не злое), и ★ПЕРСОНАЖ★ ★начинает★ игру с дополнительными ★DICE★ зм.`,
    id: SUPERNATURAL_EVENT_TEMPTED_DEVIL,
    dice: {
      diceCount: 1,
      diceType: 20,
      diceBonus: 50,
    },
    probabilityWeight: 6,
  },
  {
    name: `Однажды утром ★ПЕРСОНАЖ★ ★проснулся★ за много миль от своего дома, не имея понятия, как это случилось.`,
    id: SUPERNATURAL_EVENT_WAKE_UP_STREET,
    probabilityWeight: 6,
  },
  {
    name: `★ПЕРСОНАЖ★ ★посетил★ некое священное место, где ★почувствовал★ божественное присутствие ${VARIANT_GOD}.`,
    id: SUPERNATURAL_EVENT_SACRED_PLACE,
    detailDictIdList: VARIANT_GOD,
    probabilityWeight: 12,
  },
  {
    name: `★ПЕРСОНАЖ★ ★видел★ красную комету. ${omenText}`,
    id: SUPERNATURAL_EVENT_OMEN_RED_COMET,
    probabilityWeight: 4,
  },
  {
    name: `★ПЕРСОНАЖ★ ★видел★ лицо, появившееся среди метели. ${omenText}`,
    id: SUPERNATURAL_EVENT_OMEN_FACE_BLIZZARD,
    probabilityWeight: 4,
  },
  {
    name: `★ПЕРСОНАЖ★ ★видел★ странное событие. ${omenText}`,
    id: SUPERNATURAL_EVENT_OMEN_STRANGE,
    probabilityWeight: 4,
  },
  {
    name: `★ПЕРСОНАЖ★ чуть было не ${VARIANT_DEATH} и ★верит★, что ★его★ спасло вмешательство ${VARIANT_GOD}.`,
    id: SUPERNATURAL_EVENT_GOD_ESCAPE,
    detailDictIdList: [
      VARIANT_DEATH,
      VARIANT_GOD,
    ],
    probabilityWeight: 12,
  },
  {
    name: `★ПЕРСОНАЖ★ ★стал★ свидетелем небольшого чуда.`,
    id: SUPERNATURAL_EVENT_MIRACLE,
    probabilityWeight: 12,
  },
  {
    name: `★ПЕРСОНАЖ★ ★исследовал★ пустующий дом и ★обнаружил★, что он населён призраками.`,
    id: SUPERNATURAL_EVENT_HAUNTED_HOUSE,
    probabilityWeight: 12,
  },
  {
    name: `${possessionText} небожителем.`,
    id: SUPERNATURAL_EVENT_POSSESSED_CELESTIAL,
    probabilityWeight: 1,
  },
  {
    name: `${possessionText} дьяволом.`,
    id: SUPERNATURAL_EVENT_POSSESSED_DEVIL,
    probabilityWeight: 1,
  },
  {
    name: `${possessionText} демоном.`,
    id: SUPERNATURAL_EVENT_POSSESSED_DEMON,
    probabilityWeight: 1,
  },
  {
    name: `${possessionText} феей.`,
    id: SUPERNATURAL_EVENT_POSSESSED_FEY,
    probabilityWeight: 1,
  },
  {
    name: `${possessionText} элементалем.`,
    id: SUPERNATURAL_EVENT_POSSESSED_ELEMENTAL,
    probabilityWeight: 1,
  },
  {
    name: `${possessionText} нежитью.`,
    id: SUPERNATURAL_EVENT_POSSESSED_UNDEAD,
    probabilityWeight: 1,
  },
  {
    name: `★ПЕРСОНАЖ★ ★видел★ [привидение](CREATURE:${CREATURE_GHOST}).`,
    id: SUPERNATURAL_EVENT_GHOST,
    probabilityWeight: 6,
  },
  {
    name: `★ПЕРСОНАЖ★ ★видел★ [упыря](CREATURE:${CREATURE_GHOUL}), пожирающего мёртвое тело ${VARIANT_RACE}, ★который★ ${VARIANT_DEATH}.`,
    id: SUPERNATURAL_EVENT_GHOUL,
    detailDictIdList: [
      VARIANT_RACE,
      VARIANT_DEATH,
    ],
    probabilityWeight: 6,
  },
  {
    name: `Небожитель посетил ★его★ во сне и предупредил ${dreamText}`,
    id: SUPERNATURAL_EVENT_DREAM_CELESTIAL,
    probabilityWeight: 3,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ во сне ★посещён★ ${VARIANT_GOD} ★его★ ${dreamText}`,
    id: SUPERNATURAL_EVENT_DREAM_DEITY,
    detailDictIdList: VARIANT_GOD,
    probabilityWeight: 3,
  },
  {
    name: `★ПЕРСОНАЖ★ ненадолго ★оказался★ в Стране Фей.`,
    id: SUPERNATURAL_EVENT_FEYWILD,
    probabilityWeight: 3,
  },
  {
    name: `★ПЕРСОНАЖ★ ненадолго ★оказался★ в Царстве Теней.`,
    id: SUPERNATURAL_EVENT_SHADOW,
    probabilityWeight: 3,
  },
  {
    name: `★ПЕРСОНАЖ★ ★видел★ портал, который, по ★его★ убеждению, ведёт ${VARIANT_OUTER_PLANE}.`,
    id: SUPERNATURAL_EVENT_PORTAL,
    detailDictIdList: VARIANT_OUTER_PLANE,
    probabilityWeight: 6,
  },
]

export default supernaturalEventList
export const supernaturalEventCollection = listToCollectionById(supernaturalEventList)
