const {BACKGROUND_RUNE_CARVER_2022_07_18} = require('./../../backgroundIdList')
const {SOURCE_UA_2022_07_18_WotM} = require('./../../sourceList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_POUCH,
  GEAR_WHETSTONE,
} = require('./../../gearIdList')
const {
  LANG_ANY,
  LANG_GIANT,
} = require('./../../languageIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  SKILL_HISTORY,
  SKILL_PERCEPTION,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_RUNE_CARVER_2022_07_18,
  isReady: false,
  nameByGender: {
    [GENDER_MALE]: 'Резчик рун',
    [GENDER_FEMALE]: 'Резчица рун',
  },
  nameEn: 'Rune Carver',
  proficiency: {
    skill: {
      list: [
        SKILL_HISTORY,
        SKILL_PERCEPTION,
      ],
    },
    language: [
      LANG_ANY,
      LANG_GIANT,
    ],
  },
  equipment: `
* Один вид ремесленных инструментов,
* Небольшой нож
* [Точильный камень](GEAR:${GEAR_WHETSTONE}),
* [Комплект обычной одежды](GEAR:${GEAR_CLOTHES_COMMON}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм
`,
  source: {
    id: SOURCE_UA_2022_07_18_WotM,
    page: 4,
  },
}
