import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import action_regenerateHero from '@/components/CharacterGenerator/store/actions/regenerateHero'
import action_heroGenerationParamsUpdate from '@/components/CharacterGenerator/store/actions/heroGenerationParamsUpdate'

import controlList from './constants/controlList'

import CharacterControlsComponent from './CharacterControlsComponent'

const CharacterControlsContainer = ({ regenerateHero, heroGenerationParamsUpdate }) => {
  const setProp = propKey => ({target: {value}}) => {
    heroGenerationParamsUpdate({[propKey]: value})
  }

  return (
    <CharacterControlsComponent
      regenerateHero={regenerateHero}
      setProp={setProp}
      controlList={controlList}
    />
  )
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        regenerateHero: action_regenerateHero,
        heroGenerationParamsUpdate: action_heroGenerationParamsUpdate,
      },
      dispatch,
    ),
  }
}

export default connect(null, mapDispatchToProps)(CharacterControlsContainer)
