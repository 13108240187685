import React from 'react'

export default ({list}) => (
  <>
      {
        list.map(
          ({text}, i) => (
            <p key={i}>{text}</p>
          ),
        )
      }
  </>
)
