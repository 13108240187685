import React from 'react'
import PropTypes from 'prop-types'

import generateChildhoodHomeText from '@/components/CharacterGenerator/utils/generateChildhoodHomeText'
import generateFamilyLifeStyleText from '@/components/CharacterGenerator/utils/generateFamilyLifeStyleText'
import generateMissedParentText from '@/components/CharacterGenerator/utils/generateMissedParentText'
import generateParentHalfRaceText from '@/components/CharacterGenerator/utils/generateParentHalfRaceText'
import generateVttCharacter from '@/components/CharacterGenerator/utils/generateVttCharacter'

import generateAgeText from '@/utils/generateAgeText'
import generateBondText from '@/utils/generateBondText'
import generateFlawText from '@/utils/generateFlawText'
import generateSizeText from '@/utils/generateSizeText'
import generateTraitTextList from '@/utils/generateTraitTextList'

import generateAlignmentText from './../../utils/generateAlignmentText'
import generateBirthplaceText from './../../utils/generateBirthplaceText'

import calculateColumnCount from './utils/calculateColumnCount'
import generateAppearanceText from './utils/generateAppearanceText'
import generateGiftText from './utils/generateGiftText'
import generateOccupationText from './utils/generateOccupationText'
import generatePcClassChoiceText from './utils/generatePcClassChoiceText'
import generatePhysicalFeaturesText from './utils/generatePhysicalFeaturesText'

import generateFaceText from '@/utils/generateFaceText'
import generateHairText from '@/utils/generateHairText'

import CharacterComponent from './CharacterComponent'

export default class CharacterContainer extends React.Component {
  propTypes = {
    character: PropTypes.object
  }

  downloadVtt = () => {
    const { character } = this.props

    generateVttCharacter(character);
  }

  render() {
    const { character, ...rest } = this.props

    const ageText = generateAgeText(character)
    const alignmentText = generateAlignmentText(character)
    const appearanceText = generateAppearanceText(character)
    const birthplaceText = generateBirthplaceText(character)
    const bondText = generateBondText(character)
    const childhoodHomeText = generateChildhoodHomeText(character)
    const faceText = generateFaceText(character)
    const familyLifeStyleText = generateFamilyLifeStyleText(character)
    const flawText = generateFlawText(character)
    const giftText = generateGiftText(character)
    const hairText = generateHairText(character)
    const missedParentTextList = generateMissedParentText(character)
    const occupationText = generateOccupationText(character)
    const parentHalfRaceText = generateParentHalfRaceText(character)
    const pcClassChoiceText = generatePcClassChoiceText(character)
    const physicalFeaturesText = generatePhysicalFeaturesText(character)
    const sizeText = generateSizeText(character)
    const traitList = generateTraitTextList(character)

    const columnCount = calculateColumnCount(character)

    return (
      <CharacterComponent
        ageText={ageText}
        alignmentText={alignmentText}
        appearanceText={appearanceText}
        birthplaceText={birthplaceText}
        bondText={bondText}
        character={character}
        childhoodHomeText={childhoodHomeText}
        columnCount={columnCount}
        downloadVtt={this.downloadVtt}
        faceText={faceText}
        familyLifeStyleText={familyLifeStyleText}
        flawText={flawText}
        giftText={giftText}
        hairText={hairText}
        missedParentTextList={missedParentTextList}
        occupationText={occupationText}
        parentHalfRaceText={parentHalfRaceText}
        pcClassChoiceText={pcClassChoiceText}
        physicalFeaturesText={physicalFeaturesText}
        sizeText={sizeText}
        traitList={traitList}
        {...character}
        {...rest}
      />
    )
  }
}
