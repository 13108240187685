import listToCollectionById from '@/utils/listToCollectionById'

export const WIZARD_CHOICE_APPRENTICE = 'wizard_choice_apprentice'
export const WIZARD_CHOICE_LOST_FOREST = 'wizard_choice_lost_forest'
export const WIZARD_CHOICE_WIZARD_TALES = 'wizard_choice_wizard_tales'
export const WIZARD_CHOICE_RELATIVE = 'wizard_choice_relative'
export const WIZARD_CHOICE_ANCIENT_TOMB = 'wizard_choice_ancient_tomb'
export const WIZARD_CHOICE_ANCIENT_LIBRARY = 'wizard_choice_ancient_library'
export const WIZARD_CHOICE_ANCIENT_TEMPLE = 'wizard_choice_ancient_temple'
export const WIZARD_CHOICE_PRODIGY = 'wizard_choice_prodigy'

const oldSpellbookText = `я ★нашёл★ книгу заклинаний. Я сразу же ★принялся★ изучать всё, что ★мог★, чтобы стать волшебником.`

const wizardChoiceList = [
  {
    name: `Старый волшебник выбрал меня из нескольких кандидатов и взял в ученики.`,
    id: WIZARD_CHOICE_APPRENTICE,
    probabilityWeight: 3,
  },
  {
    name: `Когда я ★заблудился★ в лесу, скрывавшийся там волшебник нашёл меня, приютил и обучил основам магии.`,
    id: WIZARD_CHOICE_LOST_FOREST,
    probabilityWeight: 3,
  },
  {
    name: `Я ★вырос★, слушая рассказы о великих волшебниках и ★знал★, что хочу идти по их стопам. Я ★попытался★ поступить в академию магии, и у меня получилось.`,
    id: WIZARD_CHOICE_WIZARD_TALES,
    probabilityWeight: 3,
  },
  {
    name: `Один из моих родственников был опытным волшебником, и он решил, что я достаточно ★умён★, чтобы учиться этому искусству.`,
    id: WIZARD_CHOICE_RELATIVE,
    probabilityWeight: 3,
  },
  {
    name: `Исследуя старую гробницу, ${oldSpellbookText}`,
    id: WIZARD_CHOICE_ANCIENT_TOMB,
    probabilityWeight: 1,
  },
  {
    name: `Исследуя старую библиотеку, ${oldSpellbookText}`,
    id: WIZARD_CHOICE_ANCIENT_LIBRARY,
    probabilityWeight: 1,
  },
  {
    name: `Исследуя старый храм, ${oldSpellbookText}`,
    id: WIZARD_CHOICE_ANCIENT_TEMPLE,
    probabilityWeight: 1,
  },
  {
    name: `Я ★был★ вундеркиндом, который с малых лет проявлял способности к магии. Когда я ★стал★ достаточно ★взрослым★, чтобы отправиться в самостоятельное путешествие, я ★сделал★ это, чтобы получить ещё больше знаний о магии и увеличить свою силу.`,
    id: WIZARD_CHOICE_PRODIGY,
    probabilityWeight: 3,
  },
]

export default wizardChoiceList
export const wizardChoiceCollection = listToCollectionById(wizardChoiceList)
