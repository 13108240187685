import {GENDER_MALE, GENDER_FEMALE} from '@/constants/genderList'

export {genderCollection as genderVariantCollection} from '@/constants/genderList'

const genderVariantList = [
  {
    id: GENDER_MALE,
    probabilityWeight: 1,
  },
  {
    id: GENDER_FEMALE,
    probabilityWeight: 1,
  },
]

export default genderVariantList
