import calcListProbabilitySumm from '@/utils/calcListProbabilitySumm'
import listToCollectionById from '@/utils/listToCollectionById'
import listToIdListToPick from '@/utils/listToIdListToPick'
import rollDice from '@/utils/rollDice'

export default (list, rollModifier = 0) => {
  const collection = listToCollectionById(list)
  const idListToPick = listToIdListToPick(list)
  const probabilitySumm = calcListProbabilitySumm(list)
  const diceRoll = rollDice(probabilitySumm)()
  const rollToUse = Math.max(Math.min(diceRoll + rollModifier, probabilitySumm - 1), 0)
  const resultId = idListToPick[rollToUse]

  return collection[resultId]
}
