const {
  BACKGROUND_CITY_WATCH,
  BACKGROUND_INVESTIGATOR,
} = require('./../../backgroundIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_INSIGHT,
  SKILL_INVESTIGATION,
} = require('./../../skillList')
const city_watch = require('./city_watch')

module.exports = {
  ...city_watch,
  id: BACKGROUND_INVESTIGATOR,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Сыщик',
    [GENDER_FEMALE]: 'Сыщица',
  },
  nameEn: 'Investigator',
  description: [
    {
      header: 'Сыщик',
      text: `Общественные сыщики встречаются реже, чем [стражники и патрульные](BACKGROUND:${BACKGROUND_CITY_WATCH}). Они занимаются расследованием преступлений постфактум.

Хотя такие люди редко встречается в сельской местности, почти в каждом поселении приличных размеров есть по крайней мере один или два представителя стражи, обладающих навыкам исследования места преступления и выслеживания преступников.`,
      source: {
        id: SOURCE_SCAG,
        page: 146,
      },
    },
    ...city_watch.description.slice(1),
  ],
  proficiency: {
    ...city_watch.proficiency,
    skill: {
      ...city_watch.proficiency.skill,
      list: [
        SKILL_INSIGHT,
        SKILL_INVESTIGATION,
      ],
    },
  },
  source: {
    id: SOURCE_SCAG,
    page: 146,
  },
}
