import listToCollectionById from '@/utils/listToCollectionById'

export const WARLOCK_CHOICE_FORBIDDEN_PLACE = 'warlock_choice_forbidden_place'
export const WARLOCK_CHOICE_STRANGE_BOOK = 'warlock_choice_strange_book'
export const WARLOCK_CHOICE_MAGICAL_DOORWAY = 'warlock_choice_magical_doorway'
export const WARLOCK_CHOICE_CRISIS = 'warlock_choice_crisis'
export const WARLOCK_CHOICE_DREAM = 'warlock_choice_dream'
export const WARLOCK_CHOICE_ANCESTOR = 'warlock_choice_ancestor'

const warlockChoiceList =  [
  {
    name: `Когда я ★бродил★ по запретному месту, мне встретилось существо из другого мира, которое предложило мне заключить договор.`,
    id: WARLOCK_CHOICE_FORBIDDEN_PLACE,
    probabilityWeight: 1,
  },
  {
    name: `Во время изучения странной книги, которую я ★нашёл★ в заброшенной библиотеке, передо мной внезапно предстала сущность, ставшая моим покровителем.`,
    id: WARLOCK_CHOICE_STRANGE_BOOK,
    probabilityWeight: 1,
  },
  {
    name: `Я ★попал★ в лапы своего покровителя, когда случайно ★прошёл★ через волшебный портал.`,
    id: WARLOCK_CHOICE_MAGICAL_DOORWAY,
    probabilityWeight: 1,
  },
  {
    name: `Когда в моей жизни был переломный момент, я ★молился★ всем, кто мог меня слышать, и ответившее существо стало моим покровителем.`,
    id: WARLOCK_CHOICE_CRISIS,
    probabilityWeight: 1,
  },
  {
    name: `Мой будущий покровитель явился мне во сне и предложил великую силу за службу.`,
    id: WARLOCK_CHOICE_DREAM,
    probabilityWeight: 1,
  },
  {
    name: `У одного из моих предков был договор с моим покровителем, поэтому эта сущность решила заключить соглашение и со мной.`,
    id: WARLOCK_CHOICE_ANCESTOR,
    probabilityWeight: 1,
  },
]

export default warlockChoiceList
export const warlockChoiceCollection = listToCollectionById(warlockChoiceList)
