import listToCollectionById from '@/utils/listToCollectionById'

export const CLERIC_CHOICE_SUPERNATURAL_BEING = 'cleric_choice_supernatural_being'
export const CLERIC_CHOICE_INJUSTICE = 'cleric_choice_injustice'
export const CLERIC_CHOICE_GOD_SIGN = 'cleric_choice_god_sign'
export const CLERIC_CHOICE_RELIGION = 'cleric_choice_religion'
export const CLERIC_CHOICE_TEMPLE = 'cleric_choice_temple'
export const CLERIC_CHOICE_CHOSEN = 'cleric_choice_chosen'

const clericChoiceList = [
  {
    name: `Сверхъестественное существо, служащее богам, призвало меня стать ★посредником★ между богами и этим миром.`,
    id: CLERIC_CHOICE_SUPERNATURAL_BEING,
    probabilityWeight: 1,
  },
  {
    name: `Я ★видел★ несправедливость и ужас в мире и ★ощущал★ необходимость противостоять этому.`,
    id: CLERIC_CHOICE_INJUSTICE,
    probabilityWeight: 1,
  },
  {
    name: `Мой бог дал мне ясный знак. Я ★бросил★ всё, чтобы служить ему.`,
    id: CLERIC_CHOICE_GOD_SIGN,
    probabilityWeight: 1,
  },
  {
    name: `И, хотя я всегда ★был★ ★религиозен★, только по завершении паломничества я ★понял★ своё истинное призвание.`,
    id: CLERIC_CHOICE_RELIGION,
    probabilityWeight: 1,
  },
  {
    name: `Раньше я ★служил★ в бюрократии храма, но потом ★понял★, что мне нужно идти к людям и нести свет моей веры в самые тёмные уголки земли.`,
    id: CLERIC_CHOICE_TEMPLE,
    probabilityWeight: 1,
  },
  {
    name: `Я ★осознал★, что мой бог действует через меня, и я ★делал★ так, как приказано, хотя и не ★знал★, почему я ★был★ ★избран★ служить.`,
    id: CLERIC_CHOICE_CHOSEN,
    probabilityWeight: 1,
  },
]

export default clericChoiceList
export const clericChoiceCollection = listToCollectionById(clericChoiceList)
