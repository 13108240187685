import listToCollectionById from '@/utils/listToCollectionById'

export const MONK_CHOICE_CHOSEN = 'monk_choice_chosen'
export const MONK_CHOICE_DEEP_UNDERSTANDING = 'monk_choice_deep_understanding'
export const MONK_CHOICE_SHADOW_PORTAL = 'monk_choice_shadow_portal'
export const MONK_CHOICE_TRAGEDY = 'monk_choice_tragedy'
export const MONK_CHOICE_SPECIAL_POWER = 'monk_choice_special_power'
export const MONK_CHOICE_HARMONY = 'monk_choice_harmony'

const monkChoiceList = [
  {
    name: `Меня выбрали для обучения в уединённом монастыре. Там я ★освоил★ основные техники, необходимые, чтобы со временем достичь мастерства в традиции.`,
    id: MONK_CHOICE_CHOSEN,
    probabilityWeight: 1,
  },
  {
    name: `Я ★искал★ путь обретения более глубокого понимания бытия и моего места в этом мире.`,
    id: MONK_CHOICE_DEEP_UNDERSTANDING,
    probabilityWeight: 1,
  },
  {
    name: `Я ★наткнулся★ на портал в Царство Теней и ★нашёл★ убежище в странном монастыре, где я ★научился★ защищаться от сил тьмы.`,
    id: MONK_CHOICE_SHADOW_PORTAL,
    probabilityWeight: 1,
  },
  {
    name: `Я ★был★ ★охвачен★ горем после потери близкого человека и ★искал★ совета философов, чтобы смириться с потерей.`,
    id: MONK_CHOICE_TRAGEDY,
    probabilityWeight: 1,
  },
  {
    name: `Я ★чувствовал★ в себе особую силу, поэтому ★искал★ тех, кто мог бы помочь мне пробудить и использовать её.`,
    id: MONK_CHOICE_SPECIAL_POWER,
    probabilityWeight: 1,
  },
  {
    name: `В юности я ★был★ ★озорным★ и ★недисциплинированным★, но позже ★осознал★ ошибочность моего пути. Я ★обратился★ в монастырь и ★стал★ монахом, чтобы жить в гармонии.`,
    id: MONK_CHOICE_HARMONY,
    probabilityWeight: 1,
  },
]

export default monkChoiceList
export const monkChoiceCollection = listToCollectionById(monkChoiceList)
