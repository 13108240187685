const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = [
  {
    header: 'Агент фракции',
    text: `Многие организации, ведущие активную деятельность на Севере и по всему Фаэруну, часто не связаны географическими ограничениями. Такие фракции преследуют собственные цели, вне зависимости от политических границ, а их члены действуют везде, где организация считает, что это необходимо. Такие группы включают в себя наушников, торговцев информацией, контрабандистов, наёмников, каптёров (такие обычно охраняют склады драгоценностей или магии, доступных для использования фракционными оперативниками), держатели убежищ и распространителей слухов. Ядром каждой такой организации являются не те, кто оказывает какие-то небольшие услуги, но те, кто служат ей руками, головой и сердцем.

В качестве вступления в карьеру искателя приключений (или подготовки к ней), Вы служили в качестве агента какой-то конкретной фракции Фаэруна. Действовали ли Вы скрытно или в открытую, зависит от фракции и её целей, а также от того, на сколько эти цели совпадали с вашими. Став искателем приключений, Вы не обязательно покинули ряды своей организации (покинуть её или нет — решать вам). А может быть, став искателем приключений, Вы лишь укрепили свой статус в рядах фракции.`,
    source: {
      id: SOURCE_SCAG,
      page: 148,
    },
  },
  {
    header: 'Умение: Убежище',
    text: `Будучи агентом фракции, Вы обладаете доступом к секретной сети людей, поддерживающих вас, которые могут предложить вам помощь в ваших приключениях. Вы знаете набор секретных знаков и паролей, которые Вы можете использовать, чтобы опознать таких оперативников, которые могут предоставить доступ к секретному убежищу, свободной комнате или другому ночлегу или помочь раздобыть необходимую информацию. Такие агенты никогда не подвергнут из-за вас риску собственные жизни или свою «легенду».`,
    source: {
      id: SOURCE_SCAG,
      page: 148,
    },
  },
  {
    header: 'Фракции Побережья Меча',
    text: `По большей части именно из-за нехватки крупных, централизованных правительств на Севере и на Побережье Мечей в этих землях распространились секретные общества и заговоры. Если Вы выбрали предысторию в качестве агента одной из основных фракций Севера и Побережья Мечей, ниже приведены некоторые доступные варианты.`,
    source: {
      id: SOURCE_SCAG,
      page: 148,
    },
  },
]
