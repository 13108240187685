export const VARIANT_ADVENTURE = 'variant_adventure'
export const VARIANT_APPEARANCE = 'variant_appearance'
export const VARIANT_ARCANE = 'variant_arcane'
export const VARIANT_BACKGROUND = 'variant_background'
export const VARIANT_BEAST = 'variant_beast'
export const VARIANT_BIRTH_PLACE = 'variant_birth_place'
export const VARIANT_BOND = 'variant_bond'
export const VARIANT_BOON = 'variant_boon'
export const VARIANT_CANTRIP = 'variant_cantrip'
export const VARIANT_CREATURE_TYPE = 'variant_creature_type'
export const VARIANT_CRIME = 'variant_crime'
export const VARIANT_DEATH = 'variant_death'
export const VARIANT_FACE_ATTRACTIVENESS = 'variant_face_attractiveness'
export const VARIANT_FACE_BAD = 'variant_face_bad'
export const VARIANT_FACE_GOOD = 'variant_face_good'
export const VARIANT_FAMILY = 'variant_family'
export const VARIANT_FLAW = 'variant_flaw'
export const VARIANT_GENDER = 'variant_gender'
export const VARIANT_GIFT = 'variant_gift'
export const VARIANT_GOD = 'variant_god'
export const VARIANT_KNOW_PARENTS = 'variant_know_parents'
export const VARIANT_LIVE_EVENT = 'variant_live_event'
export const VARIANT_MISSED_PARENT = 'variant_missed_parent'
export const VARIANT_MONSTER = 'variant_monster'
export const VARIANT_OCCUPATION = 'variant_occupation'
export const VARIANT_OUTER_PLANE = 'variant_outer_plane'
export const VARIANT_PARENT_TYPE = 'variant_parent_type'
export const VARIANT_PHYSICAL_POSITIVE = 'variant_physical_positive'
export const VARIANT_PHYSICAL_NEGATIVE = 'variant_physical_negative'
export const VARIANT_PUNISHMENT = 'variant_punishment'
export const VARIANT_RACE = 'variant_race'
export const VARIANT_SIMPLE_WEAPON = 'variant_simple_weapon'
export const VARIANT_SPELL = 'variant_spell'
export const VARIANT_SPELL_WITH_DAMAGE = 'variant_spell_with_damage'
export const VARIANT_SUPERNATURAL = 'variant_supernatural'
export const VARIANT_TRAGEDY = 'variant_tragedy'
export const VARIANT_TRAIT = 'variant_trait'
export const VARIANT_TRINKET = 'variant_trinket'
export const VARIANT_WAR = 'variant_war'
export const VARIANT_WEIRD = 'variant_weird'
