const {
  GEAR_CLOTHES_COMMON,
  GEAR_POUCH,
} = require('./../../gearIdList')

module.exports = (factionName) => `
* Значок или эмблема ${factionName},
* Копия устава или другого основного текста вашей организации (или шифр-книга для тайных организаций),
* [Комплект обычной одежды](GEAR:${GEAR_CLOTHES_COMMON}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 15 зм.
`
