const {BACKGROUND_ATHLETE} = require('./../../backgroundIdList')
const {CAT_TRANSPORT_GROUND} = require('./../../gearCategoryList')
const {SOURCE_MOOT} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  SKILL_ACROBATICS,
  SKILL_ATHLETICS,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_ATHLETE,
  isReady: false,
  nameByGender: {
    [GENDER_MALE]: 'Атлет',
    [GENDER_FEMALE]: 'Атлетка',
  },
  nameEn: 'Athlete',
  description: [
    {
      header: 'Атлет',
      text: `You strive to perfect yourself physically and in execution of everything you do. The thrill of competition lights fire in your blood, and the roar of the crowd drives you forward. Tales of your exploits precede you and might open doors or loosen tongues.

Whether in one of the poleis, between them, or among the nonhuman peoples of Theros, physical contests and those who pursue them command respect bordering on reverence. Athletes arise from all walks of life and all cultures and quite often cross paths with one another.`,
      source: {
        id: SOURCE_MOOT,
        page: 32,
      },
    },
  ],
  proficiency: {
    tool: {
      list: [
        CAT_TRANSPORT_GROUND,
      ],
    },
    skill: {
      list: [
        SKILL_ACROBATICS,
        SKILL_ATHLETICS,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 1,
    },
  },
  equipment: `
* Бронзовый диск или кожаный мач
* Талисман на удачу или спортивный трофей
* [Комплект дорожной одежды](GEAR:${GEAR_CLOTHES_TRAVELERS}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм
`,
  source: {
    id: SOURCE_MOOT,
    page: 32,
  },
}
