const {BACKGROUND_AGENT_EMERALD_ENCLAVE} = require('./../../backgroundIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_SUBCLASS_CLERIC_NATURE_DOMAIN} = require('./../../pcSubClassIdList')
const {
  PC_CLASS_BARBARIAN,
  PC_CLASS_DRUID,
  PC_CLASS_RANGER,
} = require('./../../pcClassIdList')
const {
  SKILL_INSIGHT,
  SKILL_NATURE,
} = require('./../../skillList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const faction_agent_1st = require('./../common/faction_agent_1st')
const faction_agent_2nd = require('./../common/faction_agent_2nd')
const faction_agent_equipment = require('./../common/faction_agent_equipment')

module.exports = {
  id: BACKGROUND_AGENT_EMERALD_ENCLAVE,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Агент Изумрудного анклава',
    [GENDER_FEMALE]: 'Агент Изумрудного анклава',
  },
  nameEn: 'Emerald Enclave Agent',
  description: [
    ...faction_agent_1st,
    {
      header: 'Изумрудный Анклав',
      text: `Поддержание баланса, естественного порядка и сражение с силами, угрожающими этим вещам — вот две равноценных цели Изумрудного Анклава. Те, кто служат этой фракции являются мастерами выживания и жизни на лоне природы. Они часто владеют навыком Природы, и ищут поддержки у лесничих, охотников, [следопытов](PC_CLASS:${PC_CLASS_RANGER}), [варварских племён](PC_CLASS:${PC_CLASS_BARBARIAN}), [друидических кругов](PC_CLASS:${PC_CLASS_DRUID}) и [жрецов, поклоняющихся богам природы](PC_SUBCLASS:${PC_SUBCLASS_CLERIC_NATURE_DOMAIN}).`,
      source: {
        id: SOURCE_SCAG,
        page: 148,
      },
    },
    ...faction_agent_2nd,
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_INSIGHT,
        SKILL_NATURE,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: faction_agent_equipment('Изумрудного Анклава'),
  source: {
    id: SOURCE_SCAG,
    page: 148,
  },
}
