// https://stackoverflow.com/a/30800715/2059884

export default function downloadObjectAsJson(exportObj, exportName){
  const objStr = encodeURIComponent(JSON.stringify(exportObj));
  const dataStr = `data:text/json;charset=utf-8,${objStr}`;

  const downloadAnchorNode = document.createElement('a');

  downloadAnchorNode.setAttribute("href",     dataStr);
  downloadAnchorNode.setAttribute("download", `${exportName}.json`);

  document.body.appendChild(downloadAnchorNode); // required for firefox

  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}
