import arrify from 'arrify'

import generateTextByCharacter from '@/components/CharacterGenerator/utils/generateTextByCharacter'

export default (character, listName) => {
  const generateText = generateTextByCharacter(character)

  return arrify(character[listName])
    .map(generateText)
    .sort(
      ({happensAtAge: A}, {happensAtAge: B}) => A > B ? 1 : -1
    )
}
