const {BACKGROUND_FAR_TRAVELLER} = require('./../../backgroundIdList')
const {LANG_ANY} = require('./../../languageIdList')
const {PC_RACE_ELF} = require('./../../pcRaceIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  SKILL_INSIGHT,
  SKILL_PERCEPTION,
} = require('./../../skillList')
const {
  CAT_GAME_SET,
  CAT_MUSIC_TOOLS,
} = require('./../../gearCategoryList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_POUCH,
} = require('./../../gearIdList')

module.exports = {
  id: BACKGROUND_FAR_TRAVELLER,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Дальний путешественник',
    [GENDER_FEMALE]: 'Дальняя путешественница',
  },
  nameEn: 'Far Traveller',
  description: [
    {
      header: 'Дальний путешественник',
      text: `Почти у всего простого народа, с которыми человек может столкнуться вдоль Побережья Меча или на Севере есть одна общая черта: они проживают свою жизнь, никогда не путешествую более чем на несколько миль от места, где они родились.

Вы не один из таких людей.

Вы родом из места столь отдаленного, что мало кто из простого народа на Севере знает, что оно вообще существует, и вполне возможно, что даже если некоторые люди, которых Вы встретите, и слышали о вашей родине, то знают они лишь название и, возможно, несколько вздорных историй. Вы пришли в эту часть Фаэруна по собственным причинам, которыми Вы могли захотеть или не захотеть поделиться с другими.

Хотя вы, несомненно, найдете некоторые обычаи этих земель странными и сбивающими с толку, Вы также можете быть уверены в том, что некоторые вещи, которые местные люди воспринимают, как должное, будут для вас невиданными чудесами. К тому же, к добру это или к худу, но куда бы Вы ни отправились, вы — диковина, представляющая интерес для окружающих.`,
      source: {
        id: SOURCE_SCAG,
        page: 149,
      },
    },
    {
      header: 'Умение: Все взгляды прикованы к вам',
      text: `Ваш акцент, манеры, обороты речи и, возможно даже, ваш внешний вид выдаёт в вас чужеземца. Любопытные взгляды всегда будут прикованы к вам, куда бы Вы не отправились, что может нервировать. Но Вы также являетесь объектом дружелюбной заинтересованности учёных и других интересующихся дальними землями, не говоря уже об обычных людях, которые всегда жаждут услышать истории с вашей родины.

Вы можете пользоваться этим вниманием для того, чтобы получать доступ к людям и местам, к которым ни у вас, ни у ваших компаньонов не было бы доступа при обычных обстоятельствах. Знатные лорды, учёные, зажиточные купцы и многие другие могут быть заинтересованы в том, чтобы послушать о вашей далёкой родине и её людях.`,
      source: {
        id: SOURCE_SCAG,
        page: 150,
      },
    },
    {
      header: 'Зачем Вы здесь?',
      text: `Дальний путешественник может собраться в дорогу по множеству причин, а уход из родного края может быть как добровольным, так и нет. Чтобы определить почему Вы так далеко от дома, обратитесь с следующей таблице и выберите из возможных вариантов. Секции, рассказывающие про родные места, также содержат несколько предположений о возможных причинах ухода.

| 1к6 | Причина | 1к6 | Причина  |
|-----|---------|-----|----------|
| 1   | Эмиссар | 4   | Паломник |
| 2   | Изгой   | 5   | Турист   |
| 3   | Беглец  | 6   | Скиталец |
`,
      source: {
        id: SOURCE_SCAG,
        page: 149,
      },
    },
    {
      header: 'Откуда Вы?',
      text: `Главное решение в создании предыстории дальнего путешественника — это определение родины. Все места, приведенные ниже, расположены весьма далеко от Севера и Побережья Мечей для того, чтобы разрешить использование этой предыстории.

# Захара

Пословица, известная среди Фаэрунцев, знающих об этом месте, гласит: _«Чтобы добраться до Захары, отправляйся на юг. А потом отправляйся на юг ещё раз»_. Вы, несомненно, проделали такой же долгий путь на север от места своего рождения. Хотя и нет ничего необычного для захарцев в том, чтобы посещать южные окраины Фаэруна для торговли, немногие из них заходят так далеко от дома, как вы.

Возможно, Вы отправились на поиски чудес, которые могут скрываться за пустынями и пиками гор вашей родины, или, может быть, Вы отправились в паломничество с целью лучше познать чужих богов, для того, чтобы Вы могли лучше почитать своих.

# Кара-Тур

Континент Кара-Тур, что лежит на восток от Фаэруна, является домом для людей, обычаи которых незнакомы народам Побережья Мечей. Если Вы прибыли с Кара-Тура, то люд Фаэруна скорее всего будет называть вас Шу, даже если Вы не из их числа, потому как этот обобщающий термин используется чтобы покрыть всех, кто происходит с востока.

Люд Кара-Тура время от времени путешествует на Фаэрун в качестве дипломатов или с тем, чтобы завязать торговые отношения с процветающими купеческими картелями. Возможно, Вы прибыли сюда в составе такой делегации, но после завершения миссии решили остаться.

# Мулхоранд

Начиная с местности и архитектуры и заканчивая королями-богами, правителями этих землях, почти всё связанное с Мулхорандом чуждо для кого-то с Побережья Мечей. Вы скорее всего ощутите такой же культурный шок, покинув свой пустынный дом и отправившись в незнакомые условия северного Фаэруна. Лишь после недавних событий на вашей родине было отменено рабство и, связи с этим, трафик между Мулхорандом и отдалёнными частями Фаэруна сильно возрос.

Те, что оставили Мулхорандские палящие пустыни и древние пирамиды позади ради намёка на другую жизнь, могли сделать это по различным причинам. Вы можете оказаться на Севере просто ради того, чтобы воочию увидеть странности, которые могут предложить «мокрые земли», или потому что Вы нажили слишком много врагов среди пустынных общин вашей родины.

# Подземье

Хотя ваш дом физически ближе к Побережью Мечей, чем другие места, описанные здесь, он куда более необычен. Вы происходите из одного из поселений Подземья, у каждого из которых свои странные обычаи и законы. Если Вы происходите из одного из великих подземных городов или поселений, Вы наверняка представитель доминантной там расы. Впрочем, Вы возможно лишь выросли там после того, как вас взяли в плен и привезли туда, ещё когда Вы были совсем ребёнком.

Если же Вы коренной житель Подземья, Вы скорее всего пришли на поверхность в качестве посланника своего народа, или, возможно, Вы поднялись, чтобы избежать обвинений в преступном поведении (будь они ложными или нет). Если Вы не коренной житель, то скорее всего причина, по которой Вы покинули «дом» была как-то связанна с тем, чтобы избежать неприятностей.

# Соссал

Очень немногие слышали о вашей родине, но у многих возникают вопросы о ней, когда они видят вас. Люди Соссала кажутся сделанными из снега, с алебастровой кожей и белыми волосами, они в добавок обычно одеваются в белое.

Соссал находится далеко на северо-востоке, закованный во льды со всех сторон на сотни миль Великим Ледником и Великим Ледовитым Морем. Никто из вашего народа не предпринимает попыток пересечь этот колоссальный барьер без уважительно причины. Вы должно быть боитесь чего-то действительно ужасного или ищите что-то невероятно ценное.

# Халруаа

Расположенная на южных границах Сияющего Юга и опоясанная горами со всех сторон, магократия Халруаа — это загадочная земля даже для фаэрунцев, знающих о её существовании. Многие слышали о странных небесных кораблях, на которых ходят халруаанцы, но немногие также слышали, что даже самые низшие слои населения обладают магией.

Халруаанцы обычно отправляются на Фаэрун по личным причинам, так как их правительство занимает жёсткую позицию, по поводу отношений с другими нациями и организациями. Возможно, Вы были изгнаны за нарушение одного из многих запутанных законов Халруаа, или Вы можете быть паломником, отправившимся на поиски святилищ богов магии.

# Эвермит

Легендарные эльфийские острова на далёком Западе являются домом для [эльфов](PC_RACE:${PC_RACE_ELF}), которые никогда не были на Фаэруне. Эти места часто оказываются более суровыми, чем [эльфы](PC_RACE:${PC_RACE_ELF}) ожидали, когда совершали Исход. Если Вы [эльф](PC_RACE:${PC_RACE_ELF}), то Эвермит для вас является логичным (хотя и не обязательным) выбором вашей родины.

Большинство из тех, кто эмигрирует из Эвермита являются либо изгнанниками, вынужденными уйти за совершение некоторых преступлений эльфийских законов, либо эмиссары, которые приезжают на Фаэрун с целью, преследующими выгоды для эльфийской культуры или общества.`,
      source: {
        id: SOURCE_SCAG,
        page: 149,
      },
    },
    {
      header: 'Личные черты',
      text: `
| к6 | Личная черта |
|-|-|
| 1 | Мои представления о личном пространстве расходятся с таковыми окружающих. Нечаянно я вторгаюсь в личное пространство других, или, наоборот, реагирую на вторжение в моё собственное, что происходит из-за разницы обычаев. |
| 2 | Мои представления о том, что является едой, а что — нет, расходятся с таковыми окружающих. Я нахожу трапезные традиции окружающих восхитительными, странными или возмутительными. |
| 3 | У меня строгий кодекс чести или чувство собственности, которые не легко воспринять окружающим. |
| 4 | Я выражаю восторг или презрение не свойственным для окружающих образом. |
| 5 | Я выражаю почтение моим божествам, способами, которые кажутся чужими в этих землях. |
| 6 | Я начинаю или оканчиваю свой день традиционными ритуалами, которые незнакомы окружающим. |
`,
      source: {
        id: SOURCE_SCAG,
        page: 150,
      },
    },
    {
      header: 'Идеалы',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Открытость.** Я многому научился у добрых людей, которых я встречал по дороге. | Доброе |
| 2 | **Скрытность.** Будучи новичком в этих странных землях, я осторожен и уважителен в своих делах. | Принципиальное |
| 3 | **Авантюрность.** Я далеко от дома, а всё вокруг странное и восхитительное! | Хаотичное |
| 4 | **Хитрость.** Хоть я и не знаю их методов, они также не знают моих, что может быть мне на руку. | Злое |
| 5 | **Любознательность.** Всё такое новое, а у меня тяга к знаниям как раз разыгралась. | Нейтральное |
| 6 | **Подозрительность.** Я должен быть осторожным, так как я не могу отличить друга от врага здесь. | Любое |
`,
      source: {
        id: SOURCE_SCAG,
        page: 150,
      },
    },
    {
      header: 'Привязанности',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Пока у меня есть частичка моей родины, я могу взглянуть в лицо любым несчастьям в этих землях. |
| 2 | Боги моего народа утешают меня вдали от дома. |
| 3 | Для меня нет большего долга, чем долг моей службы моему народу. |
| 4 | Моя свобода — величайшее моё сокровище. Я не позволю никому отобрать её снова. |
| 5 | Я заворожён красотой и чудесами этих новых земель. |
| 6 | Не получив возможности выбирать, теперь я сетую, что оставил любимых. Я надеюсь увидеть их однажды вновь. |
`,
      source: {
        id: SOURCE_SCAG,
        page: 150,
      },
    },
    {
      header: 'Слабости',
      text: `
| к6 | Слабости |
|-|-|
| 1 | Втайне (или явно), я убеждён в превосходстве своей культуры над культурой этих земель. |
| 2 | Я прикидываюсь, что не понимаю местного языка, для того чтобы избежать взаимодействий, которые мне не по нраву. |
| 3 | У меня слабость к новым ощущениям в виде «возлияний» и прочих удовольствий этих земель. |
| 4 | Я резко реагирую на некоторые действия и мотивы местных людей, потому что эти люди отличаются от меня. |
| 5 | Я считаю последователей других богов по меньшей мере заблудшими овцами, или невежественными дураками по большей мере. |
| 6 | У меня слабость к экзотической красоте людей этих земель. |
`,
      source: {
        id: SOURCE_SCAG,
        page: 151,
      },
    },
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_INSIGHT,
        SKILL_PERCEPTION,
      ],
    },
    tool: {
      comment: `скорее всего, что-то свойственное вашей родине`,
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_GAME_SET,
          CAT_MUSIC_TOOLS,
        ],
      },
    },
    language: LANG_ANY,
  },
  equipment: `
* [Комплект дорожной одежды](GEAR:${GEAR_CLOTHES_TRAVELERS}),
* Музыкальный инструмент или игровой набор, которым Вы владеете,
* Схематичная карта с вашей родины, на которой отмечено ваше местонахождение на Фаэруне,
* Небольшое ювелирное украшение стоимостью 10 зм, украшенное в стиле вашей родины,
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 5 зм.
`,
  source: {
    id: SOURCE_SCAG,
    page: 149,
  },
}
