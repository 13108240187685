import listToCollectionById from '@/utils/listToCollectionById'
import { SPELL_ASTRAL_PROJECTION, SPELL_ETHEREALNESS } from '@/constants/spellIdList'
import { SOURCE_PHB } from '@/constants/sourceList'

export const PLANE_TYPE_INNER = 'inner'
export const PLANE_TYPE_MATERIAL = 'material'
export const PLANE_TYPE_OUTER = 'outer'
export const PLANE_TYPE_POSITIVE_NEGATIVE = 'positive_negative'
export const PLANE_TYPE_TRANSITIVE = 'transitive'
export const PLANE_TYPE_OTHER_PLANES = 'other'

const planeTypeList = [
  {
    id: PLANE_TYPE_MATERIAL,
    name: `Материальный план и его отражения`,
    nameEn: `The Material Plane and Its Echoes`,
    description: [
      `Страна Фей и Царство Теней — это отражения, или эхо Материального плана.`,
      {
        header: `Материальный план`,
        text: `Материальный план это ядро, в котором философские и стихийные силы, формирующие другие планы, слились, образовав смертных и материю. Все миры D&D существуют в Материальном плане, и именно он становится стартовой точкой большинства кампаний и приключений. Вся остальная часть мультивселенной описывается по отношению к Материальному плану.

Миры Материального плана бесконечно разнообразны, так как являются отражениями творческого воображения Мастеров, проводящих в них игры, а также игроков, чьи герои ищут там приключения. Туда входят и планеты, превращённые магией в пустыни, и усеянные островками водные миры, и миры, где магия переплетена с продвинутыми технологиями, и миры, оставшиеся в каменном веке, и миры, где боги свободно ходят, оставив свои дворцы.

Более всего известны миры, опубликованные за долгие годы в качестве официальных миров для игр D&D — Серый Ястреб, Чёрное Болото, Сага о Копье, Забытые Королевства, Мистара, Birthright, Тёмное Солнце и Эберрон, а также другие. Во всех этих мирах множество героических искателей приключений и коварных злодеев, древние руины и забытые артефакты, свои подземелья и свои драконы. Однако даже если ваша кампания проходит в одном из этих миров, он принадлежит вашему Мастеру — можете считать его одним из тысяч параллельных миров, который может разительно отличаться от опубликованной версии. `,
        source: {
          id: SOURCE_PHB,
          page: 301,
        },
      },
      {
        header: `Материальные отражения`,
        text: `Материальный план — богатое магией место, и его магическая сущность отражается на два плана, разделяющих его центральное место в мультивселенной. Страна Фей и Царство Теней это параллельные измерения, занимающие то же самое место в космологии, поэтому часто говорят, что это отражения или эхо Материального плана. Миры и ландшафты этих планов подобны миру природы Материального плана, но со своим собственным оттенком — более чудесные и магические в Стране Фей, исковерканные и бесцветные в Царстве Теней. Там, где на Материальном плане стоит вулкан, в Стране Фей стоит гора, увенчанная кристаллами размером с небоскрёб, светящимися внутренним огнём, а в Царстве Теней стоит зазубренная скала, похожая на череп. `,
        source: {
          id: SOURCE_PHB,
          page: 301,
        },
      },
      {
        header: `За пределами материи`,
        text: `Кроме Материального плана есть и другие планы существования и мифические таинственные царства. Эти миры не просто иные, они сформированы по совершенно другим стихийным и божественным законам, невозможным в обычном мире.`,
        source: {
          id: SOURCE_PHB,
          page: 302,
        },
      },
    ],
  },
  {
    id: PLANE_TYPE_TRANSITIVE,
    name: `Переходные Планы`,
    nameEn: `The Transitive Planes`,
    description: [
      `Эфирный План и Астральный План практически лишены каких-либо особенностей, но зато они предоставляют пути для перехода с одного плана на другой.`,
      {
        header: `Переходные планы`,
        text: `Эфирный План и Астральный План называются Переходными Планами. Это практически лишённые отличительных черт миры, служащие в основном лишь для перехода с одного плана на другой. Такие заклинания как [Эфирность](SPELL:${SPELL_ETHEREALNESS}) и [Проекция в астрал](SPELL:${SPELL_ASTRAL_PROJECTION}) позволяют персонажам входить в эти планы и переходить с них на другие планы.`,
        source: {
          id: SOURCE_PHB,
          page: 302,
        },
      },
    ],
  },
  {
    id: PLANE_TYPE_INNER,
    name: `Внутренние Планы`,
    nameEn: `The Inner Planes`,
    description: [
      `Четыре Стихийных Плана — Вода, Воздух, Земля и Огонь — формируют кольцо вокруг Материального плана, находясь при этом в кипящем Стихийном Хаосе.`,
      {
        header: `Внутренние планы`,
        text: `Внутренние Планы окружают и охватывают Материальный план и его отражения, предоставляя сырое стихийное вещество, из которого и состоят все миры. Четыре Стихийных Плана — Вода, Воздух, Земля и Огонь — формируют кольцо вокруг Материального плана, находясь при этом в кипящем Стихийном Хаосе.

На своих внутренних границах, где они ближе всего к Материальному плану (как в концептуальном, так и практически в географическом смысле), четыре Стихийных Плана напоминают миры Материального плана. Все стихии там сплетены как на Материальном плане, формируя землю, моря и небо. Однако чем дальше Вы отходите от Материального плана, тем чужероднее и враждебнее будут становиться Стихийные Планы. Там стихии существуют в чистой форме — огромные залежи земли, пылающий огонь, кристально-чистая вода и прозрачный воздух. Те регионы малоизвестны, поэтому при обсуждении Плана Огня, например, обычно имеют в виду именно пограничные территории. На дальней границе Внутренних Планов чистые стихии рассеиваются, перемешиваясь между собой в бесконечной буре сталкивающихся энергий и сливающихся субстанций, Стихийном Хаосе.`,
        source: {
          id: SOURCE_PHB,
          page: 302,
        },
      },
    ],
  },
  {
    id: PLANE_TYPE_OUTER,
    name: `Внешние Планы`,
    nameEn: `The Outer Planes`,
    description: `Если Внутренние Планы это сырая материя и энергия, составляющая мультивселенную, то Внешние Планы предоставляют направление, мысль и цель для сотворения этой самой мультивселенной. Соответственно, многие мудрецы считают Внешние Планы божественными или духовными, так как больше всего они известны тем, что там живут боги.`,
  },
  {
    id: PLANE_TYPE_POSITIVE_NEGATIVE,
    name: `Планы Позитивной и Негативной Энергии`,
    nameEn: `The Positive and Negative Planes`,
    description: `Эти два Плана охватывают оставшуюся часть космологии, обеспечивая энергией силы жизни и смерти, лежащие в основе всего сущего в мультивселенной.`,
  },
  {
    id: PLANE_TYPE_OTHER_PLANES,
    name: `Прочие планы`,
    nameEn: `Other Planes `,
    description: `Между планами и за их пределами существуют и другие миры.`,
  },
]

export default planeTypeList

export const planeTypeCollection = listToCollectionById(planeTypeList)




