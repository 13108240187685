const {BACKGROUND_SAGE} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_INK,
  GEAR_INK_PEN,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_SAGE,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Мудрец',
    [GENDER_FEMALE]: 'Мудрец',
  },
  nameEn: 'Sage',
  description: [
    {
      header: 'Мудрец',
      text: `Вы провели много лет, постигая тайны мультивселенной. Вы читали рукописи, изучали свитки, и общались с величайшими экспертами в интересующих вас темах. Всё это сделало вас знатоком в своей области.`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
    {
      header: 'Специализация',
      text: `Чтобы определить область изучаемого вами знания совершите бросок по таблице или выберите один из вариантов самостоятельно.

| к8 | Специализация                     | к8 | Специализация          |
|----|-----------------------------------|----|------------------------|
| 1  | Академик с испорченной репутацией | 5  | Исследователь          |
| 2  | Алхимик                           | 6  | Писец                  |
| 3  | Астроном                          | 7  | Подмастерье волшебника |
| 4  | Библиотекарь                      | 8  | Профессор              |
`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
    {
      header: 'Умение: Исследователь',
      text: `Если Вы пытаетесь изучить или вспомнить информацию, которой Вы не обладаете, Вы часто знаете, где и от кого её можно получить. Обычно это библиотека, скрипторий, университет, мудрец или другое образованное существо. Мастер может решить, что искомое знание является тайной и хранится в практически недоступном месте, или что оно вообще недоступно. Поиски глубочайших тайн вселенной могут потребовать отдельного приключения или даже целой кампании.`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
    {
      header: 'Персонализация',
      text: `Мудрецами становятся после продолжительных занятий, и личность таких существ отражает жизнь в постоянном обучении. Будучи в постоянном поиске мудрости, они высоко ценят знания — как для себя, так и в стремлении к идеалам.`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Я использую многосложные слова, создающие впечатление образованности. |
| 2 | Я прочитал все книги в величайших библиотеках мира — или, по крайней мере, говорю так. |
| 3 | Я привык помогать тем, кто не так умён как я, и терпеливо всем всё объясняю. |
| 4 | Больше всего на свете я люблю тайны. |
| 5 | Прежде чем принять решение, я выслушаю аргументы обеих спорящих сторон. |
| 6 | Я… говорю… медленно… когда… разговариваю… с идиотами,… то есть… практически… всегда. |
| 7 | В социальном взаимодействии я ужасно неуклюж. |
| 8 | Я убеждён, что остальные постоянно хотят украсть мои тайны. |
`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Знание.** Путь к силе и самосовершенствованию лежит в знаниях. | Нейтральное |
| 2 | **Красота.** Красота ведёт нас к тому, что истинно. | Доброе |
| 3 | **Логика.** Эмоции не должны подавлять логическое мышление. | Принципиальное |
| 4 | **Свобода.** Ничто не должно сдерживать бесконечные варианты всех возможных исходов. | Хаотичное |
| 5 | **Власть.** Знания — путь к власти и контролю. | Злое |
| 6 | **Самосовершенствование.** Цель жизни в познании — самосовершенствование. | Любое |
`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Я должен защищать своих учеников. |
| 2 | У меня есть древний текст, содержащий ужасную тайну, и который не должен попасть в чужие руки. |
| 3 | Я работаю над сохранением библиотеки, университета, скриптория или монастыря. |
| 4 | Труд всей моей жизни это серия томов, посвящённая определённой области знаний. |
| 5 | Всю свою жизнь я ищу ответ на один вопрос. |
| 6 | Ради знаний я продал душу. Когда-нибудь я надеюсь совершить великое деяние и вернуть её себе. |
`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Меня легко отвлечь, пообещав информацию. |
| 2 | Увидев демона, большинство закричит и убежит. Я же остановлюсь и буду изучать его анатомию. |
| 3 | Для раскрытия древних тайн можно пожертвовать современной цивилизацией. |
| 4 | Я избегаю очевидных решений, пользуясь замысловатыми. |
| 5 | Я говорю, не обдумывая слова, чем часто оскорбляю других. |
| 6 | Ради спасения своей или чьей-либо ещё жизни я выболтаю любую тайну. |
`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_ARCANA,
        SKILL_HISTORY,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: `
* [Бутылочка чернил](GEAR:${GEAR_INK}),
* [Писчее перо](GEAR:${GEAR_INK_PEN}),
* Небольшой нож,
* Письмо от мёртвого коллеги с вопросом, на который Вы пока не можете ответить,
* [Комплект обычной одежды](GEAR:${GEAR_CLOTHES_COMMON}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 138,
  },
}
