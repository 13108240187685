import React from 'react'

import generateLifeEventTextList from '@/components/CharacterGenerator/utils/generateLifeEventTextList'

import SimpleCharacterPropComponent from './SimpleCharacterPropComponent'

export default ({ character, variantId }) => {
  const list = generateLifeEventTextList(character, variantId)

  return list && list.length
    ? <SimpleCharacterPropComponent list={list}/>
    : null
}
