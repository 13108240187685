import listToCollectionById from '@/utils/listToCollectionById'

import { VARIANT_SPELL, VARIANT_SPELL_WITH_DAMAGE } from './variantIdList'

export const ARCANE_MATTER_CHARM = 'arcane_matter_charm'
export const ARCANE_MATTER_FEAR = 'arcane_matter_fear'
export const ARCANE_MATTER_INJURY = 'arcane_matter_injury'
export const ARCANE_MATTER_POWER_SPELL_CLERIC = 'arcane_matter_power_spell_cleric'
export const ARCANE_MATTER_POWER_SPELL_DRUID = 'arcane_matter_power_spell_druid'
export const ARCANE_MATTER_POWER_SPELL_SORCERER = 'arcane_matter_power_spell_sorcerer'
export const ARCANE_MATTER_POWER_SPELL_WARLOCK = 'arcane_matter_power_spell_warlock'
export const ARCANE_MATTER_POWER_SPELL_WIZARD = 'arcane_matter_power_spell_wizard'
export const ARCANE_MATTER_POTION = 'arcane_matter_potion'
export const ARCANE_MATTER_SCROLL = 'arcane_matter_scroll'
export const ARCANE_MATTER_TELEPORT = 'arcane_matter_teleport'
export const ARCANE_MATTER_INVISIBLE = 'arcane_matter_invisible'
export const ARCANE_MATTER_ILLUSION = 'arcane_matter_illusion'
export const ARCANE_MATTER_CONJURED_CREATURE = 'arcane_matter_conjured_creature'

const witnessText = `★ПЕРСОНАЖ★ ★стал★ ★свидетелем★ того, как`

const arcaneMatterList = [
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★очарован★ заклинанием ${VARIANT_SPELL}.`,
    id: ARCANE_MATTER_CHARM,
    detailDictIdList: VARIANT_SPELL,
    probabilityWeight: 30,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★испуган★ заклинанием ${VARIANT_SPELL}.`,
    id: ARCANE_MATTER_FEAR,
    detailDictIdList: VARIANT_SPELL,
    probabilityWeight: 30,
  },
  {
    name: `★ПЕРСОНАЖ★ ★получил★ ранение от эффекта заклинания ${VARIANT_SPELL_WITH_DAMAGE}.`,
    id: ARCANE_MATTER_INJURY,
    detailDictIdList: VARIANT_SPELL_WITH_DAMAGE,
    probabilityWeight: 60,
  },
  {
    name: `${witnessText} жрец творил мощное заклинание.`,
    id: ARCANE_MATTER_POWER_SPELL_CLERIC,
    probabilityWeight: 12,
  },
  {
    name: `${witnessText} друид творил мощное заклинание.`,
    id: ARCANE_MATTER_POWER_SPELL_DRUID,
    probabilityWeight: 12,
  },
  {
    name: `${witnessText} чародей творил мощное заклинание.`,
    id: ARCANE_MATTER_POWER_SPELL_SORCERER,
    probabilityWeight: 12,
  },
  {
    name: `${witnessText} колдун творил мощное заклинание.`,
    id: ARCANE_MATTER_POWER_SPELL_WARLOCK,
    probabilityWeight: 12,
  },
  {
    name: `${witnessText} волшебник творил мощное заклинание.`,
    id: ARCANE_MATTER_POWER_SPELL_WIZARD,
    probabilityWeight: 12,
  },
  {
    name: `★ПЕРСОНАЖ★ ★выпил★ зелье (на выбор Мастера).`,
    id: ARCANE_MATTER_POTION,
    probabilityWeight: 60,
  },
  {
    name: `★ПЕРСОНАЖ★ ★нашёл★ свиток заклинания ${VARIANT_SPELL} и ★ему★ удалось успешно применить его.`,
    id: ARCANE_MATTER_SCROLL,
    detailDictIdList: VARIANT_SPELL,
    probabilityWeight: 60,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★телепортирован★ посредством магии.`,
    id: ARCANE_MATTER_TELEPORT,
    probabilityWeight: 60,
  },
  {
    name: `★ПЕРСОНАЖ★ ★стал★ ★невидимым★ на некоторое время.`,
    id: ARCANE_MATTER_INVISIBLE,
    probabilityWeight: 60,
  },
  {
    name: `★ПЕРСОНАЖ★ ★распознал★ в чём-то иллюзию.`,
    id: ARCANE_MATTER_ILLUSION,
    probabilityWeight: 60,
  },
  {
    name: `★ПЕРСОНАЖ★ ★видел★ существо, призванное магией.`,
    id: ARCANE_MATTER_CONJURED_CREATURE,
    probabilityWeight: 60,
  },
]

export default arcaneMatterList
export const arcaneMatterCollection = listToCollectionById(arcaneMatterList)
