import listToCollectionById from '@/utils/listToCollectionById'

export const WAR_DESERTER = 'war_deserter'
export const WAR_HERO = 'war_hero'
export const WAR_HERO_MEDAL = 'WAR_HERO_MEDAL'
export const WAR_KNOCKED = 'war_knocked'
export const WAR_NIGHTMARES = 'war_nightmares'
export const WAR_UNHARMED = 'war_unharmed'
export const WAR_WOUNDED_HARD = 'war_wounded_hard'
export const WAR_WOUNDED_LIGHT = 'war_wounded_light'

const heroText = `★ПЕРСОНАЖ★ ★показал★ себя достойно в бою, и ★его★ запомнили, как героя.`

const warList = [
  {
    name: `★ПЕРСОНАЖ★ ★потерял★ сознание и ★был★ ★оставлен★ умирать. Придя в сознание позже, ★он★ не ★смог★ ничего вспомнить о битве.`,
    id: WAR_KNOCKED,
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ тяжело ★ранен★ в бою, и всё ещё ★носит★ ужасные шрамы от тех ранений.`,
    id: WAR_WOUNDED_HARD,
    probabilityWeight: 4,
  },
  {
    name: `★ПЕРСОНАЖ★ ★дезертировал★ из боя, чтобы спасти свою жизнь, но ★ему★ всё ещё стыдно за свою трусость.`,
    id: WAR_DESERTER,
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★получил★ лишь лёгкие ранения, и они зажили, не оставив шрамов.`,
    id: WAR_WOUNDED_LIGHT,
    probabilityWeight: 6,
  },
  {
    name: `★ПЕРСОНАЖ★ ★выжил★ в битве, но ★страдает★ от ужасных кошмаров, в которых ★переживает★ её раз за разом.`,
    id: WAR_NIGHTMARES,
    probabilityWeight: 4,
  },
  {
    name: `★ПЕРСОНАЖ★ ★пережил★ битву ★невредимым★, но многие ★его★ товарищи были ранены или пропали.`,
    id: WAR_UNHARMED,
    probabilityWeight: 4,
  },
  {
    name: heroText,
    id: WAR_HERO,
    probabilityWeight: 1,
  },
  {
    name: `${heroText} ★ПЕРСОНАЖ★ ★был★ ★удостоен★ медали за храбрость.`,
    id: WAR_HERO_MEDAL,
    probabilityWeight: 1,
  },
]

export default warList
export const warCollection = listToCollectionById(warList)

