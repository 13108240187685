import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import action_regenerateSubCharacter from '@/components/CharacterGenerator/store/actions/regenerateSubCharacter'

import Character from './../Character'

const SubCharacterContainer = ({ id, characterCollection, regenerateSubCharacter, ...rest }) => (
  <Character
    className='SubCharacter'
    character={characterCollection[id]}
    {...rest}
  />
)

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        regenerateSubCharacter: action_regenerateSubCharacter,
      },
      dispatch,
    ),
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  characterCollection: state.characterCollection,
})

export default connect(mapStateToProps, mapDispatchToProps)(SubCharacterContainer)
