import listToCollectionById from '@/utils/listToCollectionById'

export const FACE_SHAPE_OBLONG = 'oblong'
export const FACE_SHAPE_OVAL = 'oval'
export const FACE_SHAPE_PEAR = 'pear'
export const FACE_SHAPE_ROUND = 'round'
export const FACE_SHAPE_SQUARE = 'square'
export const FACE_SHAPE_TRIANLE = 'trianle'

const faceShapeList = [
  {
    name: `овальное`,
    id: FACE_SHAPE_OVAL,
  },
  {
    name: `треугольное`,
    id: FACE_SHAPE_TRIANLE,
  },
  {
    name: `круглое`,
    id: FACE_SHAPE_ROUND,
  },
  {
    name: `вытянутое`,
    id: FACE_SHAPE_OBLONG,
  },
  {
    name: `квадратное`,
    id: FACE_SHAPE_SQUARE,
  },
  {
    name: `грушевидное`,
    id: FACE_SHAPE_PEAR,
  },
]
  .map(
    faceShape => ({
      ...faceShape,
      probabilityWeight: 1,
    })
  )

export default faceShapeList
export const faceShapeCollection = listToCollectionById(faceShapeList)
