import listToCollectionById from '@/utils/listToCollectionById'
import {PARENT_TYPE_FATHER, PARENT_TYPE_MOTHER} from './parentTypeVariantList'
import { VARIANT_RACE } from '@/components/CharacterGenerator/constants/variantIdList'

export const FAMILY_ALMSHOUSE = 'almshouse'
export const FAMILY_AUNT_FATHER = 'aunt_father'
export const FAMILY_AUNT_MOTHER = 'aunt_mother'
export const FAMILY_AUNT_UNCLE_FATHER = 'aunt_uncle_father'
export const FAMILY_AUNT_UNCLE_MOTHER = 'aunt_uncle_mother'
export const FAMILY_CLAN = 'clan'
export const FAMILY_FATHER_FOSTER = 'father_foster'
export const FAMILY_FATHER_SINGLE = 'father_single'
export const FAMILY_FLOPHOUSE = 'flophouse'
export const FAMILY_FOSTER_OTHER_RACE = 'foster_other_race'
export const FAMILY_FOSTER_SAME_RACE = 'foster_same_race'
export const FAMILY_GRANDDAD_FATHER = 'granddad_father'
export const FAMILY_GRANDDAD_MOTHER = 'granddad_mother'
export const FAMILY_GRANNY_FATHER = 'granny_father'
export const FAMILY_GRANNY_GRANDDAD_FATHER = 'granny_granddad_father'
export const FAMILY_GRANNY_GRANDDAD_MOTHER = 'granny_granddad_mother'
export const FAMILY_GRANNY_MOTHER = 'granny_mother'
export const FAMILY_GUARDIAN = 'guardian'
export const FAMILY_MOTHER_FATHER = 'mother_father'
export const FAMILY_MOTHER_FOSTER = 'mother_foster'
export const FAMILY_NO = 'no'
export const FAMILY_ORPHANAGE = 'orphanage'
export const FAMILY_SINGLE_MOTHER = 'single_mother'
export const FAMILY_TEMPLE = 'temple'
export const FAMILY_TRIBE = 'tribe'
export const FAMILY_UNCLE_FATHER = 'uncle_father'
export const FAMILY_UNCLE_MOTHER = 'uncle_mother'

const familyVariantList = [
  {
    name: `Нет`,
    id: FAMILY_NO,
    probabilityWeight: 6,
  },
  {
    name: `Ночлежка`,
    id: FAMILY_FLOPHOUSE,
    probabilityWeight: 3,
  },
  {
    name: `Богадельня`,
    id: FAMILY_ALMSHOUSE,
    probabilityWeight: 3,
  },
  {
    name: `Храм`,
    id: FAMILY_TEMPLE,
    probabilityWeight: 6,
  },
  {
    name: `Сиротский приют`,
    id: FAMILY_ORPHANAGE,
    probabilityWeight: 12,
  },
  {
    name: `Опекун`,
    id: FAMILY_GUARDIAN,
    probabilityWeight: 12,
  },
  {
    name: `Тётя по линии отца`,
    id: FAMILY_AUNT_FATHER,
    probabilityWeight: 4,
  },
  {
    name: `Дядя по линии отца`,
    id: FAMILY_UNCLE_FATHER,
    probabilityWeight: 4,
  },
  {
    name: `Тётя и дядя по линии отца`,
    id: FAMILY_AUNT_UNCLE_FATHER,
    probabilityWeight: 4,
  },
  {
    name: `Тётя по линии матери`,
    id: FAMILY_AUNT_MOTHER,
    probabilityWeight: 4,
  },
  {
    name: `Дядя по линии матери`,
    id: FAMILY_UNCLE_MOTHER,
    probabilityWeight: 4,
  },
  {
    name: `Тётя и дядя по линии матери`,
    id: FAMILY_AUNT_UNCLE_MOTHER,
    probabilityWeight: 4,
  },
  {
    name: `Племя`,
    id: FAMILY_TRIBE,
    probabilityWeight: 12,
  },
  {
    name: `Клан`,
    id: FAMILY_CLAN,
    probabilityWeight: 12,
  },
  {
    name: `Бабушка по линии отца`,
    id: FAMILY_GRANNY_FATHER,
    probabilityWeight: 10,
  },
  {
    name: `Дедушка по линии отца`,
    id: FAMILY_GRANDDAD_FATHER,
    probabilityWeight: 10,
  },
  {
    name: `Бабушка и дедушка по линии отца`,
    id: FAMILY_GRANNY_GRANDDAD_FATHER,
    probabilityWeight: 10,
  },
  {
    name: `Бабушка по линии матери`,
    id: FAMILY_GRANNY_MOTHER,
    probabilityWeight: 10,
  },
  {
    name: `Дедушка по линии матери`,
    id: FAMILY_GRANDDAD_MOTHER,
    probabilityWeight: 10,
  },
  {
    name: `Бабушка и дедушка по линии матери`,
    id: FAMILY_GRANNY_GRANDDAD_MOTHER,
    probabilityWeight: 10,
  },
  {
    name: `Приёмная семья той же расы`,
    id: FAMILY_FOSTER_SAME_RACE,
    probabilityWeight: 30,
  },
  {
    name: `Приёмная семья ${VARIANT_RACE}`,
    id: FAMILY_FOSTER_OTHER_RACE,
    detailDictIdList: VARIANT_RACE,
    probabilityWeight: 3000,
  },
  {
    name: `Отец-одиночка`,
    id: FAMILY_FATHER_SINGLE,
    missedParentTypeIdList: [PARENT_TYPE_MOTHER],
    probabilityWeight: 45,
  },
  {
    name: `Приёмный отец`,
    id: FAMILY_FATHER_FOSTER,
    probabilityWeight: 45,
  },
  {
    name: `Мать-одиночка`,
    id: FAMILY_SINGLE_MOTHER,
    missedParentTypeIdList: [PARENT_TYPE_FATHER],
    probabilityWeight: 60,
  },
  {
    name: `Приёмная мать`,
    id: FAMILY_MOTHER_FOSTER,
    probabilityWeight: 60,
  },
  {
    name: `Мать и отец`,
    id: FAMILY_MOTHER_FATHER,
    missedParentTypeIdList: [],
    probabilityWeight: 150,
  },
]
  .map(
    family => ({
      ...family,
      missedParentTypeIdList: family.missedParentTypeIdList || [PARENT_TYPE_FATHER, PARENT_TYPE_MOTHER],
    })
  )

export default familyVariantList

export const familyVariantCollection = listToCollectionById(familyVariantList)
