import { GENDER_FEMALE, GENDER_MALE } from '@/constants/genderList'
import {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} from '@/constants/paramList'
import listToCollectionById from '@/utils/listToCollectionById'

export const PHYSICAL_NEGATIVE_ABSENTMINDED = 'absentminded'
export const PHYSICAL_NEGATIVE_BORING = 'boring'
export const PHYSICAL_NEGATIVE_CLUMSY = 'clumsy'
export const PHYSICAL_NEGATIVE_DIMWITTED = 'dimwitted'
export const PHYSICAL_NEGATIVE_DULL = 'dull'
export const PHYSICAL_NEGATIVE_FEEBLE = 'feeble'
export const PHYSICAL_NEGATIVE_FRAIL = 'frail'
export const PHYSICAL_NEGATIVE_FUMBLING = 'fumbling'
export const PHYSICAL_NEGATIVE_OBLIVIOUS = 'oblivious'
export const PHYSICAL_NEGATIVE_PALE = 'pale'
export const PHYSICAL_NEGATIVE_SCRAWNY = 'scrawny'
export const PHYSICAL_NEGATIVE_SICKLY = 'sickly'
export const PHYSICAL_NEGATIVE_SLOW = 'slow'

const physicalNegativeList = [
  {
    name: `бледность`,
    nameByGender: {
      [GENDER_MALE]: `бледный`,
      [GENDER_FEMALE]: `бледная`,
    },
    id: PHYSICAL_NEGATIVE_PALE,
    paramBonuses: {
      [PARAM_CON]: -1,
      [PARAM_CHA]: -1,
    },
  },
  {
    name: `хилость`,
    nameByGender: {
      [GENDER_MALE]: `хилый`,
      [GENDER_FEMALE]: `хилая`,
    },
    id: PHYSICAL_NEGATIVE_FRAIL,
    paramBonuses: {
      [PARAM_CON]: -2,
    },
  },
  {
    name: `болезненность`,
    nameByGender: {
      [GENDER_MALE]: `болезненный`,
      [GENDER_FEMALE]: `болезненная`,
    },
    id: PHYSICAL_NEGATIVE_SICKLY,
    paramBonuses: {
      [PARAM_CON]: -3,
    },
  },
  {
    name: `глупость`,
    nameByGender: {
      [GENDER_MALE]: `глупый`,
      [GENDER_FEMALE]: `глупая`,
    },
    id: PHYSICAL_NEGATIVE_SLOW,
    paramBonuses: {
      [PARAM_INT]: -4,
    },
  },
  {
    name: `забывчивость`,
    nameByGender: {
      [GENDER_MALE]: `забывчивый`,
      [GENDER_FEMALE]: `забывчивая`,
    },
    id: PHYSICAL_NEGATIVE_OBLIVIOUS,
    paramBonuses: {
      [PARAM_INT]: -1,
    },
  },
  {
    name: `монотонность`,
    nameByGender: {
      [GENDER_MALE]: `монотонный`,
      [GENDER_FEMALE]: `монотонная`,
    },
    id: PHYSICAL_NEGATIVE_DULL,
    paramBonuses: {
      [PARAM_CHA]: -2,
    },
  },
  {
    name: `недалёкость`,
    nameByGender: {
      [GENDER_MALE]: `недалёкий`,
      [GENDER_FEMALE]: `недалёкая`,
    },
    id: PHYSICAL_NEGATIVE_DIMWITTED,
    paramBonuses: {
      [PARAM_WIT]: -2,
    },
  },
  {
    name: `неуклюжесть`,
    nameByGender: {
      [GENDER_MALE]: `неуклюжий`,
      [GENDER_FEMALE]: `неуклюжая`,
    },
    id: PHYSICAL_NEGATIVE_CLUMSY,
    paramBonuses: {
      [PARAM_DEX]: -3,
    },
  },
  {
    name: `рассеянность`,
    nameByGender: {
      [GENDER_MALE]: `рассеянный`,
      [GENDER_FEMALE]: `рассеянная`,
    },
    id: PHYSICAL_NEGATIVE_ABSENTMINDED,
    paramBonuses: {
      [PARAM_WIT]: -2,
    },
  },
  {
    name: `скучность`,
    nameByGender: {
      [GENDER_MALE]: `скучный`,
      [GENDER_FEMALE]: `скучная`,
    },
    id: PHYSICAL_NEGATIVE_BORING,
    paramBonuses: {
      [PARAM_CHA]: -2,
    },
  },
  {
    name: `слабость`,
    nameByGender: {
      [GENDER_MALE]: `слабый`,
      [GENDER_FEMALE]: `слабая`,
    },
    id: PHYSICAL_NEGATIVE_FEEBLE,
    paramBonuses: {
      [PARAM_STR]: -3,
    },
  },
  {
    name: `угловатость`,
    nameByGender: {
      [GENDER_MALE]: `угловатый`,
      [GENDER_FEMALE]: `угловатая`,
    },
    id: PHYSICAL_NEGATIVE_FUMBLING,
    paramBonuses: {
      [PARAM_CHA]: -1,
    },
  },
  {
    name: `щуплость`,
    nameByGender: {
      [GENDER_MALE]: `щуплый`,
      [GENDER_FEMALE]: `щуплая`,
    },
    id: PHYSICAL_NEGATIVE_SCRAWNY,
    paramBonuses: {
      [PARAM_CON]: -2,
    },
  },
]
  .map(
    physicalNegative => ({
      ...physicalNegative,
      probabilityWeight: 1,
    })
  )

export default physicalNegativeList
export const physicalNegativeCollection = listToCollectionById(physicalNegativeList)
