import { GENDER_FEMALE, GENDER_MALE } from '@/constants/genderList'
import {
  PARAM_STR,
  PARAM_DEX,
  PARAM_CON,
  PARAM_INT,
  PARAM_WIT,
  PARAM_CHA,
} from '@/constants/paramList'
import listToCollectionById from '@/utils/listToCollectionById'

export const PHYSICAL_POSITIVE_AGILE = 'agile'
export const PHYSICAL_POSITIVE_BORN_LEADER = 'born_leader'
export const PHYSICAL_POSITIVE_BRAWNY = 'brawny'
export const PHYSICAL_POSITIVE_FORCEFUL = 'forceful'
export const PHYSICAL_POSITIVE_GRACEFUL = 'graceful'
export const PHYSICAL_POSITIVE_HALE = 'hale'
export const PHYSICAL_POSITIVE_HARDY = 'hardy'
export const PHYSICAL_POSITIVE_HEALTHY = 'healthy'
export const PHYSICAL_POSITIVE_INQUISITIVE = 'inquisitive'
export const PHYSICAL_POSITIVE_INSIGHTFUL = 'insightful'
export const PHYSICAL_POSITIVE_LEARNED = 'learned'
export const PHYSICAL_POSITIVE_LITHE = 'lithe'
export const PHYSICAL_POSITIVE_PERCEPTIVE = 'perceptive'
export const PHYSICAL_POSITIVE_PERSUASIVE = 'persuasive'
export const PHYSICAL_POSITIVE_POWERFUL = 'powerful'
export const PHYSICAL_POSITIVE_SPIRITUAL = 'spiritual'
export const PHYSICAL_POSITIVE_STRONG = 'strong'
export const PHYSICAL_POSITIVE_STUDIOUS = 'studious'

const physicalPositiveList = [
  {
    name: `внимательность`,
    nameByGender: {
      [GENDER_MALE]: `внимательный`,
      [GENDER_FEMALE]: `внимательная`,
    },
    id: PHYSICAL_POSITIVE_PERCEPTIVE,
    paramBonuses: {
      [PARAM_WIT]: +2,
    },
  },
  {
    name: `воля`,
    nameByGender: {
      [GENDER_MALE]: `волевой`,
      [GENDER_FEMALE]: `волевая`,
    },
    id: PHYSICAL_POSITIVE_FORCEFUL,
    paramBonuses: {
      [PARAM_CHA]: +2,
    },
  },
  {
    name: `выносливость`,
    nameByGender: {
      [GENDER_MALE]: `выносливый`,
      [GENDER_FEMALE]: `выносливая`,
    },
    id: PHYSICAL_POSITIVE_HARDY,
    paramBonuses: {
      [PARAM_CON]: +2,
    },
  },
  {
    name: `грамотность`,
    nameByGender: {
      [GENDER_MALE]: `грамотный`,
      [GENDER_FEMALE]: `грамотная`,
    },
    id: PHYSICAL_POSITIVE_STUDIOUS,
    paramBonuses: {
      [PARAM_INT]: +1,
    },
  },
  {
    name: `грациозность`,
    nameByGender: {
      [GENDER_MALE]: `грациозный`,
      [GENDER_FEMALE]: `грациозная`,
    },
    id: PHYSICAL_POSITIVE_GRACEFUL,
    paramBonuses: {
      [PARAM_DEX]: +2,
    },
  },
  {
    name: `здоровье`,
    nameByGender: {
      [GENDER_MALE]: `здоровый`,
      [GENDER_FEMALE]: `здоровая`,
    },
    id: PHYSICAL_POSITIVE_HEALTHY,
    paramBonuses: {
      [PARAM_CON]: +1,
    },
  },
  {
    name: `крепкость`,
    nameByGender: {
      [GENDER_MALE]: `крепкий`,
      [GENDER_FEMALE]: `крепкая`,
    },
    id: PHYSICAL_POSITIVE_HALE,
    paramBonuses: {
      [PARAM_CON]: +2,
    },
  },
  {
    name: `крупность`,
    nameByGender: {
      [GENDER_MALE]: `крупный`,
      [GENDER_FEMALE]: `крупная`,
    },
    id: PHYSICAL_POSITIVE_STRONG,
    paramBonuses: {
      [PARAM_CON]: +2,
    },
  },
  {
    name: `ловкость`,
    nameByGender: {
      [GENDER_MALE]: `ловкий`,
      [GENDER_FEMALE]: `ловкая`,
    },
    id: PHYSICAL_POSITIVE_AGILE,
    paramBonuses: {
      [PARAM_DEX]: +2,
    },
  },
  {
    name: `любознательность`,
    nameByGender: {
      [GENDER_MALE]: `любознательный`,
      [GENDER_FEMALE]: `любознательная`,
    },
    id: PHYSICAL_POSITIVE_INQUISITIVE,
    paramBonuses: {
      [PARAM_INT]: +2,
    },
  },
  {
    name: `мускулистость`,
    nameByGender: {
      [GENDER_MALE]: `мускулистый`,
      [GENDER_FEMALE]: `мускулистая`,
    },
    id: PHYSICAL_POSITIVE_BRAWNY,
    paramBonuses: {
      [PARAM_STR]: +3,
    },
  },
  {
    name: `набожость`,
    nameByGender: {
      [GENDER_MALE]: `набожный`,
      [GENDER_FEMALE]: `набожная`,
    },
    id: PHYSICAL_POSITIVE_SPIRITUAL,
    paramBonuses: {
      [PARAM_INT]: +1,
    },
  },
  {
    name: `образованость`,
    nameByGender: {
      [GENDER_MALE]: `образованный`,
      [GENDER_FEMALE]: `образованная`,
    },
    id: PHYSICAL_POSITIVE_LEARNED,
    paramBonuses: {
      [PARAM_INT]: +2,
    },
  },
  {
    name: `прирождённое лидерство`,
    nameByGender: {
      [GENDER_MALE]: `прирождённый лидер`,
      [GENDER_FEMALE]: `прирождённый лидер`,
    },
    id: PHYSICAL_POSITIVE_BORN_LEADER,
    paramBonuses: {
      [PARAM_CHA]: +3,
    },
  },
  {
    name: `проницательность`,
    nameByGender: {
      [GENDER_MALE]: `проницательный`,
      [GENDER_FEMALE]: `проницательная`,
    },
    id: PHYSICAL_POSITIVE_INSIGHTFUL,
    paramBonuses: {
      [PARAM_WIT]: +2,
    },
  },
  {
    name: `сила`,
    nameByGender: {
      [GENDER_MALE]: `сильный`,
      [GENDER_FEMALE]: `сильная`,
    },
    id: PHYSICAL_POSITIVE_POWERFUL,
    paramBonuses: {
      [PARAM_STR]: +3,
    },
  },
  {
    name: `стройность`,
    nameByGender: {
      [GENDER_MALE]: `стройный`,
      [GENDER_FEMALE]: `стройная`,
    },
    id: PHYSICAL_POSITIVE_LITHE,
    paramBonuses: {
      [PARAM_DEX]: +1,
    },
  },
  {
    name: `убедительность`,
    nameByGender: {
      [GENDER_MALE]: `убедительный`,
      [GENDER_FEMALE]: `убедительная`,
    },
    id: PHYSICAL_POSITIVE_PERSUASIVE,
    paramBonuses: {
      [PARAM_CHA]: +2,
    },
  },
]
  .map(
    physicalPositive => ({
      ...physicalPositive,
      probabilityWeight: 1,
    }),
  )

export default physicalPositiveList
export const physicalPositiveCollection = listToCollectionById(physicalPositiveList)
