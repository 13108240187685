import React from 'react'
import { connect } from 'react-redux'

import transformText from '@/utils/transformText'

import CharacterRelationComponent from './CharacterRelationComponent'

const CharacterRelationContainer = (
  {
    character,
    character: {
      parentCharacterId,
      relationText,
    },
    characterCollection,
    ...rest
  },
) => {
  if (relationText && parentCharacterId) {
    const transformedText = transformText(character.genderId)(relationText)
    const {name: parentCharacterName} = characterCollection[parentCharacterId]

    return (
      <CharacterRelationComponent
        relationText={transformedText}
        parentCharacterName={parentCharacterName}
        {...rest}
      />
    )
  }

  return null
}


const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  characterCollection: state.characterCollection,
})

export default connect(mapStateToProps)(CharacterRelationContainer)
