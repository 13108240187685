import spellList from '@/constants/spellList'

export { spellCollection as spellWithDamageIdVariantCollection } from '@/constants/spellList'

export default spellList
  .filter(
    ({inflictDamage}) => inflictDamage,
  )
  .map(
    ({id}) => ({
      id,
      probabilityWeight: 1,
    }),
  )
