const {BACKGROUND_CRIMINAL} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {CAT_GAME_SET} = require('./../../gearCategoryList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  SKILL_DECEPTION,
  SKILL_STEALTH,
} = require('./../../skillList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_CROWBAR,
  GEAR_POUCH,
  GEAR_THIEVES_TOOLS,
} = require('./../../gearIdList')

module.exports = {
  id: BACKGROUND_CRIMINAL,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Преступник',
    [GENDER_FEMALE]: 'Преступница',
  },
  nameEn: 'Criminal',
  description: [
    {
      header: 'Преступник',
      text: `Вы опытный преступник с большим послужным списком. Вы провели много времени, вращаясь в преступных кругах, и до сих пор имеете связи с подпольным миром. В отличие от обычных людей Вы близко познакомились с убийствами, воровством и жестокостью, что пропитывают низшие слои общества. Вы научились выживать, пренебрегая правилами и ограничениями, которым подчиняются другие.`,
      source: {
        id: SOURCE_PHB,
        page: 130,
      },
    },
    {
      header: 'Умение: Криминальные связи',
      text: `У вас есть надёжное доверенное лицо, которое выступает в роли вашего связного в криминальных кругах. Вы умеете получать и отправлять сведения связному, даже через большие расстояния: например, Вы знаете местных посыльных, продажных караванщиков и нечистых на руку матросов, которые могут доставить сообщение для вас.`,
      source: {
        id: SOURCE_PHB,
        page: 130,
      },
    },
    {
      header: 'Преступная направленность',
      text: `Существует много разновидностей преступников: в воровской гильдии или другой похожей организации отдельные члены занимаются различным промыслом и играют разные роли. И даже преступники-одиночки обычно занимаются чем-то конкретным.

| к8 | Направленность             | к8 | Направленность    |
|----|----------------------------|----|-------------------|
| 1  | Взломщик                   | 5  | Контрабандист     |
| 2  | Вымогатель                 | 6  | Наёмный убийца    |
| 3  | Грабитель с большой дороги | 7  | Скупщик краденого |
| 4  | Карманник                  | 8  | Шантажист         |
`,
      source: {
        id: SOURCE_PHB,
        page: 130,
      },
    },
    {
      header: 'Персонализация',
      text: `Преступники на первый взгляд могут выглядеть злодеями, и многие из них действительно злы. Но у некоторых есть такие черты, за которые их можно и простить. Даже среди воров есть честь, но преступники редко относятся с уважением к властям и их представителям. `,
      source: {
        id: SOURCE_PHB,
        page: 130,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | У меня всегда есть план на случай, если всё пойдёт не так как задумано. |
| 2 | Я всегда хладнокровен, несмотря ни на что. Я никогда не повышаю голоса и не позволяю эмоциям управлять мной. |
| 3 | На новом месте я первым делом подмечаю, где находятся различные ценности — или места, где они могут быть спрятаны. |
| 4 | Я предпочту обзавестись ещё один другом, нежели ещё одним врагом. |
| 5 | У меня проблемы с доверием. Те, кто выглядят самыми порядочными, зачастую скрывают множество грязных секретов. |
| 6 | Мне наплевать на риск. Никогда не говорите мне о возможных последствиях. |
| 7 | Наилучший способ заставить меня сделать что-то — сказать мне этого не делать. |
| 8 | Я срываюсь даже от малейшего оскорбления. |
`,
      source: {
        id: SOURCE_PHB,
        page: 130,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | Честь. Я не краду у своих «коллег». | Принципиальное |
| 2 | Свобода. Любые оковы должны быть сломлены, как и те, кто их выковал. | Хаотичное |
| 3 | Милосердие. Я краду у богачей, чтобы помочь людям в беде. | Доброе |
| 4 | Жадность. Я сделаю всё что угодно, лишь бы разбогатеть. | Злое |
| 5 | Друзья. Я верен не каким-то там идеалам, но своим друзьям. А все остальные могут катиться к чертям. | Нейтральное |
| 6 | Искупление. В каждом есть что-то хорошее. | Доброе |
`,
      source: {
        id: SOURCE_PHB,
        page: 130,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Я пытаюсь оплатить старый долг одному щедрому благодетелю. |
| 2 | Свои нечестно заработанные деньги я трачу на помощь своей семье. |
| 3 | У меня забрали нечто важное, и я намереваюсь выкрасть это обратно. |
| 4 | Я стану величайшим вором из всех, когда-либо живших. |
| 5 | Я виновен в ужасающем преступлении. Надеюсь, я смогу простить себя за это. |
| 6 | Близкий человек погиб из-за ошибки, которую я совершил. Это не повториться вновь. |
`,
      source: {
        id: SOURCE_PHB,
        page: 130,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Когда я вижу что-то ценное, я не могу думать ни о чём другом, кроме мыслей о том, как это украсть. |
| 2 | Когда я становлюсь перед выбором между друзьями и деньгами, я обычно выбираю деньги. |
| 3 | Если есть какой-то план, я его наверняка забуду. А если не забуду, то попросту проигнорирую. |
| 4 | У меня есть привычка, которая выдаёт меня, когда я вру. |
| 5 | Я поджимаю хвост и убегаю, когда всё складывается плохо. |
| 6 | Невинный человек находится в тюрьме из-за преступления, что я совершил. Меня это не беспокоит. |
`,
      source: {
        id: SOURCE_PHB,
        page: 131,
      },
    },
  ],
  proficiency: {
    tool: {
      list: [
        GEAR_THIEVES_TOOLS,
      ],
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_GAME_SET,
        ],
      },
    },
    skill: {
      list: [
        SKILL_DECEPTION,
        SKILL_STEALTH,
      ],
    },
  },
  equipment: `
* [Ломик](GEAR:${GEAR_CROWBAR}),
* Комплект [обычной тёмной одежды](GEAR:${GEAR_CLOTHES_COMMON}) с капюшоном,
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 15 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 130,
  },
}
