import listToCollectionById from '@/utils/listToCollectionById'

export const ROGUE_CHOICE_AGILE = 'rogue_choice_agile'
export const ROGUE_CHOICE_ENEMIES_THIEVES = 'rogue_choice_enemies_thieves'
export const ROGUE_CHOICE_ENEMIES_ASSASSINS = 'rogue_choice_enemies_assassins'
export const ROGUE_CHOICE_USEFUL_TRICKS = 'rogue_choice_useful_tricks'
export const ROGUE_CHOICE_LUCKY = 'rogue_choice_lucky'
export const ROGUE_CHOICE_DARK_GROUP = 'rogue_choice_dark_group'
export const ROGUE_CHOICE_SHINY_THINGS = 'rogue_choice_shiny_things'

const injusticeText = `поступили со мной несправедливо, поэтому я ★обучился★ умениям своих врагов, чтобы лучше с ними сражаться.`

const rogueChoiceList = [
  {
    name: `Я всегда ★был★ ★ловким★ и ★сообразительным★, так что я ★решил★ использовать эти таланты, чтобы проторить свой путь в мир.`,
    id: ROGUE_CHOICE_AGILE,
    probabilityWeight: 2,
  },
  {
    name: `Убийцы ${injusticeText}`,
    id: ROGUE_CHOICE_ENEMIES_THIEVES,
    probabilityWeight: 1,
  },
  {
    name: `Воры ${injusticeText}`,
    id: ROGUE_CHOICE_ENEMIES_ASSASSINS,
    probabilityWeight: 1,
  },
  {
    name: `Опытный плут что-то во мне увидел и обучил нескольким полезным трюкам.`,
    id: ROGUE_CHOICE_USEFUL_TRICKS,
    probabilityWeight: 2,
  },
  {
    name: `Я ★решил★ превратить свою удачливость в основу своей профессии, но я ★понимал★, что необходимо развивать свои навыки.`,
    id: ROGUE_CHOICE_LUCKY,
    probabilityWeight: 2,
  },
  {
    name: `Я ★присоединился★ к группе прохвостов, которые показали мне, как получить желаемое через скрытность, а не через прямое противостояние.`,
    id: ROGUE_CHOICE_DARK_GROUP,
    probabilityWeight: 2,
  },
  {
    name: `Я ★испытываю★ слабость к блестящим побрякушкам и кошелькам с монетами, если я ★могу★ приложить к ним руку, не рискуя при этом жизнью и конечностью.`,
    id: ROGUE_CHOICE_SHINY_THINGS,
    probabilityWeight: 2,
  },
]

export default rogueChoiceList
export const rogueChoiceCollection = listToCollectionById(rogueChoiceList)
