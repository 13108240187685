import transformText from '@/utils/transformText'

import {flawVariantCollection} from '@/components/CharacterGenerator/constants/personalization/flawVariantList'

export default ({ genderId, flawId }) => {
  if (flawId) {
    const {name} = flawVariantCollection[flawId]

    return transformText(genderId)(name)
  }

  return ''
}
