import creatureList from '@/constants/creatureList'
import {CREATURE_TYPE_MONSTROSITY} from '@/constants/creatureTypeIdList'

export {creatureCollection as monsterCollection} from '@/constants/creatureList'

export default creatureList
  .filter(
    ({creatureTypeIdList}) => typeof creatureTypeIdList === 'string'
      ? creatureTypeIdList === CREATURE_TYPE_MONSTROSITY
      : creatureTypeIdList.includes(CREATURE_TYPE_MONSTROSITY)
  )
  .map(({id}) => ({
    id,
    probabilityWeight: 1,
  }))
