import transformText from '@/utils/transformText'

import {traitVariantCollection} from '@/components/CharacterGenerator/constants/personalization/traitVariantList'

export default ({ genderId, traitIdList }) => {
  if (!traitIdList) {
    return []
  }

  return traitIdList.reduce(
    (list, traitId) => [
      ...list,
      transformText(genderId)(traitVariantCollection[traitId].name),
    ],
    []
  )
}
