import pcSubraceIdList, {
  PC_SUBRACE_DWARF_GRAY,
  PC_SUBRACE_ELF_DROW,
  PC_SUBRACE_GNOME_DEEP,
  PC_SUBRACE_HALFLING_GHOST,
  PC_SUBRACE_HUMAN_ARKAIUN,
  PC_SUBRACE_HUMAN_BEDINE,
  PC_SUBRACE_HUMAN_CHULT,
  PC_SUBRACE_HUMAN_FFOLK,
  PC_SUBRACE_HUMAN_GUR,
  PC_SUBRACE_HUMAN_HALRUAAN,
  PC_SUBRACE_HUMAN_IMASKARI,
  PC_SUBRACE_HUMAN_NAR,
  PC_SUBRACE_HUMAN_SHAARAN,
  PC_SUBRACE_HUMAN_SHOU,
  PC_SUBRACE_HUMAN_TUIGAN,
  PC_SUBRACE_HUMAN_ULUTIUN,
} from '@/constants/pcSubraceIdList'

export default pcSubraceIdList.map(
  id => {
    let probabilityWeight

    switch (id) {
      case PC_SUBRACE_DWARF_GRAY:
      case PC_SUBRACE_ELF_DROW:
      case PC_SUBRACE_HALFLING_GHOST:
      case PC_SUBRACE_HUMAN_ARKAIUN:
      case PC_SUBRACE_HUMAN_BEDINE:
      case PC_SUBRACE_HUMAN_CHULT:
      case PC_SUBRACE_HUMAN_FFOLK:
      case PC_SUBRACE_HUMAN_GUR:
      case PC_SUBRACE_HUMAN_HALRUAAN:
      case PC_SUBRACE_HUMAN_IMASKARI:
      case PC_SUBRACE_HUMAN_NAR:
      case PC_SUBRACE_HUMAN_SHAARAN:
      case PC_SUBRACE_HUMAN_SHOU:
      case PC_SUBRACE_HUMAN_TUIGAN:
      case PC_SUBRACE_HUMAN_ULUTIUN:
        probabilityWeight = 1
        break


      case PC_SUBRACE_GNOME_DEEP:
        probabilityWeight = 10
        break

      default:
        probabilityWeight = 100
        break
    }

    return {
      id,
      probabilityWeight,
    }
  },
)
