import listToCollectionById from '@/utils/listToCollectionById'

export const PUNISHMENT_GUILTY_COUNT_INNOCENT = 'punishment_guilty_count_innocent'
export const PUNISHMENT_HELP_GUILTY_COUNT_INNOCENT = 'punishment_help_guilty_count_innocent'
export const PUNISHMENT_INNOCENT = 'punishment_innocent'
export const PUNISHMENT_JAIL_ESCAPED = 'punishment_jail_escaped'
export const PUNISHMENT_JAIL_SERVED = 'punishment_jail_served'
export const PUNISHMENT_RAN = 'punishment_ran'
export const PUNISHMENT_SLAVE_ESCAPED = 'punishment_slave_escaped'
export const PUNISHMENT_SLAVE_SERVED = 'punishment_slave_served'
export const PUNISHMENT_WORK_ESCAPED = 'punishment_work_escaped'
export const PUNISHMENT_WORK_SERVED = 'punishment_work_served'

const capturedText = `★ПЕРСОНАЖ★ ★был★ ★схвачен★, ★предан★ суду и ★провёл★ много времени`
const countInnocent = `это преступление, но, тем не менее, власти признали ★его★ ★невиновным★.`
const escapedText = `★ПЕРСОНАЖ★ успешно ★бежал★ после истечения ★DICE★ лет.`
const servedText = `★ПЕРСОНАЖ★ ★отбыл★ наказание в течение ★DICE★ лет.`

const punishmentList = [
  {
    name: `Власти решили что ★ПЕРСОНАЖ★ не ★совершал★ этого преступления и ★он★ ★был★ ★освобождён★ после обвинения`,
    id: PUNISHMENT_INNOCENT,
    probabilityWeight: 6,
  },
  {
    name: `★ПЕРСОНАЖ★ ★совершил★ ${countInnocent}`,
    id: PUNISHMENT_GUILTY_COUNT_INNOCENT,
    probabilityWeight: 3,
  },
  {
    name: `★ПЕРСОНАЖ★ ★помог★ совершить ${countInnocent}`,
    id: PUNISHMENT_HELP_GUILTY_COUNT_INNOCENT,
    probabilityWeight: 3,
  },
  {
    name: `★ПЕРСОНАЖ★ едва не ★был★ ★пойман★ с поличным. ★Он★ ★должен★ ★был★ спасаться бегством и на данный момент ★его★ разыскивают в поселении, где произошло преступление`,
    id: PUNISHMENT_RAN,
    probabilityWeight: 6,
  },
  {
    name: `${capturedText} в тюрьме. ${servedText}`,
    id: PUNISHMENT_JAIL_SERVED,
    dice: {
      diceCount: 1,
      diceType: 4,
    },
    probabilityWeight: 1,
  },
  {
    name: `${capturedText} гребцом на судне. ${servedText}`,
    id: PUNISHMENT_SLAVE_SERVED,
    dice: {
      diceCount: 1,
      diceType: 4,
    },
    probabilityWeight: 1,
  },
  {
    name: `${capturedText} на каторге. ${servedText}`,
    id: PUNISHMENT_WORK_SERVED,
    dice: {
      diceCount: 1,
      diceType: 4,
    },
    probabilityWeight: 1,
  },
  {
    name: `${capturedText} в тюрьме. ${escapedText}`,
    id: PUNISHMENT_JAIL_ESCAPED,
    dice: {
      diceCount: 1,
      diceType: 4,
    },
    probabilityWeight: 1,
  },
  {
    name: `${capturedText} гребцом на судне. ${escapedText}`,
    dice: {
      diceCount: 1,
      diceType: 4,
    },
    id: PUNISHMENT_SLAVE_ESCAPED,
    probabilityWeight: 1,
  },
  {
    name: `${capturedText} на каторге. ${escapedText}`,
    id: PUNISHMENT_WORK_ESCAPED,
    dice: {
      diceCount: 1,
      diceType: 4,
    },
    probabilityWeight: 1,
  },
]

export default punishmentList
export const punishmentCollection = listToCollectionById(punishmentList)
