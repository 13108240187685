import listToCollectionById from '@/utils/listToCollectionById'

export const PARENT_TIEFLING_HUMAN_HUMAN = 'parent_tiefling_human_human'
export const PARENT_TIEFLING_M_TIEFLING_F_HUMAN = 'parent_tiefling_m_tiefling_f_human'
export const PARENT_TIEFLING_F_TIEFLING_M_HUMAN = 'parent_tiefling_f_tiefling_m_human'
export const PARENT_TIEFLING_M_TIEFLING_F_DEVIL = 'parent_tiefling_m_tiefling_f_devil'
export const PARENT_TIEFLING_F_TIEFLING_M_DEVIL = 'parent_tiefling_f_tiefling_m_devil'
export const PARENT_TIEFLING_F_DEVIL_M_HUMAN = 'parent_tiefling_f_devil_m_human'
export const PARENT_TIEFLING_M_DEVIL_F_HUMAN = 'parent_tiefling_m_devil_f_human'

const parentTieflingList = [
  {
    name: 'Оба родителя были людьми, инфернальное наследие которых не проявлялось, пока не ★родился★ ★ПЕРСОНАЖ★.',
    id: PARENT_TIEFLING_HUMAN_HUMAN,
  },
  {
    name: 'Оба родителя были тифлингами.',
    id: PARENT_TIEFLING_HUMAN_HUMAN,
  },
  {
    name: 'Отец был тифлингом, а мать — человеком.',
    id: PARENT_TIEFLING_M_TIEFLING_F_HUMAN,
  },
  {
    name: 'Мать была тифлингом, а отец — человеком.',
    id: PARENT_TIEFLING_F_TIEFLING_M_HUMAN,
  },
  {
    name: 'Отец был тифлингом, а мать — дьяволом.',
    id: PARENT_TIEFLING_M_TIEFLING_F_DEVIL,
  },
  {
    name: 'Мать была тифлингом, а отец — дьяволом.',
    id: PARENT_TIEFLING_F_TIEFLING_M_DEVIL,
  },
  {
    name: 'Отец был человеком, а мать — дьяволом.',
    id: PARENT_TIEFLING_F_DEVIL_M_HUMAN,
  },
  {
    name: 'Мать была человеком, а отец — дьяволом.',
    id: PARENT_TIEFLING_M_DEVIL_F_HUMAN,
  },
]
  .map(item => ({
    ...item,
    probabilityWeight: 1,
  }))

export default parentTieflingList
export const parentTieflingCollection = listToCollectionById(parentTieflingList)
