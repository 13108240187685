import React from 'react'

import AdsAfterCharacter from '@/ads/components/AdsAfterCharacter'

import Hero from './../Hero'
import SubCharacter from './../SubCharacter'

import './CharacterListStyles.less'

export default ({ sideCharacterIdList }) => (
  <ul className='CharacterList'>
    <li className='CharacterList_item CharacterList_item-hero'>
      <Hero/>
    </li>
    <li className='CharacterList_item CharacterList_item-ads'>
      <AdsAfterCharacter/>
    </li>
    {
      sideCharacterIdList.map(
        id => (
          <li
            key={id}
            className='CharacterList_item'
          >
            <SubCharacter id={id}/>
          </li>
        ),
      )
    }
  </ul>
)
