const {BACKGROUND_PIRATE} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const sailor = require('./sailor')

module.exports = {
  ...sailor,
  id: BACKGROUND_PIRATE,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Пират',
    [GENDER_FEMALE]: 'Пиратка',
  },
  nameEn: 'Pirate',
  description: [
    {
      header: 'Пират',
      text: `Вы провели молодость среди жутких пиратов, безжалостных головорезов, научивших вас выживать среди акул и дикарей. Вы занимались грабежом в морях, и не одну душу отправили в солёную пучину. Вы хорошо знакомы со страхом и кровью, и заслужили сомнительную репутацию во многих портовых городах.`,
      source: {
        id: SOURCE_PHB,
        page: 140,
      },
    },
    {
      header: 'Умение: Дурная репутация',
      text: `Где бы Вы ни оказались, вас боятся из-за вашей репутации. Находясь в цивилизованном поселении, Вы можете безнаказанно совершать небольшие преступления, такие как отказ платить за еду в таверне или выламывание двери в магазине, так как жители боятся сообщать о вас властям.`,
      source: {
        id: SOURCE_PHB,
        page: 140,
      },
    },
    ...sailor.description.slice(0, 1),
    ...sailor.description.slice(3),
  ],
  source: {
    id: SOURCE_PHB,
    page: 140,
  },
}
