import React from 'react'
import { Link } from 'gatsby'

import upLetter from '@/utils/upLetter'
import generateBackgroundPageUrlById from '@/utils/generateBackgroundPageUrlById'

import { backgroundCollection } from '@/constants/backgroundList'

const CharacterBackground = (
  {
    character: {
      backgroundId,
      genderId,
    },
  },
) => {
  if (backgroundId) {
    const text = upLetter(backgroundCollection[backgroundId].nameByGender[genderId])
    const url = generateBackgroundPageUrlById(backgroundId)

    return (
      <>
        До этого: <Link to={url}>{text}</Link>
      </>
    )
  }

  return null
}

export default CharacterBackground
