const {BACKGROUND_AGENT_HARPERS} = require('./../../backgroundIdList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  SKILL_INSIGHT,
  SKILL_INVESTIGATION,
} = require('./../../skillList')
const {
  PC_CLASS_BARD,
  PC_CLASS_RANGER,
} = require('./../../pcClassIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const faction_agent_1st = require('./../common/faction_agent_1st')
const faction_agent_2nd = require('./../common/faction_agent_2nd')
const faction_agent_equipment = require('./../common/faction_agent_equipment')

module.exports = {
  id: BACKGROUND_AGENT_HARPERS,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Агент арфистов',
    [GENDER_FEMALE]: 'Агент арфистов',
  },
  nameEn: 'Harper Agent',
  note: 'Арфисты ценят мирное сосуществование, сохранение истории и гармонию с природой, и каждый день они распространяют свои идеалы чуть дальше',
  description: [
    ...faction_agent_1st,
    {
      header: 'Арфисты',
      text: `Основаны более тысячелетия назад, были распущены и реорганизованы несколько раз, Арфисты остаются влиятельной, закулисной организацией, которая действует для противодействия злу и продвижению справедливости путём знаний, а не грубой силы. Агенты Арфистов часто владеют навыком Анализа, позволяющим им быть хорошими следователями и шпионами. Они часто ищут помощи у других Арфистов, симпатизирующих им [бардов](PC_CLASS:${PC_CLASS_BARD}) и владельцев постоялых дворов, [следопытов](PC_CLASS:${PC_CLASS_RANGER}) и клира богов, идеалы которых совпадают с идеалами Арфистов.`,
      source: {
        id: SOURCE_SCAG,
        page: 148,
      },
    },
    {
      header: `Арфисты`,
      text: `Возможно, самое известное тайное общество на Побережье Мечей — однако отнюдь не столь благонамеренное, каким себя представляет. Мои источники заверяют меня, что те, кто принадлежит к этой загадочной фракции, испытывают отвращение к власти — однако если это так, то почему они не обнародуют результаты своих многолетних изысканий на благо общества? Почему, спросите вы? Да потому что знание — сила, и арфисты не склонны делиться ни тем, ни другим.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    ...faction_agent_2nd,
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_INSIGHT,
        SKILL_INVESTIGATION,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: faction_agent_equipment('Арфистов'),
  source: {
    id: SOURCE_SCAG,
    page: 148,
  },
}
