import React from 'react'
import { Link } from 'gatsby'

import upLetter from '@/utils/upLetter'

import generateRacePageUrlById from '@/utils/generateRacePageUrlById'

import { pcSubraceCollection } from '@/constants/pcSubraceList'
import { pcRaceCollection } from '@/constants/pcRaceList'

const CharacterRace = (
  {
    character: {
      genderId,
      raceId,
      subRaceId,
    },
  },
) => {
  const raceText = upLetter(pcRaceCollection[raceId].name.singular[genderId].nominative)
  const url = generateRacePageUrlById(raceId)
  const subRaceText = subRaceId
    ? pcSubraceCollection[subRaceId].nameShort.singular[genderId]
    : ''

  return (
    <Link to={url}>
      {
        subRaceText
          ? `${raceText} (${subRaceText})`
          : raceText
      }
    </Link>
  )
}

export default CharacterRace
