import listToCollectionById from '@/utils/listToCollectionById'

export const FACE_BAD_ASYMMETRICAL = 'asymmetrical'
export const FACE_BAD_UGLY = 'ugly'
export const FACE_BAD_REPULSIVE = 'repulsive'
export const FACE_BAD_POCKMARKED = 'pockmarked'
export const FACE_BAD_HIDEOUS = 'hideous'
export const FACE_BAD_REPUGNANT = 'repugnant'
export const FACE_BAD_BULBOUS = 'bulbous'
export const FACE_BAD_AWFUL = 'awful'
export const FACE_BAD_DISFIGURED = 'disfigured'
export const FACE_BAD_REVOLTING = 'revolting'
export const FACE_BAD_DISGUSTING = 'disgusting'
export const FACE_BAD_CREEPY = 'creepy'
export const FACE_BAD_MEDIOCRE = 'mediocre'

const faceBadList = [
  {
    name: `ассиметричное`,
    id: FACE_BAD_ASYMMETRICAL,
    probabilityWeight: 1,
  },
  {
    name: `уродливое`,
    id: FACE_BAD_UGLY,
    probabilityWeight: 1,
  },
  {
    name: `отталкивающее`,
    id: FACE_BAD_REPULSIVE,
    probabilityWeight: 1,
  },
  {
    name: `щербатое`,
    id: FACE_BAD_POCKMARKED,
    probabilityWeight: 1,
  },
  {
    name: `страшное`,
    id: FACE_BAD_HIDEOUS,
    probabilityWeight: 1,
  },
  {
    name: `противное`,
    id: FACE_BAD_REPUGNANT,
    probabilityWeight: 1,
  },
  {
    name: `выпуклое`,
    id: FACE_BAD_BULBOUS,
    probabilityWeight: 1,
  },
  {
    name: `ужасное`,
    id: FACE_BAD_AWFUL,
    probabilityWeight: 1,
  },
  {
    name: `изуродованное`,
    id: FACE_BAD_DISFIGURED,
    probabilityWeight: 1,
  },
  {
    name: `отвратительное`,
    id: FACE_BAD_REVOLTING,
    probabilityWeight: 1,
  },
  {
    name: `мерзкое`,
    id: FACE_BAD_DISGUSTING,
    probabilityWeight: 1,
  },
  {
    name: `жуткое`,
    id: FACE_BAD_CREEPY,
    probabilityWeight: 2,
  },
  {
    name: `невыразительное`,
    id: FACE_BAD_MEDIOCRE,
    probabilityWeight: 3,
  },
]

export default faceBadList
export const faceBadCollection = listToCollectionById(faceBadList)
