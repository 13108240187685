import listToCollectionById from '@/utils/listToCollectionById'
import { GENDER_FEMALE, GENDER_MALE } from '@/constants/genderList'

export const PARENT_TYPE_MOTHER = 'parent_type_mother'
export const PARENT_TYPE_FATHER = 'parent_type_father'

const parentTypeVariantList = [
  {
    name: `Мать`,
    id: PARENT_TYPE_MOTHER,
    genderId: GENDER_FEMALE,
    probabilityWeight: 1,
  },
  {
    name: `Отец`,
    id: PARENT_TYPE_FATHER,
    genderId: GENDER_MALE,
    probabilityWeight: 1,
  },
]

export default parentTypeVariantList
export const parentTypeCollection = listToCollectionById(parentTypeVariantList)
