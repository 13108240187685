const {BACKGROUND_GUILD_ARTISAN} = require('./../../backgroundIdList')
const {CAT_ARTISAN_TOOLS} = require('./../../gearCategoryList')
const {LANG_ANY} = require('./../../languageIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_INSIGHT,
  SKILL_PERSUASION,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_GUILD_ARTISAN,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Гильдейский ремесленник',
    [GENDER_FEMALE]: 'Гильдейская ремесленница',
  },
  nameEn: 'Guild Artisan',
  description: [
    {
      header: 'Гильдейский ремесленник',
      text: `Вы — член гильдии ремесленников, знаток в какой-то области, связанный с другими мастеровыми. Вы крепко стоите на ногах в этом торгашеском мире, отделённые своим талантом и богатством от оков феодального строя. Когда-то Вы учились у мастера, под опекой гильдии, и вот Вы сами стали таким умельцем.`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
    {
      header: 'Умение: Членство в гильдии',
      text: `Будучи авторитетным и уважаемым членом гильдии, Вы можете пользоваться особыми преимуществами, предоставляемыми этим членством. Ваши товарищи по гильдии при необходимости предоставят вам жилище и питание, и даже оплатят ваши похороны. В некоторых городах у гильдий есть место для сбора, и там можно потенциально встретить покровителей, союзников и наёмников.

Гильдии часто обладают значительной политической властью. Если вас обвинят в преступлении, гильдия может помочь, если существует вероятность оправдательного приговора. Благодаря гильдии Вы получаете доступ к влиятельным политикам, если состоите в гильдии на хорошем счету. Для таких связей может потребоваться жертвование денег или магических предметов в казну гильдии.

Каждый месяц Вы должны выплачивать гильдии 5 зм. Если Вы пропустите платёж, Вы должны вначале оплатить все долги, и только тогда восстановите своё доброе имя в гильдии.`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
    {
      header: 'Специализация гильдии',
      text: `Обычно гильдии основывают в городах, достаточно больших для того, чтобы сразу несколько ремесленников занимались одним общим делом. Однако ваша гильдия может быть рассредоточенной сетью ремесленников, где каждый работает в своей деревне. Определите вместе с Мастером характер вашей гильдии. Можете выбрать специализацию гильдии из таблицы, а можете определить её случайным образом.

| к20 | Специализация гильдии |
|-|-|
| 1 | Алхимики и аптекари |
| 2 | Гончары и мастера керамики |
| 3 | Жестянщики, проволочники и литейщики |
| 4 | Каллиграфы, писцы и нотариусы |
| 5 | Каменщики и камнерезы |
| 6 | Картографы, землемеры и чертёжники |
| 7 | Кожевенники, меховщики и дубильщики |
| 8 | Кузнецы и бронники |
| 9 | Металлурги и мастера обработки металла |
| 10 | Пивовары и виноделы |
| 11 | Плотники, кровельщики и штукатуры |
| 12 | Повара и пекари |
| 13 | Сапожники и чеботари |
| 14 | Стеклодувы и стекольщики |
| 15 | Столяры, бондари и лучных дел мастера |
| 16 | Судостроители и парусные мастера |
| 17 | Тележники и колёсных дел мастера |
| 18 | Ткачи и красильщики |
| 19 | Художники, иллюстраторы и маляры |
| 20 | Ювелиры и огранщики |

Будучи членом гильдии, Вы знаете, как создавать готовые предметы из сырья (отражается вашим владением определённым инструментом), а также основы торговли и бизнеса. Вопрос лишь в том, отказались ли Вы от торговли в пользу приключений или стараетесь успевать и там, и там.`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
    {
      header: 'Персонализация',
      text: `Гильдейские ремесленники мало чем отличаются от обывателей, пока они не откладывают свои инструменты в сторону и не начинают карьеру искателя приключений. Они понимают ценность тяжёлого труда и важность общности, но они так же как другие подвержены грехам жадности и алчности.`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Я считаю, что всё, что делается, должно делаться правильно. Ничего не могу с этим поделать —я перфекционист. |
| 2 | Я сноб, свысока смотрящий на тех, кто не может оценить изящное искусство. |
| 3 | Мне всегда нужно знать, как всё вокруг устроено, и как нужно обращаться с другими. |
| 4 | У меня много остроумных афоризмов и есть пословицы на любой случай. |
| 5 | Я груб с теми, у кого нет моей тяги к честному и справедливому труду. |
| 6 | О своей профессии я могу говорить часами. |
| 7 | Я не могу легко расставаться с деньгами, и могу сколь угодно долго торговаться. |
| 8 | Благодаря работе я прославился, и хочу, чтобы все это ценили. Меня всегда потрясает, когда другие говорят, что не слышали обо мне. |
`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Сообщество.** Все цивилизованные народы обязаны укреплять сообщество и обеспечивать безопасность цивилизации. | Принципиальное |
| 2 | **Великодушие.** Талант был вручён мне, чтобы я использовал его на благо всего мира. | Доброе |
| 3 | **Свобода.** Все должны быть свободны, чтобы жить так, как хочется им. | Хаотичное |
| 4 | **Жадность.** Я просто занимаюсь этим из-за денег. | Злое |
| 5 | **Народ.** Я предан своему народу, а не идеям. | Нейтральное |
| 6 | **Стремление.** Я усердно работаю над тем, чтобы стать в своём деле лучшим. | Любое |
`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Мастерская, где я учился ремеслу, для меня — самое важное место в мире. |
| 2 | Я создал кое для кого удивительную работу, но посчитал его недостойным. Я всё ещё ищу того, кто окажется достоин её. |
| 3 | Я по гроб жизни обязан гильдии за то, что она сделала меня тем, кто я есть. |
| 4 | Богатство мне нужно для завоевания сердца возлюбленной. |
| 5 | Когда-нибудь я вернусь в гильдию и докажу, что я лучший из всех. |
| 6 | Я мщу силам зла, которые уничтожили моё торговое дело и разрушили мою жизнь. |
`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Я сделаю всё что угодно, чтобы заполучить что-то редкое или очень ценное. |
| 2 | Мне всегда кажется, что меня пытаются обжулить. |
| 3 | Никто не должен знать, что я однажды украл деньги из казны гильдии. |
| 4 | Мне всегда мало того, что у меня есть —всегда нужно больше. |
| 5 | Я готов убить ради получения благородного титула. |
| 6 | Я ужасно завидую тем, кто может превзойти мой труд. Куда бы я ни пошёл, меня окружают конкуренты. |
`,
      source: {
        id: SOURCE_PHB,
        page: 133,
      },
    },
  ],
  note: 'Вы мастерски распознаете ценные предметы, так что вас практически невозможно обмануть',
  proficiency: {
    tool: {
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_ARTISAN_TOOLS,
        ],
      },
    },
    skill: {
      list: [
        SKILL_PERSUASION,
        SKILL_INSIGHT,
      ],
    },
    language: LANG_ANY,
  },
  equipment: `
* Один вид ремесленных инструментов,
* Рекомендательное письмо из гильдии,
* [Дорожная одежда](GEAR:${GEAR_CLOTHES_TRAVELERS}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 15 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 133,
  },
}
