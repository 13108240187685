const {SOURCE_SCAG} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  BACKGROUND_CLOISTERED_SCHOLAR,
  BACKGROUND_SAGE,
} = require('./../../backgroundIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  GEAR_BOOK,
  GEAR_INK,
  GEAR_INK_PEN,
  GEAR_PARCHMENT,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_NATURE,
  SKILL_RELIGION,
} = require('./../../skillList')
const sage = require('./sage')

module.exports = {
  id: BACKGROUND_CLOISTERED_SCHOLAR,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Учёный-затворник',
    [GENDER_FEMALE]: 'Учёная-затворница',
  },
  nameEn: 'Cloistered Scholar',
  description: [
    {
      header: 'Учёный-затворник',
      text: `Будучи ребенком, Вы тянулись к знаниям, в то время как ваши сверстники бездельничали. В годы взросления Вы попали в одно из величайших учебных заведений Фаэруна, где вам преподали важный урок — что знание является сокровищем более ценным, чем золото или драгоценные камни. Теперь Вы готовы покинуть дом — не для того чтобы оставить его, но в поисках новой информации, чтобы пополнить кладезь знаний своей альма-матер.

Наиболее известным таким «кладезем знаний» Фаэруна является Кэндлкип. В большой библиотеке всегда нужны рабочие и обслуга, некоторые из которых поднимаются по карьерной лестнице, чтобы взять на себя большую ответственность и занять более выдающееся положение. Вы можете быть одним из таких представителей Кэндлкипа, посвятившим себя служению тому, что, вероятно, является наиболее полным собранием знаний и истории всего мира.

Возможно, вместо этого вас взяли к себе учёные из Убежища Мудрецов или Дома Карт Сильверимуна, и теперь Вы с удвоенной силой взялись за дело, чтобы расширить собственные знания и помочь тем, кто нуждается в ваших навыках в других местах. Вы можете стать одним из тех немногих, кто оказывает помощь Крепости Герольдов, каталогизируя и поддерживая записи информации, прибывающей со всех концов Фаэруна ежедневно.`,
      source: {
        id: SOURCE_SCAG,
        page: 147,
      },
    },
    {
      header: 'Умение: Доступ к библиотеке',
      text: `Иные терпят обширные допросы и платят крупные суммы, чтобы получить доступ даже к наиболее распространенным архивам в библиотеке, у вас же есть свободный доступ к большей части библиотеки. Следует учесть, что библиотека может обладать такими хранилищами знаний, которые слишком ценны, волшебны, или секретны, чтобы Вы имели к ним непосредственный доступ.

В своей «альма-матер» Вы знаете работников и всю бюрократию, поэтому для вас не составит труда наладить с ними связь. Кроме того, вы, скорее всего, обладаете привилегированным доступом к другим библиотекам в Королевствах, ведь профессиональное сообщество благосклонно к коллегам-ученым.`,
      source: {
        id: SOURCE_SCAG,
        page: 147,
      },
    },
    {
      header: 'Предлагаемые характеристики',
      text: `Используйте таблицы для предыстории [мудреца](BACKGROUND:${BACKGROUND_SAGE}), как базу для ваших черт характера и мотивов, изменяя их содержание при необходимости, чтобы более точно описать вашу личность в качестве учёного-затворника.

Ваши привязанности, скорее всего, будут связаны с местом, где Вы выросли или со знаниями, которые Вы надеетесь обрести путём приключений. Ваш идеал без сомнения связан с вашими взглядами на поиск знаний и правды — возможно это сама по себе цель, а возможно лишь средства её достижения.`,
      source: {
        id: SOURCE_SCAG,
        page: 147,
      },
    },
    ...sage.description.slice(4),
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_HISTORY,
      ],
      toChoose: {
        count: 1,
        list: [
          SKILL_ARCANA,
          SKILL_NATURE,
          SKILL_RELIGION,
        ],
      },
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: `
* Роба учёного из вашего заведения,
* Набор писца ([маленький кошель](GEAR:${GEAR_POUCH}) с [пером](GEAR:${GEAR_INK_PEN}), [чернилами](GEAR:${GEAR_INK}), свитками [пергамента](GEAR:${GEAR_PARCHMENT}) и ножом для заточки перьев),
* Временно взятая [книга](GEAR:${GEAR_BOOK}) по предмету вашего изучения,
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм.
`,
  source: {
    id: SOURCE_SCAG,
    page: 147,
  },
}
