import listToCollectionById from '@/utils/listToCollectionById'

export const BARD_CHOICE_SECRET_TALENT = 'bard_choice_secret_talent'
export const BARD_CHOICE_TEACHER = 'bard_choice_teacher'
export const BARD_CHOICE_FREE_SOCIETY = 'bard_choice_free_society'
export const BARD_CHOICE_HERO_TALES = 'bard_choice_hero_tales'
export const BARD_CHOICE_ANCIENT_SECRETS = 'bard_choice_ancient_secrets'
export const BARD_CHOICE_INSTANTLY = 'bard_choice_instantly'

const bardChoiceList = [
  {
    name: `Я ★пробудил★ мои скрытые таланты барда методом проб и ошибок.`,
    id: BARD_CHOICE_SECRET_TALENT,
    probabilityWeight: 1,
  },
  {
    name: `Я ★был★ талантливым исполнителем и ★привлёк★ внимание наставника бардов, который обучил меня древним техникам.`,
    id: BARD_CHOICE_TEACHER,
    probabilityWeight: 1,
  },
  {
    name: `Я ★присоединился★ к свободному обществу исследователей и ораторов чтобы изучить новые техники выступления и магии.`,
    id: BARD_CHOICE_FREE_SOCIETY,
    probabilityWeight: 1,
  },
  {
    name: `Я ★почувствовал★ призвание рассказывать о деяниях чемпионов и героев, оживив их в песнях и историях.`,
    id: BARD_CHOICE_HERO_TALES,
    probabilityWeight: 1,
  },
  {
    name: `Я ★присоединился★ к одной из великих коллегий, чтобы изучать древние знания, тайны магии и искусство выступления.`,
    id: BARD_CHOICE_ANCIENT_SECRETS,
    probabilityWeight: 1,
  },
  {
    name: `Однажды я ★взял★ в руки музыкальный инструмент и тут же ★понял★, что умею на нём играть`,
    id: BARD_CHOICE_INSTANTLY,
    probabilityWeight: 1,
  },
]

export default bardChoiceList
export const bardChoiceCollection = listToCollectionById(bardChoiceList)
