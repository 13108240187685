import React from 'react'

import PageTitle from '@/components/PageTitle'
import SourceInfo from '@/components/SourceInfo'

import {SOURCE_XGTE} from '@/constants/sourceList'

import CharacterList from './components/CharacterList'
import CharacterControls from './components/CharacterControls'

import './CharacterGeneratorStyles.less'

const SOURCE = {
  id: SOURCE_XGTE,
  page: 63,
}

export default () => (
  <section className='CharacterGenerator'>
    <PageTitle>Генератор персонажей</PageTitle>
    <p>Генерация на основе таблиц из Reddit-сообщества <a href="https://www.reddit.com/r/d100/" target="_blank" rel="noopener noreferrer nofollow">d100</a>, «<SourceInfo source={SOURCE} useFullName/>» и других источников.</p>
    <CharacterControls/>
    <CharacterList/>
  </section>
)
