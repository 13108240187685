import { REGENERATE_HERO } from '@/components/CharacterGenerator/store/action-types'
import trackAnalyticEvent from '@/utils/trackAnalyticEvent'

export default () => {
  trackAnalyticEvent('generate-character')

  return {
    type: REGENERATE_HERO,
  }
}
