const {BACKGROUND_GATE_WARDEN_2022_07_18} = require('./../../backgroundIdList')
const {SOURCE_UA_2022_07_18_WotM} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  GEAR_BOOK,
  GEAR_CLOTHES_TRAVELERS,
  GEAR_INK,
  GEAR_INK_PEN,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  SKILL_PERSUASION,
  SKILL_SURVIVAL,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_GATE_WARDEN_2022_07_18,
  isReady: false,
  nameByGender: {
    [GENDER_MALE]: 'Страж врат',
    [GENDER_FEMALE]: 'Стражница врат',
  },
  nameEn: 'Gate Warden',
  proficiency: {
    skill: {
      list: [
        SKILL_PERSUASION,
        SKILL_SURVIVAL,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: `
* Кольцо с ключами от неизвестных замков
* Чистая [книга](GEAR:${GEAR_BOOK})
* [Писчее перо](GEAR:${GEAR_INK_PEN}) или чернильная ручка,
* [Бутылочка чернил](GEAR:${GEAR_INK}),
* [Комплект дорожной одежды](GEAR:${GEAR_CLOTHES_TRAVELERS}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм
`,
  source: {
    id: SOURCE_UA_2022_07_18_WotM,
    page: 3,
  },
}
