import React from 'react'
import { Provider} from 'react-redux'

import store from './store'
import CharacterGeneratorContainer from './CharacterGeneratorContainer'

export default () => (
  <Provider store={store}>
    <CharacterGeneratorContainer/>
  </Provider>
)
