import listToCollectionById from '@/utils/listToCollectionById'

export const CHILDHOOD_HOME_ON_THE_STREETS = 'childhood_home_on_the_streets'
export const CHILDHOOD_HOME_RUNDOWN_SHACK = 'childhood_home_rundown_shack'
export const CHILDHOOD_HOME_NONE = 'childhood_home_none'
export const CHILDHOOD_HOME_ENCAMPMENT_WILDERNESS = 'childhood_home_encampment_wilderness'
export const CHILDHOOD_HOME_VILLAGE_WILDERNESS = 'childhood_home_village_wilderness'
export const CHILDHOOD_HOME_APARTMENT_RUNDOWN_NEIGHBORHOOD = 'childhood_home_apartment_rundown_neighborhood'
export const CHILDHOOD_HOME_SMALL_HOUSE = 'childhood_home_small_house'
export const CHILDHOOD_HOME_LARGE_HOUSE = 'childhood_home_large_house'
export const CHILDHOOD_HOME_MANSION = 'childhood_home_mansion'
export const CHILDHOOD_HOME_PALACE = 'childhood_home_palace'
export const CHILDHOOD_HOME_CASTLE = 'childhood_home_castle'

// TODO: Учитывать расу
const childhoodHomeVariantList = [
  {
    name: `На улицах`,
    id: CHILDHOOD_HOME_ON_THE_STREETS,
    probabilityWeight: 40,
  },
  {
    name: `Обветшалая лачуга`,
    id: CHILDHOOD_HOME_RUNDOWN_SHACK,
    probabilityWeight: 20,
  },
  {
    name: `Не было постоянного дома; Вы много скитались`,
    id: CHILDHOOD_HOME_NONE,
    probabilityWeight: 10,
  },
  {
    name: `Лагерь в глуши`,
    id: CHILDHOOD_HOME_ENCAMPMENT_WILDERNESS,
    probabilityWeight: 5,
  },
  {
    name: `Деревня в глуши`,
    id: CHILDHOOD_HOME_VILLAGE_WILDERNESS,
    probabilityWeight: 5,
  },
  {
    name: `Комната в захудалом районе`,
    id: CHILDHOOD_HOME_APARTMENT_RUNDOWN_NEIGHBORHOOD,
    probabilityWeight: 10,
  },
  {
    name: `Небольшой дом`,
    id: CHILDHOOD_HOME_SMALL_HOUSE,
    probabilityWeight: 20,
  },
  {
    name: `Большой дом`,
    id: CHILDHOOD_HOME_LARGE_HOUSE,
    probabilityWeight: 20,
  },
  {
    name: `Поместье`,
    id: CHILDHOOD_HOME_MANSION,
    probabilityWeight: 20,
  },
  {
    name: `Большой дворец`,
    id: CHILDHOOD_HOME_PALACE,
    probabilityWeight: 15,
  },
  {
    name: `Замок`,
    id: CHILDHOOD_HOME_CASTLE,
    probabilityWeight: 15,
  },
]

export default childhoodHomeVariantList
export const childhoodHomeVariantCollection = listToCollectionById(childhoodHomeVariantList)
