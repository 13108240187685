import occupationList, {occupationCollection} from '@/constants/occupationVariantList'
import appearanceList, {appearanceCollection} from '@/constants/appearanceList'

import adventureList, {adventureCollection} from './adventureList'
import arcaneMatterList, {arcaneMatterCollection} from './arcaneMatterList'
import backgroundVariantList, {backgroundVariantCollection} from './backgroundVariantList'
import beastList, {beastCollection} from './beastList'
import birthplaceVariantList , {birthplaceVariantCollection} from './birthplaceVariantList'
import boonList, {boonCollection} from './boonList'
import cantripIdVariantList, {cantripIdVariantCollection} from './cantripIdVariantList'
import creatureTypeList, {creatureTypeCollection} from './creatureTypeList'
import crimeList, {crimeCollection} from './crimeList'
import deathList, {deathCollection} from './deathList'
import familyVariantList, {familyVariantCollection} from './familyVariantList'
import genderVariantList, {genderVariantCollection} from './genderVariantList'
import godList, {godCollection} from './godList'
import knowParentsVariantList, {knowParentsVariantCollection} from './knowParentsVariantList'
import lifeEventVariantList, {lifeEventVariantCollection} from './lifeEventVariantList'
import missedParentReasonVariantList, {missedParentReasonVariantCollection} from './missedParentReasonVariantList'
import monsterList, {monsterCollection} from './monsterList'
import outerPlaneList, {outerPlaneCollection} from './outerPlaneList'
import parentTypeVariantList, {parentTypeCollection} from './parentTypeVariantList'
import punishmentList, {punishmentCollection} from './punishmentList'
import raceList, {raceCollection} from './raceList'
import simpleWeaponList, {simpleWeaponCollection} from './simpleWeaponList'
import spellIdVariantList, {spellIdVariantCollection} from './spellIdVariantList'
import spellWithDamageIdVariantList, {spellWithDamageIdVariantCollection} from './spellWithDamageIdVariantList'
import supernaturalEventList, {supernaturalEventCollection} from './supernaturalEventList'
import tragedyList, {tragedyCollection} from './tragedyList'
import warList, {warCollection} from './warList'
import weirdStuffList, {weirdStuffCollection} from './weirdStuffList'
import trinketList, {trinketCollection} from './trinketList'
import physicalPositiveList, {physicalPositiveCollection} from './physicalPositiveList'
import physicalNegativeList, {physicalNegativeCollection} from './physicalNegativeList'
import giftList, {giftCollection} from './giftList'
import faceAttractivenessVariantList, {faceAttractivenessVariantCollection} from './faceAttractivenessVariantList'
import faceBadList, {faceBadCollection} from './faceBadList'
import faceGoodList, {faceGoodCollection} from './faceGoodList'
import flawVariantList, {flawVariantCollection} from './personalization/flawVariantList'
import traitVariantList, {traitVariantCollection} from './personalization/traitVariantList'
import bondVariantList, {bondVariantCollection} from './personalization/bondVariantList'

import {
  VARIANT_ADVENTURE,
  VARIANT_APPEARANCE,
  VARIANT_ARCANE,
  VARIANT_BACKGROUND,
  VARIANT_BEAST,
  VARIANT_BIRTH_PLACE,
  VARIANT_BOND,
  VARIANT_BOON,
  VARIANT_CANTRIP,
  VARIANT_CREATURE_TYPE,
  VARIANT_CRIME,
  VARIANT_DEATH,
  VARIANT_FACE_ATTRACTIVENESS,
  VARIANT_FACE_BAD,
  VARIANT_FACE_GOOD,
  VARIANT_FAMILY,
  VARIANT_FLAW,
  VARIANT_GENDER,
  VARIANT_GIFT,
  VARIANT_GOD,
  VARIANT_KNOW_PARENTS,
  VARIANT_LIVE_EVENT,
  VARIANT_MISSED_PARENT,
  VARIANT_MONSTER,
  VARIANT_OCCUPATION,
  VARIANT_OUTER_PLANE,
  VARIANT_PARENT_TYPE,
  VARIANT_PHYSICAL_NEGATIVE,
  VARIANT_PHYSICAL_POSITIVE,
  VARIANT_PUNISHMENT,
  VARIANT_RACE,
  VARIANT_SIMPLE_WEAPON,
  VARIANT_SPELL,
  VARIANT_SPELL_WITH_DAMAGE,
  VARIANT_SUPERNATURAL,
  VARIANT_TRAGEDY,
  VARIANT_TRAIT,
  VARIANT_TRINKET,
  VARIANT_WAR,
  VARIANT_WEIRD,
} from './variantIdList'

export default {
  [VARIANT_ADVENTURE]: {
    list: adventureList,
    collection: adventureCollection,
  },
  [VARIANT_APPEARANCE]: {
    list: appearanceList,
    collection: appearanceCollection,
  },
  [VARIANT_ARCANE]: {
    list: arcaneMatterList,
    collection: arcaneMatterCollection,
  },
  [VARIANT_BACKGROUND]: {
    list: backgroundVariantList,
    collection: backgroundVariantCollection,
  },
  [VARIANT_BEAST]: {
    list: beastList,
    collection: beastCollection,
  },
  [VARIANT_BIRTH_PLACE]: {
    list: birthplaceVariantList,
    collection: birthplaceVariantCollection,
  },
  [VARIANT_BOND]: {
    list: bondVariantList,
    collection: bondVariantCollection,
  },
  [VARIANT_BOON]: {
    list: boonList,
    collection: boonCollection,
  },
  [VARIANT_CANTRIP]: {
    list: cantripIdVariantList,
    collection: cantripIdVariantCollection,
  },
  [VARIANT_CREATURE_TYPE]: {
    list: creatureTypeList,
    collection: creatureTypeCollection,
  },
  [VARIANT_CRIME]: {
    list: crimeList,
    collection: crimeCollection,
  },
  [VARIANT_DEATH]: {
    list: deathList,
    collection: deathCollection,
  },
  [VARIANT_FAMILY]: {
    list: familyVariantList,
    collection: familyVariantCollection,
  },
  [VARIANT_FACE_ATTRACTIVENESS]: {
    list: faceAttractivenessVariantList,
    collection: faceAttractivenessVariantCollection,
  },
  [VARIANT_FACE_BAD]: {
    list: faceBadList,
    collection: faceBadCollection,
  },
  [VARIANT_FACE_GOOD]: {
    list: faceGoodList,
    collection: faceGoodCollection,
  },
  [VARIANT_FLAW]: {
    list: flawVariantList,
    collection: flawVariantCollection,
  },
  [VARIANT_GENDER]: {
    list: genderVariantList,
    collection: genderVariantCollection,
  },
  [VARIANT_GIFT]: {
    list: giftList,
    collection: giftCollection,
  },
  [VARIANT_GOD]: {
    list: godList,
    collection: godCollection,
  },
  [VARIANT_KNOW_PARENTS]: {
    list: knowParentsVariantList,
    collection: knowParentsVariantCollection,
  },
  [VARIANT_LIVE_EVENT]: {
    list: lifeEventVariantList,
    collection: lifeEventVariantCollection,
  },
  [VARIANT_MISSED_PARENT]: {
    list: missedParentReasonVariantList,
    collection: missedParentReasonVariantCollection,
  },
  [VARIANT_MONSTER]: {
    list: monsterList,
    collection: monsterCollection,
  },
  [VARIANT_OCCUPATION]: {
    list: occupationList,
    collection: occupationCollection,
  },
  [VARIANT_OUTER_PLANE]: {
    list: outerPlaneList,
    collection: outerPlaneCollection,
  },
  [VARIANT_PARENT_TYPE]: {
    list: parentTypeVariantList,
    collection: parentTypeCollection,
  },
  [VARIANT_PHYSICAL_NEGATIVE]: {
    list: physicalNegativeList,
    collection: physicalNegativeCollection,
  },
  [VARIANT_PHYSICAL_POSITIVE]: {
    list: physicalPositiveList,
    collection: physicalPositiveCollection,
  },
  [VARIANT_PUNISHMENT]: {
    list: punishmentList,
    collection: punishmentCollection,
  },
  [VARIANT_RACE]: {
    list: raceList,
    collection: raceCollection,
  },
  [VARIANT_SIMPLE_WEAPON]: {
    list: simpleWeaponList,
    collection: simpleWeaponCollection,
  },
  [VARIANT_SPELL]: {
    list: spellIdVariantList,
    collection: spellIdVariantCollection,
  },
  [VARIANT_SPELL_WITH_DAMAGE]: {
    list: spellWithDamageIdVariantList,
    collection: spellWithDamageIdVariantCollection,
  },
  [VARIANT_SUPERNATURAL]: {
    list: supernaturalEventList,
    collection: supernaturalEventCollection,
  },
  [VARIANT_TRAGEDY]: {
    list: tragedyList,
    collection: tragedyCollection,
  },
  [VARIANT_TRAIT]: {
    list: traitVariantList,
    collection: traitVariantCollection,
  },
  [VARIANT_TRINKET]: {
    list: trinketList,
    collection: trinketCollection,
  },
  [VARIANT_WAR]: {
    list: warList,
    collection: warCollection,
  },
  [VARIANT_WEIRD]: {
    list: weirdStuffList,
    collection: weirdStuffCollection,
  },
}
