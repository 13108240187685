const {CAT_MUSIC_TOOLS} = require('./../../gearCategoryList')
const {BACKGROUND_OUTLANDER} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_ATHLETICS,
  SKILL_SURVIVAL,
} = require('./../../skillList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_HUNTING_TRAP,
  GEAR_POUCH,
  GEAR_QUARTERSTAFF,
} = require('./../../gearIdList')

module.exports = {
  id: BACKGROUND_OUTLANDER,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Чужеземец',
    [GENDER_FEMALE]: 'Чужеземка',
  },
  nameEn: 'Outlander',
  description: [
    {
      header: 'Чужеземец',
      text: `Вы выросли в глуши, вдали от цивилизации и её благ. Вы видели миграцию стад, чей размер был больше леса, выживали при температуре, которую горожанам и не представить, и наслаждались таким уединением, что на многие мили вокруг Вы были единственным мыслящим существом. Дикая природа в вашей крови, будь Вы кочевником, исследователем, затворником, охотником-собирателем или даже мародёром. Даже в неизвестном месте Вы найдёте что-то, что вам понятно. `,
      source: {
        id: SOURCE_PHB,
        page: 137,
      },
    },
    {
      header: 'Умение: Странник',
      text: `Вы отлично запоминаете карты и местность, и всегда можете вспомнить общие характеристики местности, поселения, и прочие особенности в окрестностях. Кроме того, Вы каждый день можете находить еду и пресную воду для себя и пяти других товарищей, при условии, что вокруг вас можно найти ягоды, дичь, воду и так далее.`,
      source: {
        id: SOURCE_PHB,
        page: 137,
      },
    },
    {
      header: 'Занятие',
      text: `Вы были в необычных местах и видели такое, что другие не могут даже вообразить. Подумайте, какие далёкие земли Вы посетили, и как они на вас повлияли. Можете совершить бросок по прилагающейся таблице, чтобы определить, чем Вы занимались в глуши, а можете сделать выбор самостоятельно.

| к10 | Занятие   | к10 | Занятие             |
|-----|-----------|-----|---------------------|
| 1   | Лесник    | 6   | Охотник за головами |
| 2   | Траппер   | 7   | Пилигрим            |
| 3   | Поселенец | 8   | Кочевник            |
| 4   | Проводник | 9   | Охотник-собиратель  |
| 5   | Изгнанник | 10  | Мародёр             |
`,
      source: {
        id: SOURCE_PHB,
        page: 137,
      },
    },
    {
      header: 'Персонализация',
      text: `Часто считающиеся цивилизованными народами грубыми и неотёсанными, чужеземцы не прельщаются жизнью в городе. Узы племени, клана, семьи и положенное место в природе — вот самые важные привязанности чужеземцев. `,
      source: {
        id: SOURCE_PHB,
        page: 137,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Мной руководит жажда приключений, которая и увела меня из дома. |
| 2 | Я слежу за друзьями, как если бы они были слепыми котятами. |
| 3 | Однажды я пробежал 40 километров без остановки, чтобы предупредить свой клан о приближающейся орде орков. Если понадобится, я повторю это. |
| 4 | Для любой ситуации у меня есть уроки, извлечённые из наблюдений за природой. |
| 5 | Я не понимаю богатых и воспитанных. Деньги и хорошие манеры не спасут тебя от голодного волка. |
| 6 | Я всё время что-то беру в руки, рассеянно кручу и иногда ломаю. |
| 7 | Я гораздо комфортнее чувствую себя среди зверей, чем среди людей. |
| 8 | Меня действительно вырастили волки. |
`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Перемены.** Жизнь как времена года, постоянно меняется, и мы должны меняться вместе с ней. | Хаотичное |
| 2 | **Процветание.** Каждый должен поступать так, чтобы всё племя было счастливо. | Доброе |
| 3 | **Честь.** Если я обесчещу себя, я обесчещу весь свой клан. | Принципиальное |
| 4 | **Мощь.** Самый сильный имеет право приказывать. | Злое |
| 5 | **Природа.** Мир природы гораздо важнее построек цивилизации. | Нейтральное |
| 6 | **Слава.** Я должен завоёвывать славу в сражениях, для себя и своего клана. | Любое |
`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Моя семья, клан или племя — самые важные вещи в моей жизни, даже когда они далеко от меня. |
| 2 | Осквернение дикой местности на моей родине я считаю личным оскорблением. |
| 3 | Я вымещу свой чудовищный гнев на злодеях, уничтоживших мои земли. |
| 4 | Я последний из своего племени, и должен сделать так, чтобы наши имена вошли в легенды. |
| 5 | Мне являются жуткие видения грядущей катастрофы, и я сделаю всё, чтобы её предотвратить. |
| 6 | Я должен создавать новых детей, которые будут поддерживать моё племя. |
`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Я слишком пристрастен к элю, вину и прочим спиртным напиткам. |
| 2 | В жизни на полную катушку нет места осторожности. |
| 3 | Я помню все полученные оскорбления и таю злобу на всех обидчиков. |
| 4 | Я с трудом доверяю представителям других рас, племён и сообществ. |
| 5 | На любые неурядицы я почти всегда отвечаю насилием. |
| 6 | Не думайте, что я буду спасать тех, кто не может сам о себе позаботиться. Сильные должны процветать, а слабые погибают, и это нормально. |
`,
      source: {
        id: SOURCE_PHB,
        page: 138,
      },
    },
  ],
  proficiency: {
    tool: {
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_MUSIC_TOOLS,
        ],
      },
    },
    skill: {
      list: [
        SKILL_ATHLETICS,
        SKILL_SURVIVAL,
      ],
    },
    language: LANG_ANY,
  },
  equipment: `
* [Посох](GEAR:${GEAR_QUARTERSTAFF}),
* [Капкан](GEAR:${GEAR_HUNTING_TRAP}),
* Трофей с убитого животного,
* [Комплект одежды путешественника](GEAR:${GEAR_CLOTHES_TRAVELERS}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 137,
  },
}
