import lootList from '@/constants/lootList'
import {
  LOOT_TYPE_TRINKET,
} from '@/constants/lootTypeList'

export {lootCollection as trinketCollection} from '@/constants/lootList'

export default lootList
  .filter(
    ({type}) => type === LOOT_TYPE_TRINKET
  )
  .map(({id}) => ({
    id,
    probabilityWeight: 1,
  }))
