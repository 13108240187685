import {
 PC_RACE_AARAKOCRA,
 PC_RACE_DRAGONBORN,
 PC_RACE_DWARF,
 PC_RACE_ELF,
 PC_RACE_GNOME,
 PC_RACE_GOLIATH,
 PC_RACE_HALF_ELF,
 PC_RACE_HALF_ORC,
 PC_RACE_HALFLING,
 PC_RACE_HUMAN,
 PC_RACE_TIEFLING,
 PC_RACE_WARFORGED,
} from '@/constants/pcRaceIdList'

export {pcRaceCollection as raceCollection} from '@/constants/pcRaceList'

export default [
  {
    id: PC_RACE_DRAGONBORN,
    probabilityWeight: 5,
  },
  {
    id: PC_RACE_DWARF,
    probabilityWeight: 10,
  },
  {
    id: PC_RACE_ELF,
    probabilityWeight: 10,
  },
  {
    id: PC_RACE_GNOME,
    probabilityWeight: 5,
  },
  {
    id: PC_RACE_HALF_ELF,
    probabilityWeight: 5,
  },
  {
    id: PC_RACE_HALF_ORC,
    probabilityWeight: 5,
  },
  {
    id: PC_RACE_HALFLING,
    probabilityWeight: 10,
  },
  {
    id: PC_RACE_HUMAN,
    probabilityWeight: 40,
  },
  {
    id: PC_RACE_TIEFLING,
    probabilityWeight: 5,
  },
  {
    id: PC_RACE_WARFORGED,
    probabilityWeight: 1,
  },
  {
    id: PC_RACE_GOLIATH,
    probabilityWeight: 5,
  },
  {
    id: PC_RACE_AARAKOCRA,
    probabilityWeight: 5,
  },
]
