import React from 'react'

import { VARIANT_KNOW_PARENTS } from '@/components/CharacterGenerator/constants/variantIdList'

import SimpleCharacterProp from './../SimpleCharacterProp'

export default props => (
  <SimpleCharacterProp
    variantId={VARIANT_KNOW_PARENTS}
    {...props}
  />
)
