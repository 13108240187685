import React from 'react'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import CharacterGenerator from '@/components/CharacterGenerator'

const CharacterGeneratorPage = () => (
  <Layout>
    <Seo
      title='Генератор персонажей для D&D'
      description='Генератор персонажей для Dungeons & Dragons'
    />
    <CharacterGenerator/>
  </Layout>
)

export default CharacterGeneratorPage
