import spellList from '@/constants/spellList'

export {spellCollection as cantripIdVariantCollection} from '@/constants/spellList'

const cantripIdVariantList = spellList
  .filter(({lvl}) => lvl === 0)
  .map(({id}) => ({
    id,
    probabilityWeight: 1,
  }))

export default cantripIdVariantList
