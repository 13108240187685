import listToCollectionById from '@/utils/listToCollectionById'

import { PLANE_TYPE_OUTER } from '@/constants/planeTypeList'

import planeList from '@/constants/planeList'

const outerPlaneList = planeList
  .filter(
    ({ type }) => type === PLANE_TYPE_OUTER,
  )
  .map(
    outerPlane => ({
      ...outerPlane,
      name: outerPlane.fullName.nominative,
      probabilityWeight: outerPlane.probabilityWeight || 1,
    }),
  )

export default outerPlaneList

export const outerPlaneCollection = listToCollectionById(outerPlaneList)
