import listToCollectionById from '@/utils/listToCollectionById'
import {CREATURE_SLAAD_TADPOLE} from '@/constants/creatureIdList'
import {
  VARIANT_BEAST,
  VARIANT_CRIME,
  VARIANT_GOD,
  VARIANT_MONSTER,
  VARIANT_OCCUPATION,
  VARIANT_RACE,
} from '@/components/CharacterGenerator/constants/variantIdList'

export const DEATH_ACCIDENT_NOT_PROFESSIONAL = 'death_accident_not_professional'
export const DEATH_ACCIDENT_PROFESSIONAL = 'death_accident_professional'
export const DEATH_BATTLE = 'death_battle'
export const DEATH_DISASTER = 'death_disaster'
export const DEATH_DISEASE = 'death_disease'
export const DEATH_EAT_MONSTER = 'death_eat_monster'
export const DEATH_EXECUTED = 'death_executed'
export const DEATH_GOD_HIMSELF = 'death_god_himself'
export const DEATH_METEORITE = 'death_meteorite'
export const DEATH_MURDERED = 'death_murdered'
export const DEATH_OLD = 'death_old'
export const DEATH_SLAAD = 'death_slaad'
export const DEATH_SUICIDE = 'death_suicide'
export const DEATH_TORN_BY_ANIMAL = 'death_torn_by_animal'
export const DEATH_TORTURE = 'death_torture'
export const DEATH_UNKNOWN = 'death_unknown'

const professionalText = `связанного с ★его★ родом занятий (${VARIANT_OCCUPATION})`
const accidentText = `★погиб★ из-за несчастного случая`

const deathList = [
  {
    name: `★умер★ по неизвестной причине`,
    id: DEATH_UNKNOWN,
    probabilityWeight: 24,
  },
  {
    name: `★был★ ★убит★ ${VARIANT_RACE}`,
    id: DEATH_MURDERED,
    detailDictIdList: VARIANT_RACE,
    probabilityWeight: 24,
  },
  {
    name: `★погиб★ в битве с ${VARIANT_RACE}`,
    id: DEATH_BATTLE,
    detailDictIdList: VARIANT_RACE,
    probabilityWeight: 24,
  },
  {
    name: `${accidentText}, ${professionalText}`,
    id: DEATH_ACCIDENT_PROFESSIONAL,
    detailDictIdList: VARIANT_OCCUPATION,
    probabilityWeight: 240,
  },
  {
    name: `${accidentText}, не ${professionalText}`,
    id: DEATH_ACCIDENT_NOT_PROFESSIONAL,
    detailDictIdList: VARIANT_OCCUPATION,
    probabilityWeight: 240,
  },
  {
    name: `★умер★ от болезни`,
    id: DEATH_DISEASE,
    probabilityWeight: 12,
  },
  {
    name: `★умер★ от старости`,
    id: DEATH_OLD,
    probabilityWeight: 12,
  },
  {
    name: `★совершил★ самоубийство`,
    id: DEATH_SUICIDE,
    probabilityWeight: 24,
  },
  {
    name: `★был★ ★разорван★ на части диким животным ${VARIANT_BEAST}`,
    id: DEATH_TORN_BY_ANIMAL,
    detailDictIdList: VARIANT_BEAST,
    probabilityWeight: 12,
  },
  {
    name: `★погиб★ во время природного бедствия`,
    id: DEATH_DISASTER,
    probabilityWeight: 12,
  },
  {
    name: `★был★ ★сожран★ монстром ${VARIANT_MONSTER}`,
    id: DEATH_EAT_MONSTER,
    detailDictIdList: VARIANT_MONSTER,
    probabilityWeight: 24,
  },
  {
    name: `★был★ ★казнён★ за ${VARIANT_CRIME}`,
    id: DEATH_EXECUTED,
    detailDictIdList: VARIANT_CRIME,
    probabilityWeight: 12,
  },
  {
    name: `★умер★ под пытками`,
    id: DEATH_TORTURE,
    probabilityWeight: 12,
  },
  {
    name: `★был★ ★убит★ метеоритом`,
    id: DEATH_METEORITE,
    probabilityWeight: 1,
  },
  {
    name: `★был★ ★поражён★ ${VARIANT_GOD}`,
    id: DEATH_GOD_HIMSELF,
    detailDictIdList: VARIANT_GOD,
    probabilityWeight: 1,
  },
  {
    name: `★погиб★ во время вылупления [слаада](CREATURE:${CREATURE_SLAAD_TADPOLE})`,
    id: DEATH_SLAAD,
    probabilityWeight: 1,
  },
]

export default deathList
export const deathCollection = listToCollectionById(deathList)
