const {BACKGROUND_GIANT_FOUNDLING_2022_07_18} = require('./../../backgroundIdList')
const {SOURCE_UA_2022_07_18_WotM} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  SKILL_INTIMIDATION,
  SKILL_SURVIVAL,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_GIANT_FOUNDLING_2022_07_18,
  isReady: false,
  nameByGender: {
    [GENDER_MALE]: 'Приёмыш великанов',
    [GENDER_FEMALE]: 'Приёмыш великанов',
  },
  nameEn: 'Giant Foundling',
  proficiency: {
    skill: {
      list: [
        SKILL_INTIMIDATION,
        SKILL_SURVIVAL,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: `
* Рюкзак
* [Комплект дорожной одежды](GEAR:${GEAR_CLOTHES_TRAVELERS}),
* Камешек или веточка, напоминающие Вам о доме
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм
`,
  source: {
    id: SOURCE_UA_2022_07_18_WotM,
    page: 4,
  },
}
