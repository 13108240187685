const {BACKGROUND_NOBLE} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_HISTORY,
  SKILL_PERSUASION,
} = require('./../../skillList')
const {
  GEAR_CLOTHES_FINE,
  GEAR_SIGNET_RING,
} = require('./../../gearIdList')
const {
  CAT_GAME_SET,
} = require('./../../gearCategoryList')
const {
  LANG_ANY,
} = require('./../../languageIdList')

module.exports = {
  id: BACKGROUND_NOBLE,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Благородный',
    [GENDER_FEMALE]: 'Благородная',
  },
  nameEn: 'Noble',
  description: [
    {
      header: 'Благородный',
      text: `Вы знаете, что такое богатство, власть и привилегии. У вас благородный титул, а ваша семья владеет землями, собирает налоги, и обладает серьёзным политическим влиянием. Вы можете быть изнеженным аристократом, незнакомым с работой и неудобствами, бывшим торговцем, только-только получившим титул, или лишённым наследства негодяем с гипертрофированным чувством собственного права. Или же Вы можете быть честным, трудолюбивым землевладельцем, искренне заботящимся о тех, кто живёт и трудится на его земле, ощущая за них ответственность.

Придумайте вместе с Мастером надлежащий титул и определите, какую власть этот титул даёт. Благородный титул не существует сам по себе — он связан со всей вашей семьёй, и каким бы титулом Вы ни обладали, Вы передадите его своим детям. Вам нужно не только определить титул, но также описать вместе с Мастером вашу семью и её влияние на вас.

Ваша семья старая и прославленная, или она основана недавно? Каким влиянием она обладает, и в какой области? Какая репутация у вашей семьи среди прочих местных аристократов? Как к вам относятся обыватели?

Какое место занимаете Вы в своей семье? Являетесь ли Вы наследником главы семейства? Перешёл ли к вам уже титул? Как Вы относитесь к этой ответственности? Или Вы так далеки от получения наследства, что всем всё равно, чем Вы там занимаетесь, лишь бы не во вред семье? Как глава вашего семейства относится к вашей карьере искателя приключений? Вы в милости, или родственники сторонятся вас?

Есть ли у вашей семьи герб? Есть ли у вас кольцо с печатью? Может, Вы одеты в особые цвета? Есть ли животное, считающееся символом вашей родословной или духовным наставником?

Такие детали помогут сформировать образ вашей семьи в контексте мира и кампании.`,
      source: {
        id: SOURCE_PHB,
        page: 136,
      },
    },
    {
      header: 'Умение: Привилегированность',
      text: `Благодаря знатному происхождению, другие хорошо к вам относятся. Вас принимают в высшем обществе, и считается, что у вас есть право посещать любые места. Обыватели изо всех сил стараются сделать вам приятно и избежать вашего гнева, а другие высокородные считают вас своей ровней. Если нужно, Вы можете получить аудиенцию местного дворянина.`,
      source: {
        id: SOURCE_PHB,
        page: 136,
      },
    },
    {
      header: 'Альтернативное умение: Слуги',
      text: `Если у вашего персонажа предыстория благородного, Вы можете выбрать это умение вместо **«Привилегированности»**.

На вас работают трое слуг, преданных вашей семье. Это могут быть помощники и слуги, а один может быть дворецким. Это обыватели, выполняющие за вас простую работу, но они не будут за вас сражаться, не пойдут за вами в опасные места (такие как подземелья), и они покинут вас, если их будут подвергать опасности или с ними будут плохо обращаться. `,
      source: {
        id: SOURCE_PHB,
        page: 137,
      },
    },
    {
      header: 'Персонализация',
      text: `Благородные рождаются и растут в особом окружении, и их личность несёт отпечаток этого воспитания. Благородный титул идёт вкупе с множеством обязательств — ответственностью перед семьёй, другими благородными (включая короля), народом, доверенным вашей семье, и даже перед самим титулом. Все эти обязательства другие могут использовать против вас. `,
      source: {
        id: SOURCE_PHB,
        page: 136,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Я применяю так много лести, что все, с кем я разговариваю, чувствуют себя самыми чудесными и важными персонами в мире. |
| 2 | Обыватели любят меня за доброту и великодушие. |
| 3 | Один лишь взгляд на мои регалии заставляет перестать сомневаться в том, что я выше немытого отребья. |
| 4 | Я много трачу на то, чтобы выглядеть потрясающе, и по последнему слову моды. |
| 5 | Мне не нравится марать руки, и я не хочу умереть в каком-нибудь неподобающем месте. |
| 6 | Несмотря на благородное рождение, я не ставлю себя выше народа. У всех нас течёт одинаковая кровь. |
| 7 | Потеряв мою милость, обратно её не вернёшь. |
| 8 | Оскорбишь меня, и я сотру тебя в порошок, уничтожу твоё имя, и сожгу твои поля. |
`,
      source: {
        id: SOURCE_PHB,
        page: 136,
      },
    },
    {
      header: 'Мировоззрение',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Уважение.** Уважение — мой долг. Кем бы ты ни был, к другим нужно относиться с уважением, невзирая на их происхождение. | Доброе |
| 2 | **Ответственность.** Я должен уважать тех, кто выше меня, а те, кто ниже меня, должны уважать меня. | Принципиальное |
| 3 | **Независимость.** Я должен доказать, что справлюсь и без заботы своей семьи. | Хаотичное |
| 4 | **Власть.** Если соберу много власти, никто не посмеет указывать мне, что делать. | Злое |
| 5 | **Семья.** Настоящая кровь гуще. | Любое |
| 6 | **Благородный **долг. Я должен защищать тех, кто ниже меня, и заботиться о них. | Доброе |
`,
      source: {
        id: SOURCE_PHB,
        page: 137,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Я пойду на любой риск, лишь бы заслужить признание семьи. |
| 2 | Союз моего дома с другой благородной семьёй нужно поддерживать любой ценой. |
| 3 | Нет никого важнее других членов моей семьи. |
| 4 | Я влюблён в наследницу семейства, презираемого моей роднёй. |
| 5 | Моя преданность правителю непоколебима. |
| 6 | Обыватели должны считать меня своим героем. |
`,
      source: {
        id: SOURCE_PHB,
        page: 137,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Я втайне считаю всех ниже себя. |
| 2 | Я скрываю позорную тайну, которая может уничтожить мою семью. |
| 3 | Я слишком часто слышал завуалированные оскорбления и угрозы, и потому быстро впадаю в гнев. |
| 4 | У меня неустанная страсть к плотским удовольствиям. |
| 5 | Весь мир вращается вокруг меня. |
| 6 | Я часто навлекаю позор на свою семью словами и действиями. |
`,
      source: {
        id: SOURCE_PHB,
        page: 137,
      },
    },
  ],
  proficiency: {
    tool: {
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_GAME_SET,
        ],
      },
    },
    skill: {
      list: [
        SKILL_HISTORY,
        SKILL_PERSUASION,
      ],
    },
    language: LANG_ANY,
  },
  equipment: `
* [Комплект отличной одежды](GEAR:${GEAR_CLOTHES_FINE}),
* [Кольцо с печатью](GEAR:${GEAR_SIGNET_RING}),
* Свиток с генеалогическим древом,
* Кошелёк с 25 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 136,
  },
}
