import listToCollectionById from '@/utils/listToCollectionById'
import {GENDER_MALE, GENDER_FEMALE} from '@/constants/genderList'

export const AGE_20_LESS = 'age_20_less'
export const AGE_20_30 = 'age_20_30'
export const AGE_30_40 = 'age_30_40'
export const AGE_40_50 = 'age_40_50'
export const AGE_50_60 = 'age_50_60'
export const AGE_61_PLUS = 'age_61_plus'

const ageVariantList = [
  {
    name: {
      [GENDER_MALE]: 'Юный',
      [GENDER_FEMALE]: 'Юная',
    },
    id: AGE_20_LESS,
    lifePart: {
      from: 0,
      to: .2,
    },
    probabilityWeight: 20,
  },
  {
    name: {
      [GENDER_MALE]: 'Молодой',
      [GENDER_FEMALE]: 'Молодая',
    },
    id: AGE_20_30,
    lifePart: {
      from: .2,
      to: .3,
    },
    probabilityWeight: 40,
  },
  {
    name: {
      [GENDER_MALE]: 'Взрослый',
      [GENDER_FEMALE]: 'Взрослая',
    },
    id: AGE_30_40,
    lifePart: {
      from: .3,
      to: .4,
    },
    probabilityWeight: 10,
  },
  {
    name: {
      [GENDER_MALE]: 'Среднего возраста',
      [GENDER_FEMALE]: 'Среднего возраста',
    },
    id: AGE_40_50,
    lifePart: {
      from: .4,
      to: .5,
    },
    probabilityWeight: 20,
  },
  {
    name: {
      [GENDER_MALE]: 'Пожилой',
      [GENDER_FEMALE]: 'Пожилая',
    },
    id: AGE_50_60,
    lifePart: {
      from: .5,
      to: .6,
    },
    probabilityWeight: 10,
  },
  {
    name: {
      [GENDER_MALE]: 'Старый',
      [GENDER_FEMALE]: 'Старая',
    },
    id: AGE_61_PLUS,
    lifePart: {
      from: .6,
      to: .8,
    },
    probabilityWeight: 1,
  },
]

export default ageVariantList
export const ageVariantCollection = listToCollectionById(ageVariantList)
