import declint from 'declint-ru'
import uid from 'uid'

import calcParamBonus from '@/utils/calcParamBonus'
import downloadObjectAsJson from '@/utils/downloadObjectAsJson'
import generateAgeText from '@/utils/generateAgeText'
import generateBondText from '@/utils/generateBondText'
import generateFaceText from '@/utils/generateFaceText'
import generateFlawText from '@/utils/generateFlawText'
import generateHairText from '@/utils/generateHairText'
import generateSizeText from '@/utils/generateSizeText'
import generateTraitTextList from '@/utils/generateTraitTextList'
import trackAnalyticEvent from '@/utils/trackAnalyticEvent'
import upLetter from '@/utils/upLetter'

import generateLifeEventTextList from '@/components/CharacterGenerator/utils/generateLifeEventTextList'
import generateFamilyLifeStyleText from '@/components/CharacterGenerator/utils/generateFamilyLifeStyleText'
import generateChildhoodHomeText from '@/components/CharacterGenerator/utils/generateChildhoodHomeText'
import generateMissedParentText from '@/components/CharacterGenerator/utils/generateMissedParentText'
import generateParentHalfRaceText from '@/components/CharacterGenerator/utils/generateParentHalfRaceText'
import {
  VARIANT_FAMILY,
  VARIANT_KNOW_PARENTS,
  VARIANT_LIVE_EVENT,
} from '@/components/CharacterGenerator/constants/variantIdList'

import { pcRaceCollection } from '@/constants/pcRaceList'
import { OCCUPATION_ADVENTURER, occupationCollection } from '@/constants/occupationVariantList'
import {backgroundCollection} from '@/constants/backgroundList'
import {appearanceCollection} from '@/constants/appearanceList'

import generateAlignmentText from './../utils/generateAlignmentText'
import generateBirthplaceText from './../utils/generateBirthplaceText'

import defaultVttCharacter from './../constants/FoundryVTT/defaultVttCharacter'

export default (character) => {
  const id = uid(16)
  const abilities = Object
    .keys(character.stats)
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: {
          value: character.stats[key],
          proficient: 0,
        },
      }),
      {}
    )
  const background = upLetter(
    character.occupationId === OCCUPATION_ADVENTURER
      ? backgroundCollection[character.backgroundId].nameByGender[character.genderId]
      : occupationCollection[character.occupationId].name[character.genderId]
  )

  const appearance = `${appearanceCollection[character.appearanceId].name}
${generateFaceText(character)}
${generateHairText(character)}`

  const lifeEventText = generateLifeEventTextList(character, VARIANT_LIVE_EVENT)
    .map(
      item => {
        const { text, happensAtAge } = typeof item == 'string'
          ? { text: item }
          : item
        const ageText = `${happensAtAge} ${declint(happensAtAge, ['год', 'года', 'лет'])}`

        return `<li><b>${ageText}</b>. ${text}</li>`
      }
    )
    .join('\n')

  const familyInfoText = generateLifeEventTextList(character, VARIANT_FAMILY)
    .map(({text}) => `<p>${text}</p>`)
    .join('\n')

  const knowParentsText = generateLifeEventTextList(character, VARIANT_KNOW_PARENTS)
    .map(({text}) => `<p>${text}</p>`)
    .join('\n')

  const missedParentText = generateMissedParentText(character)
    .map(text => `<p>${text}</p>`)
    .join('\n')

  const parentHalfRace = generateParentHalfRaceText(character)
  const parentHalfRaceText = parentHalfRace
    ? `<p>${parentHalfRace}</p>`
    : ''

  const biographyText = `
<h1>Внешний вид</h1>

<h2>Возраст</h2>
${generateAgeText(character)}

<h2>Размер</h2>
${generateSizeText(character)}

<hr/>

<h1>Семья</h1>
  
<p>${generateBirthplaceText(character)}</p>
${familyInfoText}
<p>${generateFamilyLifeStyleText(character)}</p>
<p>${generateChildhoodHomeText(character)}</p>
${knowParentsText}
${parentHalfRaceText}
${missedParentText}

<hr/>

<h1>События в жизни</h1>

<ul>${lifeEventText}</ul>
`

  const vttCharacter = {
    ...defaultVttCharacter,
    items: [],
    _id: id,
    name: character.name,
    data: {
      ...defaultVttCharacter.data,
      abilities,
      attributes: {
        ...defaultVttCharacter.data.attributes,
        ac: {
          value: 10 + calcParamBonus(character.stats.dex),
        },
        init: {
          ...defaultVttCharacter.data.attributes.init,
          bonus: calcParamBonus(character.stats.dex),
        },
      },
      details: {
        ...defaultVttCharacter.data.details,
        appearance,
        background,
        alignment: upLetter(generateAlignmentText(character)),
        trait: generateTraitTextList(character).join('\n\n'),
        bond: generateBondText(character),
        flaw: generateFlawText(character),
        race: upLetter(pcRaceCollection[character.raceId].name.singular[character.genderId].nominative),
        xp: {
          ...defaultVttCharacter.data.details.xp,
          value: 0,
        },
        biography: {
          ...defaultVttCharacter.data.details.biography,
          value: biographyText,
        },
      },
      traits: {
        ...defaultVttCharacter.data.traits,
        size: pcRaceCollection[character.raceId].sizeType,
      },
    },
  }

  delete vttCharacter.img
  delete vttCharacter.token

  console.info('generateVttCharacter', {abilities, vttCharacter});
  trackAnalyticEvent('generate-character-vtt-json')

  downloadObjectAsJson(vttCharacter, character.name)
}
