const {BACKGROUND_ACOLYTE} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_POUCH,
  GEAR_ROBES,
} = require('./../../gearIdList')
const {
  SKILL_INSIGHT,
  SKILL_RELIGION,
} = require('./../../skillList')

module.exports = {
  id: BACKGROUND_ACOLYTE,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Прислужник',
    [GENDER_FEMALE]: 'Прислужница',
  },
  nameEn: 'Acolyte',
  description: [
    {
      header: 'Прислужник',
      text: `Вы провели свою жизнь, служа в храме, посвящённому какому-то конкретному богу или же пантеону богов. Вы — посредник между царством небесным и миром живых. Вы совершали священные ритуалы и приносили жертвоприношения для того чтобы молящиеся могли предстать пред ликом богов. Вы не обязательно жрец — совершение священных обрядов не то же самое, что направление божественной силы.

Выберите божество, пантеон богов или полубожественную сущность из тех, что перечислены в [каталоге](/god-catalog) или предложены Мастером, и продумайте вместе с Мастером природу вашего религиозного служения. Вы были младшим служкой в храме, с детства помогающим священникам? Или Вы верховный жрец, получивший видение, как можно послужить своему богу? Возможно, вы — лидер небольшого культа, не имеющего своего храма, или даже представитель оккультных сил, служивший чудовищному повелителю, но отрёкшийся от него. `,
      source: {
        id: SOURCE_PHB,
        page: 128,
      },
    },
    {
      header: 'Умение: Приют для верующих',
      text: `Вы и ваши спутники можете рассчитывать на бесплатное лечение и уход в храмах, святынях и других подобных местах, посвящённых вашей вере. Вам придётся предоставить материальные компоненты для заклинаний, если таковые понадобятся. Те, кто разделяют вашу веру, могут обеспечить вам (но только вам) скромное существование.

У вас также могут быть связи с каким-то конкретным храмом, посвящённым вашему божеству или пантеону, в котором у вас есть жилая комната. Пока Вы находитесь с этим храмом в хороших отношениях, находясь неподалёку от него, Вы можете попросить у его служителей помощи, если она не подвергнет их опасности. `,
      source: {
        id: SOURCE_PHB,
        page: 128,
      },
    },
    {
      header: 'Персонализация',
      text: `Прислужники изучают историю и догматы своей веры, и на их привычки и идеалы влияет их отношение к храмам, святыням и иерархиям. Их слабостью может быть лицемерие или еретическая идея, либо идеал или привязанность, возведённая до гротеска.`,
      source: {
        id: SOURCE_PHB,
        page: 128,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Я идеализирую конкретного героя своей веры и постоянно ссылаюсь на его поступки и свершения. |
| 2 | Я могу найти общую позицию даже у самых яростных врагов, сопереживая им, и всегда стремясь к примирению. |
| 3 | Я вижу знамения в каждом событии и поступке. Боги пытаются говорить с нами, нужно лишь прислушаться. |
| 4 | Ничто не может поколебать мой оптимизм. |
| 5 | При любой оказии я цитирую (или перевираю) священные тексты и притчи. |
| 6 | Я терпим (или нетерпим) к другим верованиям, и уважаю (или порицаю) поклонение другим богам. |
| 7 | Я люблю хорошую еду, выпивку и высокое общество представителей своего храма. Жизнь вдали от этого меня раздражает. |
| 8 | Я пробыл в храме слишком долго, и мне недостаёт опыта взаимодействия с людьми за его пределами. |
`,
      source: {
        id: SOURCE_PHB,
        page: 128,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Я не пощажу живота своего, лишь бы найти древнюю реликвию своей веры, что была потеряна давнымдавно. |
| 2 | Однажды я отомщу развращённым представителям верховной иерархии своего храма, что объявили меня еретиком. |
| 3 | Я обязан своей жизнью священнику, который позаботился обо мне, когда умерли родители. |
| 4 | Всё, что я делаю — для простых людей. |
| 5 | Я пойду на всё что угодно, дабы защитить свой храм. |
| 6 | Я пытаюсь сохранить священные тексты, которые мои враги считают еретическими и пытаются уничтожить. |
`,
      source: {
        id: SOURCE_PHB,
        page: 128,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Я не проявляю снисходительности к другим, но к себе я ещё более суров. |
| 2 | Я слишком доверяю тем, кто обладает властью в моей церковной иерархии. |
| 3 | Моя набожность зачастую приводит к тому, что я слепо верю всем, кто исповедует мою религию. |
| 4 | Я непреклонен в своих убеждениях. |
| 5 | Я настороженно отношусь к незнакомцам и всегда жду от них худшего. |
| 6 | Однажды выбрав цель, я становлюсь одержимым ею в ущерб всему прочему в своей жизни. |
`,
      source: {
        id: SOURCE_PHB,
        page: 128,
      },
    },
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_INSIGHT,
        SKILL_RELIGION,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: `
* Священный символ (подаренный Вам в момент принятия священного сана),
* Молитвенник или молитвенный барабан,
* 5 палочек благовоний,
* [Облачение](GEAR:${GEAR_ROBES}),
* [Комплект обычной одежды](GEAR:${GEAR_CLOTHES_COMMON}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 15 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 128,
  },
}
