import listToCollectionById from '@/utils/listToCollectionById'
import {
  BACKGROUND_ACOLYTE,
  BACKGROUND_AGENT_EMERALD_ENCLAVE,
  BACKGROUND_AGENT_HARPERS,
  BACKGROUND_AGENT_ORDER_GAUNTLET,
  BACKGROUND_AGENT_ZHENTARIM,
  BACKGROUND_CHARLATAN,
  BACKGROUND_CITY_WATCH,
  BACKGROUND_CLAN_CRAFTER,
  BACKGROUND_CLOISTERED_SCHOLAR,
  BACKGROUND_COURTIER,
  BACKGROUND_CRIMINAL,
  BACKGROUND_ENTERTAINER,
  BACKGROUND_FAR_TRAVELLER,
  BACKGROUND_FOLK_HERO,
  BACKGROUND_GUILD_ARTISAN,
  BACKGROUND_GUILD_MERCHANT,
  BACKGROUND_HERMIT,
  BACKGROUND_INHERITOR,
  BACKGROUND_INVESTIGATOR,
  BACKGROUND_KNIGHT,
  BACKGROUND_KNIGHT_OF_THE_ORDER,
  BACKGROUND_MERCENARY_VETERAN,
  BACKGROUND_NOBLE,
  BACKGROUND_OUTLANDER,
  BACKGROUND_PIRATE,
  BACKGROUND_SAGE,
  BACKGROUND_SAILOR,
  BACKGROUND_SOLDIER,
  BACKGROUND_SPY,
  BACKGROUND_URBAN_BOUNTY_HUNTER,
  BACKGROUND_URCHIN,
  BACKGROUND_UTHGARDT_TRIBE_MEMBER,
  BACKGROUND_WATERDHAVIAN_NOBLE,
} from '@/constants/backgroundIdList'

const FLAW_BOLSHE = 'flaw_bolshe'
const FLAW_BOLTUN = 'flaw_boltun'
const FLAW_CATEGORICHEN = 'flaw_categorichen'
const FLAW_CULTURA_OK_HIDDEN = 'flaw_cultura_ok_hidden'
const FLAW_CULTURA_OK_PUBLIC = 'flaw_cultura_ok_public'
const FLAW_DEMON = 'flaw_demon'
const FLAW_DENGI = 'flaw_dengi'
const FLAW_DOLGI = 'flaw_dolgi'
const FLAW_DOVERIE_CERKVI = 'flaw_doverie_cerkvi'
const FLAW_DOVERIE_FRAKCII = 'flaw_doverie_frakcii'
const FLAW_GNEV = 'flaw_gnev'
const FLAW_GORDOST = 'flaw_gordost'
const FLAW_HRANIT_TAYNY = 'flaw_hranit_tayny'
const FLAW_INFO = 'flaw_info'
const FLAW_JADEN = 'flaw_jaden'
const FLAW_KONKURENTY = 'flaw_konkurenty'
const FLAW_LENTAY = 'flaw_lentay'
const FLAW_MIR_VRASHAETSYA = 'flaw_mir_vrashaetsya'
const FLAW_MONSTER = 'flaw_monster'
const FLAW_MOTIVY = 'flaw_motivy'
const FLAW_NASILIE = 'flaw_nasilie'
const FLAW_NASTOROJEN = 'flaw_nastorojen'
const FLAW_NEPRAV = 'flaw_neprav'
const FLAW_NEPREKLONEN = 'flaw_nepreklonen'
const FLAW_NET_DENEG = 'flaw_net_deneg'
const FLAW_NET_OSTOROJNOSTI = 'flaw_net_ostorojnosti'
const FLAW_NEVINNIY = 'flaw_nevinniy'
const FLAW_NE_DUMAYU = 'flaw_ne_dumayu'
const FLAW_NE_POLAGATSYA = 'flaw_ne_polagatsya'
const FLAW_NE_ZNAYU_YAZYKA = 'flaw_ne_znayu_yazyka'
const FLAW_NIKTO_NE_ODURACHIT = 'flaw_nikto_ne_odurachit'
const FLAW_OBJULIT = 'flaw_objulit'
const FLAW_OBLAPOSHIT = 'flaw_oblaposhit'
const FLAW_ODERJIM = 'flaw_oderjim'
const FLAW_OSHIBKA = 'flaw_oshibka'
const FLAW_OSKORBLENIYA = 'flaw_oskorbleniya'
const FLAW_OSTRIY_YAZYK = 'flaw_ostriy_yazyk'
const FLAW_PLOT = 'flaw_plot'
const FLAW_POBEDA_V_SPORE = 'flaw_pobeda_v_spore'
const FLAW_POLUCHIT_REDKOE = 'flaw_poluchit_redkoe'
const FLAW_POROKI_GORODA = 'flaw_poroki_goroda'
const FLAW_POZORNAYA_TAYNA = 'flaw_pozornaya_tayna'
const FLAW_POZOR_NA_SEMYU = 'flaw_pozor_na_semyu'
const FLAW_PREDNAZNACHENIE = 'flaw_prednaznachenie'
const FLAW_PRIKAZY = 'flaw_prikazy'
const FLAW_PYANICA = 'flaw_pyanica'
const FLAW_RACIST = 'flaw_racist'
const FLAW_RADOSTI_MIRA = 'flaw_radosti_mira'
const FLAW_SILNYE_NE_SLABYE = 'flaw_silnye_ne_slabye'
const FLAW_SLANDAL = 'flaw_slandal'
const FLAW_SLAVA = 'flaw_slava'
const FLAW_SLEPO_VERYU = 'flaw_slepo_veryu'
const FLAW_SLOJNYE_RESHENIYA = 'flaw_slojnye_resheniya'
const FLAW_SMAZLIVAYA = 'flaw_smazlivaya'
const FLAW_SOBAKA_ZA_MASHINOY = 'flaw_sobaka_za_mashinoy'
const FLAW_SOYUZNIKI = 'flaw_soyuzniki'
const FLAW_SPIRT = 'flaw_spirt'
const FLAW_SUROV = 'flaw_surov'
const FLAW_TAYNA = 'flaw_tayna'
const FLAW_TEMNYE_MYSLI = 'flaw_temnye_mysli'
const FLAW_TIRAN = 'flaw_tiran'
const FLAW_TIRAN_UBIT = 'flaw_tiran_ubit'
const FLAW_TITUL = 'flaw_titul'
const FLAW_TOLKO_YA = 'flaw_tolko_ya'
const FLAW_TRUS = 'flaw_trus'
const FLAW_UBEGAYU = 'flaw_ubegayu'
const FLAW_UBEGU = 'flaw_ubegu'
const FLAW_UBIT_VO_SNE = 'flaw_ubit_vo_sne'
const FLAW_UKRAL_U_GILDII = 'flaw_ukral_u_gildii'
const FLAW_UKRAL_U_KLANA = 'flaw_ukral_u_klana'
const FLAW_UKRAST = 'flaw_ukrast'
const FLAW_VOIN = 'flaw_voin'
const FLAW_VOR = 'flaw_vor'
const FLAW_VOZLIYANIYA = 'flaw_vozliyaniya'
const FLAW_VRU = 'flaw_vru'
const FLAW_VSE_NIJE_MENYA = 'flaw_vse_nije_menya'
const FLAW_VYSMEYAL = 'flaw_vysmeyal'
const FLAW_VYZOV = 'flaw_vyzov'
const FLAW_V_JOPU_CIVILIZACIYU = 'flaw_v_jopu_civilizaciyu'
const FLAW_YAROST = 'flaw_yarost'
const FLAW_ZAKON = 'flaw_zakon'
const FLAW_ZASLUJILI = 'flaw_zaslujili'
const FLAW_ZNANIYA = 'flaw_znaniya'
const FLAW_ZOLOTO = 'flaw_zoloto'

const flawVariantList = [
  {
    id: FLAW_SUROV,
    name: `Я не проявляю снисходительности к другим, но к себе я ещё более ★суров★.`,
  },
  {
    id: FLAW_DOVERIE_CERKVI,
    name: `Я слишком доверяю тем, кто обладает властью в моей церковной иерархии.`,
    backgroundLimit: BACKGROUND_ACOLYTE,
  },
  {
    id: FLAW_DOVERIE_FRAKCII,
    name: `Я слишком доверяю тем, кто обладает властью в моей фракции.`,
    backgroundLimit: [
      BACKGROUND_AGENT_EMERALD_ENCLAVE,
      BACKGROUND_AGENT_HARPERS,
      BACKGROUND_AGENT_ORDER_GAUNTLET,
      BACKGROUND_AGENT_ZHENTARIM,
    ]
  },
  {
    id: FLAW_SLEPO_VERYU,
    name: `Моя набожность зачастую приводит к тому, что я слепо верю всем, кто исповедует мою религию.`,
    backgroundLimit: BACKGROUND_ACOLYTE,
  },
  {
    id: FLAW_NEPREKLONEN,
    name: `Я ★непреклонен★ в своих убеждениях.`,
    backgroundLimit: [
      BACKGROUND_ACOLYTE,
      BACKGROUND_AGENT_EMERALD_ENCLAVE,
      BACKGROUND_AGENT_HARPERS,
      BACKGROUND_AGENT_ORDER_GAUNTLET,
      BACKGROUND_AGENT_ZHENTARIM,
    ],
  },
  {
    id: FLAW_NASTOROJEN,
    name: `Я настороженно отношусь к незнакомцам и всегда жду от них худшего.`,
  },
  {
    id: FLAW_ODERJIM,
    name: `Однажды выбрав цель, я становлюсь ★одержимым★ ею в ущерб всему прочему в своей жизни.`,
  },
  {
    id: FLAW_SMAZLIVAYA,
    name: `Я не могу устоять перед смазливой мордашкой.`,
  },
  {
    id: FLAW_DOLGI,
    name: `Я постоянно в долгах. Я трачу свои нечестно нажитые деньги быстрее, чем их добываю.`,
    backgroundLimit: BACKGROUND_CHARLATAN,
  },
  {
    id: FLAW_NIKTO_NE_ODURACHIT,
    name: `Я ★убеждён★ в том, что никто не сможет одурачить меня так, как я одурачиваю других.`,
    backgroundLimit: BACKGROUND_CHARLATAN,
  },
  {
    id: FLAW_JADEN,
    name: `Я слишком ★жаден★ до золота. И не могу противиться риску, если в этом замешаны деньги.`,
  },
  {
    id: FLAW_OBLAPOSHIT,
    name: `Я не могу устоять и не облапошить людей, стоящих гораздо выше, чем я.`,
    backgroundLimit: BACKGROUND_CHARLATAN,
  },
  {
    id: FLAW_TRUS,
    name: `Я ненавижу себя за это, но я сбегу, чтобы спасти свою собственную шкуру, если вдруг запахнет жареным.`,
  },
  {
    id: FLAW_UKRAST,
    name: `Когда я вижу что-то ценное, я не могу думать ни о чём другом, кроме мыслей о том, как это украсть.`,
    backgroundLimit: BACKGROUND_CRIMINAL,
  },
  {
    id: FLAW_DENGI,
    name: `Когда я становлюсь перед выбором между друзьями и деньгами, я обычно выбираю деньги.`,
  },
  {
    id: FLAW_SOBAKA_ZA_MASHINOY,
    name: `Если есть какой-то план, я его наверняка забуду. А если не забуду, то попросту проигнорирую.`,
  },
  {
    id: FLAW_VRU,
    name: `У меня есть привычка, которая выдаёт меня, когда я вру.`,
  },
  {
    id: FLAW_UBEGAYU,
    name: `Я поджимаю хвост и убегаю, когда всё складывается плохо.`,
  },
  {
    id: FLAW_NEVINNIY,
    name: `Невинный человек находится в тюрьме из-за преступления, что я ★совершил★. Меня это не беспокоит.`,
    backgroundLimit: [
      BACKGROUND_CRIMINAL,
      BACKGROUND_URBAN_BOUNTY_HUNTER,
      BACKGROUND_SPY,
    ],
  },
  {
    id: FLAW_SLAVA,
    name: `Я пойду на всё ради славы и известности.`,
  },
  {
    id: FLAW_SLANDAL,
    name: `Я не могу вернуться домой из-за скандала. Неприятности такого рода словно преследуют меня.`,
  },
  {
    id: FLAW_VYSMEYAL,
    name: `Однажды я ★высмеял★ дворянина, который всё ещё хочет оторвать мне голову. Это была ошибка, но я могу повторить её ещё неоднократно.`,
    backgroundLimit: BACKGROUND_ENTERTAINER,
  },
  {
    id: FLAW_OSTRIY_YAZYK,
    name: `Я не могу скрывать свои истинные чувства. Острый язык всегда приносит мне неприятности.`,
  },
  {
    id: FLAW_NE_POLAGATSYA,
    name: `Я очень стараюсь исправиться, но друзьям не стоит на меня полагаться.`,
  },
  {
    id: FLAW_TIRAN_UBIT,
    name: `Тиран, правящий моими землями, ни перед чем не остановится, лишь бы убить меня.`,
    backgroundLimit: BACKGROUND_FOLK_HERO,
  },
  {
    id: FLAW_PREDNAZNACHENIE,
    name: `Я слепо верю в своё предназначение, и не замечаю ни своих недостатков, ни смертельных опасностей.`,
    backgroundLimit: [
      BACKGROUND_FOLK_HERO,
      BACKGROUND_INHERITOR,
    ],
  },
  {
    id: FLAW_TAYNA,
    name: `Те, кто знают меня ★молодым★, знают и мою самую постыдную тайну, так что я никогда не вернусь домой.`,
  },
  {
    id: FLAW_POROKI_GORODA,
    name: `Я ★беззащитен★ перед пороками большого города, особенно перед пьянством.`,
  },
  {
    id: FLAW_TIRAN,
    name: `Я тайно считаю, что было бы хорошо стать тираном, правящим землями.`,
    backgroundLimit: BACKGROUND_FOLK_HERO,
  },
  {
    id: FLAW_SOYUZNIKI,
    name: `Я не доверяю союзникам.`,
  },
  {
    id: FLAW_POLUCHIT_REDKOE,
    name: `Я сделаю всё что угодно, чтобы заполучить что-то редкое или очень ценное.`,
    backgroundLimit: [
      BACKGROUND_CRIMINAL,
      BACKGROUND_PIRATE,
      BACKGROUND_CLAN_CRAFTER,
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_GUILD_MERCHANT,
    ],
  },
  {
    id: FLAW_OBJULIT,
    name: `Мне всегда кажется, что меня пытаются обжулить.`,
  },
  {
    id: FLAW_UKRAL_U_GILDII,
    name: `Никто не должен знать, что я однажды ★украл★ деньги из казны гильдии.`,
    backgroundLimit: [
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_GUILD_MERCHANT,
    ],
  },
  {
    id: FLAW_UKRAL_U_KLANA,
    name: `Никто не должен знать, что я однажды ★украл★ деньги из казны клана.`,
    backgroundLimit: BACKGROUND_CLAN_CRAFTER,
  },
  {
    id: FLAW_BOLSHE,
    name: `Мне всегда мало того, что у меня есть — всегда нужно больше.`,
  },
  {
    id: FLAW_TITUL,
    name: `Я ★готов★ убить ради получения благородного титула.`,
    backgroundLimit: [
      BACKGROUND_CLAN_CRAFTER,
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_GUILD_MERCHANT,
    ],
  },
  {
    id: FLAW_KONKURENTY,
    name: `Я ужасно завидую тем, кто может превзойти мой труд. Куда бы я ни ★пошёл★, меня окружают конкуренты.`,
    backgroundLimit: [
      BACKGROUND_CLAN_CRAFTER,
      BACKGROUND_GUILD_ARTISAN,
    ],
  },
  {
    id: FLAW_RADOSTI_MIRA,
    name: `Теперь, вернувшись в мир, я слишком сильно увлекаюсь его радостями.`,
    backgroundLimit: BACKGROUND_HERMIT,
  },
  {
    id: FLAW_TEMNYE_MYSLI,
    name: `Я скрываю тёмные, кровожадные мысли, не ушедшие ни после изоляции, ни после медитаций.`,
    backgroundLimit: BACKGROUND_HERMIT,
  },
  {
    id: FLAW_CATEGORICHEN,
    name: `Я ★категоричен★ в мыслях и подходе к жизни.`,
  },
  {
    id: FLAW_POBEDA_V_SPORE,
    name: `Я позволяю жажде победить в споре становиться сильнее дружбы и гармонии.`,
  },
  {
    id: FLAW_ZNANIYA,
    name: `Я слишком сильно рискую, стремясь найти утраченные частички знаний.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: FLAW_HRANIT_TAYNY,
    name: `Мне нравится хранить тайны, и я ими ни с кем не делюсь.`,
  },
  {
    id: FLAW_VSE_NIJE_MENYA,
    name: `Я втайне считаю всех ниже себя.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_KNIGHT,
    ],
  },
  {
    id: FLAW_POZORNAYA_TAYNA,
    name: `Я скрываю позорную тайну, которая может уничтожить мою семью.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_COURTIER,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_KNIGHT,
    ],
  },
  {
    id: FLAW_GNEV,
    name: `Я слишком часто слышал завуалированные оскорбления и угрозы, и потому быстро впадаю в гнев.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_COURTIER,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_KNIGHT,
    ],
  },
  {
    id: FLAW_PLOT,
    name: `У меня неустанная страсть к плотским удовольствиям.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_COURTIER,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_KNIGHT,
    ],
  },
  {
    id: FLAW_MIR_VRASHAETSYA,
    name: `Весь мир вращается вокруг меня.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
    ],
  },
  {
    id: FLAW_POZOR_NA_SEMYU,
    name: `Я часто навлекаю позор на свою семью словами и действиями.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_KNIGHT,
      BACKGROUND_WATERDHAVIAN_NOBLE,
    ],
  },
  {
    id: FLAW_SPIRT,
    name: `Я слишком ★пристрастен★ к элю, вину и прочим спиртным напиткам.`,
  },
  {
    id: FLAW_NET_OSTOROJNOSTI,
    name: `В жизни на полную катушку нет места осторожности.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: FLAW_OSKORBLENIYA,
    name: `Я помню все полученные оскорбления и таю злобу на всех обидчиков.`,
  },
  {
    id: FLAW_RACIST,
    name: `Я с трудом доверяю представителям других рас, племён и сообществ.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: FLAW_NASILIE,
    name: `На любые неурядицы я почти всегда отвечаю насилием.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: FLAW_SILNYE_NE_SLABYE,
    name: `Не думайте, что я буду спасать тех, кто не может сам о себе позаботиться. Сильные должны процветать, а слабые погибают, и это нормально.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: FLAW_INFO,
    name: `Меня легко отвлечь, пообещав информацию.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: FLAW_DEMON,
    name: `Увидев демона, большинство закричит и убежит. Я же остановлюсь и буду изучать его анатомию.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: FLAW_V_JOPU_CIVILIZACIYU,
    name: `Для раскрытия древних тайн можно пожертвовать современной цивилизацией.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: FLAW_SLOJNYE_RESHENIYA,
    name: `Я избегаю очевидных решений, пользуясь замысловатыми.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: FLAW_NE_DUMAYU,
    name: `Я говорю, не обдумывая слова, чем часто оскорбляю других.`,
  },
  {
    id: FLAW_BOLTUN,
    name: `Ради спасения своей или чьей-либо ещё жизни я выболтаю любую тайну.`,
  },
  {
    id: FLAW_PRIKAZY,
    name: `Я выполняю приказы, даже если считаю, что они несправедливые.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_CITY_WATCH,
      BACKGROUND_INVESTIGATOR,
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
    ],
  },
  {
    id: FLAW_LENTAY,
    name: `Я наплету с три короба, лишь бы не делать лишнюю работу.`,
  },
  {
    id: FLAW_VYZOV,
    name: `Когда кто-то бросает мне вызов, я никогда не отступлюсь, какой бы опасной не была ситуация.`,
  },
  {
    id: FLAW_PYANICA,
    name: `Начав пить, я не могу остановиться.`,
  },
  {
    id: FLAW_NET_DENEG,
    name: `Не могу ничего поделать, но деньги у меня не задерживаются.`,
  },
  {
    id: FLAW_GORDOST,
    name: `Моя гордость меня когда-нибудь погубит.`,
  },
  {
    id: FLAW_MONSTER,
    name: `Одно чудовище, с которым мы когда-то сразились, до сих пор заставляет меня дрожать от страха.`,
    backgroundLimit: [
      BACKGROUND_CITY_WATCH,
      BACKGROUND_SOLDIER,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: FLAW_VOIN,
    name: `Я мало уважаю тех, кто не доказал, что является достойным воителем.`,
    backgroundLimit: [
      BACKGROUND_CITY_WATCH,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: FLAW_OSHIBKA,
    name: `В одном сражении я ★совершил★ ужасную ошибку, стоившую жизни многим — и я сделаю что угодно, лишь бы это оставалось в тайне.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: FLAW_YAROST,
    name: `Ярость к врагам ослепляет меня и делает ★безрассудным★.`,
    backgroundLimit: [
      BACKGROUND_CITY_WATCH,
      BACKGROUND_SOLDIER,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: FLAW_ZAKON,
    name: `Я подчиняюсь закону, даже если закон вызывает страдания.`,
    backgroundLimit: [
      BACKGROUND_CITY_WATCH,
      BACKGROUND_INVESTIGATOR,
      BACKGROUND_SOLDIER,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
    ],
  },
  {
    id: FLAW_NEPRAV,
    name: `Я скорее съем свой доспех, чем признаюсь, что ★был★ ★неправ★.`,
    backgroundLimit: [
      BACKGROUND_CITY_WATCH,
      BACKGROUND_SOLDIER,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: FLAW_UBEGU,
    name: `Если я в меньшинстве, то я убегу из битвы.`,
  },
  {
    id: FLAW_ZOLOTO,
    name: `Золото в любом виде выглядит для меня как большая куча денег, и я сделаю всё, чтобы его у меня стало больше.`,
    backgroundLimit: BACKGROUND_URCHIN,
  },
  {
    id: FLAW_TOLKO_YA,
    name: `Я никогда не доверяю полностью кому бы то ни было, кроме себя.`,
  },
  {
    id: FLAW_UBIT_VO_SNE,
    name: `Я предпочту убить кого-нибудь во сне, нежели в честном поединке.`,
    backgroundLimit: BACKGROUND_URCHIN,
  },
  {
    id: FLAW_VOR,
    name: `Это не воровство, если я ★взял★ то, в чём нуждаюсь больше, чем кто-либо другой.`,
  },
  {
    id: FLAW_ZASLUJILI,
    name: `Те, кто не могут позаботиться о себе, получат то, что заслужили.`,
  },
  {
    id: FLAW_CULTURA_OK_HIDDEN,
    name: `Я втайне ★убеждён★ в превосходстве своей культуры над культурой этих земель.`,
    backgroundLimit: BACKGROUND_FAR_TRAVELLER,
  },
  {
    id: FLAW_CULTURA_OK_PUBLIC,
    name: `Я открыто ★убеждён★ в превосходстве своей культуры над культурой этих земель.`,
    backgroundLimit: BACKGROUND_FAR_TRAVELLER,
  },
  {
    id: FLAW_NE_ZNAYU_YAZYKA,
    name: `Я прикидываюсь, что не понимаю местного языка, для того чтобы избежать взаимодействий, которые мне не по нраву.`,
    backgroundLimit: BACKGROUND_FAR_TRAVELLER,
  },
  {
    id: FLAW_VOZLIYANIYA,
    name: `У меня слабость к новым ощущениям в виде «возлияний» и прочих удовольствий этих земель.`,
    backgroundLimit: BACKGROUND_FAR_TRAVELLER,
  },
  {
    id: FLAW_MOTIVY,
    name: `Я резко реагирую на некоторые действия и мотивы местных людей, потому что эти люди отличаются от меня.`,
    backgroundLimit: BACKGROUND_FAR_TRAVELLER,
  },
].map(
  item => ({
    ...item,
    probabilityWeight: item.backgroundLimit ? 10 : 1,
  })
)

export default flawVariantList
export const flawVariantCollection = listToCollectionById(flawVariantList)
