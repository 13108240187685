import upLetter from '@/utils/upLetter'

import { VARIANT_GIFT } from '@/components/CharacterGenerator/constants/variantIdList'
import generateTextByCharacter from '@/components/CharacterGenerator/utils/generateTextByCharacter'

export default character => {
  const {text} = generateTextByCharacter(character)(character[VARIANT_GIFT])

  return upLetter(text)
}
