import listToCollectionById from '@/utils/listToCollectionById'
import {
  PC_RACE_AASIMAR,
  PC_RACE_BUGBEAR,
  PC_RACE_DRAGONBORN,
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_FILBORG,
  PC_RACE_GNOME,
  PC_RACE_GOBLIN,
  PC_RACE_GOLIATH,
  PC_RACE_GRUNG,
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_HALFLING,
  PC_RACE_HOBGOBLIN,
  PC_RACE_HUMAN,
  PC_RACE_KENKU,
  PC_RACE_KOBOLD,
  PC_RACE_LIZARDFOLK,
  PC_RACE_ORC,
  PC_RACE_TABAXI,
  PC_RACE_TIEFLING,
  PC_RACE_TRITON,
  PC_RACE_YUAN_TI_PUREBLOOD,
} from '@/constants/pcRaceIdList'
import {
  PC_SUBRACE_ELF_MOON,
  PC_SUBRACE_ELF_SUN,
  PC_SUBRACE_ELF_WOOD,
  PC_SUBRACE_ELF_DROW,
  PC_SUBRACE_DWARF_MOUNTAIN,
  PC_SUBRACE_DWARF_HILL,
  PC_SUBRACE_DWARF_GRAY,
  PC_SUBRACE_HUMAN_SHOU,
  PC_SUBRACE_HUMAN_CHONDATAN,
  PC_SUBRACE_HUMAN_TURAMI,
  PC_SUBRACE_HUMAN_TETHYRIAN,
  PC_SUBRACE_HUMAN_RASHEMI,
  PC_SUBRACE_HUMAN_CALISHITE,
  PC_SUBRACE_HUMAN_MULAN,
  PC_SUBRACE_HUMAN_FFOLK,
  PC_SUBRACE_HUMAN_ILLUSKAN,
  PC_SUBRACE_HUMAN_DAMARAN,
  PC_SUBRACE_HUMAN_HALRUAAN,
  PC_SUBRACE_HUMAN_IMASKARI,
  PC_SUBRACE_HUMAN_NAR,
  PC_SUBRACE_HUMAN_SHAARAN,
  PC_SUBRACE_HUMAN_TUIGAN,
  PC_SUBRACE_HUMAN_ULUTIUN,
  PC_SUBRACE_HUMAN_CHULT,
  PC_SUBRACE_HUMAN_ARKAIUN,
  PC_SUBRACE_HUMAN_BEDINE,
  PC_SUBRACE_HUMAN_GUR,
} from '@/constants/pcSubraceIdList'

export const HAIR_COLOR_BROWN = 'brown'
export const HAIR_COLOR_AUBURN = 'auburn'
export const HAIR_COLOR_BLOND = 'blond'
export const HAIR_COLOR_RED = 'red'
export const HAIR_COLOR_GOLDEN = 'golden'
export const HAIR_COLOR_SILVER = 'silver'
export const HAIR_COLOR_GRAY = 'gray'
export const HAIR_COLOR_WHITE = 'white'
export const HAIR_COLOR_BLACK = 'black'
export const HAIR_COLOR_GREEN = 'green'
export const HAIR_COLOR_BLUE = 'blue'
export const HAIR_COLOR_VIOLET = 'violet'
export const HAIR_COLOR_LIGHT_BLUE = 'light_blue'
export const HAIR_COLOR_DYED_BLACK = 'dyed_black'
export const HAIR_COLOR_DYED_BLUE = 'dyed_blue'
export const HAIR_COLOR_DYED_PURPLE = 'dyed_purple'
export const HAIR_COLOR_DYED_PINK = 'dyed_pink'
export const HAIR_COLOR_DYED_YELLOW = 'dyed_yellow'
export const HAIR_COLOR_DYED_RED = 'dyed_red'
export const HAIR_COLOR_DYED_ORANGE = 'dyed_orange'
export const HAIR_COLOR_DYED_GREEN = 'dyed_green'
export const HAIR_COLOR_DYED_TEAL = 'dyed_teal'

const hairColorList = [
  {
    name: `русые`,
    id: HAIR_COLOR_BROWN,
    probabilityWeight: 25,
    probabilityChange: {
      raceId: {
        [PC_RACE_DWARF]: 50,
        [PC_RACE_HALFLING]: 50,
        [PC_RACE_GNOME]: 50,
      },
      subRaceId: {
        [PC_SUBRACE_DWARF_HILL]: 50,
        [PC_SUBRACE_DWARF_MOUNTAIN]: 50,
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_WOOD]: 50,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
        [PC_SUBRACE_HUMAN_SHOU]: 0,
        [PC_SUBRACE_HUMAN_RASHEMI]: 0,
        [PC_SUBRACE_HUMAN_TURAMI]: 0,
        [PC_SUBRACE_HUMAN_CHONDATAN]: 50,
        [PC_SUBRACE_HUMAN_TETHYRIAN]: 50,
        [PC_SUBRACE_HUMAN_FFOLK]: 50,
        [PC_SUBRACE_HUMAN_CALISHITE]: 50,
        [PC_SUBRACE_HUMAN_MULAN]: 50,
        [PC_SUBRACE_HUMAN_ILLUSKAN]: 50,
        [PC_SUBRACE_HUMAN_DAMARAN]: 50,
        [PC_SUBRACE_HUMAN_HALRUAAN]: 0,
        [PC_SUBRACE_HUMAN_NAR]: 0,
        [PC_SUBRACE_HUMAN_SHAARAN]: 0,
        [PC_SUBRACE_HUMAN_TUIGAN]: 0,
        [PC_SUBRACE_HUMAN_ULUTIUN]: 0,
        [PC_SUBRACE_HUMAN_CHULT]: 0,
        [PC_SUBRACE_HUMAN_ARKAIUN]: 0,
        [PC_SUBRACE_HUMAN_BEDINE]: 0,
        [PC_SUBRACE_HUMAN_GUR]: 0,
      },
    },
  },
  {
    name: `каштановые`,
    id: HAIR_COLOR_AUBURN,
    probabilityWeight: 20,
    probabilityChange: {
      raceId: {
        [PC_RACE_HALFLING]: 50,
        [PC_RACE_TIEFLING]: 50,
      },
      subRaceId: {
        [PC_SUBRACE_DWARF_MOUNTAIN]: 50,
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_SUN]: 50,
        [PC_SUBRACE_ELF_WOOD]: 50,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
        [PC_SUBRACE_HUMAN_SHOU]: 0,
        [PC_SUBRACE_HUMAN_RASHEMI]: 0,
        [PC_SUBRACE_HUMAN_TURAMI]: 0,
        [PC_SUBRACE_HUMAN_CHONDATAN]: 50,
        [PC_SUBRACE_HUMAN_TETHYRIAN]: 50,
        [PC_SUBRACE_HUMAN_FFOLK]: 50,
        [PC_SUBRACE_HUMAN_CALISHITE]: 50,
        [PC_SUBRACE_HUMAN_MULAN]: 50,
        [PC_SUBRACE_HUMAN_DAMARAN]: 50,
        [PC_SUBRACE_HUMAN_HALRUAAN]: 0,
        [PC_SUBRACE_HUMAN_NAR]: 0,
        [PC_SUBRACE_HUMAN_SHAARAN]: 0,
        [PC_SUBRACE_HUMAN_TUIGAN]: 0,
        [PC_SUBRACE_HUMAN_ULUTIUN]: 0,
        [PC_SUBRACE_HUMAN_CHULT]: 0,
        [PC_SUBRACE_HUMAN_ARKAIUN]: 0,
        [PC_SUBRACE_HUMAN_BEDINE]: 0,
        [PC_SUBRACE_HUMAN_GUR]: 0,
      },
    },
  },
  {
    name: `светлые`,
    id: HAIR_COLOR_BLOND,
    probabilityWeight: 12,
    probabilityChange: {
      raceId: {
        [PC_RACE_DWARF]: 0,
      },
      subRaceId: {
        [PC_SUBRACE_DWARF_MOUNTAIN]: 50,
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_DROW]: 50,
        [PC_SUBRACE_HUMAN_IMASKARI]: 50,
        [PC_SUBRACE_HUMAN_SHOU]: 0,
        [PC_SUBRACE_HUMAN_RASHEMI]: 0,
        [PC_SUBRACE_HUMAN_TURAMI]: 0,
        [PC_SUBRACE_HUMAN_CHONDATAN]: 50,
        [PC_SUBRACE_HUMAN_CALISHITE]: 0,
        [PC_SUBRACE_HUMAN_MULAN]: 0,
        [PC_SUBRACE_HUMAN_ILLUSKAN]: 50,
        [PC_SUBRACE_HUMAN_HALRUAAN]: 50,
        [PC_SUBRACE_HUMAN_NAR]: 0,
        [PC_SUBRACE_HUMAN_SHAARAN]: 0,
        [PC_SUBRACE_HUMAN_TUIGAN]: 0,
        [PC_SUBRACE_HUMAN_ULUTIUN]: 0,
        [PC_SUBRACE_HUMAN_CHULT]: 0,
        [PC_SUBRACE_HUMAN_ARKAIUN]: 0,
        [PC_SUBRACE_HUMAN_BEDINE]: 0,
        [PC_SUBRACE_HUMAN_GUR]: 0,
      },
    },
  },
  {
    name: `рыжие`,
    id: HAIR_COLOR_RED,
    probabilityWeight: 14,
    probabilityChange: {
      raceId: {
        [PC_RACE_TIEFLING]: 50,
      },
      subRaceId: {
        [PC_SUBRACE_DWARF_MOUNTAIN]: 50,
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_WOOD]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
        [PC_SUBRACE_HUMAN_SHOU]: 0,
        [PC_SUBRACE_HUMAN_RASHEMI]: 0,
        [PC_SUBRACE_HUMAN_TURAMI]: 0,
        [PC_SUBRACE_HUMAN_CHONDATAN]: 0,
        [PC_SUBRACE_HUMAN_CALISHITE]: 0,
        [PC_SUBRACE_HUMAN_MULAN]: 0,
        [PC_SUBRACE_HUMAN_ILLUSKAN]: 50,
        [PC_SUBRACE_HUMAN_HALRUAAN]: 0,
        [PC_SUBRACE_HUMAN_NAR]: 0,
        [PC_SUBRACE_HUMAN_SHAARAN]: 0,
        [PC_SUBRACE_HUMAN_TUIGAN]: 0,
        [PC_SUBRACE_HUMAN_ULUTIUN]: 0,
        [PC_SUBRACE_HUMAN_CHULT]: 0,
        [PC_SUBRACE_HUMAN_ARKAIUN]: 0,
        [PC_SUBRACE_HUMAN_BEDINE]: 0,
        [PC_SUBRACE_HUMAN_GUR]: 0,
      },
    },
  },
  {
    name: `пшеничные`,
    id: HAIR_COLOR_GOLDEN,
    probabilityWeight: 8,
    probabilityChange: {
      raceId: {
        [PC_RACE_DWARF]: 0,
      },
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_SUN]: 50,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
        [PC_SUBRACE_HUMAN_SHOU]: 0,
        [PC_SUBRACE_HUMAN_RASHEMI]: 0,
        [PC_SUBRACE_HUMAN_TURAMI]: 0,
        [PC_SUBRACE_HUMAN_CHONDATAN]: 0,
        [PC_SUBRACE_HUMAN_CALISHITE]: 0,
        [PC_SUBRACE_HUMAN_MULAN]: 0,
        [PC_SUBRACE_HUMAN_HALRUAAN]: 50,
        [PC_SUBRACE_HUMAN_NAR]: 0,
        [PC_SUBRACE_HUMAN_SHAARAN]: 0,
        [PC_SUBRACE_HUMAN_TUIGAN]: 0,
        [PC_SUBRACE_HUMAN_ULUTIUN]: 0,
        [PC_SUBRACE_HUMAN_CHULT]: 0,
        [PC_SUBRACE_HUMAN_ARKAIUN]: 0,
        [PC_SUBRACE_HUMAN_BEDINE]: 0,
        [PC_SUBRACE_HUMAN_GUR]: 0,
      },
    },
  },
  {
    name: `серебристые`,
    id: HAIR_COLOR_SILVER,
    probabilityWeight: 5,
    probabilityChange: {
      raceId: {
        [PC_RACE_DWARF]: 0,
      },
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_MOON]: 50,
        [PC_SUBRACE_ELF_WOOD]: 0,
        [PC_SUBRACE_ELF_DROW]: 50,
        [PC_SUBRACE_HUMAN_IMASKARI]: 50,
        [PC_SUBRACE_HUMAN_SHOU]: 0,
        [PC_SUBRACE_HUMAN_RASHEMI]: 0,
        [PC_SUBRACE_HUMAN_TURAMI]: 0,
        [PC_SUBRACE_HUMAN_CHONDATAN]: 0,
        [PC_SUBRACE_HUMAN_CALISHITE]: 0,
        [PC_SUBRACE_HUMAN_MULAN]: 0,
        [PC_SUBRACE_HUMAN_HALRUAAN]: 0,
        [PC_SUBRACE_HUMAN_NAR]: 0,
        [PC_SUBRACE_HUMAN_SHAARAN]: 0,
        [PC_SUBRACE_HUMAN_TUIGAN]: 0,
        [PC_SUBRACE_HUMAN_ULUTIUN]: 0,
        [PC_SUBRACE_HUMAN_CHULT]: 0,
        [PC_SUBRACE_HUMAN_ARKAIUN]: 0,
        [PC_SUBRACE_HUMAN_BEDINE]: 0,
        [PC_SUBRACE_HUMAN_GUR]: 0,
      },
    },
  },
  {
    name: `серые`,
    id: HAIR_COLOR_GRAY,
    probabilityWeight: 16,
    probabilityChange: {
      raceId: {
        [PC_RACE_DWARF]: 50,
      },
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 50,
        [PC_SUBRACE_ELF_WOOD]: 0,
        [PC_SUBRACE_ELF_DROW]: 50,
        [PC_SUBRACE_HUMAN_IMASKARI]: 50,
        [PC_SUBRACE_HUMAN_CHONDATAN]: 0,
        [PC_SUBRACE_HUMAN_CALISHITE]: 0,
        [PC_SUBRACE_HUMAN_MULAN]: 0,
        [PC_SUBRACE_HUMAN_HALRUAAN]: 0,
        [PC_SUBRACE_HUMAN_NAR]: 0,
        [PC_SUBRACE_HUMAN_SHAARAN]: 0,
        [PC_SUBRACE_HUMAN_TUIGAN]: 0,
        [PC_SUBRACE_HUMAN_ULUTIUN]: 0,
        [PC_SUBRACE_HUMAN_CHULT]: 0,
        [PC_SUBRACE_HUMAN_ARKAIUN]: 0,
        [PC_SUBRACE_HUMAN_BEDINE]: 0,
        [PC_SUBRACE_HUMAN_GUR]: 0,
      },
    },
  },
  {
    name: `белые`,
    id: HAIR_COLOR_WHITE,
    probabilityWeight: 8,
    probabilityChange: {
      raceId: {
        [PC_RACE_DWARF]: 0,
      },
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_WOOD]: 0,
        [PC_SUBRACE_ELF_DROW]: 50,
        [PC_SUBRACE_HUMAN_IMASKARI]: 50,
        [PC_SUBRACE_HUMAN_CHONDATAN]: 0,
        [PC_SUBRACE_HUMAN_CALISHITE]: 0,
        [PC_SUBRACE_HUMAN_MULAN]: 0,
        [PC_SUBRACE_HUMAN_HALRUAAN]: 0,
        [PC_SUBRACE_HUMAN_NAR]: 0,
        [PC_SUBRACE_HUMAN_SHAARAN]: 0,
        [PC_SUBRACE_HUMAN_TUIGAN]: 0,
        [PC_SUBRACE_HUMAN_ULUTIUN]: 0,
        [PC_SUBRACE_HUMAN_CHULT]: 0,
        [PC_SUBRACE_HUMAN_ARKAIUN]: 0,
        [PC_SUBRACE_HUMAN_BEDINE]: 0,
        [PC_SUBRACE_HUMAN_GUR]: 0,
      },
    },
  },
  {
    name: `чёрные`,
    id: HAIR_COLOR_BLACK,
    probabilityWeight: 20,
    probabilityChange: {
      raceId: {
        [PC_RACE_DWARF]: 50,
        [PC_RACE_TIEFLING]: 50,
      },
      subRaceId: {
        [PC_SUBRACE_DWARF_HILL]: 50,
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_SUN]: 50,
        [PC_SUBRACE_ELF_MOON]: 50,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
        [PC_SUBRACE_HUMAN_SHOU]: 50,
        [PC_SUBRACE_HUMAN_RASHEMI]: 50,
        [PC_SUBRACE_HUMAN_TURAMI]: 50,
        [PC_SUBRACE_HUMAN_CHONDATAN]: 50,
        [PC_SUBRACE_HUMAN_CALISHITE]: 0,
        [PC_SUBRACE_HUMAN_MULAN]: 50,
        [PC_SUBRACE_HUMAN_ILLUSKAN]: 50,
        [PC_SUBRACE_HUMAN_DAMARAN]: 50,
        [PC_SUBRACE_HUMAN_HALRUAAN]: 50,
        [PC_SUBRACE_HUMAN_NAR]: 50,
        [PC_SUBRACE_HUMAN_SHAARAN]: 50,
        [PC_SUBRACE_HUMAN_TUIGAN]: 50,
        [PC_SUBRACE_HUMAN_ULUTIUN]: 50,
        [PC_SUBRACE_HUMAN_CHULT]: 50,
        [PC_SUBRACE_HUMAN_ARKAIUN]: 50,
        [PC_SUBRACE_HUMAN_BEDINE]: 50,
        [PC_SUBRACE_HUMAN_GUR]: 50,
      },
    },
  },
  {
    name: `зелёные`,
    id: HAIR_COLOR_GREEN,
    probabilityWeight: 0,
    probabilityChange: {
      raceId: {
        [PC_RACE_ELF]: 50,
      },
      subRaceId: {
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
  {
    name: `лиловые`,
    id: HAIR_COLOR_VIOLET,
    probabilityWeight: 0,
    probabilityChange: {
      raceId: {
        [PC_RACE_TIEFLING]: 50,
      },
    },
  },
  {
    name: `синие`,
    id: HAIR_COLOR_BLUE,
    probabilityWeight: 0,
    probabilityChange: {
      raceId: {
        [PC_RACE_ELF]: 50,
        [PC_RACE_TIEFLING]: 50,
      },
      subRaceId: {
        [PC_SUBRACE_ELF_MOON]: 50,
        [PC_SUBRACE_ELF_WOOD]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
  {
    name: `голубые`,
    id: HAIR_COLOR_LIGHT_BLUE,
    probabilityWeight: 0,
    probabilityChange: {
      subRaceId: {
        [PC_SUBRACE_ELF_MOON]: 50,
      },
    },
  },
  {
    name: `крашеные чёрные`,
    id: HAIR_COLOR_DYED_BLACK,
    probabilityWeight: 5,
    probabilityChange: {
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
  {
    name: `крашеные синие`,
    id: HAIR_COLOR_DYED_BLUE,
    probabilityWeight: 1,
    probabilityChange: {
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
  {
    name: `крашеные фиолетовые`,
    id: HAIR_COLOR_DYED_PURPLE,
    probabilityWeight: 1,
    probabilityChange: {
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
  {
    name: `крашеные розовые`,
    id: HAIR_COLOR_DYED_PINK,
    probabilityWeight: 1,
    probabilityChange: {
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
  {
    name: `крашеные жёлтые`,
    id: HAIR_COLOR_DYED_YELLOW,
    probabilityWeight: 1,
    probabilityChange: {
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
  {
    name: `крашеные красные`,
    id: HAIR_COLOR_DYED_RED,
    probabilityWeight: 1,
    probabilityChange: {
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
  {
    name: `крашеные оранжевые`,
    id: HAIR_COLOR_DYED_ORANGE,
    probabilityWeight: 1,
    probabilityChange: {
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
  {
    name: `крашеные зеленые`,
    id: HAIR_COLOR_DYED_GREEN,
    probabilityWeight: 1,
    probabilityChange: {
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
  {
    name: `крашеные бирюзовые`,
    id: HAIR_COLOR_DYED_TEAL,
    probabilityWeight: 1,
    probabilityChange: {
      subRaceId: {
        [PC_SUBRACE_DWARF_GRAY]: 0,
        [PC_SUBRACE_ELF_DROW]: 0,
        [PC_SUBRACE_HUMAN_IMASKARI]: 0,
      },
    },
  },
]

export default hairColorList
export const hairColorCollection = listToCollectionById(hairColorList)

/*
Кожа

  ДВАРФЫ
    от тёмно-коричневой до светлой, с красным оттенком. Наиболее распространённые оттенки — светло-коричневый или смуглый, как разные виды земли

    ЩИТОВЫЕ (ГОРНЫЕ) ДВАРФЫ
      Кожа у них, как правило, светлая
      скорее светлокожи

    ЗОЛОТЫЕ ДВАРФЫ (ХОЛМОВЫЕ)
      со смуглой кожей

    ДВЕРГАРЫ
      пепельнокожие

  ЭЛЬФЫ
    все человеческие оттенки, а также цвета с медным, бронзовым и голубовато-белым отливом

    СОЛНЕЧНЫХ ЭЛЬФОВ
      бронзовая кожа
      Кожа их бронзового цвета

    ЛУННЫЕ ЭЛЬФЫ
      бледнее, с алебастровой кожей, имеющей иногда оттенок синего
      Их кожа бледная с голубым

    ЛЕСНЫХ ЭЛЬФОВ
      как правило, имеет медный оттенок, иногда со следами зелёного

    ДРОУ
      чёрную кожу, которая напоминает полированный обсидиан

  ПОЛУРОСЛИКИ
    Кожа у полуросликов встречается от смуглой до бледной, с румянцем

  ЛЮДИ
    Оттенок кожи у людей варьируется от почти чёрного до очень бледного

    ШУ
      желтовато-бронзовый оттенок кожи

    ЧОНДАТАНЦЫ
      смуглый народ

    ТЁРАМИ
      с тёмно-красной кожей

    ТЕТИРЦЫ
      со смуглой кожей, которая светлее у северных поселенцев

    РАШЕМИ
      тёмная кожа

    КАЛИШИТЫ
      смугло-коричневую кожу, волосы и глаза

    МУЛАНЫ
      с кожей цвета янтаря

    ИЛЛУСКАНЦЫ
      светлокожий народ

    ДАМАРЦЫ
      оттенок их кожи варьируется от смуглого до светлого

    ЧУЛЬТЫ
      темной кожей

    АРКАЮНЫ
      смуглые

    БЕДИНЫ
      Темнокожие

    ФФОЛКИ
      происходят от тетирских поселенцев

    ГУРЫ
      серокожие

    ХАЛРУААНЦЫ
      оливковый цвет кожи

    ИМАСКАРИ
      кожа побелела

    НАРЫ
      Кожа смуглая

    ШААРЦЫ
      смуглые

    ТУЙГАНЫ
      похожи на людей Шу бронзовыми и золотыми оттенками кожи, но их кожа немного темнее

    УЛУТИУНЕ
      с кожей золотого оттенка


  ГНОМЫ
    смуглые или коричневые лица

    СВИРФНЕБЛИНЫ
      с тёмной, землистой кожей серых оттенков. кожа их на вид похожа на камень

  ПОЛУОРКИ
    Серый цвет кожи

  ТИФЛИНГИ
    Цвет кожи может принимать все тона, характерные для человека, а также различные оттенки красного
     грубая или чешуйчатая кожа красного или тёмно-синего цвета

*/

/*
ГЛАЗА

  ДВАРФЫ
    ЩИТОВЫЕ ДВАРФЫ
      глаза — зелёные, карие или серебристо-голубые

    ЗОЛОТЫЕ ДВАРФЫ (ХОЛМОВЫЕ)
      глазами коричневого или орехового цвета. Зеленый цвет глаз встречается редко (и считается счастливым)

    ДВЕРГАРЫ
      черные глаза

  ЭЛЬФЫ
    помимо человеческих цветов могут приобретать цвет жидкого золота или серебра.

    СОЛНЕЧНЫХ ЭЛЬФОВ
      золотые, серебристые или чёрные глаза
      глаза чёрные, металлически золотые или серебряные

    ЛУННЫЕ ЭЛЬФЫ
      синие или зелёные глаза с золотыми вкраплениями
      Глаза у них голубые или зелёные с золотыми пятнышками.

    ЛЕСНЫХ ЭЛЬФОВ
      зелёные, карие или орехового цвета глаза.

    ДРОУ
      У них обычно бледные глаза (настолько бледные, что могут показаться белыми) с сиреневым, серебряным, розовым, красным или синим оттенком.


  ПОЛУРОСЛИКИ
    карие или ореховые

  ЛЮДИ

    ШУ
      с чёрными глазами

    ЧОНДАТАНЦЫ
      зелёные или карие глаза

    ТЁРАМИ
      чёрными глазами.

    ТЕТИРЦЫ
      голубые глаза — наиболее типичны

    РАШЕМИ
      чёрные глаза

    КАЛИШИТЫ
      смугло-коричневые глаза

    МУЛАНЫ
      карими или светло-коричневыми глазами

    ИЛЛУСКАНЦЫ
      с голубыми или серыми со стальным оттенком глазами

    ДАМАРЦЫ
      цвет глаз сильно отличается, хотя карие — самые распространённые.

    ФФОЛКИ
      происходят от тетирских поселенцев

    ХАЛРУААНЦЫ
      Черные, карие и зелёные глаза наиболее распространены.

    НАРЫ
      глаза карие или тёмные

  ГНОМЫ
    светлые глаза


  ТИФЛИНГИ
    глаза сплошного цвета без намёка на зрачок или белок, обычно чёрного, красного, белого, серебряного или золотого цвета



ЧЕШУЯ

  ДРАКОНОРОЖДЁННЫЕ
    маленькие, аккуратные чешуйки обычно цвета меди или латуни, иногда с алым, золотым, медно-зелёным или рыжим оттенком
    Кровь определённых видов драконов чрезвычайно сильна в представителях некоторых кланов. Эти драконорождённые часто гордятся тем, что цвет их чешуи близок к цвету их прародителей — ярко красный, зелёный, синий или белый, глянцево-чёрный или блестяще-золотой, серебряный, латунный, медный или бронзовый.

РОГА

  ТИФЛИНГИ
    витые рога, как у барана, у других длинные и прямые, как у газели, у третьих спиральные, как у антилопы

*/
