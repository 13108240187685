import { GENDER_MALE } from '@/constants/genderList'
import {
  birthplaceVariantCollection,
  BIRTHPLACE_OUTER_PLANE,
} from '@/components/CharacterGenerator/constants/birthplaceVariantList'
import {
  VARIANT_BIRTH_PLACE,
} from '@/components/CharacterGenerator/constants/variantIdList'
import generateTextByCharacter from '@/components/CharacterGenerator/utils/generateTextByCharacter'

export default character => {
  const {detailId} = character[VARIANT_BIRTH_PLACE]
  const {genderId} = character

  const birthplacePrefix = genderId === GENDER_MALE
    ? 'Родился'
    : 'Родилась'

  const birthplaceText = detailId === BIRTHPLACE_OUTER_PLANE
    ? generateTextByCharacter(character)(character[VARIANT_BIRTH_PLACE]).text
    : birthplaceVariantCollection[detailId].name.local

  return `${birthplacePrefix} ${birthplaceText}`
}
