import rollDice from '@/utils/rollDice'

export default (
  {
    diceType = 0,
    diceCount = 0,
    diceBonus = 0,
  },
) => Math.max(
  diceBonus + rollDice(diceType)(diceCount),
  0,
)
