const {BACKGROUND_CHARLATAN} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  GENDER_MALE,
  GENDER_FEMALE,
} = require('./../../genderList')
const {
  SKILL_DECEPTION,
  SKILL_SLEIGHT_OF_HAND,
} = require('./../../skillList')
const {
  GEAR_BOTTLE_GLASS,
  GEAR_CLOTHES_FINE,
  GEAR_DICE_SET,
  GEAR_DISGUISE_KIT,
  GEAR_FORGERY_KIT,
  GEAR_POUCH,
  GEAR_SIGNET_RING,
} = require('./../../gearIdList')

module.exports = {
  id: BACKGROUND_CHARLATAN,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Шарлатан',
    [GENDER_FEMALE]: 'Шарлатанка',
  },
  nameEn: 'Charlatan',
  description: [
    {
      header: 'Шарлатан',
      text: `Вы хорошо знаете людей. Вы понимаете, что ими движет, и можете распознать их самые сокровенные желания спустя всего пару минут после начала разговора. Несколько наводящих вопросов, и вот Вы уже читаете их словно книжки, написанные для детей. Это полезный талант, и Вы вполне готовы использовать его себе на благо.

Вы знаете, что люди хотят, и Вы даёте им это, или же обещаете дать. Чувство здравого смысла должно заставлять людей держаться подальше от тех вещей, которые слишком хорошо звучат, чтобы быть правдой. Но здравый смысл куда-то улетучивается, когда Вы неподалёку. Бутылочка с розовой жидкостью всенепременно вылечит эту неблаговидную сыпь, а эта чудодейственная мазь (не более чем горсть жира, смешанного с серебряной пылью) без сомнения вернёт молодость и энергичность. Все эти чудеса, конечно, звучат неправдоподобно, но Вы знаете, как преподнести их так, чтобы они казались крайне выгодным делом.`,
      source: {
        id: SOURCE_PHB,
        page: 129,
      },
    },
    {
      header: 'Умение: Поддельная личность',
      text: `Вы создали себе вторую личность, включая необходимые документы, знакомства и маскировку, что позволяет вам перевоплощаться в этот образ. В дополнение к этому Вы можете подделывать документы, включая официальные документы и личные письма, если ранее видели пример подобного документа или почерк, который пытаетесь скопировать.`,
      source: {
        id: SOURCE_PHB,
        page: 129,
      },
    },
    {
      header: 'Излюбленные махинации',
      text: `У каждого шарлатана есть свой излюбленный подход к совершению афер.

| к6 | Афера |
|-|-|
| 1 | Я мухлюю в азартных играх. |
| 2 | Я подделываю монеты или документы. |
| 3 | Я втираюсь к людям в доверие, чтобы потом играть на их слабостях и наживаться за их счёт. |
| 4 | Я меняю личности как перчатки. |
| 5 | Я занимаюсь уличным жульничеством с помощью ловкости рук. |
| 6 | Я убеждаю людей, что эта бесполезная куча хлама стоит их кровно заработанных денег. |
`,
      source: {
        id: SOURCE_PHB,
        page: 129,
      },
    },
    {
      header: 'Персонализация',
      text: `Шарлатаны — яркие персонажи, скрывающиеся за создаваемыми масками. Они являются отражениями того, что другие хотят видеть, во что другие верят, и как другие видят мир. Их истинную натуру может мучить совесть, постоянное недоверие, и им может угрожать враг.`,
      source: {
        id: SOURCE_PHB,
        page: 129,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Я легко начинаю и разрываю романтические отношения, и постоянно нахожусь в поиске новой пассии. |
| 2 | У меня припасены шуточки на все случаи жизни, особенно на те, где юмор вообще неуместен. |
| 3 | Лесть — мой излюбленный способ добиться желаемого. |
| 4 | Я прирождённый игрок, и не могу удержаться от риска, если на кону стоит что-то стоящее. |
| 5 | Я постоянно вру, зачастую даже без малейшей на то причины. |
| 6 | Сарказм и оскорбления — мои излюбленные приёмы. |
| 7 | Я ношу с собой различные святые символы, и взываю к тем божествам, что лучше подходят для каждого конкретного случая. |
| 8 | Я тащу в карман всё, что плохо лежит и имеет хоть какую-то ценность. |
`,
      source: {
        id: SOURCE_PHB,
        page: 129,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Независимость.** У меня свободолюбивая натура — никто не смеет указывать мне, что делать. | Хаотичное |
| 2 | **Честность.** Я никогда не выберу своей жертвой людей, которые находятся в бедственном положении. | Принципиальное |
| 3 | **Милосердие.** Я распределяю добытые деньги между людьми, которые в них действительно нуждаются. | Доброе |
| 4 | **Креативность.** Я никогда не иду проторённой дорожкой. | Хаотичное |
| 5 | **Дружба.** Материальные блага приходят и уходят, а друзья остаются. | Доброе |
| 6 | **Стремление.** Я собираюсь стать кем-то значимым в этой жизни. | Любое |
`,
      source: {
        id: SOURCE_PHB,
        page: 129,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Я перешёл дорожку не тому человеку, и должен постоянно заботиться о том, чтобы мне и моим близким не навредили. |
| 2 | Я всем обязан своему наставнику — ужасному человеку, который, вполне вероятно, гниёт сейчас в какой-нибудь тюрьме. |
| 3 | Где-то живёт мой ребёнок, который не знает обо мне. И для него я пытаюсь сделать этот мир лучше. |
| 4 | Я отпрыск благородного рода, и когда-нибудь заберу свои земли и титул у тех, кто их украл. |
| 5 | Влиятельный враг убил дорогого мне человека. И однажды я отомщу. |
| 6 | Я пустил по миру человека, который того не заслуживал. Я стремлюсь искупить свой проступок, но скорее всего никогда себя не прощу. |
`,
      source: {
        id: SOURCE_PHB,
        page: 129,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Я не могу устоять перед смазливой мордашкой. |
| 2 | Я постоянно в долгах. Я трачу свои нечестно нажитые деньги быстрее, чем их добываю. |
| 3 | Я убеждён в том, что никто не сможет одурачить меня так, как я одурачиваю других. |
| 4 | Я слишком жаден до золота. И не могу противиться риску, если в этом замешаны деньги. |
| 5 | Я не могу устоять и не облапошить людей, стоящих гораздо выше, чем я. |
| 6 | Я ненавижу себя за это, но я сбегу, чтобы спасти свою собственную шкуру, если вдруг запахнет жареным. |
`,
      source: {
        id: SOURCE_PHB,
        page: 129,
      },
    },
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_DECEPTION,
        SKILL_SLEIGHT_OF_HAND,
      ],
    },
    tool: {
      list: [
        GEAR_DISGUISE_KIT,
        GEAR_FORGERY_KIT,
      ],
    },
  },
  equipment: `
* [Комплект отличной одежды](GEAR:${GEAR_CLOTHES_FINE}).
* [Набор для грима](GEAR:${GEAR_DISGUISE_KIT}).
* Приспособление для жульничества на ваш выбор:
  * Десять запечатанных [бутылей](GEAR:${GEAR_BOTTLE_GLASS}) с подкрашенной жидкостью;
  * Набор шулерских [костей](GEAR:${GEAR_DICE_SET});
  * Колода краплёных [карт](GEAR:${GEAR_BOTTLE_GLASS});
  * [Кольцо с печатью](GEAR:${GEAR_SIGNET_RING}) какого-нибудь воображаемого герцога.
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 15 зм.
`,
  source: {
    id: SOURCE_PHB,
    page: 129,
  },
}
