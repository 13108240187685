import listToCollectionById from '@/utils/listToCollectionById'

export const SORCERER_CHOICE_FROST_BIRTH = 'sorcerer_choice_frost_birth'
export const SORCERER_CHOICE_CRISIS_HIDDEN_POWER = 'sorcerer_choice_crisis_hidden_power'
export const SORCERER_CHOICE_SECRET_ANCESTOR = 'sorcerer_choice_secret_ancestor'
export const SORCERER_CHOICE_FEAR = 'sorcerer_choice_fear'
export const SORCERER_CHOICE_STRANGER = 'sorcerer_choice_stranger'
export const SORCERER_CHOICE_MAGICAL_CONFLAGRATION = 'sorcerer_choice_magical_conflagration'

const sorcererChoiceList = [
  {
    name: `Когда я ★родился★, вся вода в доме замёрзла, молоко скисло, а всё железо превратилось в медь. Мою семью уверяли в том, что это событие предвещало странные вещи, которые со мной произойдут.`,
    id: SORCERER_CHOICE_FROST_BIRTH,
    probabilityWeight: 1,
  },
  {
    name: `У меня было сильное эмоциональное и физическое напряжение, которое вызвало к жизни мою скрытую магическую силу. С тех пор я всё время ★боролся★, чтобы контролировать её.`,
    id: SORCERER_CHOICE_CRISIS_HIDDEN_POWER,
    probabilityWeight: 1,
  },
  {
    name: `В моей семье никогда не говорили о моих предках, и когда я ★спрашивал★ их об этом, они меняли тему. И лишь когда я ★начал★ проявлять свои странные способности, правда о моём наследии вышла наружу.`,
    id: SORCERER_CHOICE_SECRET_ANCESTOR,
    probabilityWeight: 1,
  },
  {
    name: `Когда чудовище угрожало одному из моих друзей, меня объял страх. Я инстинктивно ★бросился★ на существо и ★уничтожил★ его силой, исходящей изнутри меня.`,
    id: SORCERER_CHOICE_FEAR,
    probabilityWeight: 1,
  },
  {
    name: `Почувствовав что-то особенное во мне, незнакомец научил меня контролировать мой дар.`,
    id: SORCERER_CHOICE_STRANGER,
    probabilityWeight: 1,
  },
  {
    name: `После спасения из колдовского пожара, я ★понял★, что ★изменился★, хоть и ★остался★ ★цел★. Я ★начал★ проявлять необычные способности, которые только начинаю понимать.`,
    id: SORCERER_CHOICE_MAGICAL_CONFLAGRATION,
    probabilityWeight: 1,
  },
]

export default sorcererChoiceList
export const sorcererChoiceCollection = listToCollectionById(sorcererChoiceList)
