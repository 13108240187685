const {
  BACKGROUND_CITY_WATCH,
  BACKGROUND_SOLDIER,
  BACKGROUND_INVESTIGATOR,
} = require('./../../backgroundIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_ATHLETICS,
  SKILL_INSIGHT,
} = require('./../../skillList')
const {
  GEAR_HORN,
  GEAR_MANACLES,
  GEAR_POUCH,
} = require('./../../gearIdList')
const soldier = require('./soldier')

module.exports = {
  id: BACKGROUND_CITY_WATCH,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Городской стражник',
    [GENDER_FEMALE]: 'Городская стражница',
  },
  nameEn: 'City Watch',
  description: [
    {
      header: 'Городской стражник',
      text: `Вы служили сообществу, в котором выросли, являясь первой линией обороны от преступного мира. Вы не солдат, чей взгляд скорее смотрит на внешних врагов. Вместо этого, ваша служба родному городу — это охрана местного населения, защита жителей от правонарушителей и злоумышленников всех мастей.

Вы могли быть одним из Городской Стражи Глубоководья, носящими жезлы стражами правопорядка Города Роскоши, защищающими простой народ как от воров, так и от буйных дворян. Или, может быть, Вы один из доблестных защитников Сильверимуна, член Серебряной Стражи или даже один из владеющих магией членов Магической Гвардии.

Возможно, Вы родом из Невервинтера и служили в качестве одного из дозорных Зимнего Щита, нового подразделения стражей, поклявшихся охранять безопасность Города Умелых Рук.

Даже если Вы не были рождены и не были воспитаны в городе, эта предыстория может описать ваши ранние годы как представителя правоохранительных органов. Большинство населенных пунктов любого размера имеют своих констеблей или другие полицейские силы, и даже у небольших общин есть шерифы и судебные приставы, которые готовы защищать их сообщество.`,
      source: {
        id: SOURCE_SCAG,
        page: 146,
      },
    },
    {
      header: 'Умение: Намётанный глаз',
      text: `Ваш опыт блюстителя закона и общения с правонарушителями даёт вам возможность легко понять местные законы и положение дел в преступном мире. Вы можете легко найти местные опорный пункт стражи, или подобной организации, и так же легко определить логово преступников в общине. Также, вам скорее всего окажут радушный приём в первых местах, нежели во вторых.`,
      source: {
        id: SOURCE_SCAG,
        page: 146,
      },
    },
    {
      header: 'Предлагаемые характеристики',
      text: `Используйте таблицы для предыстории [солдата](BACKGROUND:${BACKGROUND_SOLDIER}), как базу для ваших черт характера и мотивов, изменяя их содержание при необходимости, чтобы более точно описать вашу личность в качестве [члена городской стражи](BACKGROUND:${BACKGROUND_CITY_WATCH}).

Ваши привязанности, скорее всего, связаны с другими членами стражи или с самой организацией и почти наверняка касается вашей общины. Ваш идеал наверняка предполагает укрепление мира и безопасности. [Сыщик](BACKGROUND:${BACKGROUND_INVESTIGATOR}), скорее всего, имеет идеал, связанный с установлением справедливости через раскрытие преступлений.`,
      source: {
        id: SOURCE_SCAG,
        page: 146,
      },
    },
    ...soldier.description.slice(4),
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_ATHLETICS,
        SKILL_INSIGHT,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: `
* Униформа вашего подразделения со знаками отличия,
* [Рожок](GEAR:${GEAR_HORN}), которым можно позвать на помощь,
* [Наручники](GEAR:${GEAR_MANACLES}),
* [Кошель](GEAR:${GEAR_POUCH}) с 10 зм.
`,
  source: {
    id: SOURCE_SCAG,
    page: 146,
  },
}
