import listToCollectionById from '@/utils/listToCollectionById'

export const PARENT_HALF_ORC_F_HUMAN_M_ORC = 'parent_half_orc_f_human_m_orc'
export const PARENT_HALF_ORC_M_HUMAN_F_ORC = 'parent_half_orc_m_human_f_orc'
export const PARENT_HALF_ORC_F_HALFORC_M_ORC = 'parent_half_orc_f_halforc_m_orc'
export const PARENT_HALF_ORC_M_HALFORC_F_ORC = 'parent_half_orc_m_halforc_f_orc'
export const PARENT_HALF_ORC_M_HALFORC_F_HUMAN = 'parent_half_orc_m_halforc_f_human'
export const PARENT_HALF_ORC_F_HALFORC_M_HUMAN = 'parent_half_orc_f_halforc_m_human'
export const PARENT_HALF_ORC_HALFORC_HALFORC = 'parent_half_orc_halforc_halforc'

const parentHalfOrcList = [
  {
    name: 'Отец родитель был орком, а мать — человеком.',
    id: PARENT_HALF_ORC_F_HUMAN_M_ORC,
    probabilityWeight: 3,
  },
  {
    name: 'Мать была орком, а отец — человеком.',
    id: PARENT_HALF_ORC_M_HUMAN_F_ORC,
    probabilityWeight: 3,
  },
  {
    name: 'Отец был орком, а мать — полуорчихой.',
    id: PARENT_HALF_ORC_F_HALFORC_M_ORC,
    probabilityWeight: 2,
  },
  {
    name: 'Мать была орчихой, а отец — полуорком.',
    id: PARENT_HALF_ORC_M_HALFORC_F_ORC,
    probabilityWeight: 2,
  },
  {
    name: 'Отец был полуорком, а мать — человеком.',
    id: PARENT_HALF_ORC_M_HALFORC_F_HUMAN,
    probabilityWeight: 2,
  },
  {
    name: 'Мать была полуорчихой, а отец — человеком.',
    id: PARENT_HALF_ORC_F_HALFORC_M_HUMAN,
    probabilityWeight: 2,
  },
  {
    name: 'Оба родителя — полуорки.',
    id: PARENT_HALF_ORC_HALFORC_HALFORC,
    probabilityWeight: 2,
  },
]

export default parentHalfOrcList
export const parentHalfOrcCollection = listToCollectionById(parentHalfOrcList)
