const {LANG_ANY} = require('./../../languageIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  BACKGROUND_KNIGHT_OF_THE_ORDER,
  BACKGROUND_SOLDIER,
} = require('./../../backgroundIdList')
const {
  GOD_KELEMVOR,
  GOD_MYSTRA,
} = require('./../../godIdList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_POUCH,
  GEAR_SIGNET_RING,
} = require('./../../gearIdList')
const {
  CAT_GAME_SET,
  CAT_MUSIC_TOOLS,
} = require('./../../gearCategoryList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_NATURE,
  SKILL_PERSUASION,
  SKILL_RELIGION,
} = require('./../../skillList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const soldier = require('./soldier')

module.exports = {
  id: BACKGROUND_KNIGHT_OF_THE_ORDER,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Рыцарь ордена',
    [GENDER_FEMALE]: 'Рыцарь ордена',
  },
  nameEn: 'Knight of the Order',
  description: [
    {
      header: 'Рыцарь ордена',
      text: `Вы принадлежите к рыцарскому ордену, давшему клятву добиться определенной цели. Суть цели зависит от того, какому ордену Вы служите, но для вас эта цель является жизненно важным и достойным делом, не подлежащим обсуждению. Многие рыцарские ордена Фаэруна имеют схожие взгляды на свои действия и возложенные на себя обязанности.

Несмотря на то, что понятие «рыцарь» ассоциируется с тяжеловооруженным всадником благородных кровей, большинство рыцарских орденов Фаэруна не накладывают подобных ограничений на своих членов. Ордена ставят свои цели и философию выше снаряжения и стиля боя своих рыцарей, так что большинство орденов открыты для всех желающих сражаться и умирать за дело ордена, не ограничиваясь в способах ведения боевых действий.

Врезка «Рыцарские Ордены Фаэруна» содержит детали о рыцарских орденах, которые существуют в данный момент, она создана для того, чтобы помочь вам определиться в том, какому ордену Вы будете хранить верность.`,
      source: {
        id: SOURCE_SCAG,
        page: 152,
      },
    },
    {
      header: 'Умение: Рыцарские связи',
      text: `Вы получаете укрытие и помощь от членов вашего рыцарского ордена и от тех, кто симпатизирует его целям. Если ваш орден религиозной направленности, то Вы также можете получить помощь в храмах и иных религиозных общинах вашего божества. Рыцари светских орденов могут получить помощь в обществе, которому они служат (будь это одинокое поселение или огромный город). А рыцари орденов, служащих определенным идеалам, могут найти помощь как у тех, кому они помогли на пути к своим идеалам, так и у тех, кто эти идеалы разделяет.

Эта помощь может выражаться в ночлеге и еде, и при необходимости исцелении, а может выражаться в рискованной помощи, например, когда группа местных граждан вступает в неравный бой на стороне припёртого к стенке рыцаря, или поддерживающие орден помогают незаметно покинуть город несправедливо обвинённому рыцарю.`,
      source: {
        id: SOURCE_SCAG,
        page: 152,
      },
    },
    {
      header: 'Рыцарские ордена Фаэруна',
      text: `Многие, по праву называющие себя «рыцарями», заслужили этот титул в ордене, служащем божеству, таком как Вечный Орден [Келемвора](GOD:${GOD_KELEMVOR}) или Рыцари Мистического Огня [Мистры](GOD:${GOD_MYSTRA}). Иные ордена служат правительствам, королевским семьям или другим военным или феодальным организациям — жестокие Рыцари-Чернокнижники Ваасы, к примеру. Другие рыцарские организации, наоборот, изолированные и не государственные, состоят из воинов, которые следуют своей философии или считают друг друга подобием семьи, на подобие монашеского ордена. Также существуют организации, такие как Рыцари Щита, которые используют атрибуты рыцарства, без того, чтобы быть воином — люд Фаэруна по большинству своему, услышав слово «рыцарь» представляет себе воина в доспехах, верхом на лошади, придерживающегося кодекса чести. Ниже приведены некоторые рыцарские организации.

# Рыцари Единорога

Рыцари Единорога начинались как причуда романтично настроенной «золотой молодёжи» Врат Балдура. В шутку, они взяли богиню единорога Лурью своим талисманом и окунулись в приключения в поисках развлечений. Реальность опасностей, с которыми они столкнулись в последствии, затянула их, также, как и тенета Лурью. Со временем, небольшая группа росла и распространялась, обретая последователей даже в Кормире. Рыцари Единорога — искатели приключений, следующие романтическим и рыцарским идеалам: жить следует с наслаждением и смехом; задания следует брать «на спор»; невозможные мечты следует стараться выполнить с тем, чтобы удивиться их выполнению; оценивать каждого следует по силам его и слабым даровать утешение.

# Рыцари Миф Драннора

Давным-давно, Рыцари Миф Драннора были знаменитой группой искателей приключений, а Дав Фальконхэнд, одна из знаменитых Семи Сестёр, была одной из них. Группа взяла себе имя в честь великого, но павшего города, также, как и новые Рыцари Миф Драннора современности. Когда город снова был разрушен, Дав Фальконхэнд решила реформировать группу, поставив главной целью — строить альянсы и прочные дружеские связи между цивилизованными расами мира и добрым людом для борьбы со злом. Нынче Рыцари Миф Драннора снова ездят по дорогам Долин и начинают распространяться во внешние земли. Члены отряда утверждаются самой Дав и ставят превыше всего смелость и честь.

# Рыцари Серебряной Чаши

Рыцари Серебряной Чаши были сформированы по указу полубогини Сиаморф в Глубоководье сотню лет назад. Характер Сиаморф включал в себя благородное право и ответственность правления, и богиня перерождалась в качестве разных смертных благородных из поколения в поколение. По декрету Сиаморф, в то время Рыцари Серебряной Чаши брали на себя задачу возведения достойного наследника на трон Тетира и восстановления порядка в королевстве. С тех пор, организация выросла до наиболее популярного рыцарского ордена Тетира, где существует много рыцарских орденов, присягнувших короне.`,
      source: {
        id: SOURCE_SCAG,
        page: 152,
      },
    },
    {
      header: 'Предлагаемые характеристики',
      text: `Используйте таблицы предыстории [солдата](BACKGROUND:${BACKGROUND_SOLDIER}) как базовые для ваших черт и мотивов, изменяя их содержимое так, чтобы оно соответствовало вашей идентичности рыцаря вашего ордена.

Привязанность рыцаря почти всегда включает в себя орден, к которому он принадлежит (или как минимум его ключевых членов). Также, крайне не свойственно для рыцарских идеалов не отражать устав, настрой или философию его ордена.`,
      source: {
        id: SOURCE_SCAG,
        page: 152,
      },
    },
    ...soldier.description.slice(4),
  ],
  proficiency: {
    skill: {
      comment: `который более подходит вашему ордену`,
      list: [
        SKILL_PERSUASION,
      ],
      toChoose: {
        count: 1,
        list: [
          SKILL_ARCANA,
          SKILL_HISTORY,
          SKILL_NATURE,
          SKILL_RELIGION,
        ],
      },
    },
    tool: {
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_GAME_SET,
          CAT_MUSIC_TOOLS,
        ],
      },
    },
    language: LANG_ANY,
  },
  equipment: `
* [Комплект дорожной одежды](GEAR:${GEAR_CLOTHES_TRAVELERS}),
* [Кольцо с печатью](GEAR:${GEAR_SIGNET_RING}),
* Знамя или печать, определяющая ваш ранг в ордене,
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм.
`,
  source: {
    id: SOURCE_SCAG,
    page: 152,
  },
}
