import listToCollectionById from '@/utils/listToCollectionById'
import {
  BACKGROUND_ACOLYTE,
  BACKGROUND_CHARLATAN,
  BACKGROUND_CITY_WATCH,
  BACKGROUND_CLAN_CRAFTER,
  BACKGROUND_CLOISTERED_SCHOLAR,
  BACKGROUND_COURTIER,
  BACKGROUND_CRIMINAL,
  BACKGROUND_ENTERTAINER,
  BACKGROUND_FAR_TRAVELLER,
  BACKGROUND_FOLK_HERO,
  BACKGROUND_GLADIATOR,
  BACKGROUND_GUILD_ARTISAN,
  BACKGROUND_GUILD_MERCHANT,
  BACKGROUND_HERMIT,
  BACKGROUND_INVESTIGATOR,
  BACKGROUND_KNIGHT,
  BACKGROUND_KNIGHT_OF_THE_ORDER,
  BACKGROUND_MERCENARY_VETERAN,
  BACKGROUND_NOBLE,
  BACKGROUND_OUTLANDER,
  BACKGROUND_PIRATE,
  BACKGROUND_SAGE,
  BACKGROUND_SAILOR,
  BACKGROUND_SOLDIER,
  BACKGROUND_SPY,
  BACKGROUND_URBAN_BOUNTY_HUNTER,
  BACKGROUND_UTHGARDT_TRIBE_MEMBER,
  BACKGROUND_WATERDHAVIAN_NOBLE,
} from '@/constants/backgroundIdList'

const BOND_BABA_V_GAVANI = 'bond_baba_v_gavani'
const BOND_BIBLIO = 'bond_biblio'
const BOND_BLAGORODNIY_PIDAR = 'bond_blagorodniy_pidar'
const BOND_BOGATSTVA_DLYA_TELKI = 'bond_bogatstva_dlya_telki'
const BOND_BOGI_NARODA = 'bond_bogi_naroda'
const BOND_CHEST = 'bond_chest'
const BOND_CHLENY_SEMYI_ETO_OK = 'bond_chleny_semyi_eto_ok'
const BOND_DREVNIY_TEKST = 'bond_drevniy_tekst'
const BOND_DRUGIE_OTSHELNIKI = 'bond_drugie_otshelniki'
const BOND_DUSHI_NET = 'bond_dushi_net'
const BOND_ERETIKOM = 'bond_eretikom'
const BOND_GNEV_ZEMEL = 'bond_gnev_zemel'
const BOND_GOREC = 'bond_gorec'
const BOND_GREENPEACE = 'bond_greenpeace'
const BOND_HERO = 'bond_hero'
const BOND_INSTRUMENT_PAMYAT = 'bond_instrument_pamyat'
const BOND_INSTRUMENT_SIMVOL = 'bond_instrument_simvol'
const BOND_INSTRUMENT_UKRALI = 'bond_instrument_ukrali'
const BOND_JIVOTA = 'bond_jivota'
const BOND_KAPITAN = 'bond_kapitan'
const BOND_KATASTROFA = 'bond_katastrofa'
const BOND_KLAN_DALEKO_NO_OK = 'bond_klan_daleko_no_ok'
const BOND_KLYATVA_SLUJIT = 'bond_klyatva_slujit'
const BOND_KONRUKENTU_PIZDA = 'bond_konrukentu_pizda'
const BOND_KORABL = 'bond_korabl'
const BOND_KRASOTA_ZEMEL = 'bond_krasota_zemel'
const BOND_KUSOK_RODINY = 'bond_kusok_rodiny'
const BOND_LUCHSHIY_V_GILDII = 'bond_luchshiy_v_gildii'
const BOND_LYUBIMYE = 'bond_lyubimye'
const BOND_MASTERSKAYA = 'bond_masterskaya'
const BOND_MIRU_PIZDA = 'bond_miru_pizda'
const BOND_MONASTYR = 'bond_monastyr'
const BOND_MOYA_LYUBOV = 'bond_moya_lyubov'
const BOND_MOYA_OSHIBKA = 'bond_moya_oshibka'
const BOND_MOY_HRAM = 'bond_moy_hram'
const BOND_MOY_ORDEN = 'bond_moy_orden'
const BOND_NASTAVNIK_GNIET = 'bond_nastavnik_gniet'
const BOND_NE_DALI_DENEG = 'bond_ne_dali_deneg'
const BOND_NE_TA_BABA = 'bond_ne_ta_baba'
const BOND_NE_TOMU = 'bond_ne_tomu'
const BOND_NOVYE_DETI = 'bond_novye_deti'
const BOND_OBYAZAN_GILDII = 'bond_obyazan_gildii'
const BOND_OTPRYSK = 'bond_otprysk'
const BOND_PERVIY_KORABL = 'bond_perviy_korabl'
const BOND_PIRATY_PIDARY = 'bond_piraty_pidary'
const BOND_PIZDA_ZLU = 'bond_pizda_zlu'
const BOND_PLEMYA_DALEKO_NO_OK = 'bond_plemya_daleko_no_ok'
const BOND_POMOSH_SEMYE = 'bond_pomosh_semye'
const BOND_PORAZHENIE = 'bond_porazhenie'
const BOND_PRAVITEL = 'bond_pravitel'
const BOND_PRESTUPLENIE = 'bond_prestuplenie'
const BOND_PRIZNANIE_SEMYI = 'bond_priznanie_semyi'
const BOND_PROSTYH_LYUDEY = 'bond_prostyh_lyudey'
const BOND_PROSVETLENIE = 'bond_prosvetlenie'
const BOND_PUSTIL_PO_MIRU = 'bond_pustil_po_miru'
const BOND_REBENOK = 'bond_rebenok'
const BOND_ROMEO = 'bond_romeo'
const BOND_SACRED_TEXTS = 'bond_sacred_texts'
const BOND_SCRIPTORIY = 'bond_scriptoriy'
const BOND_SEMYA_DALEKO_NO_OK = 'bond_semya_daleko_no_ok'
const BOND_SEMYA_GDE_TO = 'bond_semya_gde_to'
const BOND_SLUJBA_NARODU = 'bond_slujba_narodu'
const BOND_SOYUZNIKI = 'bond_soyuzniki'
const BOND_SOYUZ_S_DRUGIMI_NORMISAMI = 'bond_soyuz_s_drugimi_normisami'
const BOND_SPAS_MNE_JIZN = 'bond_spas_mne_jizn'
const BOND_STARIY_DOLG = 'bond_stariy_dolg'
const BOND_SVOBODA = 'bond_svoboda'
const BOND_TOMA = 'bond_toma'
const BOND_TRUPPA = 'bond_truppa'
const BOND_UCHENIKI = 'bond_ucheniki'
const BOND_UDIVITELNAYA_RABOTA = 'bond_udivitelnaya_rabota'
const BOND_UNIVERSITY = 'bond_university'
const BOND_VELIKIY_VOR = 'bond_velikiy_vor'
const BOND_VOPROS = 'bond_vopros'
const BOND_VRAG_UBIL = 'bond_vrag_ubil'
const BOND_YA_BOREC_SO_ZLOM = 'bond_ya_borec_so_zlom'
const BOND_YA_ESHE_VERNUUUUS = 'bond_ya_eshe_vernuuuus'
const BOND_YA_GEROY = 'bond_ya_geroy'
const BOND_YA_YOBA_ZASHITNIK = 'bond_ya_yoba_zashitnik'
const BOND_ZABOTILSYA = 'bond_zabotilsya'
const BOND_ZABRALI_MOE = 'bond_zabrali_moe'
const BOND_ZA_TEH_KTO_NE_SMOG_SAM = 'bond_za_teh_kto_ne_smog_sam'
const BOND_ZEMLYA_ETO_OK = 'bond_zemlya_eto_ok'
const BOND_ZNAMENITYM = 'bond_znamenitym'

const bondVariantList = [
  {
    id: BOND_JIVOTA,
    name: `Я не пощажу живота своего, лишь бы найти древнюю реликвию своей веры, что была потеряна давным-давно.`,
    backgroundLimit: [
      BACKGROUND_ACOLYTE,
    ],
  },
  {
    id: BOND_ERETIKOM,
    name: `Однажды я отомщу развращённым представителям верховной иерархии своего храма, что объявили меня ★еретиком★.`,
    backgroundLimit: [
      BACKGROUND_ACOLYTE,
    ],
  },
  {
    id: BOND_ZABOTILSYA,
    name: `Я ★обязан★ своей жизнью священнику, который позаботился обо мне, когда умерли родители.`,
    backgroundLimit: [
      BACKGROUND_ACOLYTE,
    ],
  },
  {
    id: BOND_PROSTYH_LYUDEY,
    name: `Всё, что я делаю — для простых людей.`,
    backgroundLimit: [
      BACKGROUND_ACOLYTE,
      BACKGROUND_NOBLE,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_KNIGHT,
    ],
  },
  {
    id: BOND_MOY_HRAM,
    name: `Я пойду на всё что угодно, дабы защитить свой храм.`,
    backgroundLimit: [
      BACKGROUND_ACOLYTE,
    ],
  },
  {
    id: BOND_SACRED_TEXTS,
    name: `Я пытаюсь сохранить священные тексты, которые мои враги считают еретическими и пытаются уничтожить.`,
    backgroundLimit: [
      BACKGROUND_ACOLYTE,
    ],
  },
  {
    id: BOND_NE_TOMU,
    name: `Я ★перешёл★ дорожку не тому человеку, и ★должен★ постоянно заботиться о том, чтобы мне и моим близким не навредили.`,
    backgroundLimit: [
      BACKGROUND_CHARLATAN,
      BACKGROUND_URBAN_BOUNTY_HUNTER,
      BACKGROUND_INVESTIGATOR,
      BACKGROUND_CRIMINAL,
    ],
  },
  {
    id: BOND_NASTAVNIK_GNIET,
    name: `Я всем ★обязан★ своему наставнику — ужасному человеку, который, вполне вероятно, гниёт сейчас в какой-нибудь тюрьме.`,
    backgroundLimit: [
      BACKGROUND_CHARLATAN,
      BACKGROUND_SPY,
      BACKGROUND_CRIMINAL,
    ],
  },
  {
    id: BOND_REBENOK,
    name: `Где-то живёт мой ребёнок, который не знает обо мне. И для него я пытаюсь сделать этот мир лучше.`,
  },
  {
    id: BOND_OTPRYSK,
    name: `Я — отпрыск благородного рода, и когда-нибудь заберу свои земли и титул у тех, кто их украл.`,
  },
  {
    id: BOND_VRAG_UBIL,
    name: `Влиятельный враг убил дорогого мне человека. И однажды я отомщу.`,
  },
  {
    id: BOND_PUSTIL_PO_MIRU,
    name: `Я ★пустил★ по миру человека, который того не заслуживал. Я стремлюсь искупить свой проступок, но скорее всего никогда себя не прощу.`,
    backgroundLimit: [
      BACKGROUND_CHARLATAN,
      BACKGROUND_CRIMINAL,
    ],
  },
  {
    id: BOND_STARIY_DOLG,
    name: `Я пытаюсь оплатить старый долг одному щедрому благодетелю.`,
    backgroundLimit: [
      BACKGROUND_CRIMINAL,
      BACKGROUND_SPY,
      BACKGROUND_CHARLATAN,
    ],
  },
  {
    id: BOND_POMOSH_SEMYE,
    name: `Свои нечестно заработанные деньги я трачу на помощь своей семье.`,
    backgroundLimit: [
      BACKGROUND_CRIMINAL,
      BACKGROUND_PIRATE,
      BACKGROUND_CHARLATAN,
    ],
  },
  {
    id: BOND_ZABRALI_MOE,
    name: `У меня забрали нечто важное, и я намереваюсь выкрасть это обратно.`,
    backgroundLimit: BACKGROUND_CRIMINAL,
  },
  {
    id: BOND_VELIKIY_VOR,
    name: `Я стану величайшим вором из всех, когда-либо живших.`,
    backgroundLimit: BACKGROUND_CRIMINAL,
  },
  {
    id: BOND_PRESTUPLENIE,
    name: `Я ★виновен★ в ужасающем преступлении. Надеюсь, я смогу простить себя за это.`,
  },
  {
    id: BOND_MOYA_OSHIBKA,
    name: `Близкий человек погиб из-за ошибки, которую я ★совершил★. Это не повторится вновь.`,
  },
  {
    id: BOND_INSTRUMENT_PAMYAT,
    name: `Мой инструмент — самое драгоценное, что у меня есть, и он напоминает мне о моей любви.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
    ],
  },
  {
    id: BOND_INSTRUMENT_UKRALI,
    name: `Кто-то украл мой драгоценный инструмент, и когда-нибудь я верну его.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
    ],
  },
  {
    id: BOND_ZNAMENITYM,
    name: `Я хочу быть ★знаменитым★, чего бы это ни стоило.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
      BACKGROUND_GLADIATOR,
    ],
  },
  {
    id: BOND_HERO,
    name: `Я боготворю ★героя★ старых рассказов, и всегда сравниваю свои поступки с его.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
      BACKGROUND_GLADIATOR,
    ],
  },
  {
    id: BOND_KONRUKENTU_PIZDA,
    name: `Я всё сделаю, чтобы доказать превосходство над ненавистным конкурентом.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
      BACKGROUND_CLAN_CRAFTER,
      BACKGROUND_GUILD_MERCHANT,
      BACKGROUND_GUILD_ARTISAN,
    ],
  },
  {
    id: BOND_TRUPPA,
    name: `Я сделаю что угодно для других членов моей старой труппы.`,
    backgroundLimit: [
      BACKGROUND_ENTERTAINER,
    ],
  },
  {
    id: BOND_SEMYA_GDE_TO,
    name: `У меня есть семья, но я не знаю, где она. Надеюсь, когда-нибудь я увижу их вновь.`,
  },
  {
    id: BOND_ZEMLYA_ETO_OK,
    name: `Я работал на земле, я люблю землю, и я буду защищать землю.`,
    backgroundLimit: [
      BACKGROUND_FOLK_HERO,
    ],
  },
  {
    id: BOND_BLAGORODNIY_PIDAR,
    name: `Один благородный меня дико поколотил, и я вымещаю свой гнев на всех встреченных задирах.`,
    backgroundLimit: [
      BACKGROUND_FOLK_HERO,
    ],
  },
  {
    id: BOND_INSTRUMENT_SIMVOL,
    name: `Мои инструменты — символ моей былой жизни, и я ношу их, чтобы не забывать корни.`,
    backgroundLimit: [
      BACKGROUND_FOLK_HERO,
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_CLAN_CRAFTER,
    ],
  },
  {
    id: BOND_YA_YOBA_ZASHITNIK,
    name: `Я защищаю тех, кто не может защитить себя сам.`,
    backgroundLimit: [
      BACKGROUND_FOLK_HERO,
      BACKGROUND_CITY_WATCH,
      BACKGROUND_KNIGHT,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
    ],
  },
  {
    id: BOND_MOYA_LYUBOV,
    name: `★Хотел★ бы я, чтобы моя первая любовь разделила со мной судьбу.`,
    backgroundLimit: [
      BACKGROUND_FOLK_HERO,
    ],
  },
  {
    id: BOND_MASTERSKAYA,
    name: `Мастерская, где я ★учился★ ремеслу, для меня — самое важное место в мире.`,
    backgroundLimit: [
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_CLAN_CRAFTER,
    ],
  },
  {
    id: BOND_UDIVITELNAYA_RABOTA,
    name: `Я ★создал★ кое для кого удивительную работу, но ★посчитал★ его недостойным. Я всё ещё ищу того, кто окажется достоин её.`,
    backgroundLimit: [
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_CLAN_CRAFTER,
    ],
  },
  {
    id: BOND_OBYAZAN_GILDII,
    name: `Я по гроб жизни ★обязан★ гильдии за то, что она сделала меня ★тем★, кто я есть.`,
    backgroundLimit: [
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_GUILD_MERCHANT,
    ],
  },
  {
    id: BOND_BOGATSTVA_DLYA_TELKI,
    name: `Богатство мне нужно для завоевания любимого сердца.`,
    backgroundLimit: [
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_GUILD_MERCHANT,
      BACKGROUND_CHARLATAN,
      BACKGROUND_CRIMINAL,
      BACKGROUND_PIRATE,
    ],
  },
  {
    id: BOND_LUCHSHIY_V_GILDII,
    name: `Когда-нибудь я вернусь в гильдию и докажу, что я ★лучший★ из всех.`,
    backgroundLimit: [
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_GUILD_MERCHANT,
    ],
  },
  {
    id: BOND_PIZDA_ZLU,
    name: `Я мщу силам зла, которые уничтожили моё торговое дело и разрушили мою жизнь.`,
    backgroundLimit: [
      BACKGROUND_GUILD_ARTISAN,
      BACKGROUND_GUILD_MERCHANT,
      BACKGROUND_CLAN_CRAFTER,
    ],
  },
  {
    id: BOND_DRUGIE_OTSHELNIKI,
    name: `Самое важное для меня — это другие отшельники, жившие рядом.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: BOND_MOY_ORDEN,
    name: `Самое важное для меня — это мой орден.`,
    backgroundLimit: [
      BACKGROUND_KNIGHT_OF_THE_ORDER,
    ],
  },
  {
    id: BOND_YA_ESHE_VERNUUUUS,
    name: `Я ★уединился★, чтобы спрятаться от тех, кто мог охотиться за мной. Когда-нибудь я ★сам★ брошу им вызов.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: BOND_PROSVETLENIE,
    name: `Я всё ещё ищу просветления, которого не ★достиг★ в уединении, но оно по-прежнему ускользает от меня.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: BOND_NE_TA_BABA,
    name: `Я ★уединился★, потому что ★влюбился★ в человека, с которым мне не суждено жить.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: BOND_MIRU_PIZDA,
    name: `Если моё откровение сбудется, мир будет обречён на гибель.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_SAGE,
    ],
  },
  {
    id: BOND_YA_BOREC_SO_ZLOM,
    name: `Моя изоляция позволила познать великое зло, уничтожить которое могу один лишь я.`,
    backgroundLimit: [
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: BOND_PRIZNANIE_SEMYI,
    name: `Я пойду на любой риск, лишь бы заслужить признание семьи.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_KNIGHT,
      BACKGROUND_WATERDHAVIAN_NOBLE,
    ],
  },
  {
    id: BOND_SOYUZ_S_DRUGIMI_NORMISAMI,
    name: `Союз моего дома с другой благородной семьёй нужно поддерживать любой ценой.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_KNIGHT,
      BACKGROUND_WATERDHAVIAN_NOBLE,
    ],
  },
  {
    id: BOND_CHLENY_SEMYI_ETO_OK,
    name: `Нет никого важнее других членов моей семьи.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_KNIGHT,
      BACKGROUND_WATERDHAVIAN_NOBLE,
    ],
  },
  {
    id: BOND_ROMEO,
    name: `Я ★влюблён★ в члена семейства, презираемого моей роднёй.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_KNIGHT,
      BACKGROUND_WATERDHAVIAN_NOBLE,
    ],
  },
  {
    id: BOND_PRAVITEL,
    name: `Моя преданность правителю непоколебима.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_KNIGHT,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_COURTIER,
    ],
  },
  {
    id: BOND_YA_GEROY,
    name: `Обыватели должны считать меня ★своим★ ★героем★.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_KNIGHT,
      BACKGROUND_WATERDHAVIAN_NOBLE,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_FOLK_HERO,
    ],
  },
  {
    id: BOND_SEMYA_DALEKO_NO_OK,
    name: `Моя семья — самая важная вещь в моей жизни, даже когда она далеко от меня.`,
  },
  {
    id: BOND_KLAN_DALEKO_NO_OK,
    name: `Мой клан — самая важная вещь в моей жизни, даже когда он далеко от меня.`,
    backgroundLimit: [
      BACKGROUND_CLAN_CRAFTER,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: BOND_PLEMYA_DALEKO_NO_OK,
    name: `Моё племя — самая важная вещь в моей жизни, даже когда оно далеко от меня.`,
    backgroundLimit: [
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_OUTLANDER,
    ],
  },
  {
    id: BOND_GREENPEACE,
    name: `Осквернение дикой местности на моей родине я считаю личным оскорблением.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_FAR_TRAVELLER,
    ],
  },
  {
    id: BOND_GNEV_ZEMEL,
    name: `Я вымещу свой чудовищный гнев на злодеях, уничтоживших мои земли.`,
    backgroundLimit: [
      BACKGROUND_NOBLE,
      BACKGROUND_KNIGHT,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
      BACKGROUND_FAR_TRAVELLER,
    ],
  },
  {
    id: BOND_GOREC,
    name: `Я ★последний★ из своего племени, и ★должен★ сделать так, чтобы наши имена вошли в легенды.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: BOND_KATASTROFA,
    name: `Мне являются жуткие видения грядущей катастрофы, и я сделаю всё, чтобы её предотвратить.`,
  },
  {
    id: BOND_NOVYE_DETI,
    name: `Я ★должен★ создавать новых детей, которые будут поддерживать моё племя.`,
    backgroundLimit: [
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: BOND_UCHENIKI,
    name: `Я ★должен★ защищать своих учеников.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: BOND_DREVNIY_TEKST,
    name: `У меня есть древний текст, содержащий ужасную тайну, и который не должен попасть в чужие руки.`,
  },
  {
    id: BOND_BIBLIO,
    name: `Я работаю над сохранением библиотеки.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: BOND_UNIVERSITY,
    name: `Я работаю над сохранением университета.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: BOND_SCRIPTORIY,
    name: `Я работаю над сохранением скриптория.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: BOND_MONASTYR,
    name: `Я работаю над сохранением монастыря.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: BOND_TOMA,
    name: `Труд всей моей жизни это серия томов, посвящённая определённой области знаний.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
    ],
  },
  {
    id: BOND_VOPROS,
    name: `Всю свою жизнь я ищу ответ на один вопрос.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: BOND_DUSHI_NET,
    name: `Ради знаний я ★продал★ душу. Когда-нибудь я надеюсь совершить великое деяние и вернуть её себе.`,
    backgroundLimit: [
      BACKGROUND_SAGE,
      BACKGROUND_CLOISTERED_SCHOLAR,
      BACKGROUND_HERMIT,
    ],
  },
  {
    id: BOND_KAPITAN,
    name: `В первую очередь я ★верен★ своему капитану, всё остальное вторично.`,
    backgroundLimit: [
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
    ],
  },
  {
    id: BOND_KORABL,
    name: `Самое важное — это корабль, а экипаж и капитаны постоянно меняются.`,
    backgroundLimit: [
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
    ],
  },
  {
    id: BOND_PERVIY_KORABL,
    name: `Я всегда буду помнить свой первый корабль.`,
    backgroundLimit: [
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
    ],
  },
  {
    id: BOND_BABA_V_GAVANI,
    name: `В гавани у меня есть человек, чьи глаза стоят того, чтобы оставить ради него море.`,
    backgroundLimit: [
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
    ],
  },
  {
    id: BOND_NE_DALI_DENEG,
    name: `Как-то раз мне выплатили неполное жалованье, и я хочу вернуть себе причитающееся.`,
    backgroundLimit: [
      BACKGROUND_SAILOR,
      BACKGROUND_PIRATE,
      BACKGROUND_SOLDIER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: BOND_PIRATY_PIDARY,
    name: `Безжалостные пираты убили моего капитана и почти всю команду, разграбили корабль, и оставили меня умирать. Месть будет страшной.`,
    backgroundLimit: [
      BACKGROUND_SAILOR,
    ],
  },
  {
    id: BOND_KLYATVA_SLUJIT,
    name: `Я по-прежнему ★готов★ отдать свою жизнь за тех, кому ★поклялся★ служить.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_CITY_WATCH,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
    ],
  },
  {
    id: BOND_SPAS_MNE_JIZN,
    name: `Кое-кто спас мне жизнь на поле боя. С тех пор я никогда не оставляю друзей, оставшихся в бою.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: BOND_CHEST,
    name: `Моя честь — моя жизнь.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_CITY_WATCH,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: BOND_PORAZHENIE,
    name: `Никогда не забуду сокрушительное поражение, которое потерпел наш отряд, и врага, устроившего это.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: BOND_SOYUZNIKI,
    name: `Те, кто сражаются рядом со мной, достойны того, чтобы за них умереть.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: BOND_ZA_TEH_KTO_NE_SMOG_SAM,
    name: `Я сражаюсь за тех, кто не может это сделать сам.`,
    backgroundLimit: [
      BACKGROUND_SOLDIER,
      BACKGROUND_CITY_WATCH,
      BACKGROUND_KNIGHT_OF_THE_ORDER,
      BACKGROUND_MERCENARY_VETERAN,
    ],
  },
  {
    id: BOND_KUSOK_RODINY,
    name: `Пока у меня есть частичка моей родины, я могу взглянуть в лицо любым несчастьям в этих землях.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: BOND_BOGI_NARODA,
    name: `Боги моего народа утешают меня вдали от дома.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: BOND_SLUJBA_NARODU,
    name: `Для меня нет большего долга, чем долг моей службы моему народу.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: BOND_SVOBODA,
    name: `Моя свобода — величайшее моё сокровище. Я не позволю никому отобрать её.`,
  },
  {
    id: BOND_KRASOTA_ZEMEL,
    name: `Я заворожён красотой и чудесами этих новых земель.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
  {
    id: BOND_LYUBIMYE,
    name: `Я не мог взять с собой любимых и ★оставил★ их так далеко. Я надеюсь увидеть их однажды вновь.`,
    backgroundLimit: [
      BACKGROUND_FAR_TRAVELLER,
      BACKGROUND_OUTLANDER,
      BACKGROUND_UTHGARDT_TRIBE_MEMBER,
    ],
  },
].map(
  item => ({
    ...item,
    probabilityWeight: item.backgroundLimit ? 10 : 1,
  })
)

export default bondVariantList
export const bondVariantCollection = listToCollectionById(bondVariantList)
