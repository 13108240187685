const {BACKGROUND_URCHIN} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {CREATURE_RAT} = require('./../../creatureIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_SLEIGHT_OF_HAND,
  SKILL_STEALTH,
} = require('./../../skillList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_DISGUISE_KIT,
  GEAR_POUCH,
  GEAR_THIEVES_TOOLS,
} = require('./../../gearIdList')

module.exports = {
  id: BACKGROUND_URCHIN,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Беспризорник',
    [GENDER_FEMALE]: 'Беспризорница',
  },
  nameEn: 'Urchin',
  description: [
    {
      header: 'Беспризорник',
      text: `Вы выросли на улице в бедности и одиночестве, лишённые родителей. Никто не присматривал и не заботился о вас, и вам пришлось научиться делать это самому. Вам приходилось постоянно бороться за еду и следить за другими неприкаянными душами, способными обокрасть вас. Вы спали на чердаках и в переулках, мокли под дождём и боролись с болезнями, не получая медицинской помощи или приюта. Вы выжили, невзирая на все трудности, и сделали это благодаря своей сноровке, силе или скорости.

Вы начинаете приключение с суммой денег, достаточной, чтоб скромно, но уверенно прожить десять дней. Как Вы получили эти деньги? Что позволило вам перейти к нормальной жизни, преодолев нищету?`,
      source: {
        id: SOURCE_PHB,
        page: 142,
      },
    },
    {
      header: 'Умение: Городские тайны',
      text: `Вы знаете тайные лазы и проходы городских улиц, позволяющие пройти там, где другие не увидят пути. Вне боя Вы (и ведомые вами союзники) можете перемещаться по городу вдвое быстрее обычного.`,
      source: {
        id: SOURCE_PHB,
        page: 142,
      },
    },
    {
      header: 'Персонализация',
      text: `Жизнь в нищете оставляет свой отпечаток на беспризорниках. В них, как правило, сильна привязанность к людям, с которыми они делили тяготы уличной жизни, или они исполнены желанием добиться лучшей доли, и, возможно, расквитаться с богачами, от которых они натерпелись.`,
      source: {
        id: SOURCE_PHB,
        page: 142,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | В моих карманах полно побрякушек и объедков. |
| 2 | Я задаю очень много вопросов. |
| 3 | Я часто забиваюсь в узкие закутки, где никто не сможет добраться до меня. |
| 4 | Я всегда сплю, прижавшись спиной к стене или дереву, сжимая узелок со всеми своими пожитками в руках. |
| 5 | Я не воспитан, и ем как свинья. |
| 6 | Я убеждён, что все, кто проявляют доброту ко мне, на самом деле скрывают злые намерения. |
| 7 | Я не люблю мыться. |
| 8 | Я прямо говорю о том, на что прочие предпочитают лишь намекнуть, или промолчать. |
`,
      source: {
        id: SOURCE_PHB,
        page: 142,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Уважение.** Все люди, богатые ли они, или бедные, достойны уважения. | Доброе |
| 2 | **Общность.** Вы должны заботиться друг о друге, ведь никто другой этого не сделает. | Принципиальное |
| 3 | **Перемены.** Убогие возвысятся, а великие падут. Перемены в природе вещей. | Хаотичное |
| 4 | **Возмездие.** Нужно показать богачам, чего стоит жизнь и смерть в трущобах. | Злое |
| 5 | **Люди.** Я помогаю тем, кто помогает мне. Это позволяет нам выжить. | Нейтральное |
| 6 | **Устремление.** Я готов доказать, что достоин лучшей жизни. | Любое |
`,
      source: {
        id: SOURCE_PHB,
        page: 142,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Мой город или деревня это мой дом, и я буду защитить его. |
| 2 | Я спонсирую приют, чтобы оградить других от того, что пришлось пережить мне. |
| 3 | Я выжил лишь благодаря другому беспризорнику, что передал мне знания, как вести себя на улицах. |
| 4 | Я в неоплатном долгу перед человеком, что сжалился и помог мне. |
| 5 | Я избавился от нищеты, ограбив важного человека, и меня разыскивают. |
| 6 | Никто не должен пережить те трудности, через которые пришлось пройти мне. |
`,
      source: {
        id: SOURCE_PHB,
        page: 142,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Если я в меньшинстве, то я убегу из битвы. |
| 2 | Золото в любом виде выглядит для меня как большая куча денег, и я сделаю всё, чтобы его у меня стало больше. |
| 3 | Я никогда не доверяю полностью кому бы то ни было, кроме себя. |
| 4 | Я предпочту убить кого-нибудь во сне, нежели в честном поединке. |
| 5 | Это не воровство, если я взял то, в чём нуждаюсь больше, чем кто-либо другой. |
| 6 | Те, кто не могут позаботиться о себе, получат то, что заслужили. |
`,
      source: {
        id: SOURCE_PHB,
        page: 142,
      },
    },
  ],
  note: 'Вы росли на суровых улицах и стали хитры, упорны и мудры не по годам',
  proficiency: {
    tool: {
      list: [
        GEAR_THIEVES_TOOLS,
        GEAR_DISGUISE_KIT,
      ],
    },
    skill: {
      list: [
        SKILL_SLEIGHT_OF_HAND,
        SKILL_STEALTH,
      ],
    },
  },
  equipment: `
* Маленький нож,
* Карта города, в котором Вы выросли,
* Ручная [мышь](CREATURE:${CREATURE_RAT}),
* Безделушка в память о родителях,
* [Комплект повседневной одежды](GEAR:${GEAR_CLOTHES_COMMON}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 142,
  },
}
