const {BACKGROUND_ENTERTAINER} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_ACROBATICS,
  SKILL_PERFORMANCE,
} = require('./../../skillList')
const {
  GEAR_CLOTHES_COSTUME,
  GEAR_DISGUISE_KIT,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  CAT_MUSIC_TOOLS,
} = require('./../../gearCategoryList')

module.exports = {
  id: BACKGROUND_ENTERTAINER,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Артист',
    [GENDER_FEMALE]: 'Артистка',
  },
  nameEn: 'Entertainer',
  description: [
    {
      header: 'Артист',
      text: `Вам нравится выступать на публике. Вы знаете, как их развлечь, очаровать и даже воодушевить. Ваша поэзия может трогать сердца слушателей, пробуждать в них горе или радость, смех или гнев. Ваша музыка ободряет их или заставляет скорбеть. Ваши танцы захватывают, а шутки всегда смешны. Чем бы Вы ни занимались, ваша жизнь тесно связана с искусством.`,
      source: {
        id: SOURCE_PHB,
        page: 131,
      },
    },
    {
      header: 'Умение: По многочисленным просьбам',
      text: `Вы всегда можете найти место для выступления. Обычно это таверна или постоялый двор, но это может быть цирк, театр или даже двор знатного господина. В этом месте Вы получаете бесплатный постой и еду по скромным или комфортным стандартам (в зависимости от качества заведения), если Вы выступаете каждый вечер. Кроме того, ваши выступления делают вас местной знаменитостью. Когда посторонние узнают вас в городе, в котором Вы давали представление, они, скорее всего, будут к вам относиться хорошо.`,
      source: {
        id: SOURCE_PHB,
        page: 131,
      },
    },
    {
      header: 'Номера артиста',
      text: `Хороший артист обладает разнообразными номерами. Выберите от одного до трёх амплуа из приведённой таблицы, чтобы определить, чем Вы развлекаете публику.

| к10 | Амплуа   | к10 | Амплуа          |
|-----|----------|-----|-----------------|
| 1   | Акробат  |  6  | Пожиратель огня |
| 2   | Актёр    |  7  | Поэт            |
| 3   | Жонглёр  |  8  | Рассказчик      |
| 4   | Музыкант |  9  | Танцор          |
| 5   | Певец    | 10  | Шут             |
`,
      source: {
        id: SOURCE_PHB,
        page: 131,
      },
    },
    {
      header: 'Персонализация',
      text: `Успешные артисты могут овладевать вниманием публики, поэтому у них яркий или напористый характер. Они могут быть романтичными, и в искусстве и красоте часто обращаются к возвышенным идеалам.`,
      source: {
        id: SOURCE_PHB,
        page: 131,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Для любой ситуации я найду подходящий рассказ. |
| 2 | Куда бы я ни пришёл, я начинаю собирать местные слухи и распространять сплетни. |
| 3 | Я безнадёжный романтик, всегда ищущий «кого-то особого». |
| 4 | Никто не сердится на меня или возле меня подолгу, так как я могу разрядить любую напряжённую обстановку. |
| 5 | Мне нравятся оскорбления, даже если они направлены на меня. |
| 6 | Мне обидно, если я не нахожусь в центре внимания. |
| 7 | Превыше всего я ценю совершенство. |
| 8 | Моё настроение и намерения меняются так же быстро как ноты в музыке. |
`,
      source: {
        id: SOURCE_PHB,
        page: 131,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Красота.** Выступая, я делаю мир лучше. | Доброе |
| 2 | **Традиции.** Рассказы, легенды и песни прошлого не должны забываться, так как они учат нас тому, кто мы такие. | Принципиальное |
| 3 | **Творчество.** Миру нужны новые идеи и смелые действия. | Хаотичное |
| 4 | **Жадность.** Я занимаюсь всем этим ради денег и славы. | Злое |
| 5 | **Народ.** Мне нравится видеть улыбки на лицах во время выступления. В этом-то всё дело. | Нейтральное |
| 6 | **Честность.** Искусство должно отражать душу; оно должно идти изнутри и показывать, чем мы являемся. | Любое |
`,
      source: {
        id: SOURCE_PHB,
        page: 132,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Мой инструмент — самое драгоценное, что у меня есть, и он напоминает мне о моей любви. |
| 2 | Кто-то украл мой драгоценный инструмент, и когда-нибудь я верну его. |
| 3 | Я хочу быть знаменитым, чего бы это ни стоило. |
| 4 | Я боготворю героя старых рассказов, и всегда сравниваю свои поступки с его. |
| 5 | Я всё сделаю, чтобы доказать превосходство над ненавистным конкурентом. |
| 6 | Я сделаю что угодно для других членов моей старой труппы. |
`,
      source: {
        id: SOURCE_PHB,
        page: 132,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Я пойду на всё ради славы и известности. |
| 2 | Не могу устоять перед смазливым личиком. |
| 3 | Я не могу вернуться домой из-за скандала. Неприятности такого рода словно преследуют меня. |
| 4 | Однажды я высмеял дворянина, который всё ещё хочет оторвать мне голову. Это была ошибка, но я могу повторить её ещё неоднократно. |
| 5 | Я не могу скрывать свои истинные чувства. Острый язык всегда приносит мне неприятности. |
| 6 | Я очень стараюсь исправиться, но друзьям не стоит на меня полагаться. |
`,
      source: {
        id: SOURCE_PHB,
        page: 132,
      },
    },
  ],
  proficiency: {
    tool: {
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_MUSIC_TOOLS,
        ],
      },
      list: [
        GEAR_DISGUISE_KIT,
      ],
    },
    skill: {
      list: [
        SKILL_PERFORMANCE,
        SKILL_ACROBATICS,
      ],
    },
  },
  equipment: `
* Музыкальный инструмент (на Ваш выбор),
* Подарок от поклонницы (любовное письмо, локон волос или безделушка),
* [Костюм](GEAR:${GEAR_CLOTHES_COSTUME}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 15 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 131,
  },
}
