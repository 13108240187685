import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import generateTextLinks from '@/utils/generateTextLinks'
import Markdown from '@/components/Markdown'
import ParamList from '@/components/ParamList'
import FvttExportBtn from '@/components/FvttExportBtn'

import { OCCUPATION_ADVENTURER } from '@/constants/occupationVariantList'

import FamilyInfo from './components/FamilyInfo'
import LifeEventList from './components/LifeEventList'
import CharacterRelation from './components/CharacterRelation'
import KnowParents from './components/KnowParents'
import Personality from './components/Personality'
import CharacterRace from './components/CharacterRace'
import CharacterBackground from './components/CharacterBackground'

import './CharacterStyles.less'

const CharacterComponent = (
  {
    ageText,
    alignmentText,
    appearanceText,
    birthplaceText,
    bondText,
    character,
    childhoodHomeText,
    className,
    columnCount,
    downloadVtt,
    faceText,
    familyLifeStyleText,
    flawText,
    giftText,
    hairText,
    missedParentTextList,
    name,
    occupationText,
    parentHalfRaceText,
    pcClassChoiceText,
    physicalFeaturesText,
    sizeText,
    stats,
    traitList,
  },
) => (
  <section
    className={classNames(
      `Character ${className}`,
      {
        'Character-isAdventurer': character.occupationId === OCCUPATION_ADVENTURER,
        'Character-isNotAdventurer': character.occupationId !== OCCUPATION_ADVENTURER,
      }
    )}
    style={{columnCount: columnCount}}
  >
    <section className='Character_infoBlock'>
      <header className='Character_header'>
        <FvttExportBtn
          className='Character_downloadVttBtn'
          onClick={downloadVtt}
        />
        <h2 className='Character_name'>{name}</h2>
      </header>
      <CharacterRelation
        className='Character_relation'
        character={character}
      />
      <p><CharacterRace character={character}/>, {alignmentText}</p>
      {
        sizeText
          ? <p>{sizeText}</p>
          : null
      }
      <ParamList params={stats}/>
      <Markdown>{generateTextLinks(occupationText)}</Markdown>
      <CharacterBackground character={character}/>
      {
        pcClassChoiceText
          ? <p>{pcClassChoiceText}</p>
          : null
      }
      <p>{ageText}</p>
      <p>{physicalFeaturesText}</p>
      <p>{giftText}</p>
    </section>

    {
      bondText || flawText || traitList
        ? (
          <section className='Character_infoBlock'>
            <header className='Character_blockHeader'>Личность</header>
            {
              traitList && traitList.length
                ? (
                  <Personality header='Характер'>
                    <ul>
                      {
                        traitList.map(
                          trait => (
                            <li>{trait}</li>
                          )
                        )
                      }
                    </ul>
                  </Personality>
                )
                : null
            }
            {
              bondText
                ? (
                  <Personality header='Привязанность'>
                    {bondText}
                  </Personality>
                )
                : null
            }
            {
              flawText
                ? (
                  <Personality header='Слабость'>
                    {flawText}
                  </Personality>
                )
                : null
            }
          </section>
        )
        : null
    }

    <section className='Character_infoBlock'>
      <header className='Character_blockHeader'>Внешность</header>
      <p>{appearanceText}</p>
      <p>{faceText}</p>
      {
        hairText
          ? <p>{hairText}</p>
          : null
      }
    </section>

    {
      character.occupationId === OCCUPATION_ADVENTURER
        ? (
          <>
            <section className='Character_infoBlock'>
              <header className='Character_blockHeader'>Семья</header>
              <p>{birthplaceText}</p>
              <FamilyInfo character={character}/>
              <p>{familyLifeStyleText}</p>
              <p>{childhoodHomeText}</p>
              <KnowParents character={character}/>
              {
                parentHalfRaceText && parentHalfRaceText.length
                  ? <p>{parentHalfRaceText}</p>
                  : null
              }
              {
                missedParentTextList.length
                  ? missedParentTextList.map((missedParent, i) => (
                    <Markdown key={i}>{generateTextLinks(missedParent)}</Markdown>
                  ))
                  : null
              }
            </section>

            <LifeEventList character={character}/>
          </>
        )
        : null
    }
  </section>
)

CharacterComponent.propTypes = {
  age: PropTypes.string,
  alignmentText: PropTypes.string,
  birthplaceText: PropTypes.string,
  character: PropTypes.object.isRequired,
  childhoodHomeText: PropTypes.string,
  faceText: PropTypes.string,
  familyLifeStyleText: PropTypes.string,
  missedParentTextList: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  name: PropTypes.string,
  occupationText: PropTypes.string,
  parentHalfRaceText: PropTypes.string,
  pcClassChoiceText: PropTypes.string,
  raceText: PropTypes.string,
  stats: PropTypes.object.isRequired,
}

CharacterComponent.defautProps = {
  age: '',
  alignmentText: '',
  birthplaceText: '',
  childhoodHomeText: '',
  faceText: '',
  familyLifeStyleText: '',
  missedParentTextList: '',
  name: '',
  occupationText: '',
  parentHalfRaceText: '',
  pcClassChoiceText: '',
  raceText: '',
}

export default CharacterComponent
