const {BACKGROUND_AGENT_ZHENTARIM} = require('./../../backgroundIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {PC_CLASS_WIZARD} = require('./../../pcClassIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  GOD_BANE,
  GOD_DENEIR,
} = require('./../../godIdList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
} = require('./../../skillList')
const faction_agent_1st = require('./../common/faction_agent_1st')
const faction_agent_2nd = require('./../common/faction_agent_2nd')
const faction_agent_equipment = require('./../common/faction_agent_equipment')

module.exports = {
  id: BACKGROUND_AGENT_ZHENTARIM,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Агент Жентарима',
    [GENDER_FEMALE]: 'Агент Жентарима',
  },
  nameEn: 'Zhentarim Agent',
  description: [
    ...faction_agent_1st,
    {
      header: 'Жентарим',
      text: `В последние годы, Жентарим для мира в целом стала видимой более, как группа, которая делает многое, чтобы улучшить свою репутацию среди простого люда. Фракция нанимает работников и партнёров из различных областей, давая задания, которые служат целям Чёрной Сети, но которые не всегда преступны по своей натуре. Агентам Чёрной Сети часто приходится работать в тайне, и они часто владеют навыком Обмана. Они ищут поддержки у [волшебников](PC_CLASS:${PC_CLASS_WIZARD}), наёмников, купцов и жречества, союзного Жентариму.`,
      source: {
        id: SOURCE_SCAG,
        page: 148,
      },
    },
    {
      header: 'Лидеры Жентарима',
      text: `_Фрагмент из очередного тома «Нового метатекста» Иосефы Эльгин — учёной дамы, отлучённой от церкви [Денеира](GOD:${GOD_DENEIR}) за еретические попытки воссоздать Метатекст, божественную хронику скрытого и утерянного знания._

Как бы сами Жентарим ни утверждали обратное, не нужно быть семи пядей во лбу, чтобы распознать в их современном укладе явные черты философии служителей [Бэйна](GOD:${GOD_BANE}). Жентарим — военизированная организация со строгой иерархией, в которой вся полнота власти принадлежит единоличному лидеру. Достоверно неизвестно, кто именно занимает эту должность теперь — до недавнего времени бытовало мнение что вся правящая верхушка Чёрной сети была уничтожена вместе с её штаб-квартирой в крепости Жентил ещё в 1383 г.

Однако в последующие десятилетия новым центром их влияния стала Тёмная Твердыня — могучая крепость, что находится далеко на юге. Одной из ключевых тамошних фигур является некий Перегост — таинственный рыцарь, который, согласно общепринятому мнению, и возродил Жентарим из пепла практически в одиночку.

Кем бы ни был их лидер, не подлежит сомнению, что Жентарим не оставили своих планов на мировое господство, а лишь изменили свой подход к их осуществлению. Если раньше Чёрная сеть активно вела боевые действия и занималась подрывной деятельностью, стремясь подчинить себе другие народы, то теперь она больше стремится укрепить своё влияние на рынке и так проникнуть за границы, закрытые для её армий.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    ...faction_agent_2nd,
  ],
  note: {
    text: `Сталь убирает врагов, золото приводит друзей.`,
    author: `Жентаримская поговорка`,
  },
  proficiency: {
    skill: {
      list: [
        SKILL_INSIGHT,
        SKILL_DECEPTION,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: faction_agent_equipment('Жентарима'),
  source: {
    id: SOURCE_SCAG,
    page: 148,
  },
}
