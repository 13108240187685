import React from 'react'

import generateLifeEventTextList from '@/components/CharacterGenerator/utils/generateLifeEventTextList'
import { VARIANT_LIVE_EVENT } from '@/components/CharacterGenerator/constants/variantIdList'

import LifeEventListComponent from './LifeEventListComponent'

const LifeEventListContainer = ({ character }) => {
  const list = generateLifeEventTextList(character, VARIANT_LIVE_EVENT)
    .map(
      (item, i) => {
        const resultObj = typeof item == 'string'
          ? { text: item }
          : item

        const id = `${character.id}_${i}_${resultObj.text.length}`

        return {
          ...resultObj,
          id,
        }
      }
    )

  return list && list.length
    ? (
      <LifeEventListComponent
        character={character}
        list={list}
      />
    )
    : null
}

export default LifeEventListContainer
