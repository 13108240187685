import arrify from 'arrify'
import _ from 'lodash'

import generateStats from '@/utils/generateStats'
import pickByPropability from '@/utils/pickByPropability'
import rollDice from '@/utils/rollDice'
import calcDiceRandom from '@/utils/calcDiceRandom'

import pcSubClassList from '@/constants/pcSubClassList'
import { pcClassCollection } from '@/constants/pcClassList'
import HOMOSEXUAL_COEF from '@/constants/HOMOSEXUAL_COEF'
import { familyVariantCollection } from '@/components/CharacterGenerator/constants/familyVariantList'
import OCCUPATION_ID_NOT_ADVENTURER from '@/components/CharacterGenerator/constants/OCCUPATION_ID_NOT_ADVENTURER'
import { pcSubraceCollection, pcSubraceIdByRaceIdDict } from '@/constants/pcSubraceList'
import {
  pcHalfRaceIdList,
  pcRaceCollection,
} from '@/constants/pcRaceList'
import {
  PC_RACE_HALF_ELF,
  PC_RACE_HALF_ORC,
  PC_RACE_TIEFLING,
} from '@/constants/pcRaceIdList'
import { GENDER_FEMALE, GENDER_MALE } from '@/constants/genderList'
import {paramIdList} from '@/constants/paramList'
import occupationVariantList, { OCCUPATION_ADVENTURER } from '@/constants/occupationVariantList'
import appearanceList from '@/constants/appearanceList'

import { generateFullName } from '@/components/NameGenerator'

import ageVariantList from './constants/ageVariantList'
import alignmentVariantList from './constants/alignmentVariantList'
import backgroundVariantList from './constants/backgroundVariantList'
import faceShapeList from '@/constants/faceShapeList'
import hairColorList from '@/constants/hairColorList'
import hairFormList from '@/constants/hairFormList'
import hairLengthList from '@/constants/hairLengthList'
import childhoodHomeVariantList from './constants/childhoodHomeVariantList'
import familyLifeStyleVariantList from './constants/familyLifeStyleVariantList'
import familyVariantList from './constants/familyVariantList'
import genderVariantList from './constants/genderVariantList'
import parentHalfElfList from './constants/parentHalfElfList'
import parentHalfOrcList from './constants/parentHalfOrcList'
import parentTieflingList from './constants/parentTieflingList'
import pcClassChoice from './constants/pcClassChoice'
import pcClassVariantList from './constants/pcClassVariantList'
import pcSubClassVariantList from './constants/pcSubClassVariantList'
import raceList from './constants/raceList'
import subRaceIdVariantList from './constants/subRaceIdVariantList'
import variantDict from './constants/variantDict'
import CHARACTER_GENERATION_PARAM_COLLECTION_DEFAULT from './constants/CHARACTER_GENERATION_PARAM_COLLECTION_DEFAULT'

import bondVariantList from './constants/personalization/bondVariantList'
import flawVariantList from './constants/personalization/flawVariantList'
import traitVariantList from './constants/personalization/traitVariantList'

import addStatBonuses from './utils/addStatBonuses'

import store from './store'
import {
  VARIANT_LIVE_EVENT,
  VARIANT_FAMILY,
  VARIANT_FACE_ATTRACTIVENESS,
  VARIANT_KNOW_PARENTS,
  VARIANT_MISSED_PARENT,
  VARIANT_BIRTH_PLACE,
  VARIANT_PHYSICAL_NEGATIVE,
  VARIANT_PHYSICAL_POSITIVE,
  VARIANT_GIFT,
} from './constants/variantIdList'

export default class Character {
  constructor(characterGenerationParams = CHARACTER_GENERATION_PARAM_COLLECTION_DEFAULT) {
    this.characterGenerationParams = {
      ...CHARACTER_GENERATION_PARAM_COLLECTION_DEFAULT,
      ...characterGenerationParams,
    }

    this.id = this.characterGenerationParams.id

    this.setRelation()
    this.generateGenderId()
    this.generateRaceId()
    this.generateOccupation()
    this.generateStats()
    this.generateGift()
    this.generateAppearance()
    this.generatePhysicalFeatures()
    this.generateFace()
    this.generateHair()
    this.generateSize()
    this.generateAlignmentId()
    this.generateAge()
    this.generateBirthplaceId()
    this.generateFamilyDetail()
    this.generateKnowParents()
    this.generateFamilyLifeStyleId()
    this.generateChildhoodHomeId()
    this.generateName()
    this.generateLifeEventList()
    this.fixStats()
  }

  generateName = () => {
    const { raceId, genderId, subRaceId } = this
    this.name = generateFullName({raceId, genderId, subRaceId})
    this.shortName = this.name.firstName
  }

  filterByCurrentCharacter = listToFilter => listToFilter.reduce(
    (list, item) => {
      let itemToAdd = item

      if (item.probabilityChange) {
        Object
          .entries(item.probabilityChange)
          .forEach(
            ([key, value]) => {
              const keyList = Object.keys(value)

              if (keyList && keyList.includes(this[key])) {
                itemToAdd = {
                  ...item,
                  probabilityWeight: value[this[key]],
                }
              }
            }
          )
      }

      return [
        ...list,
        itemToAdd,
      ]
    },
    []
  )

  generateAppearance = () => {
    const {id, paramBonuses} = pickByPropability(
      appearanceList.filter(
        appearance => appearance.genderId
          ? appearance.genderId === this.genderId
          : true
      )
    )

    addStatBonuses(this.stats, paramBonuses)

    this.appearanceId = id
  }

  generateGenderId = () => {
    const {isLoveInterest, genderId} = this.characterGenerationParams

    if (genderId) {
      this.genderId = genderId
      this.isHomosexual = Math.random() <= HOMOSEXUAL_COEF
    } else {
      if (isLoveInterest) {
        const {characterCollection} = store.getState()
        const parentCharacter = characterCollection[this.parentCharacterId]

        this.isHomosexual = parentCharacter.isHomosexual

        if (parentCharacter.isHomosexual) {
          this.genderId = parentCharacter.genderId
        } else {
          this.genderId = parentCharacter.genderId === GENDER_MALE
            ? GENDER_FEMALE
            : GENDER_MALE
        }
      } else {
        this.isHomosexual = Math.random() <= HOMOSEXUAL_COEF
        this.genderId = pickByPropability(genderVariantList).id
      }
    }
  }

  generateFamilyLifeStyleId = () => {
    const { id, childhoodHomeModifier } = pickByPropability(familyLifeStyleVariantList)

    this.familyLifeStyleId = id
    this.childhoodHomeModifier = childhoodHomeModifier
  }

  generateSize = () => {
    const size = (
      this.subRaceId
      && pcSubraceCollection[this.subRaceId]
      && pcSubraceCollection[this.subRaceId].size
      || pcRaceCollection[this.raceId].size
    )

    if (size) {
      const {
        tall: {
          base: tallBase,
          coefDice: tallDiceCoef,
        },
        weight: {
          base: weightBase,
          coefDice: weightDiceCoef,
        },
      } = size

      const tallCoef = calcDiceRandom(tallDiceCoef)
      const weightCoef = calcDiceRandom(weightDiceCoef)

      this.tall = tallBase + tallCoef
      this.weight = weightBase + weightCoef * tallCoef
    }
  }

  generateChildhoodHomeId = () => {
    const { childhoodHomeModifier } = this
    const { id } = pickByPropability(childhoodHomeVariantList, childhoodHomeModifier)

    this.childhoodHomeId = id
  }

  generateKnowParents = () => {
    this[VARIANT_KNOW_PARENTS] = this.generateDetails(VARIANT_KNOW_PARENTS)
  }

  generatePhysicalFeatures = () => {
    const {id: physicalNegativeId, paramBonuses: physicalNegativeBonuses} = pickByPropability(variantDict[VARIANT_PHYSICAL_NEGATIVE].list)
    const {id: physicalPositiveId, paramBonuses: physicalPositiveBonuses} = pickByPropability(variantDict[VARIANT_PHYSICAL_POSITIVE].list)

    this[VARIANT_PHYSICAL_NEGATIVE] = physicalNegativeId
    this[VARIANT_PHYSICAL_POSITIVE] = physicalPositiveId

    addStatBonuses(this.stats, physicalNegativeBonuses)
    addStatBonuses(this.stats, physicalPositiveBonuses)
  }

  generateGift = () => {
    this[VARIANT_GIFT] = this.generateDetails(VARIANT_GIFT)

    const {paramBonuses} = this[VARIANT_GIFT]

    addStatBonuses(this.stats, paramBonuses)
  }

  generateRaceId = () => {
    const {sameRace, raceId} = this.characterGenerationParams
    const parentCharacter = store.getState().characterCollection[this.parentCharacterId]

    this.raceId = raceId || (
      sameRace && parentCharacter
        ? parentCharacter.raceId
        : pickByPropability(raceList).id
    )

    const subraceIdList = pcSubraceIdByRaceIdDict[this.raceId]

    if (subraceIdList) {
      const currentSubRaceIdVariantList = subRaceIdVariantList.filter(
        ({id}) => subraceIdList.includes(id)
      )
      this.subRaceId = pickByPropability(currentSubRaceIdVariantList).id
    }

    if (pcHalfRaceIdList.includes(this.raceId)) {
      if (this.raceId === PC_RACE_HALF_ELF) {
        this.parentHalfRaceId = pickByPropability(parentHalfElfList).id
      }
      if (this.raceId === PC_RACE_HALF_ORC) {
        this.parentHalfRaceId = pickByPropability(parentHalfOrcList).id
      }
      if (this.raceId === PC_RACE_TIEFLING) {
        this.parentHalfRaceId = pickByPropability(parentTieflingList).id
      }
    }
  }

  generateFamilyDetail = () => {
    const { id } = pickByPropability(familyVariantList)

    this[VARIANT_FAMILY] = this.generateDetails(VARIANT_FAMILY)

    const { missedParentTypeIdList } = familyVariantCollection[id]

    this.missedParentList = missedParentTypeIdList && missedParentTypeIdList.length
      ? missedParentTypeIdList.map(
        parentTypeId => ({
          parentTypeId,
          details: this.generateDetails(VARIANT_MISSED_PARENT),
        }),
      )
      : null
  }

  generateBirthplaceId = () => {
    this[VARIANT_BIRTH_PLACE] = this.generateDetails(VARIANT_BIRTH_PLACE)
  }

  generateAlignmentId = () => {
    this.alignmentId = pickByPropability(alignmentVariantList).id
  }

  generateStats = () => {
    if (this.pcClassId) {
      const statList = paramIdList.map(() => generateStats())

      const [statMain, statSecondary] = pcClassCollection[this.pcClassId].featureCollection.proficiency.savingThrow

      const mainStat = Math.max.call(this, ...statList)
      const mainStatIndex = statList.findIndex(stat => stat === mainStat)
      const statWithoutMainList = statList.filter((e, index) => index !== mainStatIndex)
      const secondStat = Math.max.call(this, ...statWithoutMainList)
      const secondStatIndex = statWithoutMainList.findIndex(
        (stat, index) => stat === secondStat && index !== mainStatIndex
      )
      const statWithoutMainAndSecondList = statWithoutMainList.filter((e, index) => index !== secondStatIndex)

      let notImportantStatIndex = 0;

      this.stats = paramIdList.reduce(
        (statObj, statId) => {
          switch (statId) {
            case statMain:
              return {
                ...statObj,
                [statId]: mainStat,
              }
            case statSecondary:
              return {
                ...statObj,
                [statId]: secondStat,
              }
            default:
              return {
                ...statObj,
                [statId]: statWithoutMainAndSecondList[notImportantStatIndex++],
              }
          }
        },
        {}
      )
    } else {
      this.stats = paramIdList.reduce(
        (statObj, id) => ({
          ...statObj,
          [id]: generateStats(true),
        }),
        {}
      )
    }

    if (this.raceId) {
      const {paramBonuses} = pcRaceCollection[this.raceId]

      addStatBonuses(this.stats, paramBonuses)
    }

    if (this.subRaceId) {
      const {paramBonuses} = pcSubraceCollection[this.subRaceId]

      addStatBonuses(this.stats, paramBonuses)
    }
  }

  fixStats = () => {
    this.stats = Object
      .entries(this.stats)
      .reduce(
        (statObj, [id, value]) => ({
          ...statObj,
          [id]: Math.min(Math.max(Number(value), 1), 20),
        }),
        {}
      )
  }

  generateFace = () => {
    this.faceShapeId = pickByPropability(faceShapeList).id

    this[VARIANT_FACE_ATTRACTIVENESS] = this.generateDetails(VARIANT_FACE_ATTRACTIVENESS)

    const {paramBonuses} = this[VARIANT_FACE_ATTRACTIVENESS]

    addStatBonuses(this.stats, paramBonuses)
  }

  generateHair = () => {
    if (
      !pcRaceCollection[this.raceId].noHairs &&
      (
        !this.subRaceId ||
        !pcSubraceCollection[this.subRaceId].noHairs
      )
    ) {
      this.hair = {
        colorId: pickByPropability(this.filterByCurrentCharacter(hairColorList)).id,
        formId: pickByPropability(hairFormList).id,
        lengthId: pickByPropability(hairLengthList).id,
      }
    }
  }

  generateClassId = () => {
    this.pcClassId = pickByPropability(pcClassVariantList).id
    const choiceData = pcClassChoice[this.pcClassId]
    if (choiceData) {
      this.pcClassChoiceId = pickByPropability(choiceData.list).id
    }
  }

  setRelation = () => {
    const {relationText, parentCharacterId} = this.characterGenerationParams

    this.relationText = relationText
    this.parentCharacterId = parentCharacterId
  }

  generateOccupation = () => {
    const { occupationId } = this.characterGenerationParams

    this.occupationId = occupationId
      ? occupationId === OCCUPATION_ID_NOT_ADVENTURER
        ? pickByPropability(occupationVariantList.filter(({id}) => id !== OCCUPATION_ADVENTURER)).id
        : occupationId
      : pickByPropability(occupationVariantList).id

    if (this.occupationId === OCCUPATION_ADVENTURER) {
      this.generateClassId()
      this.generateSubClassId()
      this.generateBackground()
    }

    this.generateFlaw()
    this.generateTrait()
    this.generateTrait()
    this.generateBond()
  }

  generateBackground = () => {
    const { backgroundId } = this.characterGenerationParams

    this.backgroundId = backgroundId || pickByPropability(backgroundVariantList).id
  }

  generateFlaw = () => {
    const { flawId } = this.characterGenerationParams

    if (flawId) {
      this.flawId = flawId
    } else {
      this.flawId = pickByPropability(
        flawVariantList.filter(
          ({backgroundLimit}) => backgroundLimit
            ? this.backgroundId && arrify(backgroundLimit).includes(this.backgroundId)
            : true
        )
      ).id
    }
  }

  generateTrait = () => {
    const { traitIdList } = this.characterGenerationParams

    if (traitIdList) {
      this.traitIdList = traitIdList
    } else {
      let traitCount = 2

      this.traitIdList = []

      while (traitCount--) {
        const { id: traitId } = pickByPropability(
          traitVariantList.filter(
            ({backgroundLimit}) => backgroundLimit
              ? this.backgroundId && arrify(backgroundLimit).includes(this.backgroundId)
              : true
          )
        )
        this.traitIdList.push(traitId)
      }
    }
  }

  generateBond = () => {
    const { bondId } = this.characterGenerationParams

    if (bondId) {
      this.bondId = bondId
    } else {
      this.bondId = pickByPropability(
        bondVariantList.filter(
          ({backgroundLimit}) => backgroundLimit
            ? this.backgroundId && arrify(backgroundLimit).includes(this.backgroundId)
            : true
        )
      ).id
    }
  }

  generateLifeEventList = () => {
    this[VARIANT_LIVE_EVENT] = []
    let { lifeEventCount } = this

    while (lifeEventCount) {
      const {age: {adult}} = pcRaceCollection[this.raceId]
      const happensAtAge = _.random(adult, Math.max(this.ageYears, adult))

      const event = {
        ...this.generateDetails(VARIANT_LIVE_EVENT),
        happensAtAge,
      }


      this[VARIANT_LIVE_EVENT].push(event)
      lifeEventCount--
    }
  }

  generateDetails = data => {
    if (typeof data === 'string') {
      const variantItem = variantDict[data]

      if (variantItem) {
        const variant = pickByPropability(variantItem.list)

        return this.generateDetails({
          detailDictId: data,
          ...variant,
        })
      }

      return null
    }

    const {id, detailId, detailDictId, detailDictIdList, dice, ...rest} = data

    const diceResult = dice
      ? calcDiceRandom(dice) + 1
      : null

    const detailList = detailDictIdList
      ? arrify(detailDictIdList).map(
        detailDictId => {
          const detail = pickByPropability(variantDict[detailDictId].list)
          return this.generateDetails({
            ...detail,
            detailId: detail.id,
            detailDictId,
          })
        }
      )
      : null

    return {
      ...rest,
      detailId: detailId || id,
      diceResult,
      detailDictId,
      detailList,
    }
  }

  generateAge = () => {
    const {age: {adult, max: raceMaxYears}} = pcRaceCollection[this.raceId]

    const ageVariantListModifiedByCurrentRace = ageVariantList.reduce(
      (list, ageVariant, i) => {
        if (
          ageVariantList[i + 1] &&
          ageVariantList[i + 1].lifePart.from * raceMaxYears <= adult
        ) {
          return list
        }

        if (ageVariant.lifePart.from * raceMaxYears < adult) {

          return [
            ...list,
            {
              ...ageVariant,
              lifePart: {
                ...ageVariant.lifePart,
                from: adult / raceMaxYears,
              },
            },
          ]
        }

        if (ageVariant.lifePart.to * raceMaxYears < adult) {

          return [
            ...list,
            {
              ...ageVariant,
              lifePart: {
                ...ageVariant.lifePart,
                to: adult / raceMaxYears,
              },
            },
          ]
        }

        return [
          ...list,
          ageVariant,
        ]
      },
      []
    )

    const { name, lifePart } = pickByPropability(ageVariantListModifiedByCurrentRace)
    const yearsMin = Math.round(raceMaxYears * lifePart.from)
    const yearsMax = Math.round(raceMaxYears * lifePart.to)

    this.ageYears = _.random(yearsMin, yearsMax)

    this.age = name[this.genderId]

    this.lifeEventCount = Math.round(this.ageYears / 20) * (rollDice(4)() + 1)
  }

  generateSubClassId = () => {
    const { pcClassId } = this

    const pcSubClassFilteredIdList = pcSubClassList
      .filter(
        ({ pcClassId: classId }) => pcClassId === classId,
      )
      .map(({ id }) => id)

    const pcSubClassVariantFilteredList = pcSubClassVariantList.filter(
      ({ id }) => pcSubClassFilteredIdList.includes(id),
    )

    this.pcSubClassId = pickByPropability(pcSubClassVariantFilteredList).id
  }
}
