const {
  BACKGROUND_AGENT_HARPERS,
  BACKGROUND_AGENT_ORDER_GAUNTLET,
} = require('./../../backgroundIdList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_HELM,
  GOD_HOAR,
  GOD_TORM,
  GOD_TYR,
} = require('./../../godIdList')
const {
  SKILL_INSIGHT,
  SKILL_RELIGION,
} = require('./../../skillList')
const faction_agent_1st = require('./../common/faction_agent_1st')
const faction_agent_2nd = require('./../common/faction_agent_2nd')
const faction_agent_equipment = require('./../common/faction_agent_equipment')

module.exports = {
  id: BACKGROUND_AGENT_ORDER_GAUNTLET,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Агент ордена Перчатки',
    [GENDER_FEMALE]: 'Агент ордена Перчатки',
  },
  nameEn: 'Gauntlet Order Member',
  description: [
    ...faction_agent_1st,
    {
      header: 'Орден Перчатки',
      text: `Одна из новых влиятельных групп на Фаэруне, Орден Перчатки имеет цели, перекликающиеся с целями [Арфистов](BACKGROUND:${BACKGROUND_AGENT_HARPERS}). В прочем, методы его сильно отличаются: носители перчатки — священные воины, поставившие перед собой задачу уничтожить зло и установить справедливость, и они никогда не прячутся в тени. Агенты Ордена склонны владеть навыком Религии, и часто ищут помощи у правоохранителей, дружественных ордену, и клира богов покровителей ордена.`,
      source: {
        id: SOURCE_SCAG,
        page: 148,
      },
    },
    {
      header: 'Орден Перчатки: о законе и правосудии',
      text: `_Добро_ есть отсутствие зла. Быть добрым означает стремиться к миру и понуждать к нему; преследовать безнравственность и всячески бороться с нею; твёрдо верить в своего бога и в себя, ибо величайшая добродетель из всех есть _вера_. При этом _добро_ не есть _законопослушание_.

_Законы_ суть заповеди богов и людей. Закон есть повеление тем, кто блюдёт его, и кара ослушникам. Закон есть отец _порядка_. Отвергать закон — значит приветствовать хаос, в котором всякая жизнь теряет свой смысл. В то же время законы могут быть извращены. _Законопослушание_ не есть _добро_._
 
Итак, я говорю вам, что мы не стремимся быть добродетельными или же законопослушными, но устремление наше — к _справедливости_. Что толку быть добрым, не понимая, кто и что вредит добру? И что хорошего в следовании закону, если он отражает повеление дьявола? _Справедливость_ — вот длань, кормящая голодных, и щит, охраняющий слабых; она есть меч, что разит порочных, и плащ, согревающий неимущих. Орден Перчатки стоит на страже _справедливости_. _Справедливость_ — вот чего требуют от нас [Тир](GOD:${GOD_TYR}) и [Торм](GOD:${GOD_TORM}), [Хельм](GOD:${GOD_HELM}) и [Хоар](GOD:${GOD_HOAR}).

_— Собрание сочинений на тему закона и порядка. На обложке изображена ладонь, сжимающая клинок меча._`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    ...faction_agent_2nd,
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_INSIGHT,
        SKILL_RELIGION,
      ],
    },
    language: {
      id: LANG_ANY,
      count: 2,
    },
  },
  equipment: faction_agent_equipment('Ордена Перчатки'),
  source: {
    id: SOURCE_SCAG,
    page: 148,
  },
}
