import React from 'react'

import './CharacterControlsStyles.less'

export default ({ regenerateHero, setProp, controlList }) => (
  <section className='CharacterControls'>
    <ul className='CharacterControls_list'>
      {
        controlList.map(
          ({propKey, list}) => (
            <li
              className='CharacterControls_item'
              key={propKey}
            >
              <select
                onChange={setProp(propKey)}
                className='CharacterControls_select'
                key={propKey}
              >
                {
                  list.map(
                    ({value, text}) => (
                      <option
                        value={value}
                        key={value}
                        className='CharacterControls_option'
                      >
                        {text}
                      </option>
                    )
                  )
                }
              </select>
            </li>
          )
        )
      }
    </ul>

    <button
      onClick={regenerateHero}
      className='CharacterControls_generateBtn'
    >
      Сгенерировать
    </button>
  </section>
)
