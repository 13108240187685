const {SOURCE_SCAG} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  BACKGROUND_FOLK_HERO,
  BACKGROUND_INHERITOR,
} = require('./../../backgroundIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  CAT_GAME_SET,
  CAT_MUSIC_TOOLS,
} = require('./../../gearCategoryList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  SKILL_ARCANA,
  SKILL_HISTORY,
  SKILL_RELIGION,
  SKILL_SURVIVAL,
} = require('./../../skillList')
const folk_hero = require('./folk_hero')

module.exports = {
  id: BACKGROUND_INHERITOR,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Наследник',
    [GENDER_FEMALE]: 'Наследница',
  },
  nameEn: 'Inheritor',
  description: [
    {
      header: 'Наследник',
      text: `Вы наследник чего-то очень ценного — не просто монет или богатства, но объекта, который доверили вам и только вам. Ваше наследие может быть получено вами непосредственно от члена вашей семьи, по праву рождения, или его мог оставить вам друг, наставник, учитель, или кто-то еще важный из вашей жизни. Откровение о вашем наследстве изменило вашу жизнь, и возможно наставило вас на путь искателя приключений, но также оно подвергло вас множеству опасностей, включая тех, кто жаждет заполучить ваш дар себе — если потребуется, даже силой.`,
      source: {
        id: SOURCE_SCAG,
        page: 151,
      },
    },
    {
      header: 'Умение: Наследие',
      text: `Выбери или определи случайно, что является вашим наследием из возможных вариантов в таблице ниже. Обсудите это со своим Мастером, чтобы определиться с деталями: почему ваше наследие такое важное, и какова его полная история? Возможно, Вы предпочтёте, чтобы Мастер придумывал или раскрывал эти детали по ходу игры, позволяя вам узнавать новые детали истории о вашем наследии вместе с вашим персонажем.

Мастер волен использовать ваше наследие как зацепку для истории, отправляя вас на задания, чтобы узнать новые детали истории или истинной природы вашего наследия, или для того, чтобы Вы столкнулись с противниками, которые хотят заполучить ваше наследие себе или не позволить вам узнать то, что Вы ищете. Мастер также определяет свойства вашего наследия и то, как они отражаются на его истории и важности. Например, предмет наследия может быть слабым магическим предметом, невзрачным на первый взгляд, и раскрыться только при определённых условиях.

Когда Вы начинаете свою карьеру искателя приключений, вам следует решить, говорить ли вашим компаньонам о вашем наследии сразу, или нет. Возможно, вместо того, чтобы привлекать внимание к себе, Вы захотите сохранить это в секрете пока не узнаете больше о том, что это может значить и чем может обернуться для вас.`,
      source: {
        id: SOURCE_SCAG,
        page: 151,
      },
    },
    {
      header: 'Наследие',
      text: `
| к8 | Объект или предмет |
|-|-|
| 1 | Документ, такой как карта, письмо или журнал |
| 2–3 | Безделушка |
| 4 | Предмет одежды |
| 5 | Ювелирное украшение |
| 6 | Книга или справочник магии |
| 7 | Рукопись истории, песни, стихотворения или шифра |
| 8 | Татуировка или другая отметка на теле |
`,
      source: {
        id: SOURCE_SCAG,
        page: 151,
      },
    },
    {
      header: 'Предлагаемые характеристики',
      text: `Используйте таблицы для предыстории [народного героя](BACKGROUND:${BACKGROUND_FOLK_HERO}), как основу для ваших черт характера и мотивов, изменяя их содержание при необходимости, чтобы более точно описать вашу личность в качестве наследника.

Ваша привязанность может быть непосредственно связанна с вашим наследством, или с личностью, от которой Вы его получили. То, что Вы уже знаете или узнаете, как только поймёте, на что ваше наследство способно, может повлиять на ваши идеалы.`,
      source: {
        id: SOURCE_SCAG,
        page: 152,
      },
    },
    ...folk_hero.description.slice(4),
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_SURVIVAL,
      ],
      toChoose: {
        count: 1,
        list: [
          SKILL_ARCANA,
          SKILL_HISTORY,
          SKILL_RELIGION,
        ],
      },
    },
    tool: {
      comment: `скорее всего, что-то свойственное вашей родине`,
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_GAME_SET,
          CAT_MUSIC_TOOLS,
        ],
      },
    },
    language: LANG_ANY,
  },
  equipment: `
* Ваше наследство,
* [Комплект дорожной одежды](GEAR:${GEAR_CLOTHES_TRAVELERS}),
* Любые предметы, которыми Вы профессионально владеете,
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 15 зм.
`,
  source: {
    id: SOURCE_SCAG,
    page: 151,
  },
}
