import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

import Icon from '@/components/Icon'

import FoundryVTTIcon from '@/images/foundry-vtt.png'

import './FvttExportBtnStyles.less'

const FvttExportBtn = ({className, onClick}) => (
  <button
    className={classNames('FvttExportBtn', className)}
    title='Скачать JSON в формате Foundry VTT'
    onClick={onClick}
  >
    <Icon icon={faFileDownload}/>
    <Icon icon={FoundryVTTIcon}/>
  </button>
)

FvttExportBtn.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

FvttExportBtn.defautProps = {
  className: '',
  onClick: noop,
}

export default FvttExportBtn
