import {pcRaceCollection} from '@/constants/pcRaceList'
import {genderCollection} from '@/constants/genderList'
import { OCCUPATION_ADVENTURER } from '@/constants/occupationVariantList'
import upLetter from '@/utils/upLetter'

import raceList from '@/components/CharacterGenerator/constants/raceList'
import genderVariantList from '@/components/CharacterGenerator/constants/genderVariantList'
import OCCUPATION_ID_NOT_ADVENTURER from '@/components/CharacterGenerator/constants/OCCUPATION_ID_NOT_ADVENTURER'

export default [
  {
    propKey: 'raceId',
    list: [
      {
        value: '',
        text: '— Раса —',
      },
      ...raceList
        .map(
          ({id}) => ({
            value: id,
            text: upLetter(pcRaceCollection[id].name.plural.nominative)
          })
        )
        .sort(
          ({text: A}, {text: B}) => A > B ? 1 : -1
        )
    ]
  },
  {
    propKey: 'occupationId',
    list: [
      {
        value: '',
        text: '— Занятие —',
      },
      {
        value: OCCUPATION_ADVENTURER,
        text: 'Приключенец',
      },
      {
        value: OCCUPATION_ID_NOT_ADVENTURER,
        text: 'Не приключенец',
      },
    ]
  },
  {
    propKey: 'genderId',
    list: [
      {
        value: '',
        text: '— Пол —',
      },
      ...genderVariantList
        .map(
          ({id}) => ({
            value: id,
            text: upLetter(genderCollection[id].name)
          })
        )
        .sort(
          ({text: A}, {text: B}) => A > B ? 1 : -1
        )
    ]
  },
]
