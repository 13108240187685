import { pcClassCollection } from '@/constants/pcClassList'
import { pcSubClassCollection } from '@/constants/pcSubClassList'
import { occupationCollection } from '@/constants/occupationVariantList'

import upLetter from '@/utils/upLetter'

const generateOccupationText = ({ genderId, occupationId, pcClassId, pcSubClassId }) => {
  const occupationPretext = occupationCollection[occupationId].name[genderId]

  if (pcClassId && pcSubClassId) {
    const {name: { singular: { nominative: pcClassName } }} = pcClassCollection[pcClassId]
    const {name: pcSubClassName, fullName, isReady} = pcSubClassCollection[pcSubClassId]
    const preText = upLetter(occupationPretext)
    const postText = isReady
      ? `[${upLetter(pcClassName)}](PC_CLASS:${pcClassId}): [${pcSubClassName}](PC_SUBCLASS:${pcSubClassId})`
      : fullName
    return `${preText}, ${postText}`
  } else {
    return upLetter(occupationPretext)
  }
}

export default generateOccupationText
