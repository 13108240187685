import listToCollectionById from '@/utils/listToCollectionById'

export const HAIR_FORM_BRAIDED = 'braided'
export const HAIR_FORM_CURLED = 'curled'
export const HAIR_FORM_STRAIGHT = 'straight'
export const HAIR_FORM_WAVY = 'wavy'

const hairFormList = [
  {
    name: `прямые`,
    id: HAIR_FORM_STRAIGHT,
    probabilityWeight: 3,
  },
  {
    name: `волнистые`,
    id: HAIR_FORM_WAVY,
    probabilityWeight: 3,
  },
  {
    name: `курчавые`,
    id: HAIR_FORM_CURLED,
    probabilityWeight: 3,
  },
  {
    name: `заплетёные`,
    id: HAIR_FORM_BRAIDED,
    probabilityWeight: 1,
  },
]

export default hairFormList
export const hairFormCollection = listToCollectionById(hairFormList)

/*
ВОЛОСЫ

  ДВАРФЫ
    волосы, которые они носят длинными, но собранными в простые причёски, обычно чёрного, серого или  коричневого цвета. У дварфов с бледной кожей часто встречаются рыжие волосы.

    ЩИТОВЫЕ ДВАРФЫ
      волосы — светлые, рыжие или коричневые. Мужчины обычно носят полные бороды и усы.

    ЗОЛОТЫЕ ДВАРФЫ (ХОЛМОВЫЕ)
      черными или коричневыми волосами. Мужчины отращивают полные бороды, тщательно ухаживают за ними и умащивают их маслом, и оба пола носят волосы длинными, часто - искусно заплетенными

    ДВЕРГАРЫ
      лысая голова, а мужчины отращивают длинные, растрёпанные серые бороды

  ЭЛЬФЫ
    помимо человеческих цветов могут быть зелёными или синими
    не растут волосы на лице, и почти отсутствуют волосы на теле

    СОЛНЕЧНЫХ ЭЛЬФОВ
      волосы медного, чёрного или золотистого оттенка
      Волосы солнечных эльфов чёрные, каштановые с медным отливом или золотистые

    ЛУННЫЕ ЭЛЬФЫ
      часто серебристо-белые, чёрные или синие волосы, но и различные оттенки светлых, коричневых и рыжих тонов также не являются редкими
      Цвет волос лунных эльфов такой же, как и у людей, но у некоторых из них волосы бывают серебристо-белого или одного из оттенков голубого цвета

    ЛЕСНЫХ ЭЛЬФОВ
      коричневые и чёрные волосы, но иногда они бывают светлого или бронзового оттенков

    ДРОУ
      совершенно белые или очень светлые волосы


  ПОЛУРОСЛИКИ
    Волосы обычно коричневые или рыже-коричневые, вьющиеся
    Мужчины часто отпускают длинные бакенбарды, но бороды носят редко, а усы тем более.

  ЛЮДИ
    волосы — от чёрного до светлого тонов (волнистые, кудрявые или прямые); мужчины могут носить волосы на лице, густые или редкие.

    ШУ
      с тёмными волосами

    ЧОНДАТАНЦЫ
      с коричневыми, от светлых до почти чёрных, волосами

    ТЁРАМИ
      чёрными волнистыми волосами

    ТЕТИРЦЫ
      коричневые волосы наиболее типичны

    РАШЕМИ
      густые чёрные волосы

    КАЛИШИТЫ
      смугло-коричневые волосы

    МУЛАНЫ
      Волосы у них от чёрного до тёмно-коричневого оттенков

    ИЛЛУСКАНЦЫ
      чёрные волосы цвета вороньего крыла, светлые, рыжие или светло-русые волосы.

    ДАМАРЦЫ
      волосы обычно коричневые или чёрные

    ЧУЛЬТЫ
      темными  волосами

    АРКАЮНЫ
      черноволосые

    БЕДИНЫ
      темноволосые

    ФФОЛКИ
      происходят от тетирских поселенцев

    ГУРЫ
      темноволосые

    ХАЛРУААНЦЫ
      блондины или брюнеты

    ИМАСКАРИ
      волосы побелели и разгладились

    НАРЫ
      волосы чёрные. Часто Нары носят длинные волосы, схваченные в хвост или пучок

    ШААРЦЫ
      Темноволосые

    ТУЙГАНЫ
      похожи на людей Шу тёмными волосами

    УЛУТИУНЕ
      черноволосый народ

  ГНОМЫ
    русые волосы обычно торчат в разные стороны. Гномы мужчины содержат свои бороды, в отличие от растрёпанных волос, аккуратно подстриженными, но часто расчёсывают их на несколько прядей, или придают забавную заострённую форму

    СВИРФНЕБЛИНЫ
      Мужчины обычно лысы и не носят бороды, в то время как женщины имеют волосы на своих головах. Оба пола имеют мало волосяного покрова


  ТИФЛИНГИ
    волосы, струящиеся из-за рогов, обычно тёмные, от чёрного или коричневого до тёмно-красного, синего или фиолетового

*/
