import listToCollectionById from '@/utils/listToCollectionById'

export const FAMILY_LIFESTYLE_WRETCHED = 'family_lifestyle_wretched'
export const FAMILY_LIFESTYLE_SQUALID = 'family_lifestyle_squalid'
export const FAMILY_LIFESTYLE_POOR = 'family_lifestyle_poor'
export const FAMILY_LIFESTYLE_MODEST = 'family_lifestyle_modest'
export const FAMILY_LIFESTYLE_COMFORTABLE = 'family_lifestyle_comfortable'
export const FAMILY_LIFESTYLE_WEALTHY = 'family_lifestyle_wealthy'
export const FAMILY_LIFESTYLE_ARISTOCRATIC = 'family_lifestyle_aristocratic'

// TODO: Учитывать расу
const familyLifeStyleVariantList = [
  {
    name: `Никудышный`,
    id: FAMILY_LIFESTYLE_WRETCHED,
    childhoodHomeModifier: -40,
    probabilityWeight: 1, // 3
  },
  {
    name: `Нищенский`,
    id: FAMILY_LIFESTYLE_SQUALID,
    childhoodHomeModifier: -20,
    probabilityWeight: 2, // 4-5
  },
  {
    name: `Бедный`,
    id: FAMILY_LIFESTYLE_POOR,
    childhoodHomeModifier: -10,
    probabilityWeight: 3, // 6-8
  },
  {
    name: `Скромный`,
    id: FAMILY_LIFESTYLE_MODEST,
    childhoodHomeModifier: 0,
    probabilityWeight: 4, // 9-12
  },
  {
    name: `Комфортный`,
    id: FAMILY_LIFESTYLE_COMFORTABLE,
    childhoodHomeModifier: +10,
    probabilityWeight: 3, // 13-15
  },
  {
    name: `Богатый`,
    id: FAMILY_LIFESTYLE_WEALTHY,
    childhoodHomeModifier: +20,
    probabilityWeight: 2, // 16-17
  },
  {
    name: `Аристократический`,
    id: FAMILY_LIFESTYLE_ARISTOCRATIC,
    childhoodHomeModifier: +40,
    probabilityWeight: 1, // 18
  },
]

export default familyLifeStyleVariantList
export const familyLifeStyleVariantCollection = listToCollectionById(familyLifeStyleVariantList)
