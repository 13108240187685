const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GEAR_POUCH,
  GEAR_THIEVES_TOOLS,
} = require('./../../gearIdList')
const {
  BACKGROUND_CRIMINAL,
  BACKGROUND_URBAN_BOUNTY_HUNTER,
} = require('./../../backgroundIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  CAT_GAME_SET,
  CAT_MUSIC_TOOLS,
} = require('./../../gearCategoryList')
const {
  SKILL_DECEPTION,
  SKILL_INSIGHT,
  SKILL_PERSUASION,
  SKILL_STEALTH,
} = require('./../../skillList')
const criminal = require('./criminal')

module.exports = {
  id: BACKGROUND_URBAN_BOUNTY_HUNTER,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Городской охотник за головами',
    [GENDER_FEMALE]: 'Городская охотница за головами',
  },
  nameEn: 'Urban Bounty Hunter',
  description: [
    {
      header: 'Городской охотник за головами',
      text: `До того, как Вы стали искателем приключений, ваша жизнь уже была полна конфликтов и других событий, так как Вы зарабатывали на жизнь выслеживанием людей за вознаграждение. В отличие от других охотников за головами Вы не преследуете цель в дикой местности. Ваш прибыльный бизнес охватывает лишь место вашего проживания, постоянно проверяя ваши навыки и инстинкты выживания. Также, Вы не работаете в одиночку, как это делают охотники за головами дикой местности: Вы регулярно взаимодействуете с криминальным миром и другими охотниками за головами, поддерживая контакты с обеими сторонами для достижения успеха.

Вы можете бы быть хитрым ловцом воров, шныряющим по городским крышам для поимки одного из множества городских грабителей. Возможно, Вы именно тот, кто слышит, что говорят на улицах и в курсе дел воровской гильдии и уличных банд. Вы можете быть охотником за головами в «бархатной маске», вхожим в высшее и благородное общество, чтобы ловить преступников, которые ориентируются на богачей, будь то карманники или аферисты. Обществом, в котором Вы ведёте свои дела, может быть один из крупных мегаполисов Фаэруна, такой как Глубоководье или Врата Балдура, или менее густонаселённые области, например, Лускан или Яртар — вам подойдёт любая область, достаточно большая, чтобы стабильно поставлять потенциальные цели.

Как член группы искателей приключений, у вас могут возникнуть трудности с преследованием собственных целей, которые не входят в общие цели группы. С другой стороны, Вы сможете загнать значительно более внушительные цели с помощью ваших компаньонов.`,
      source: {
        id: SOURCE_SCAG,
        page: 153,
      },
    },
    {
      header: 'Умение: Ухо к земле',
      text: `Вы часто контактируете с людьми из социального слоя, в котором обычно появляются цели, на которых Вы специализируетесь. Эти люди могут быть связанны с криминальным сообществом, простые люди с улиц или представители высшего общества. Эта связь выражается в виде контактного лица, которое может предоставить информацию о людях и местах в любом городе и его окрестностях, которые Вы посещаете.`,
      source: {
        id: SOURCE_SCAG,
        page: 154,
      },
    },
    {
      header: 'Предлагаемые характеристики',
      text: `Используйте таблицы для предыстории [преступника](BACKGROUND:${BACKGROUND_CRIMINAL}), как базу для ваших черт характера и мотивов, изменяя их содержание при необходимости, чтобы более точно описать вашу личность в качестве охотника за головами. Например, ваши привязанности могут включать других охотников за головами или с организациями или личностями, которые вас нанимают. Ваш идеал может быть связан с тем, чтобы никогда не упускать своей добычи или поддерживать репутацию того, на кого можно положиться.`,
      source: {
        id: SOURCE_SCAG,
        page: 154,
      },
    },
    ...criminal.description.slice(4),
  ],
  proficiency: {
    skill: {
      toChoose: {
        count: 2,
        list: [
          SKILL_DECEPTION,
          SKILL_INSIGHT,
          SKILL_PERSUASION,
          SKILL_STEALTH,
        ],
      },
    },
    tool: {
      list: [
        GEAR_THIEVES_TOOLS,
      ],
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_GAME_SET,
          CAT_MUSIC_TOOLS,
        ],
      },
    },
  },
  equipment: `
* Комплект одежды, соответствующие вашей специализации,
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 20 зм.
`,
  source: {
    id: SOURCE_SCAG,
    page: 153,
  },
}
