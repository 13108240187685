const {SOURCE_PHB} = require('./../../sourceList')
const {LANG_ANY} = require('./../../languageIdList')
const {
  BACKGROUND_ACOLYTE,
  BACKGROUND_CHARLATAN,
  BACKGROUND_HERMIT,
  BACKGROUND_OUTLANDER,
} = require('./../../backgroundIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_MEDICINE,
  SKILL_RELIGION,
} = require('./../../skillList')
const {
  GEAR_BLANKET,
  GEAR_CASE_MAP_OR_SCROLL,
  GEAR_CLOTHES_COMMON,
  GEAR_HERBALISM_KIT,
} = require('./../../gearIdList')

module.exports = {
  id: BACKGROUND_HERMIT,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Отшельник',
    [GENDER_FEMALE]: 'Отшельница',
  },
  nameEn: 'Hermit',
  description: [
    {
      header: 'Отшельник',
      text: `Вы значительную часть своей жизни прожили в уединении — либо в закрытой общине, такой как монастырь, либо вообще в одиночестве. Вдали от шумного общества Вы нашли тишину, спокойствие, а возможно, и ответы на важные вопросы.`,
      source: {
        id: SOURCE_PHB,
        page: 135,
      },
    },
    {
      header: 'Умение: Откровение',
      text: `Тихое уединение в долгом отшельничестве дало вам доступ к великому откровению. Точная природа этого откровения зависит от характера вашего уединения. Это может быть истина о вселенной, божествах, влиятельных созданиях на внешних планах, или силах природы. Это может быть место, которое никто и никогда не видел. Вы могли обнаружить что-то, что давно было забыто, или найти реликвию прошлого, способную переписать историю. Это может быть информация, которая может причинить вред тем, кто изгнал вас, и тем самым ставшая причиной возвращения в общество.

Придумайте вместе с Мастером детали вашего откровения и его влияние на кампанию.`,
      source: {
        id: SOURCE_PHB,
        page: 135,
      },
    },
    {
      header: 'Жизнь в уединении',
      text: `Почему Вы жили в изоляции, и из-за чего Вы прервали своё уединение? Придумайте вместе с Мастером точную природу вашего уединения, либо определите её по таблице, случайным образом.

| к8 | Жизнь в уединении |
|-|-|
| 1 | Я искал духовное просветление. |
| 2 | Я жил в общине согласно предписаниям моей веры. |
| 3 | Я был изгнан за преступление, которого не совершал. |
| 4 | Я покинул общество после события, изменившего мою жизнь. | 
| 5 | Мне нужно было тихое место для работы над картиной, книгой, музыкой или манифестом. |
| 6 | Мне нужна была община, близкая к природе, вдали от цивилизации. |
| 7 | Я был смотрителем древних руин или реликвии. |
| 8 | Я был пилигримом, ищущим существо, место или реликвию. |
`,
      source: {
        id: SOURCE_PHB,
        page: 135,
      },
    },
    {
      header: 'Персонализация',
      text: `Некоторым отшельникам комфортно живётся в уединении, а другие жаждут вернуться в общество. Как бы Вы ни относились к одиночеству, такая жизнь повлияла на вашу жизненную позицию и идеалы. Некоторые даже сходят с ума от долгих лет вдали от общества.`,
      source: {
        id: SOURCE_PHB,
        page: 135,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Я так много прожил в изоляции, что редко говорю, предпочитая жесты и редкое мычание. |
| 2 | Я абсолютно безмятежен, даже в самых ужасных ситуациях. |
| 3 | У лидера моей общины были высказывания для любой ситуации, и я стремлюсь поделиться этой мудростью. |
| 4 | Я ужасно сопереживаю всем, кто страдает. |
| 5 | Мне чужд этикет и общественные ожидания. |
| 6 | Я связываю всё, что происходит со мной, с грандиозным замыслом космического масштаба. |
| 7 | Я часто погружаюсь в свои мысли, отвлекаясь от окружения. |
| 8 | Я работаю над великой философской теорией и люблю тех, кто разделяет мои идеи. |
`,
      source: {
        id: SOURCE_PHB,
        page: 135,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | Всеобщее благо. Мои дары предназначены для всех, я не должен использовать их в одиночку. | Доброе |
| 2 | Логика. Эмоции не должны затмевать наше видение того, что истинно и справедливо, а также наше логическое мышление. | Принципиальное |
| 3 | Свободомыслие. Изыскания и любопытство — столпы прогресса. | Хаотичное |
| 4 | Сила. Уединение и созерцание — путь к мистической и магической силе. | Злое |
| 5 | Сам живи и другим не мешай. Вмешиваясь в дела других, причиняешь одни лишь проблемы. | Нейтральное |
| 6 | Самопознание. Познаешь себя — познаешь всё вокруг. | Любое |
`,
      source: {
        id: SOURCE_PHB,
        page: 135,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Самое важное для меня — это другие отшельники, жившие рядом, или мой орден. |
| 2 | Я уединился, чтобы спрятаться от тех, кто мог охотиться за мной. Когда-нибудь я сам брошу им вызов. |
| 3 | Я всё ещё ищу просветление, которого не достиг в уединении, но оно по-прежнему ускользает от меня. |
| 4 | Я уединился, потому что влюбился в ту, с кем мне не суждено жить. |
| 5 | Если моё откровение сбудется, мир будет обречён на гибель. |
| 6 | Моя изоляция позволила познать великое зло, уничтожить которое могу один лишь я. |
`,
      source: {
        id: SOURCE_PHB,
        page: 136,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Теперь, вернувшись в мир, я слишком сильно увлекаюсь его радостями. |
| 2 | Я скрываю тёмные, кровожадные мысли, не ушедшие ни после изоляции, ни после медитаций. |
| 3 | Я категоричен в мыслях и подходе к жизни. |
| 4 | Я позволяю жажде победить в споре становиться сильнее дружбы и гармонии. |
| 5 | Я слишком сильно рискую, стремясь найти утраченные частички знаний. |
| 6 | Мне нравится хранить тайны, и я ими ни с кем не делюсь. |
`,
      source: {
        id: SOURCE_PHB,
        page: 136,
      },
    },
    {
      header: 'Другие отшельники',
      text: `Эта предыстория подразумевает созерцательное уединение в учениях и молитве. Если Вы хотите играть жителем суровой дикой местности, избегающим других существ, попробуйте взять предысторию [чужеземца](BACKGROUND:${BACKGROUND_OUTLANDER}). С другой стороны, если вам нужна более религиозная направленность, можете взять предысторию [прислужника](BACKGROUND:${BACKGROUND_ACOLYTE}). Вы можете даже быть [шарлатаном](BACKGROUND:${BACKGROUND_CHARLATAN}), выдающим себя за мудреца и святого, и дурачащим набожных глупцов.`,
      source: {
        id: SOURCE_PHB,
        page: 136,
      },
    },
  ],
  proficiency: {
    tool: {
      list: [
        GEAR_HERBALISM_KIT,
      ],
    },
    skill: {
      list: [
        SKILL_MEDICINE,
        SKILL_RELIGION,
      ],
    },
    language: LANG_ANY,
  },
  equipment: `
* [Контейнер для свитков](GEAR:${GEAR_CASE_MAP_OR_SCROLL}), битком набитый вашими молитвами и изысканиями,
* [Тёплое одеяло](GEAR:${GEAR_BLANKET}),
* [Обычная одежда](GEAR:${GEAR_CLOTHES_COMMON}),
* [Набор травника](GEAR:${GEAR_HERBALISM_KIT}),
* 5 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 135,
  },
}
