import * as pcSubClassIdCollection from '@/constants/pcSubClassIdList'

const pcSubClassIdList = Object.values(pcSubClassIdCollection)

export default pcSubClassIdList.map(
  id => ({
    id,
    probabilityWeight: 1,
  })
)
