const {
  BACKGROUND_MERCENARY_VETERAN,
  BACKGROUND_SOLDIER,
} = require('./../../backgroundIdList')
const {
  CAT_GAME_SET,
  CAT_TRANSPORT_GROUND,
} = require('./../../gearCategoryList')
const {LANG_ANY} = require('./../../languageIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {GOD_MORADIN} = require('./../../godIdList')
const {
  CREATURE_GNOLL,
  CREATURE_TROLL,
} = require('./../../creatureIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  SKILL_ATHLETICS,
  SKILL_PERSUASION,
} = require('./../../skillList')
const {
  PC_RACE_ELF,
  PC_RACE_DWARF,
  PC_RACE_ORC,
} = require('./../../pcRaceIdList')
const soldier = require('./soldier')

module.exports = {
  id: BACKGROUND_MERCENARY_VETERAN,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Ветеран-наёмник',
    [GENDER_FEMALE]: 'Ветеранша-наёмница',
  },
  nameEn: 'Mercenary Veteran',
  description: [
    {
      header: 'Ветеран-наёмник',
      text: `Будучи наёмником, сражающимся за звонкую монету, Вы хорошо знакомы с риском для жизни и конечностей за долю в добыче. Теперь, Вы смотрите в будущее уже в качестве искателя приключений, где также предстоят сражения с врагом, но гонорары больше. Вы опытны в том, что такое быть наёмником, и у вас наверняка имеются душераздирающие истории о событиях на поле боя. Вы могли служить в крупной организации, такой как Жентарим, побывать солдатом Минтарна, или служили в меньшем отряде наёмников, а возможно и не одном.

Теперь Вы ищете нечто иное, возможно большее вознаграждение за риск, которому Вы подвергаетесь, или свободу выбора вашей деятельности. По какой бы причине Вы не оставили позади жизнь наёмного солдата, ваши навыки несомненно подходят именно для битв, потому Вы продолжаете сражаться, но теперь всё по-другому.`,
      source: {
        id: SOURCE_SCAG,
        page: 152,
      },
    },
    {
      header: 'Умение: Жизнь наёмника',
      text: `Вы знаете жизнь наёмника так хорошо, как только можно. Вы способны различать группы наёмников по их эмблемам, знаете понемногу про каждую из них, включая имена и репутации их командиров и лидеров, а также тех, кто был их недавними нанимателями. Вы знаете таверны и пиршественные залы, где часто собираются наёмники региона, язык которого вам знаком. Вы можете подыскать себе работу наёмника, на время между приключениями, приносящую достаточно, чтобы поддерживать комфортный образ жизни.`,
      source: {
        id: SOURCE_SCAG,
        page: 153,
      },
    },
    {
      header: 'Наёмники Севера',
      text: `Бесчисленные группы наёмников работают по всему Побережью Мечей и на его Севере. Большинство — небольшие группы, насчитывающие от дюжины до сотни наёмников, и предлагающие услуги по обеспечению безопасности, выслеживанию монстров и разбойников, или готовые отправиться на войну за плату в золоте. Некоторые организации, как Жентарим, Пылающие Кулаки или государство Минтарн, насчитывают от сотен до тысяч наёмников и могут предоставить личные армии тем, кто может себе это позволить. Ниже описаны некоторые группы, работающие на Севере.

# Холода

Холодное и загадочное Тайнолесье служит домом для многочисленных групп гоблиноидов, которые сбились вместе в одно племя под названием Холода. В отличие от большинства их сородичей, Холода воздерживаются от грабежа северян и поддерживают с ними относительно хорошие отношения, что позволяет им наниматься в качестве воинов. Некоторые города-государства Севера готовы сражаться вместе с Холодами, но некоторым более по нраву тихо платить Холодам, чтобы те сражались с Утгардтом, [орками](PC_RACE:${PC_RACE_ORC}), [троллями](CREATURE:${CREATURE_TROLL}) Вечных Болот и другими угрозами цивилизации.

# Тихий Дождь

Состоящий исключительно из [эльфов](PC_RACE:${PC_RACE_ELF}), Тихий Дождь — это легендарный отряд наёмников, работающий только в окрестностях Эверески. Слабо заинтересованные в золоте или славе, Тихий Дождь соглашается только на работы, продвигающие эльфийские интересы или включающие в себя уничтожение [орков](PC_RACE:${PC_RACE_ORC}), [гноллов](CREATURE:${CREATURE_GNOLL}) и им подобных. Предполагаемые наниматели должны оставить записку (на эльфийском языке) близ Эверески, а Тихий Дождь вышлет представителя, если их заинтересует предложение.

# Кровавые Топоры

Основанные в Сандабаре около двух столетий назад, Кровавые Топоры изначально были группой [дварфов](PC_RACE:${PC_RACE_DWARF}), изгнанных из своих кланов за преступления против учения [Морадина Кователя Душ](GOD:${GOD_MORADIN}). Они начали наниматься к северянам, способным им заплатить. С тех пор, отряд наёмников открыл возможность вступления для других рас, но каждый член отряда — изгой, преступник или отброс другого толка, пришедший в отряд в поисках нового начала и новой семьи среди смельчаков Кровавых Топоров.`,
      source: {
        id: SOURCE_SCAG,
        page: 153,
      },
    },
    {
      header: 'Предлагаемые характеристики',
      text: `Используйте таблицы предыстории [солдата](BACKGROUND:${BACKGROUND_SOLDIER}) как базовые для ваших черт и мотивов, изменяя их содержимое так, чтобы оно соответствовало вашей идентичности наёмника.

Ваша привязанность может быть связанна с отрядом, с которым Вы путешествовали ранее, или с какими-то товарищами, служившими с вами. Идеалы, которые Вы исповедуете, по большинству своему зависят от вашего мировоззрения и вашей мотивации к борьбе.`,
      source: {
        id: SOURCE_SCAG,
        page: 153,
      },
    },
    ...soldier.description.slice(4),
  ],
  proficiency: {
    skill: {
      list: [
        SKILL_ATHLETICS,
        SKILL_PERSUASION,
      ],
    },
    tool: {
      list: [
        CAT_TRANSPORT_GROUND,
      ],
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_GAME_SET,
        ],
      },
    },
    language: LANG_ANY,
  },
  equipment: `
* Униформа вашей организации (аналогично [дорожной одежде](GEAR:${GEAR_CLOTHES_TRAVELERS}) по качеству),
* Знак отличия вашего ранга,
* Игровой набор на ваш выбор,
* [Поясной кошель](GEAR:${GEAR_POUCH}) с остатком вашего жалования (10 зм).
`,
  source: {
    id: SOURCE_SCAG,
    page: 152,
  },
}
