import generateTextByCharacter from '@/components/CharacterGenerator/utils/generateTextByCharacter'
import { VARIANT_FACE_ATTRACTIVENESS } from '@/components/CharacterGenerator/constants/variantIdList'

import { faceShapeCollection } from '@/constants/faceShapeList'

export default character => {
  const { faceShapeId } = character
  const faceShape = faceShapeCollection[faceShapeId].name

  const {text: faceAttractiveness} = generateTextByCharacter(character)(character[VARIANT_FACE_ATTRACTIVENESS])

  return `Лицо ${faceShape}, ${faceAttractiveness}`
}
