import arrify from 'arrify'

import transformText from '@/utils/transformText'

import variantDict from '@/components/CharacterGenerator/constants/variantDict'
import generateTextByVariantList from '@/components/CharacterGenerator/utils/generateTextByVariantList'

function generateTextByCharacter(character) {
  function generateDetailText({ text = '', detailDictId, detailId, diceResult, characterGenerationParams, detailList, genderId }) {
    const { name } = variantDict[detailDictId].collection[detailId]

    let resultText = generateTextByVariantList({ name, genderId })(text, { detailDictId, detailId, text })

    const childrenTextList = detailList
      ? arrify(detailList)
        .map(
          child => ({
            ...child,
            ...generateEvent({ ...child, genderId }),
            parentDetailId: detailId,
          }),
        )
      : null

    if (childrenTextList) {
      resultText = childrenTextList.reduce(
        generateTextByVariantList({ name, genderId }),
        resultText,
      )
    }

    if (diceResult) {
      resultText = resultText.replace('★DICE★', diceResult)
    }

    return resultText
  }

  function generateEvent({ name, detailList, detailDictId, detailId, diceResult, characterGenerationParams, genderId = character.genderId, ...rest }) {
    let resultText = generateDetailText({
      text: name,
      detailDictId,
      detailId,
      diceResult,
      characterGenerationParams,
      detailList,
      genderId,
    })

    let characterGenerationParamsList = []

    if (characterGenerationParams) {
      characterGenerationParamsList = characterGenerationParamsList.concat(arrify(characterGenerationParams))
    }

    if (detailList) {
      resultText = arrify(detailList).reduce(
        (text, { detailDictId, detailId, diceResult, characterGenerationParams, detailList }) => {
          if (characterGenerationParams) {
            characterGenerationParamsList = characterGenerationParamsList.concat(arrify(characterGenerationParams))
          }

          return generateDetailText({
            text,
            detailDictId,
            detailId,
            diceResult,
            characterGenerationParams,
            detailList,
            genderId,
          })
        },
        resultText,
      )
    }

    return {
      text: transformText(genderId)(resultText).replace(/★ПЕРСОНАЖ★/g, character.shortName),
      characterGenerationParams: characterGenerationParamsList.length
        ? characterGenerationParamsList
        : null,
      ...rest,
    }
  }

  return generateEvent
}

export default generateTextByCharacter
