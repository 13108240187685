import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import action_regenerateHero from './store/actions/regenerateHero'

import CharacterGeneratorComponent from './CharacterGeneratorComponent'

class CharacterGeneratorContainer extends Component {
  componentDidMount () {
    const {generateHero} = this.props

    generateHero()
  }

  render () {
    return (
      <CharacterGeneratorComponent/>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        generateHero: action_regenerateHero,
      },
      dispatch,
    ),
  }
}

export default connect(null, mapDispatchToProps)(CharacterGeneratorContainer)
