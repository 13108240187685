import listToCollectionById from '@/utils/listToCollectionById'

export const DRUID_CHOICE_DEVASTATION = 'druid_choice_devastation'
export const DRUID_CHOICE_CATASTROPHE = 'druid_choice_catastrophe'
export const DRUID_CHOICE_ANIMALS_TALENT = 'druid_choice_animals_talent'
export const DRUID_CHOICE_FRIEND = 'druid_choice_friend'
export const DRUID_CHOICE_SPIRITS = 'druid_choice_spirits'
export const DRUID_CHOICE_ORDER = 'druid_choice_order'

const druidChoiceList = [
  {
    name: `Я ★видел★ слишком много разорения в диких местах, слишком много природного великолепия было разрушено грабителями. Я ★присоединился★ к кругу друидов, чтобы сражаться против врагов природы.`,
    id: DRUID_CHOICE_DEVASTATION,
    probabilityWeight: 1,
  },
  {
    name: `Я ★нашёл★ своё место среди друидов после того, как ★сбежал★ от катастрофы.`,
    id: DRUID_CHOICE_CATASTROPHE,
    probabilityWeight: 1,
  },
  {
    name: `Мне всегда было легко найти общий язык с животными, я ★изучил★ этот талант и ★нашёл★ способ использовать его наилучшим образом.`,
    id: DRUID_CHOICE_ANIMALS_TALENT,
    probabilityWeight: 1,
  },
  {
    name: `Я ★подружился★ с друидом и ★проникся★ учением друидов. Я ★решил★ следовать наставлениям моего друга и отдать что-нибудь миру.`,
    id: DRUID_CHOICE_FRIEND,
    probabilityWeight: 1,
  },
  {
    name: `Когда я ★рос★, я ★видел★ духов вокруг меня — сущностей, которых окружающие не могли воспринимать. Я ★разыскал★ друидов, чтобы они помогли мне понять, что же я вижу, и научили общаться с этими сущностями.`,
    id: DRUID_CHOICE_SPIRITS,
    probabilityWeight: 1,
  },
  {
    name: `Я всегда ★испытывал★ отвращение к существам неестественного происхождения. По этой причине я ★погрузился★ в изучение друидских тайн и ★стал★ ★защитником★ естественного порядка вещей.`,
    id: DRUID_CHOICE_ORDER,
    probabilityWeight: 1,
  },
]

export default druidChoiceList
export const druidChoiceCollection = listToCollectionById(druidChoiceList)
