import React from 'react'

import AdsPlacer from '@/components/AdsPlacer'

import './AdsAfterCharacterStyles.less'

// Баннер РСЯ после персонажа
const AdsAfterCharacter = () => (
  <AdsPlacer
    blockId='R-A-2421058-4'
    className='AdsAfterCharacter'
    selfReload
  />
)

export default AdsAfterCharacter