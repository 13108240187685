const {BACKGROUND_SOLDIER} = require('./../../backgroundIdList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  CAT_GAME_SET,
  CAT_TRANSPORT_GROUND,
} = require('./../../gearCategoryList')
const {CREATURE_HELL_HOUND} = require('./../../creatureIdList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const {
  SKILL_ATHLETICS,
  SKILL_INTIMIDATION,
} = require('./../../skillList')
const {
  GEAR_CLOTHES_COMMON,
  GEAR_DAGGER,
  GEAR_DICE_SET,
  GEAR_PLAYING_CARD_SET,
  GEAR_POUCH,
} = require('./../../gearIdList')

module.exports = {
  id: BACKGROUND_SOLDIER,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Солдат',
    [GENDER_FEMALE]: 'Солдатка',
  },
  nameEn: 'Soldier',
  description: [
    {
      header: 'Солдат',
      text: `Сколько Вы помните, в вашей жизни всегда была война. С молодости Вы проходили тренировки, учились использовать оружие и доспехи, изучали технику выживания, включая то, как оставаться живым на поле боя. Вы могли быть частью армии страны или отряда наёмников, а может, были местным ополченцем во время войны.

Выбрав эту предысторию, определите вместе с Мастером, в какой военной организации Вы состояли, до какого звания Вы дослужились, и что вам за это время довелось пережить. Что это было, регулярная армия, городская стража или деревенское ополчение? Это могла быть частная армия наёмников дворянина или самостоятельный бродячий отряд наёмников. `,
      source: {
        id: SOURCE_PHB,
        page: 141,
      },
    },
    {
      header: 'Умение: Воинское звание',
      text: `Будучи солдатом, Вы заслужили звание. Солдаты, верные военной организации, которой Вы раньше принадлежали, всё ещё признают вашу власть и влияние, и они подчиняются вам, если их звание ниже вашего. С помощью своего звания Вы можете оказывать влияние на других солдат и брать во временное пользование простое снаряжение или лошадей. Возможно, вас пропустят внутрь военного лагеря или крепости, если к вашему званию отнесутся с уважением.`,
      source: {
        id: SOURCE_PHB,
        page: 141,
      },
    },
    {
      header: 'Специализация',
      text: `Будучи солдатом, Вы выполняли особую роль. Совершите бросок к8 или выберите один из вариантов из таблицы самостоятельно:

| к8 | Специализация | к8 | Специализация |
|-|-|-|-|
| 1 | Офицер | 5 | Врач | 
| 2 | Разведчик | 6 | Интендант |
| 3 | Пехотинец | 7 | Знаменосец |
| 4 | Кавалерист | 8 | Обслуживающий персонал (повар, кузнец, и т. п.) |
`,
      source: {
        id: SOURCE_PHB,
        page: 141,
      },
    },
    {
      header: 'Персонализация',
      text: `Ужасы войны вкупе с жёсткой дисциплиной воинской службы оставляют следы на всех солдатах, формируя их идеалы, создавая привязанности, а также оставляя на них шрамы, как физические, так и ментальные.`,
      source: {
        id: SOURCE_PHB,
        page: 141,
      },
    },
    {
      header: 'Черта характера',
      text: `
| к8 | Черта характера |
|-|-|
| 1 | Я всегда вежлив и почтителен. |
| 2 | Меня терзают воспоминания о войне. Не могу забыть то насилие, что мне довелось увидеть. |
| 3 | Я потерял много друзей, и теперь с трудом завожу новых. |
| 4 | У меня много воодушевляющих и предостерегающих рассказов из военного прошлого, подходящих практически к любой боевой ситуации. |
| 5 | Я могу смотреть прямо на [адскую гончую](CREATURE:${CREATURE_HELL_HOUND}), не отводя глаз. |
| 6 | Мне нравится быть сильным и крушить вещи. |
| 7 | У меня грубое чувство юмора. |
| 8 | Я решаю проблемы прямолинейно. Простое решение — лучший путь к успеху. |
`,
      source: {
        id: SOURCE_PHB,
        page: 141,
      },
    },
    {
      header: 'Идеал',
      text: `
| к6 | Идеал | Мировоззрение |
|-|-|-|
| 1 | **Высшее благо.** Наш жребий — отдать свои жизни ради других. | Доброе |
| 2 | **Ответственность.** Я делаю что должен, и подчиняюсь вышестоящим. | Принципиальное |
| 3 | **Независимость.** Если слепо следовать приказам, попадаешь под пяту тирании. | Хаотичное |
| 4 | **Мощь.** В жизни и войне побеждает сильнейший. | Злое |
| 5 | **Живи и не мешай другим.** Идеалы не стоят того, чтобы ради них убивать или воевать. | Нейтральное |
| 6 | **Нация.** Мой город, нация и народ — вот что самое главное. | Любое |
`,
      source: {
        id: SOURCE_PHB,
        page: 141,
      },
    },
    {
      header: 'Привязанность',
      text: `
| к6 | Привязанность |
|-|-|
| 1 | Я по-прежнему готов отдать свою жизнь за тех, кому поклялся служить. |
| 2 | Кое-кто спас мне жизнь на поле боя. С тех пор я никогда не оставляю друзей, оставшихся в бою. |
| 3 | Моя честь — моя жизнь. |
| 4 | Никогда не забуду сокрушительное поражение, которое потерпел наш отряд, или врага, устроившего это. |
| 5 | Те, кто сражаются рядом со мной, достойны того, чтобы за них умереть. |
| 6 | Я сражаюсь за тех, кто не может это сделать сам. |
`,
      source: {
        id: SOURCE_PHB,
        page: 142,
      },
    },
    {
      header: 'Слабость',
      text: `
| к6 | Слабость |
|-|-|
| 1 | Одно чудовище, с которым мы когда-то сразились, до сих пор заставляет меня дрожать от страха. |
| 2 | Я мало уважаю тех, кто не доказал, что является достойным воителем. |
| 3 | В одном сражении я совершил ужасную ошибку, стоившую жизни многим — и я сделаю что угодно, лишь бы это оставалось в тайне. |
| 4 | Ярость к врагам ослепляет меня и делает безрассудным. |
| 5 | Я подчиняюсь закону, даже если закон вызывает страдания. |
| 6 | Я скорее съем свой доспех, чем признаюсь, что был неправ. |
`,
      source: {
        id: SOURCE_PHB,
        page: 142,
      },
    },
  ],
  proficiency: {
    tool: {
      list: [
        CAT_TRANSPORT_GROUND,
      ],
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_GAME_SET,
        ],
      },
    },
    skill: {
      list: [
        SKILL_ATHLETICS,
        SKILL_INTIMIDATION,
      ],
    },
  },
  equipment: `
* Знак отличия,
* Трофей с убитого врага ([кинжал](GEAR:${GEAR_DAGGER}), сломанный клинок или кусок знамени),
* Набор [игровых костей](GEAR:${GEAR_DICE_SET}) или [колода карт](GEAR:${GEAR_PLAYING_CARD_SET}),
* [Комплект обычной одежды](GEAR:${GEAR_CLOTHES_COMMON}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм
`,
  source: {
    id: SOURCE_PHB,
    page: 141,
  },
}
