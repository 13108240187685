import listToCollectionById from '@/utils/listToCollectionById'
import { PARAM_CHA } from '@/constants/paramList'
import {
  VARIANT_FACE_BAD,
  VARIANT_FACE_GOOD,
} from './variantIdList'

export const FACE_ATTRACTIVENESS_GOOD_SLIGHTLY = 'good_slightly'
export const FACE_ATTRACTIVENESS_GOOD_VERY = 'good_very'
export const FACE_ATTRACTIVENESS_GOOD_INCREDIBLY = 'good_incredibly'
export const FACE_ATTRACTIVENESS_GOOD_EXTREMELY = 'good_extremely'
export const FACE_ATTRACTIVENESS_BAD_SLIGHTLY = 'bad_slightly'
export const FACE_ATTRACTIVENESS_BAD_VERY = 'bad_very'
export const FACE_ATTRACTIVENESS_BAD_INCREDIBLY = 'bad_incredibly'
export const FACE_ATTRACTIVENESS_BAD_EXTREMELY = 'bad_extremely'

const faceAttractivenessVariantList = [
  {
    name: `немного ${VARIANT_FACE_GOOD}`,
    id: FACE_ATTRACTIVENESS_GOOD_SLIGHTLY,
    detailDictIdList: VARIANT_FACE_GOOD,
    probabilityWeight: 3,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: VARIANT_FACE_GOOD,
    id: FACE_ATTRACTIVENESS_GOOD_VERY,
    detailDictIdList: VARIANT_FACE_GOOD,
    probabilityWeight: 2,
    paramBonuses: {
      [PARAM_CHA]: 2,
    },
  },
  {
    name: `очень ${VARIANT_FACE_GOOD}`,
    id: FACE_ATTRACTIVENESS_GOOD_INCREDIBLY,
    detailDictIdList: VARIANT_FACE_GOOD,
    probabilityWeight: 1,
    paramBonuses: {
      [PARAM_CHA]: 3,
    },
  },
  {
    name: `невероятно ${VARIANT_FACE_GOOD}`,
    id: FACE_ATTRACTIVENESS_GOOD_EXTREMELY,
    detailDictIdList: VARIANT_FACE_GOOD,
    probabilityWeight: 1,
    paramBonuses: {
      [PARAM_CHA]: 4,
    },
  },
  {
    name: `немного ${VARIANT_FACE_BAD}`,
    id: FACE_ATTRACTIVENESS_BAD_SLIGHTLY,
    detailDictIdList: VARIANT_FACE_BAD,
    probabilityWeight: 3,
    paramBonuses: {
      [PARAM_CHA]: -1,
    },
  },
  {
    name: VARIANT_FACE_BAD,
    id: FACE_ATTRACTIVENESS_BAD_VERY,
    detailDictIdList: VARIANT_FACE_BAD,
    probabilityWeight: 2,
    paramBonuses: {
      [PARAM_CHA]: -2,
    },
  },
  {
    name: `очень ${VARIANT_FACE_BAD}`,
    id: FACE_ATTRACTIVENESS_BAD_INCREDIBLY,
    detailDictIdList: VARIANT_FACE_BAD,
    probabilityWeight: 1,
    paramBonuses: {
      [PARAM_CHA]: -3,
    },
  },
  {
    name: `невероятно ${VARIANT_FACE_BAD}`,
    id: FACE_ATTRACTIVENESS_BAD_EXTREMELY,
    detailDictIdList: VARIANT_FACE_BAD,
    probabilityWeight: 1,
    paramBonuses: {
      [PARAM_CHA]: -4,
    },
  },
]

export default faceAttractivenessVariantList
export const faceAttractivenessVariantCollection = listToCollectionById(faceAttractivenessVariantList)
