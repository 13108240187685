import listToCollectionById from '@/utils/listToCollectionById'
import {
  ALIGNMENT_CE,
  ALIGNMENT_CG,
  ALIGNMENT_CN,
  ALIGNMENT_LE,
  ALIGNMENT_LG,
  ALIGNMENT_LN,
  ALIGNMENT_N,
  ALIGNMENT_NE,
  ALIGNMENT_NG,
} from '@/constants/aligmentList'

// TODO: Учитывать расу
const alignmentVariantList = [
  {
    id: ALIGNMENT_CE,
    probabilityWeight: 1,
  },
  {
    id: ALIGNMENT_CG,
    probabilityWeight: 1,
  },
  {
    id: ALIGNMENT_CN,
    probabilityWeight: 2,
  },
  {
    id: ALIGNMENT_LE,
    probabilityWeight: 18,
  },
  {
    id: ALIGNMENT_LG,
    probabilityWeight: 9,
  },
  {
    id: ALIGNMENT_LN,
    probabilityWeight: 9,
  },
  {
    id: ALIGNMENT_N,
    probabilityWeight: 208,
  },
  {
    id: ALIGNMENT_NE,
    probabilityWeight: 92,
  },
  {
    id: ALIGNMENT_NG,
    probabilityWeight: 92,
  },
]

export default alignmentVariantList
export const alignmentVariantCollection = listToCollectionById(alignmentVariantList)
