import * as spellIdCollection from '@/constants/spellIdList'

const spellIdList = Object.values(spellIdCollection)

export {spellCollection as spellIdVariantCollection} from '@/constants/spellList'

const spellIdVariantList = spellIdList
  .map(id => ({
    id,
    probabilityWeight: 1,
  }))

export default spellIdVariantList
