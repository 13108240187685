import listToCollectionById from '@/utils/listToCollectionById'
import { VARIANT_OUTER_PLANE } from '@/components/CharacterGenerator/constants/variantIdList'

export const BIRTHPLACE_ASTRAL = 'astral'
export const BIRTHPLACE_BARN = 'barn'
export const BIRTHPLACE_BASEMENT = 'basement'
export const BIRTHPLACE_BATTLEFIELD = 'battlefield'
export const BIRTHPLACE_BROTHEL = 'brothel'
export const BIRTHPLACE_CASTLE = 'castle'
export const BIRTHPLACE_CAVE = 'cave'
export const BIRTHPLACE_CRIB = 'crib'
export const BIRTHPLACE_ETHER = 'ether'
export const BIRTHPLACE_FEYWILD = 'feywild'
export const BIRTHPLACE_FIELD = 'field'
export const BIRTHPLACE_FOREST = 'forest'
export const BIRTHPLACE_FORTRESS = 'fortress'
export const BIRTHPLACE_HOME = 'home'
export const BIRTHPLACE_HOME_DOCTOR = 'home_doctor'
export const BIRTHPLACE_HOME_FRIEND = 'home_friend'
export const BIRTHPLACE_HOME_WITCH = 'home_witch'
export const BIRTHPLACE_INN = 'inn'
export const BIRTHPLACE_JAIL = 'jail'
export const BIRTHPLACE_MAGE_LAB = 'mage_lab'
export const BIRTHPLACE_OTHER_RACE = 'other_race'
export const BIRTHPLACE_OUTER_PLANE = 'outer_plane'
export const BIRTHPLACE_PALACE = 'palace'
export const BIRTHPLACE_SCRAPYARD = 'scrapyard'
export const BIRTHPLACE_SECRET_HEADQUARTERS = 'secret_headquarters'
export const BIRTHPLACE_SEWER = 'sewer'
export const BIRTHPLACE_SHADOW = 'shadow'
export const BIRTHPLACE_SHIP_ABOARD = 'ship_aboard'
export const BIRTHPLACE_STREET = 'street'
export const BIRTHPLACE_TAVERN = 'tavern'
export const BIRTHPLACE_TEMPLE = 'temple'
export const BIRTHPLACE_TOWER = 'tower'
export const BIRTHPLACE_TRUCK = 'truck'
export const BIRTHPLACE_VALLEY = 'valley'
export const BIRTHPLACE_VAN = 'van'
export const BIRTHPLACE_WAGON = 'wagon'

const birthplaceVariantList = [
  {
    name: {
      nominative: `дом`,
      local: `в доме`,
    },
    probabilityWeight: 300,
    id: BIRTHPLACE_HOME,
  },
  {
    name: {
      nominative: `дом друга семьи`,
      local: `в доме друга семьи`,
    },
    probabilityWeight: 30,
    id: BIRTHPLACE_HOME_FRIEND,
  },
  {
    name: {
      nominative: `дом знахаря`,
      local: `в доме знахаря`,
    },
    probabilityWeight: 24,
    id: BIRTHPLACE_HOME_DOCTOR,
  },
  {
    name: {
      nominative: `дом повивальной бабки`,
      local: `в доме повивальной бабки`,
    },
    probabilityWeight: 24,
    id: BIRTHPLACE_HOME_WITCH,
  },
  {
    name: {
      nominative: `повозка`,
      local: `в повозке`,
    },
    probabilityWeight: 4,
    id: BIRTHPLACE_WAGON,
  },
  {
    name: {
      nominative: `тележка`,
      local: `в тележке`,
    },
    probabilityWeight: 4,
    id: BIRTHPLACE_TRUCK,
  },
  {
    name: {
      nominative: `фургон`,
      local: `в фургоне`,
    },
    probabilityWeight: 4,
    id: BIRTHPLACE_VAN,
  },
  {
    name: {
      nominative: `хлев`,
      local: `в хлеву`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_CRIB,
  },
  {
    name: {
      nominative: `сарай`,
      local: `в сарае`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_BARN,
  },
  {
    name: {
      nominative: `подвал`,
      local: `в подвале`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_BASEMENT,
  },
  {
    name: {
      nominative: `пещера`,
      local: `в пещере`,
    },
    probabilityWeight: 12,
    id: BIRTHPLACE_CAVE,
  },
  {
    name: {
      nominative: `поле`,
      local: `в поле`,
    },
    probabilityWeight: 12,
    id: BIRTHPLACE_FIELD,
  },
  {
    name: {
      nominative: `лес`,
      local: `в лесу`,
    },
    probabilityWeight: 12,
    id: BIRTHPLACE_FOREST,
  },
  {
    name: {
      nominative: `храм`,
      local: `в храме`,
    },
    probabilityWeight: 18,
    id: BIRTHPLACE_TEMPLE,
  },
  {
    name: {
      nominative: `поле боя`,
      local: `на поле боя`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_BATTLEFIELD,
  },
  {
    name: {
      nominative: `аллея`,
      local: `на аллее`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_VALLEY,
  },
  {
    name: {
      nominative: `улица`,
      local: `на улице`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_STREET,
  },
  {
    name: {
      nominative: `бордель`,
      local: `в борделе`,
    },
    probabilityWeight: 4,
    id: BIRTHPLACE_BROTHEL,
  },
  {
    name: {
      nominative: `таверна`,
      local: `в таверне`,
    },
    probabilityWeight: 4,
    id: BIRTHPLACE_TAVERN,
  },
  {
    name: {
      nominative: `трактир`,
      local: `в трактире`,
    },
    probabilityWeight: 4,
    id: BIRTHPLACE_INN,
  },
  {
    name: {
      nominative: `замок`,
      local: `в замке`,
    },
    probabilityWeight: 3,
    id: BIRTHPLACE_CASTLE,
  },
  {
    name: {
      nominative: `крепость`,
      local: `в крепости`,
    },
    probabilityWeight: 3,
    id: BIRTHPLACE_FORTRESS,
  },
  {
    name: {
      nominative: `башня`,
      local: `в башне`,
    },
    probabilityWeight: 3,
    id: BIRTHPLACE_TOWER,
  },
  {
    name: {
      nominative: `дворец`,
      local: `во дворце`,
    },
    probabilityWeight: 3,
    id: BIRTHPLACE_PALACE,
  },
  {
    name: {
      nominative: `канализация`,
      local: `в канализации`,
    },
    probabilityWeight: 3,
    id: BIRTHPLACE_SEWER,
  },
  {
    name: {
      nominative: `помойка`,
      local: `на помойке`,
    },
    probabilityWeight: 3,
    id: BIRTHPLACE_SCRAPYARD,
  },
  {
    name: {
      nominative: `представители другой расы`,
      local: `среди представителей другой расы`,
    },
    probabilityWeight: 18,
    id: BIRTHPLACE_OTHER_RACE,
  },
  {
    name: {
      nominative: `борт корабля`,
      local: `на борту корабля`,
    },
    probabilityWeight: 18,
    id: BIRTHPLACE_SHIP_ABOARD,
  },
  {
    name: {
      nominative: `тюрьма`,
      local: `в тюрьме`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_JAIL,
  },
  {
    name: {
      nominative: `штаб-квартира секретной организации`,
      local: `в штаб-квартире секретной организации`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_SECRET_HEADQUARTERS,
  },
  {
    name: {
      nominative: `лаборатория мага`,
      local: `в лаборатории мага`,
    },
    probabilityWeight: 12,
    id: BIRTHPLACE_MAGE_LAB,
  },
  {
    name: {
      nominative: `Страна Фей`,
      local: `в Стране Фей`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_FEYWILD,
  },
  {
    name: {
      nominative: `Царство Теней`,
      local: `в Царстве Теней`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_SHADOW,
  },
  {
    name: {
      nominative: `Астральный План`,
      local: `на Астральном Плане`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_ASTRAL,
  },
  {
    name: {
      nominative: `Эфирный План`,
      local: `на Эфирном Плане`,
    },
    probabilityWeight: 6,
    id: BIRTHPLACE_ETHER,
  },
  {
    name: VARIANT_OUTER_PLANE,
    detailDictIdList: VARIANT_OUTER_PLANE,
    id: BIRTHPLACE_OUTER_PLANE,
    probabilityWeight: 6,
  },
]

export default birthplaceVariantList
export const birthplaceVariantCollection = listToCollectionById(birthplaceVariantList)
