import listToCollectionById from '@/utils/listToCollectionById'
import {OCCUPATION_ADVENTURER} from '@/constants/occupationVariantList'

import {
  VARIANT_ADVENTURE,
  VARIANT_ARCANE,
  VARIANT_BOON, VARIANT_CREATURE_TYPE,
  VARIANT_CRIME,
  VARIANT_PUNISHMENT,
  VARIANT_RACE,
  VARIANT_SUPERNATURAL,
  VARIANT_TRAGEDY,
  VARIANT_WAR,
  VARIANT_WEIRD,
} from './variantIdList'

export const EVENT_ADVENTURE = 'adventure'
export const EVENT_BATTLE_CREATURE_TYPE = 'battle_creature_type'
export const EVENT_BATTLE_PC_RACE = 'battle_pc_race'
export const EVENT_BOON = 'boon'
export const EVENT_CRIME_GUILTY = 'crime_guilty'
export const EVENT_CRIME_PURE = 'crime_pure'
export const EVENT_ENEMY_ADVENTURER_GUILTY = 'enemy_adventurer_guilty'
export const EVENT_ENEMY_ADVENTURER_PURE = 'enemy_adventurer_pure'
export const EVENT_FRIEND_ADVENTURER = 'friend_adventurer'
export const EVENT_JOB = 'job'
export const EVENT_LOVE = 'love'
export const EVENT_MARRY = 'marry'
export const EVENT_MAGICAL = 'magical'
export const EVENT_STRANGE = 'strange'
export const EVENT_SUPERNATURAL = 'supernatural'
export const EVENT_TRAGEDY = 'tragedy'
export const EVENT_VIP = 'vip'

const battleText = `★ПЕРСОНАЖ★ ★сражался★ в битве против`
const loverPersonalityText = `Если Вы получите этот результат более одного раза, Вы можете выбрать вместо него рождение ребёнка. Обсудите с вашим Мастером личность возлюбленного/возлюбленной вашего персонажа.`
const enemyAdventurerPreText = `★ПЕРСОНАЖ★ ★нажил★ себе врага искателя приключений.`

const lifeEventVariantList = [
  {
    name: VARIANT_TRAGEDY,
    id: EVENT_TRAGEDY,
    detailDictIdList: VARIANT_TRAGEDY,
    probabilityWeight: 2,
  },
  {
    name: VARIANT_BOON,
    id: EVENT_BOON,
    detailDictIdList: VARIANT_BOON,
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★влюбился★. ${loverPersonalityText}`,
    id: EVENT_LOVE,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: '★возлюбленный★',
    },
    probabilityWeight: 1,
  },
  {
    name: `★ПЕРСОНАЖ★ ★поженился★. ${loverPersonalityText}`,
    id: EVENT_MARRY,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: '★супруг★',
    },
    probabilityWeight: 1,
  },
  {
    name: `${enemyAdventurerPreText} ★ПЕРСОНАЖ★ ★виноват★ в этой ссоре.`,
    id: EVENT_ENEMY_ADVENTURER_GUILTY,
    characterGenerationParams: {
      occupationId: OCCUPATION_ADVENTURER,
      relationText: 'враг',
    },
    probabilityWeight: 1,
  },
  {
    name: `${enemyAdventurerPreText} ★ПЕРСОНАЖ★ не ★виноват★ в этой ссоре.`,
    id: EVENT_ENEMY_ADVENTURER_PURE,
    characterGenerationParams: {
      occupationId: OCCUPATION_ADVENTURER,
      relationText: 'враг',
    },
    probabilityWeight: 1,
  },
  {
    name: `★ПЕРСОНАЖ★ ★подружился★ с искателем приключений.`,
    id: EVENT_FRIEND_ADVENTURER,
    characterGenerationParams: {
      occupationId: OCCUPATION_ADVENTURER,
      relationText: '★друг★',
    },
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★провёл★ время, работая по профессии, связанной с ★его★ предысторией. Начните игру с дополнительными ★DICE★ зм.`,
    id: EVENT_JOB,
    dice: {
      diceCount: 2,
      diceType: 6,
    },
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★повстречался★ с очень важной персоной.`,
    id: EVENT_VIP,
    characterGenerationParams: {
      relationText: 'встреченная очень важная персона',
    },
    probabilityWeight: 2,
  },
  {
    name: `★ПЕРСОНАЖ★ ★отправился★ в приключение. ${VARIANT_ADVENTURE}`,
    id: EVENT_ADVENTURE,
    detailDictIdList: VARIANT_ADVENTURE,
    probabilityWeight: 2,
  },
  {
    name: VARIANT_SUPERNATURAL,
    id: EVENT_SUPERNATURAL,
    detailDictIdList: VARIANT_SUPERNATURAL,
    probabilityWeight: 2,
  },
  {
    name: `${battleText} ${VARIANT_RACE}. ${VARIANT_WAR}`,
    id: EVENT_BATTLE_PC_RACE,
    detailDictIdList: [
      VARIANT_WAR,
      VARIANT_RACE,
    ],
    probabilityWeight: 1,
  },
  {
    name: `${battleText} ${VARIANT_CREATURE_TYPE}. ${VARIANT_WAR}`,
    id: EVENT_BATTLE_CREATURE_TYPE,
    detailDictIdList: [
      VARIANT_WAR,
      VARIANT_CREATURE_TYPE,
    ],
    probabilityWeight: 1,
  },
  {
    name: `★ПЕРСОНАЖ★ ★совершил★ ${VARIANT_CRIME}. ${VARIANT_PUNISHMENT}.`,
    id: EVENT_CRIME_GUILTY,
    detailDictIdList: [
      VARIANT_CRIME,
      VARIANT_PUNISHMENT,
    ],
    probabilityWeight: 1,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ ошибочно ★обвинён★ в ${VARIANT_CRIME}. ${VARIANT_PUNISHMENT}.`,
    id: EVENT_CRIME_PURE,
    detailDictIdList: [
      VARIANT_CRIME,
      VARIANT_PUNISHMENT,
    ],
    probabilityWeight: 1,
  },
  {
    name: VARIANT_ARCANE,
    id: EVENT_MAGICAL,
    detailDictIdList: VARIANT_ARCANE,
    probabilityWeight: 2,
  },
  {
    name: VARIANT_WEIRD,
    id: EVENT_STRANGE,
    detailDictIdList: VARIANT_WEIRD,
    probabilityWeight: 2,
  },
]

export default lifeEventVariantList
export const lifeEventVariantCollection = listToCollectionById(lifeEventVariantList)
