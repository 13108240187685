import listToCollectionById from '@/utils/listToCollectionById'
import {
  PARAM_CHA,
  PARAM_CON,
  PARAM_DEX,
  PARAM_INT,
  PARAM_WIT,
} from '@/constants/paramList'

export const GIFT_ANIMALS = 'animals'
export const GIFT_CARPENTER = 'carpenter'
export const GIFT_CHILDREN = 'children'
export const GIFT_COOK = 'cook'
export const GIFT_DANCER = 'dancer'
export const GIFT_DART_THROWER = 'dart_thrower'
export const GIFT_DISGUISE = 'disguise'
export const GIFT_DRAWS = 'draws'
export const GIFT_DRINKS = 'drinks'
export const GIFT_GAME = 'game'
export const GIFT_IMPERSONATIONS = 'impersonations'
export const GIFT_JUGGLER = 'juggler'
export const GIFT_LANGUAGES = 'languages'
export const GIFT_LUCKY = 'lucky'
export const GIFT_MEMORY = 'memory'
export const GIFT_MUSICAL = 'musical'
export const GIFT_PAINTS = 'paints'
export const GIFT_PUZZLES = 'puzzles'
export const GIFT_ROCK_SKIPPER = 'rock_skipper'
export const GIFT_SINGS = 'sings'
export const GIFT_THIEVES_CANT = 'thieves_cant'

const giftList = [
  {
    name: `Играет на музыкальном инструменте`,
    id: GIFT_MUSICAL,
    paramBonuses: {
      [PARAM_DEX]: 1,
    },
  },
  {
    name: `Свободно говорит на ★DICE★ языках`,
    id: GIFT_LANGUAGES,
    paramBonuses: {
      [PARAM_INT]: 2,
    },
    dice: {
      diceCount: 2,
      diceType: 4,
    },
  },
  {
    name: `Невероятно удачлив`,
    id: GIFT_LUCKY,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Прекрасная память`,
    id: GIFT_MEMORY,
    paramBonuses: {
      [PARAM_INT]: 1,
    },
  },
  {
    name: `Хорошо ладит с животными`,
    id: GIFT_ANIMALS,
    paramBonuses: {
      [PARAM_WIT]: 1,
    },
  },
  {
    name: `Хорошо ладит с детьми`,
    id: GIFT_CHILDREN,
    paramBonuses: {
      [PARAM_WIT]: 1,
    },
  },
  {
    name: `Прекрасно разгадывает загадки и головоломки`,
    id: GIFT_PUZZLES,
    paramBonuses: {
      [PARAM_INT]: 1,
    },
  },
  {
    name: `Прекрасно играет в одну игру`,
    id: GIFT_GAME,
    paramBonuses: {
      [PARAM_INT]: 1,
    },
  },
  {
    name: `Может перевоплощаться в других`,
    id: GIFT_IMPERSONATIONS,
    paramBonuses: {
      [PARAM_CHA]: 2,
    },
  },
  {
    name: `Красиво пишет`,
    id: GIFT_DRAWS,
    paramBonuses: {
      [PARAM_DEX]: 1,
    },
  },
  {
    name: `Красиво рисует`,
    id: GIFT_PAINTS,
    paramBonuses: {
      [PARAM_DEX]: 1,
    },
  },
  {
    name: `Красиво поёт`,
    id: GIFT_SINGS,
    paramBonuses: {
      [PARAM_CHA]: 1,
    },
  },
  {
    name: `Может перепить любого`,
    id: GIFT_DRINKS,
    paramBonuses: {
      [PARAM_CON]: 2,
    },
  },
  {
    name: `Прекрасный плотник`,
    id: GIFT_CARPENTER,
    paramBonuses: {
      [PARAM_DEX]: 1,
    },
  },
  {
    name: `Отлично готовит`,
    id: GIFT_COOK,
    paramBonuses: {
      [PARAM_WIT]: 1,
    },
  },
  {
    name: `Отлично метает дротики`,
    id: GIFT_DART_THROWER,
    paramBonuses: {
      [PARAM_DEX]: 1,
    },
  },
  {
    name: `Отлично пускает блинчики по воде`,
    id: GIFT_ROCK_SKIPPER,
    paramBonuses: {
      [PARAM_DEX]: 1,
    },
  },
  {
    name: `Отлично жонглирует`,
    id: GIFT_JUGGLER,
    paramBonuses: {
      [PARAM_DEX]: 2,
    },
  },
  {
    name: `Прекрасный актёр и знает, как использовать грим`,
    id: GIFT_DISGUISE,
    paramBonuses: {
      [PARAM_CHA]: 3,
    },
  },
  {
    name: `Умело танцует`,
    id: GIFT_DANCER,
    paramBonuses: {
      [PARAM_DEX]: 2,
    },
  },
  {
    name: `Знает воровской жаргон`,
    id: GIFT_THIEVES_CANT,
    paramBonuses: {
      [PARAM_INT]: 1,
    },
  },
]
  .map(
    gift => ({
      ...gift,
      probabilityWeight: 1,
    }),
  )

export default giftList
export const giftCollection = listToCollectionById(giftList)

