const {CAT_ARTISAN_TOOLS} = require('./../../gearCategoryList')
const {GOD_MORADIN} = require('./../../godIdList')
const {
  LANG_ANY,
  LANG_DWARVISH,
} = require('./../../languageIdList')
const {PC_RACE_DWARF} = require('./../../pcRaceIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  BACKGROUND_CLAN_CRAFTER,
  BACKGROUND_GUILD_ARTISAN,
} = require('./../../backgroundIdList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  SKILL_HISTORY,
  SKILL_INSIGHT,
} = require('./../../skillList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const guild_artisan = require('./guild_artisan')

module.exports = {
  id: BACKGROUND_CLAN_CRAFTER,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Клановый ремесленник',
    [GENDER_FEMALE]: 'Клановая ремесленница',
  },
  nameEn: 'Clan Crafter',
  description: [
    {
      header: 'Клановый ремесленник',
      text: `Крепкий народ хорошо известен своим мастерством и стоимостью своих изделий, и Вы прошли обучение согласно их древним традициям. В течение многих лет Вы трудились подмастерьем у дварфийского мастера, выдерживая долгие часы пренебрежительного и саркастичного отношения для того, чтобы получить те отличные навыки, которыми обладаете сегодня.

Вы, скорее всего, [дварф](PC_RACE:${PC_RACE_DWARF}), но не обязательно — в частности кланам щитовых [дварфов](PC_RACE:${PC_RACE_DWARF}) Севера давно известно, что только горделивые дураки, которые беспокоятся за своё эго больше, чем за своё ремесло, отказывают перспективным ученикам, пусть даже и представителям других рас. Если Вы не [дварф](PC_RACE:${PC_RACE_DWARF}), тем не менее, Вы приняли торжественную клятву никогда не брать ученика в ремесле: передавать навыки любимых детей [Морадина](GOD:${GOD_MORADIN}) могут только [дварфы](PC_RACE:${PC_RACE_DWARF}). Впрочем, вам не составит труда найти дварфийского мастера, готового принять потенциальных учеников, которые пришли по вашей рекомендации.`,
      source: {
        id: SOURCE_SCAG,
        page: 146,
      },
    },
    {
      header: 'Особенность: Уважение крепкого народа',
      text: `Хотя клановые ремесленники везде пользуются уважением, никто не ценит их так, как [дварфы](PC_RACE:${PC_RACE_DWARF}). У них всегда найдётся свободная комната и кровать в любом поселении, где обитают щитовые и золотые [дварфы](PC_RACE:${PC_RACE_DWARF}). Отдельные личности в таких поселениях могут даже спорить друг с другом о том, кто сможет предложить вам (и, возможно, вашим соратникам) лучшие условия и помощь.`,
      source: {
        id: SOURCE_SCAG,
        page: 146,
      },
    },
    {
      header: 'Предлагаемые характеристики',
      text: `Используйте предысторию [ремесленника](BACKGROUND:${BACKGROUND_GUILD_ARTISAN}) как базис для определения ваших черт и мотиваций, изменяя её где необходимо, чтобы она подходила к вашему клановому ремесленнику (например, считайте слова «гильдия» и «клан» взаимозаменяемыми). Ваша связь скорее всего будет с мастером клана, который обучал вас или с какой-нибудь вещью, изготовленной вами. Вашими идеалами могут являться повышение качества ваших работ или сохранение традиций ремесла [дварфов](PC_RACE:${PC_RACE_DWARF}).`,
      source: {
        id: SOURCE_SCAG,
        page: 146,
      },
    },
    ...guild_artisan.description.slice(2),
  ],
  proficiency: {
    tool: {
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_ARTISAN_TOOLS,
        ],
      },
    },
    skill: {
      list: [
        SKILL_HISTORY,
        SKILL_INSIGHT,
      ],
    },
    language: [
      [
        LANG_DWARVISH,
        {
          id: LANG_ANY,
          comment: 'если Вы уже знаете дварфийский',
        },
      ],
    ],
  },
  equipment: `
* Специальное долото, которое Вы ставите на свою продукцию метки своего клана;
* [Дорожная одежда](GEAR:${GEAR_CLOTHES_TRAVELERS});
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 5 зм и самоцветом стоимостью 10 зм.
`,
  source: {
    id: SOURCE_SCAG,
    page: 146,
  },
}
