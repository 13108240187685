import upLetter from '@/utils/upLetter'
import { hairColorCollection } from '@/constants/hairColorList'
import { hairFormCollection } from '@/constants/hairFormList'
import { hairLengthCollection } from '@/constants/hairLengthList'

export default character => {
  const { hair } = character

  if (hair) {
    const {colorId, formId, lengthId} = hair

    const {name: hairColor} = hairColorCollection[colorId]
    const {name: hairForm} = hairFormCollection[formId]
    const {name: hairLength} = hairLengthCollection[lengthId]

    return `${upLetter(hairLength)} ${hairForm} ${hairColor} волосы`
  }

  return ''
}
