import listToCollectionById from '@/utils/listToCollectionById'

import {
  CREATURE_DRAGON_SILVER_ADULT,
  CREATURE_DROW,
  CREATURE_DUERGAR,
  CREATURE_GREEN_HAG,
  CREATURE_KUO_TOA,
  CREATURE_QUAGGOTH,
  CREATURE_SATYR,
} from '@/constants/creatureIdList'
import { OCCUPATION_ADVENTURER } from '@/constants/occupationVariantList'

import {
  VARIANT_CREATURE_TYPE,
} from './variantIdList'
import {
  VARIANT_BEAST,
} from './variantIdList'

export const WEIRD_STUFF_ADVENTURER = 'weird_stuff_adventurer'
export const WEIRD_STUFF_ARCHDEVIL = 'weird_stuff_archdevil'
export const WEIRD_STUFF_ARCHFEY = 'weird_stuff_archfey'
export const WEIRD_STUFF_CULT = 'weird_stuff_cult'
export const WEIRD_STUFF_DEMIGOD = 'weird_stuff_demigod'
export const WEIRD_STUFF_DEMON_LORD = 'weird_stuff_demon_lord'
export const WEIRD_STUFF_DRAGON = 'weird_stuff_dragon'
export const WEIRD_STUFF_DROW = 'weird_stuff_drow'
export const WEIRD_STUFF_DUERGAR = 'weird_stuff_duergar'
export const WEIRD_STUFF_GIANT_FISH = 'weird_stuff_giant_fish'
export const WEIRD_STUFF_HAG = 'weird_stuff_hag'
export const WEIRD_STUFF_KUO_TOA = 'weird_stuff_kuo_toa'
export const WEIRD_STUFF_MIN_STRANGE = 'weird_stuff_min_strange'
export const WEIRD_STUFF_MIND_HEALTH = 'weird_stuff_mind_health'
export const WEIRD_STUFF_MIND_TICK = 'weird_stuff_mind_tick'
export const WEIRD_STUFF_QUAGGOTH = 'weird_stuff_quaggoth'
export const WEIRD_STUFF_SATYR = 'weird_stuff_satyr'
export const WEIRD_STUFF_SILVER_DRAGON = 'weird_stuff_silver_dragon'
export const WEIRD_STUFF_SLAVERY = 'weird_stuff_slavery'
export const WEIRD_STUFF_STONE = 'weird_stuff_stone'
export const WEIRD_STUFF_TITAN = 'weird_stuff_titan'
export const WEIRD_STUFF_TOAD = 'weird_stuff_toad'
export const WEIRD_STUFF_WISH = 'weird_stuff_wish'

const capturedText = `★Попал★ в плен к`
const slaveUnderdarkText = `В Подземье ★он★ ★жил★ как ★раб★, пока ★ему★ не удалось сбежать.`
const noMindText = `★ПЕРСОНАЖ★ ★потерял★ рассудок на ★DICE★ лет, но недавно он вернулся к ★нему★.`
const meetsText = `★ПЕРСОНАЖ★ ★встречался★ с`
const aliveText = `★выжил★ и ★может★ рассказать об этом.`
const slavePreText = `★ПЕРСОНАЖ★ ★попал★ в рабство к`

const weirdStuffList = [
  {
    name: `★Был★ ★обращён★ в ${VARIANT_BEAST} и ★оставался★ в таком облике в течение ★DICE★ декад.`,
    id: WEIRD_STUFF_TOAD,
    detailDictIdList: VARIANT_BEAST,
    dice: {
      diceCount: 1,
      diceType: 4,
    },
    probabilityWeight: 12,
  },
  {
    name: `★Был★ ★обращён★ в камень, и ★оставался★ в виде каменной статуи ★DICE★ декад, пока кто-то не освободил ★его★.`,
    id: WEIRD_STUFF_STONE,
    dice: {
      diceCount: 4,
      diceType: 12,
    },
    probabilityWeight: 12,
  },
  {
    name: `${slavePreText} [карге](CREATURE:${CREATURE_GREEN_HAG}), и ★провёл★ так ★DICE★ лет.`,
    id: WEIRD_STUFF_HAG,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 4,
  },
  {
    name: `${slavePreText} [сатиру](CREATURE:${CREATURE_SATYR}) и ★провёл★ так ★DICE★ лет.`,
    id: WEIRD_STUFF_SATYR,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 4,
  },
  {
    name: `${slavePreText} ${VARIANT_CREATURE_TYPE} и ★провёл★ так ★DICE★ лет.`,
    id: WEIRD_STUFF_SLAVERY,
    detailDictIdList: VARIANT_CREATURE_TYPE,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 4,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★заложником★ дракона ★DICE★ месяца, пока группа искателей приключений не убила его.`,
    id: WEIRD_STUFF_DRAGON,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 12,
  },
  {
    name: `${capturedText} [куо-тоа](CREATURE:${CREATURE_KUO_TOA}). ${slaveUnderdarkText}`,
    id: WEIRD_STUFF_KUO_TOA,
    probabilityWeight: 3,
  },
  {
    name: `${capturedText} [квагготам](CREATURE:${CREATURE_QUAGGOTH}). ${slaveUnderdarkText}`,
    id: WEIRD_STUFF_QUAGGOTH,
    probabilityWeight: 3,
  },
  {
    name: `${capturedText} [дроу](CREATURE:${CREATURE_DROW}). ${slaveUnderdarkText}`,
    id: WEIRD_STUFF_DROW,
    probabilityWeight: 3,
  },
  {
    name: `${capturedText} [двергарам](CREATURE:${CREATURE_DUERGAR}). ${slaveUnderdarkText}`,
    id: WEIRD_STUFF_DUERGAR,
    probabilityWeight: 3,
  },
  {
    name: `★ПЕРСОНАЖ★ ★служил★ могущественному искателю приключений наёмником. Недавно ★ПЕРСОНАЖ★ ★ушёл★ с этой службы.`,
    id: WEIRD_STUFF_ADVENTURER,
    characterGenerationParams: {
      occupationId: OCCUPATION_ADVENTURER,
      relationText: 'бывший работодатель',
    },
    probabilityWeight: 12,
  },
  {
    name: noMindText,
    id: WEIRD_STUFF_MIND_HEALTH,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 4,
  },
  {
    name: `${noMindText} У ★него★ сохранился тик.`,
    id: WEIRD_STUFF_MIND_TICK,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 4,
  },
  {
    name: `${noMindText} У ★него★ сохранилась странность в поведении.`,
    id: WEIRD_STUFF_MIN_STRANGE,
    dice: {
      diceCount: 1,
      diceType: 6,
    },
    probabilityWeight: 4,
  },
  {
    name: `Возлюбленный втайне был [серебряным драконом](CREATURE:${CREATURE_DRAGON_SILVER_ADULT}).`,
    id: WEIRD_STUFF_SILVER_DRAGON,
    characterGenerationParams: {
      isLoveInterest: true,
      relationText: '★возлюбленный★',
    },
    probabilityWeight: 12,
  },
  {
    name: `★Попал★ в плен к культу и почти ★был★ ★принесён★ в жертву на алтаре сущности, которой служили культисты. ★ПЕРСОНАЖ★ ★сбежал★, но ★боится★, что они найдут ★его★.`,
    id: WEIRD_STUFF_CULT,
    probabilityWeight: 12,
  },
  {
    name: `${meetsText} полубогом, ${aliveText}`,
    id: WEIRD_STUFF_DEMIGOD,
    probabilityWeight: 3,
  },
  {
    name: `${meetsText} архидьяволом, ${aliveText}`,
    id: WEIRD_STUFF_ARCHDEVIL,
    probabilityWeight: 3,
  },
  {
    name: `${meetsText} архифеей, ${aliveText}`,
    id: WEIRD_STUFF_ARCHFEY,
    probabilityWeight: 3,
  },
  {
    name: `${meetsText} владыкой демонов, ${aliveText}`,
    id: WEIRD_STUFF_DEMON_LORD,
    probabilityWeight: 3,
  },
  {
    name: `${meetsText} титаном, ${aliveText}`,
    id: WEIRD_STUFF_TITAN,
    probabilityWeight: 3,
  },
  {
    name: `★ПЕРСОНАЖ★ ★был★ ★проглочен★ гигантской рыбой, и ★провёл★ месяц внутри неё, прежде чем ★ему★ удалось выбраться.`,
    id: WEIRD_STUFF_GIANT_FISH,
    probabilityWeight: 12,
  },
  {
    name: `Могущественное существо готово было исполнить ★его★ желание, но ★ПЕРСОНАЖ★ ★прошляпил★ этот шанс на что-то глупое.`,
    id: WEIRD_STUFF_WISH,
    probabilityWeight: 12,
  },
]

export default weirdStuffList
export const weirdStuffCollection = listToCollectionById(weirdStuffList)
