import {
  PC_CLASS_BARBARIAN,
  PC_CLASS_BARD,
  PC_CLASS_CLERIC,
  PC_CLASS_DRUID,
  PC_CLASS_FIGHTER,
  PC_CLASS_MONK,
  PC_CLASS_PALADIN,
  PC_CLASS_RANGER,
  PC_CLASS_ROGUE,
  PC_CLASS_SORCERER,
  PC_CLASS_WARLOCK,
  PC_CLASS_WIZARD,
} from '@/constants/pcClassIdList'

import barbarianChoiceList, {barbarianChoiceCollection} from './barbarianChoiceList'
import bardChoiceList, {bardChoiceCollection} from './bardChoiceList'
import clericChoiceList, {clericChoiceCollection} from './clericChoiceList'
import druidChoiceList, {druidChoiceCollection} from './druidChoiceList'
import fighterChoiceList, {fighterChoiceCollection} from './fighterChoiceList'
import monkChoiceList, {monkChoiceCollection} from './monkChoiceList'
import paladinChoiceList, {paladinChoiceCollection} from './paladinChoiceList'
import rangerChoiceList, {rangerChoiceCollection} from './rangerChoiceList'
import rogueChoiceList, {rogueChoiceCollection} from './rogueChoiceList'
import sorcererChoiceList, {sorcererChoiceCollection} from './sorcererChoiceList'
import warlockChoiceList, {warlockChoiceCollection} from './warlockChoiceList'
import wizardChoiceList, {wizardChoiceCollection} from './wizardChoiceList'

export default {
  [PC_CLASS_BARBARIAN]: {
    list: barbarianChoiceList,
    collection: barbarianChoiceCollection,
  },
  [PC_CLASS_BARD]: {
    list: bardChoiceList,
    collection: bardChoiceCollection,
  },
  [PC_CLASS_CLERIC]: {
    list: clericChoiceList,
    collection: clericChoiceCollection,
  },
  [PC_CLASS_DRUID]: {
    list: druidChoiceList,
    collection: druidChoiceCollection,
  },
  [PC_CLASS_FIGHTER]: {
    list: fighterChoiceList,
    collection: fighterChoiceCollection,
  },
  [PC_CLASS_MONK]: {
    list: monkChoiceList,
    collection: monkChoiceCollection,
  },
  [PC_CLASS_PALADIN]: {
    list: paladinChoiceList,
    collection: paladinChoiceCollection,
  },
  [PC_CLASS_RANGER]: {
    list: rangerChoiceList,
    collection: rangerChoiceCollection,
  },
  [PC_CLASS_ROGUE]: {
    list: rogueChoiceList,
    collection: rogueChoiceCollection,
  },
  [PC_CLASS_SORCERER]: {
    list: sorcererChoiceList,
    collection: sorcererChoiceCollection,
  },
  [PC_CLASS_WARLOCK]: {
    list: warlockChoiceList,
    collection: warlockChoiceCollection,
  },
  [PC_CLASS_WIZARD]: {
    list: wizardChoiceList,
    collection: wizardChoiceCollection,
  },
}
