import listToCollectionById from '@/utils/listToCollectionById'

export const PARENT_HALF_ELF_M_HUMAN_F_ELF = 'parent_half_elf_m_human_f_elf'
export const PARENT_HALF_ELF_F_HUMAN_M_ELF = 'parent_half_elf_f_human_m_elf'
export const PARENT_HALF_ELF_M_ELF_F_HALFELF = 'parent_half_elf_m_elf_f_halfelf'
export const PARENT_HALF_ELF_F_HALFELF_M_ELF = 'parent_half_elf_f_halfelf_m_elf'
export const PARENT_HALF_ELF_M_HALFELF_F_HUMAN = 'parent_half_elf_m_halfelf_f_human'
export const PARENT_HALF_ELF_F_HALFELF_M_HUMAN = 'parent_half_elf_f_halfelf_m_human'
export const PARENT_HALF_ELF_HALFELF_HALFELF = 'parent_half_elf_halfelf_halfelf'

const parentHalfElfList = [
  {
    name: 'Отец был человеком, а мать — эльфийкой.',
    id: PARENT_HALF_ELF_M_HUMAN_F_ELF,
    probabilityWeight: 5,
  },
  {
    name: 'Мать была человеком, а отец — эльфом.',
    id: PARENT_HALF_ELF_F_HUMAN_M_ELF,
    probabilityWeight: 5,
  },
  {
    name: 'Отец был эльфом, а мать — полуэльфийкой.',
    id: PARENT_HALF_ELF_M_ELF_F_HALFELF,
    probabilityWeight: 1,
  },
  {
    name: 'Мать была эльфийкой, а отец — полуэльфом.',
    id: PARENT_HALF_ELF_F_HALFELF_M_ELF,
    probabilityWeight: 1,
  },
  {
    name: 'Отец был полуэльфом, а мать — человеком.',
    id: PARENT_HALF_ELF_M_HALFELF_F_HUMAN,
    probabilityWeight: 1,
  },
  {
    name: 'Мать была полуэльфийкой, а отец — человеком.',
    id: PARENT_HALF_ELF_F_HALFELF_M_HUMAN,
    probabilityWeight: 1,
  },
  {
    name: 'Оба родителя — полуэльфы.',
    id: PARENT_HALF_ELF_HALFELF_HALFELF,
    probabilityWeight: 2,
  },
]

export default parentHalfElfList
export const parentHalfElfCollection = listToCollectionById(parentHalfElfList)
