const {LANG_ANY} = require('./../../languageIdList')
const {PC_RACE_ELF} = require('./../../pcRaceIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  BACKGROUND_OUTLANDER,
  BACKGROUND_UTHGARDT_TRIBE_MEMBER,
} = require('./../../backgroundIdList')
const {
  PC_CLASS_BARBARIAN,
  PC_CLASS_CLERIC,
  PC_CLASS_DRUID,
} = require('./../../pcClassIdList')
const {
  GEAR_CLOTHES_TRAVELERS,
  GEAR_HUNTING_TRAP,
  GEAR_POUCH,
} = require('./../../gearIdList')
const {
  CAT_ARTISAN_TOOLS,
  CAT_MUSIC_TOOLS,
} = require('./../../gearCategoryList')
const {
  SKILL_STEALTH,
  SKILL_ATHLETICS,
} = require('./../../skillList')
const {
  GENDER_FEMALE,
  GENDER_MALE,
} = require('./../../genderList')
const outlander = require('./outlander')

module.exports = {
  id: BACKGROUND_UTHGARDT_TRIBE_MEMBER,
  isReady: true,
  nameByGender: {
    [GENDER_MALE]: 'Утгардтский соплеменник',
    [GENDER_FEMALE]: 'Утгардтская соплеменница',
  },
  nameEn: 'Uthgardt Tribe Member',
  description: [
    {
      header: 'Утгардтский соплеменник',
      text: `Несмотря на то, что Вы лишь недавно прибыли в цивилизованные земли, вам не чужды ценности сотрудничества и взаимодействия для достижения превосходства. Вы освоили эти и другие принципы будучи в Утгардтском племени.

Ваш народ всегда собирался в племена чтобы следовать старым порядкам. Традиции и табу поддерживали утгардтцев сильными, пока другие королевства впадали в хаос и разруху. Но за последние несколько поколений, некоторые группировки в племенах всё больше склоняются к тому, чтобы осесть, заключить мир, торговать и даже строить города. Возможно, именно поэтому Утгар и установил тотемы среди людей, как живые напоминания его мощи. Возможно там были необходимы напоминания о том, кто они и откуда пришли. Избранные Утгара привели племена к старым традициям, и большинство утгардтцев отринули мягкие пути цивилизации.

Вы можете быть светловолосым [варваром](PC_CLASS:${PC_CLASS_BARBARIAN}) Регхеда, обитающим в тени Ледника Регхед, что далеко на Севере, близ Долины Ледяного Ветра. Вы можете также происходить из кочевников рашеми, известных своими дикими берсерками и ведьмами в масках. А может быть Вы происходите из племени лесных [эльфов](PC_RACE:${PC_RACE_ELF}) Чондалвуда, или ненавидящих магию человеческих племён из тропических джунглей Чульта.

Вы могли вырасти в одном из племён, которые решили вести оседлый образ жизни. Но теперь, когда они отринули этот путь, Вы чувствуете себя брошенным. Возможно, Вы пришли из части Утгардта, где придерживаются традиций, но Вы ищете возможности прославить ваше племя, достигнув многого в качестве бывалого путешественника.`,
      source: {
        id: SOURCE_SCAG,
        page: 154,
      },
    },
    {
      header: 'Умение: Утгардтское наследие',
      text: `У вас превосходные знания не только территорий своего племени, но также местности и природных ресурсов остального Севера. Вы настолько хорошо знакомы с дикой природой, что находите в два раза больше еды и воды, чем при обычном поиске пропитания в такой местности.

Кроме того, Вы всегда можете положиться на гостеприимство вашего народа, а также людей, союзных с вашим племенем, часто включающих [друидов](PC_CLASS:${PC_CLASS_DRUID}) круга, племена кочевых [эльфов](PC_RACE:${PC_RACE_ELF}), Арфистов, и [жрецов](PC_CLASS:${PC_CLASS_CLERIC}), посвященных богам Первого Круга.`,
      source: {
        id: SOURCE_SCAG,
        page: 155,
      },
    },
    {
      header: 'Варварские племена Фаэруна',
      text: `Хотя эта секция описывает именно Утгардт, либо эта предыстория, либо предыстория [чужеземца](BACKGROUND:${BACKGROUND_OUTLANDER}) может быть использована для персонажей, чьё происхождение относится к одному из прочих варварских племён Фаэруна.`,
      source: {
        id: SOURCE_SCAG,
        page: 154,
      },
    },
    {
      header: 'Предлагаемые характеристики',
      text: `Используйте таблицы для предыстории [чужеземца](BACKGROUND:${BACKGROUND_OUTLANDER}), как базу для ваших черт характера и мотивов, изменяя их содержание при необходимости, чтобы более точно описать вашу личность в качестве утгардтского соплеменника.

Даже если Вы оставили ваше племя (хотя бы на данный момент), Вы поддерживаете традиции вашего народа. Вы никогда не срубите живое дерево, и не потерпите чтобы такое делали в вашем присутствии. Утгардтские курганы предков — великие холмы, где Утгаром были побеждены духи тотемов и где похоронены герои ваших племён — священные места для вас.

Ваши привязанности несомненно связанны с вашим племенем и некоторыми аспектами утгардтской философии или культуры (возможно даже с самим Утгаром). Ваши идеалы являются вашим личным выбором, который в прочем не далеко ушёл от этики вашего народа и однозначно не противоречит и не подвергает риску основы того, на чём зиждется Утгардт.`,
      source: {
        id: SOURCE_SCAG,
        page: 155,
      },
    },
    ...outlander.description.slice(4),
  ],
  proficiency: {
    tool: {
      toChoose: {
        count: 1,
        limitCatList: [
          CAT_ARTISAN_TOOLS,
          CAT_MUSIC_TOOLS,
        ],
      },
    },
    skill: {
      list: [
        SKILL_STEALTH,
        SKILL_ATHLETICS,
      ],
    },
    language: LANG_ANY,
  },
  equipment: `
* [Охотничий капкан](GEAR:${GEAR_HUNTING_TRAP}),
* Фигурка тотема или татуировки, отмечающие вашу принадлежность к Утгару и вашему племенному тотему,
* [Комплект одежды путешественника](GEAR:${GEAR_CLOTHES_TRAVELERS}),
* [Поясной кошель](GEAR:${GEAR_POUCH}) с 10 зм
`,
  source: {
    id: SOURCE_SCAG,
    page: 154,
  },
}
