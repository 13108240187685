import transformText from '@/utils/transformText'

import pcClassChoiceCollection from '@/components/CharacterGenerator/constants/pcClassChoice'

export default ({ genderId, pcClassChoiceId, pcClassId }) => {
  if (pcClassId && pcClassChoiceId) {
    const {name: pcClassChoiceText} = pcClassChoiceCollection[pcClassId].collection[pcClassChoiceId]

    return transformText(genderId)(pcClassChoiceText)
  }

  return ''
}
