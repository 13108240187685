import React from 'react'

import { VARIANT_FAMILY } from '@/components/CharacterGenerator/constants/variantIdList'

import SimpleCharacterProp from './../SimpleCharacterProp'

export default props => (
  <SimpleCharacterProp
    variantId={VARIANT_FAMILY}
    {...props}
  />
)
