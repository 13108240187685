import creatureTypeList from '@/constants/creatureTypeList'

export {creatureTypeCollection} from '@/constants/creatureTypeList'

export default creatureTypeList
  .filter(({notRealRace}) => !notRealRace)
  .map(({id}) => ({
    id,
    probabilityWeight: 1,
  }))
