const {SOURCE_SCAG} = require('./../../sourceList')
const {BACKGROUND_ACOLYTE} = require('./../../backgroundIdList')
const acolyte = require('./../list/acolyte')

module.exports = [
  {
    header: 'Предлагаемые характеристики',
    text: `Используйте таблицы для предыстории [прислужника](BACKGROUND:${BACKGROUND_ACOLYTE}), как основу для ваших черт характера и мотивов, изменяя их содержание при необходимости, чтобы более точно описать вашу личность в качестве представителя фракций (например, слова «религия» и «фракция» взаимозаменяемы).

Ваша привязанность может быть связанна с другими представителями вашей фракции или места, или объекта, важного для вашей фракции. Идеалы, которым Вы следуете, скорее всего совпадают с принципами и доктриной вашей организации, но могут быть более личными по своей природе.`,
    source: {
      id: SOURCE_SCAG,
      page: 148,
    },
  },
  ...acolyte.description.slice(3),
]
