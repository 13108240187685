import listToCollectionById from '@/utils/listToCollectionById'

export const BARBARIAN_CHOICE_PEOPLE = 'barbarian_choice_people'
export const BARBARIAN_CHOICE_SPIRIT = 'barbarian_choice_spirit'
export const BARBARIAN_CHOICE_LOST_CONTROL = 'barbarian_choice_lost_control'
export const BARBARIAN_CHOICE_JOURNEY = 'barbarian_choice_journey'
export const BARBARIAN_CHOICE_LIGHTING = 'barbarian_choice_lighting'
export const BARBARIAN_CHOICE_FOCUS_RAGE = 'barbarian_choice_focus_rage'

const barbarianChoiceList = [
  {
    name: `Моя верность моему народу поддерживала меня в битве, сделав меня ★могучим★ и ★опасным★.`,
    id: BARBARIAN_CHOICE_PEOPLE,
    probabilityWeight: 1,
  },
  {
    name: `Дух моих предков призвал меня выполнить великую миссию.`,
    id: BARBARIAN_CHOICE_SPIRIT,
    probabilityWeight: 1,
  },
  {
    name: `Однажды в бою я ★потерял★ контроль над собой, как будто кто-то другой управлял моим телом, заставляя его убивать всех врагов, до которых я ★мог★ дотянуться.`,
    id: BARBARIAN_CHOICE_LOST_CONTROL,
    probabilityWeight: 1,
  },
  {
    name: `Я ★отправился★ в паломничество, чтобы найти себя, но вместо этого ★нашёл★ дух зверя, который направляет, защищает и вдохновляет меня.`,
    id: BARBARIAN_CHOICE_JOURNEY,
    probabilityWeight: 1,
  },
  {
    name: `Меня ударило молнией, и я ★выжил★. После этого я ★обнаружил★ в себе новую силу, которая позволила мне оставить позади мою прежнюю немощь.`,
    id: BARBARIAN_CHOICE_LIGHTING,
    probabilityWeight: 1,
  },
  {
    name: `Мою ярость нужно было направить в бой, иначе я ★рисковал★ начать убивать всех без разбора.`,
    id: BARBARIAN_CHOICE_FOCUS_RAGE,
    probabilityWeight: 1,
  },
]

export default barbarianChoiceList
export const barbarianChoiceCollection = listToCollectionById(barbarianChoiceList)
